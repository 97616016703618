<template>
  <main class="main-tag">
    <ProfileInfoDialog v-if="!isProfileComplited && !isSKS" />
    <SportProfessionDialog v-if="!isProfessionSelected && isSKSenv" />
    <div class="spinner-page" v-if="isPageLoading">
      <q-spinner class="spinner" color="primary" size="3em" />
    </div>
    <q-layout v-else class="portal-search-grants">
      <q-drawer id="drawer" v-model="drawerLeft" class="text-white drawer">
        <PortalSideBarSearch class="drawer-sidebar" ref="sideBar" />

        <div
          class="absolute drawer-close-container"
          style="top: 50%; right: -34px; z-index: 200"
        >
          <q-btn
            dense
            class="drawer-close-btn"
            round
            unelevated
            color="accent"
            icon="close"
            @click="disableDrawerLeft()"
          ></q-btn>
        </div>
      </q-drawer>
      <span class="curr-nav row" :class="drawerLeft ? 'blurred-nav' : ''"
        >Mijn overzicht</span
      >

      <div
        class="portal-grants-main"
        :class="drawerLeft ? 'blurred-portal' : ''"
      >
        <div class="column auto-margin">
          <div class="row justify-between">
            <div class="column items-container">
              <span class="heading bold-font">Regelingen</span>
              <PortalSearchBar />

              <div class="cards-section">
                <PortalSchemeCard
                  v-for="grant in grants"
                  :key="grant.id"
                  :title="grant.name"
                  :grant="grant"
                  :id="grant.id"
                  :introduction="grant.introduction"
                />
              </div>
            </div>
            <PortalsNewsColumn />
          </div>

          <div class="pagination pagination-bottom">
            <q-pagination
              class="pagination-item"
              v-model="currentPage"
              :max="maxPages"
              direction-links
              :max-pages="6"
              @click="goToPage(currentPage)"
              color="grey"
              active-color="green-7"
              text-color="white"
              size="13px"
              aria-label="Paginanummering"
            ></q-pagination>
          </div>
        </div>
      </div>
    </q-layout>

    <q-dialog v-model="showSavedInfoDialog" class="saved-items-dialog">
      <q-card>
        <q-card-section class="row items-center">
          <q-icon
            class="info-icon-searchgrants"
            size="28px"
            name="fa-solid fa-circle-info"
          >
          </q-icon>
          <span class="q-ml-sm"> Item is toegevoegd aan je&nbsp; </span
          ><a href="/profile/savedItemsSchemes">favorieten </a>
        </q-card-section>
        <q-card-section style="padding-top: 0; padding-left: 12px">
          <q-toggle
            v-if="isVind"
            v-model="receiveAlertForSavedItems"
            label="Wil je alerts ontvangen voor deze regeling?"
          />
          <p v-else>
            Je ontvangt notificaties bij wijzigingen in deze regeling. Dit kun
            je uitzetten in je favorieten.
          </p>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            flat
            class="orange-btn-filled"
            color="white"
            label="Ok"
            no-caps
            @click="disableShowSavedInfoDialog()"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <PortalSaveScheme />
  </main>
</template>

<script>
import PortalSchemeCard from '../components/portals-components/PortalSchemeCard.vue';
import PortalSearchBar from '../components/portals-components/PortalSearchBar.vue';
import { computed, onBeforeMount, onMounted } from 'vue';
import store from '../store/index';
import GrantsService from '../services/grants.service';
import SchemeUtils from '../utils/schemes';
import PortalsNewsColumn from '../components/portals-components/PortalsNewsColumn.vue';
import PortalSideBarSearch from '../components/portals-components/PortalSideBarSearch.vue';
import UserService from '../services/userprofile.service';
import PortalSaveScheme from '../components/portals-components/PortalSaveScheme.vue';
import ProfileInfoDialog from '../components/portals-components/ProfileInfoDialog.vue';
import SportProfessionDialog from '../components/portals-components/SportProfessionDialog.vue';

export default {
  name: 'PortalSearchGrants',
  components: {
    PortalSchemeCard,
    PortalSearchBar,
    PortalsNewsColumn,
    PortalSideBarSearch,
    PortalSaveScheme,
    ProfileInfoDialog,
    SportProfessionDialog,
  },
  setup() {
    const locale = "";
    onBeforeMount(() => {
      store.commit('portalsData/updateIsPortalPageLoading', true);
    });

    onMounted(async () => {
      await SchemeUtils.searchSchemes(locale);
      addAriaLabels();
    });

    const drawerLeft = computed({
      get: () => store.state.advancedSearch.portalDrawerLeft,
    });

    const disableDrawerLeft = () => {
      store.commit('advancedSearch/updatePortalDrawerLeft', false);
    };

    const grants = computed({
      get: () => store.state.grantItems.grants,
    });

    const currentPage = computed({
      get: () => store.state.advancedSearch.currentPage,
      set: (value) => store.commit('advancedSearch/updateCurrentPage', value),
    });

    const showSavedInfoDialog = computed({
      get: () => store.state.user.showSavedInfoDialog,
    });

    const disableShowSavedInfoDialog = () => {
      store.commit('user/disableShowSavedInfoDialog');
    };

    const receiveAlertForSavedItems = computed({
      get: () => store.state.user.receiveAlertForSavedItems,
      set: (val) => {
        store.commit('user/setReceiveAlertForSavedItems', val);

        UserService.updateFolderItemsAlert(
          savedSchemesFolderItemsIds.value,
          val
        );
      },
    });

    const savedSchemesFolderItemsIds = computed({
      get: () => store.state.user.savedSchemesFolderItemsIds,
    });

    const isProfileComplited = computed({
      get: () => store.state.user.isProfileComplited,
    });

    const isProfessionSelected = computed({
      get: () => store.state.user.isProfessionSelected,
    });
    const complexityRating = computed({
      get: () => store.state.advancedSearch.difficultiesString,
    });

    const timeForApplicationRating = computed({
      get: () => store.state.advancedSearch.applicationTimeString,
    });

    const chanceOfSuccessRating = computed({
      get: () => store.state.advancedSearch.chancesString,
    });

    const goToPage = async (currentPage) => {
      store.commit('advancedSearch/updateCurrentPage', currentPage);
      store.commit('advancedFilters/createTermsString');
      store.commit('advancedFilters/createSMEString');
      store.commit('advancedFilters/createScopesString');
      await GrantsService.getResulstsByPage(
        locale,
        currentPage - 1,
        searchText,
        statuses,
        audiences,
        locations,
        //This is just hidden for now, don't remove it:
        //industries,
        purposes,
        schemeTypes,
        projectTypes,
        smeString,
        applicationTermsString,
        minBudget,
        maxBudget,
        scopesString,
        orgsIncludeString,
        orgsExcludeString,
        startDate,
        endDate,
        selectedSort,
        complexityRating,
        chanceOfSuccessRating,
        timeForApplicationRating
      );
      window.scrollTo(0, 0);
    };

    const searchText = computed({
      get: () => store.state.advancedSearch.searchText,
    });

    const statuses = computed({
      get: () => sessionStorage.getItem('statusesString'),
    });

    const audiences = computed({
      get: () =>
        store.state.advancedSearch.selectedAudiences != ''
          ? store.state.advancedSearch.selectedAudiences
          : sessionStorage.getItem('selectedAudiences'),
    });
    const locations = computed({
      get: () =>
        store.state.advancedSearch.selectedLocations != ''
          ? store.state.advancedSearch.selectedLocations
          : sessionStorage.getItem('selectedLocations'),
    });

    const purposes = computed({
      get: () =>
        store.state.advancedSearch.selectedPurposes != ''
          ? store.state.advancedSearch.selectedPurposes
          : sessionStorage.getItem('selectedPurposes'),
    });
    const schemeTypes = computed({
      get: () =>
        store.state.advancedSearch.selectedSchemeTypes != ''
          ? store.state.advancedSearch.selectedSchemeTypes
          : sessionStorage.getItem('selectedSchemeTypes'),
    });
    const projectTypes = computed({
      get: () => store.state.advancedSearch.selectedProjectTypes,
    });

    const smeString = computed({
      get: () => store.state.advancedFilters.smeString,
    });
    const applicationTermsString = computed({
      get: () => store.state.advancedFilters.termsString,
    });
    const minBudget = computed({
      get: () =>
        store.state.advancedSearch.minBudget != null
          ? store.state.advancedSearch.minBudget
          : Number(sessionStorage.getItem('minBudget')),
    });

    const maxBudget = computed({
      get: () =>
        store.state.advancedSearch.maxBudget != null
          ? store.state.advancedSearch.maxBudget
          : Number(sessionStorage.getItem('maxBudget')),
    });

    const scopesString = computed({
      get: () => store.state.advancedFilters.scopesString,
    });

    const startDate = computed({
      get: () =>
        store.state.advancedSearch.startDate != null &&
        store.state.advancedSearch.startDate != ''
          ? store.state.advancedSearch.startDate
          : JSON.parse(sessionStorage.getItem('startDate')),
    });

    const endDate = computed({
      get: () =>
        store.state.advancedSearch.endDate != null &&
        store.state.advancedSearch.endDate != ''
          ? store.state.advancedSearch.endDate
          : JSON.parse(sessionStorage.getItem('endDate')),
    });
    const orgsIncludeString = computed({
      get: () =>
        store.state.advancedSearch.organisationsIncludeString != ''
          ? store.state.advancedSearch.organisationsIncludeString
          : sessionStorage.getItem('organisationsIncludeString'),
    });

    const orgsExcludeString = computed({
      get: () =>
        store.state.advancedSearch.organisationsExcludeString != ''
          ? store.state.advancedSearch.organisationsExcludeString
          : sessionStorage.getItem('organisationsExcludeString'),
    });

    const selectedSort = computed({
      get: () => store.state.advancedSearch.selectedSort,
      set: async (value) => {
        store.commit('advancedSearch/updateSelectedSort', value);
        await SchemeUtils.searchSchemes(locale);
      },
    });
    const isSKSenv = computed({
      get: () => store.state.auth.env === 30,
    });
    const maxPages = computed({
      get: () => store.state.advancedSearch.pageCount,
    });

    const themeType = computed({
      get: () => store.state.user.user.themeType,
    });
    const searchInterest = computed({
      get: () => store.state.user.user.searchInterest,
    });

    const portalOrganisation = computed({
      get: () => store.state.user.user.person.portalOrganisation,
    });
    const isPageLoading = computed({
      get: () => store.state.portalsData.isPortalsPageLoading,
    });

    const isSideBarLoading = computed({
      get: () => store.state.portalsData.isPortalsSideBarLoading,
    });

    const disableDrawerOnClickOutside = computed({
      get: () => store.state.portalsData.disableDrawerOnOutsideClick,
    });
    window.addEventListener('click', function (e) {
      if (
        drawerLeft.value &&
        !document.getElementById('drawer').contains(e.target) &&
        !document.getElementById('enableDrawerBtn').contains(e.target)
      ) {
        disableDrawerLeft();
      }
    });

    const addAriaLabels = () => {
      const buttons = document.querySelectorAll('.q-pagination .q-btn');

      buttons.forEach((button, index) => {
        const ariaLabel = `Paginas  ${index + 1}`;
        button.setAttribute('aria-label', ariaLabel);
      });
    };

    return {
      locale,
      isSKSenv,
      disableDrawerOnClickOutside,
      isPageLoading,
      isSideBarLoading,
      portalOrganisation,
      themeType,
      isProfileComplited,
      searchInterest,
      maxPages,
      goToPage,
      currentPage,
      disableDrawerLeft,
      disableShowSavedInfoDialog,
      drawerLeft,
      showSavedInfoDialog,
      receiveAlertForSavedItems,
      savedSchemesFolderItemsIds,
      grants,
      addAriaLabels,
      isProfessionSelected,
    };
  },
};
</script>
