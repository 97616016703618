const state = () => ({
  newsTitle: 'Een ogenblik aub',
  body: '',
  date: new Date(),

  onzeNewsList: [],
});

const getters = {};

const actions = {};

const mutations = {
  updatePublicNewsItem(state, data) {
    state.newsTitle = data.title;
    state.body = data.body;
    state.date = data.date;
    state.introduction = data.introduction;
  },

  updateOnzeNewsList(state, val) {
    state.onzeNewsList = val.items;
    state.onzeNewsListTitle = val.title;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
