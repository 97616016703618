<template>
  <div class="schemes-list-container">
    <span class="container-heading bold-font row">{{ title }}</span>

    <div class="homepage-schemes q-mb-md">
      <div class="schemes-list-container">
        <q-list>
          <q-item v-for="scheme in schemesProp" :key="scheme.id">
            <q-item-section
              class="list-section"
              v-if="title == 'Komende deadlines'"
            >
              <q-item-label>
                <router-link
                  style="text-decoration: none"
                  :to="{ name: 'GrantDetails', params: { grantId: scheme.id } }"
                  v-bind:target="openDetailsInNewTab ? '_blank' : null"
                >
                  <span class="title bold-font"> {{ scheme.name }} </span>
                </router-link>
              </q-item-label>

              <div class="row-class date">
                <span
                  v-if="
                    title == 'Komende deadlines' && scheme.currentDeadlineName
                  "
                  class="row deadline-name"
                >
                  {{ scheme.currentDeadlineName }}, &nbsp;
                </span>

                <span class="deadline-name">{{
                  formatDate(scheme.currentDeadline)
                }}</span>
              </div>

              <q-separator class="separator"></q-separator>
            </q-item-section>

            <q-item-section class="list-section" v-else>
              <q-item-label>
                <router-link
                  style="text-decoration: none"
                  :to="{ name: 'GrantDetails', params: { grantId: scheme.id } }"
                  v-bind:target="openDetailsInNewTab ? '_blank' : null"
                >
                  <span class="title bold-font"> {{ scheme.name }} </span>
                </router-link>
              </q-item-label>
              <div>
                <q-item-section avatar class="date">
                  <q-item-label caption v-if="title == 'Nieuwste regelingen'"
                    >Gepubliceerd op:
                    {{ formatDate(scheme.createdDate) }}</q-item-label
                  >
                </q-item-section>
              </div>

              <q-separator class="separator"></q-separator>
            </q-item-section>
          </q-item>
        </q-list>
      </div>

      <div class="more-btn-container">
        <router-link
          v-if="title == 'Komende deadlines'"
          style="text-decoration: none"
          :to="{ name: 'DeadlinesSchemes' }"
          v-bind:target="openDetailsInNewTab ? '_blank' : null"
          class="q-mt-md"
        >
          <q-btn class="see-more-btn" no-caps>
            {{ t('my_dashboard.view_more') }}
            <q-icon name="east" size="18px" class="q-ml-sm"></q-icon>
          </q-btn>
        </router-link>

        <router-link
          v-else
          style="text-decoration: none"
          :to="{ name: 'NewestSchemes' }"
          v-bind:target="openDetailsInNewTab ? '_blank' : null"
          class="q-mt-md"
        >
          <q-btn class="see-more-btn" no-caps>
            {{ t('my_dashboard.view_more') }}
            <q-icon name="east" size="18px" class="q-ml-sm"></q-icon>
          </q-btn>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import moment from 'moment';
import store from '../../store/index';
export default {
  props: {
    schemesProp: Array,
    title: String,
  },
  setup(props) {
    const schemes = ref(props.schemesProp);

    const formatDate = (date) => {
      return moment(date).format('DD-MM-YYYY');
    };

    const openDetailsInNewTab = computed({
      get: () => store.state.user.openDetailsInNewTab,
    });

    return {
      formatDate,
      schemes,
      openDetailsInNewTab,
    };
  },
};
</script>
