<script>
import { onMounted } from 'vue';
import store from '../store/index.js';
import { useRouter } from 'vue-router'

export default {
  name: 'OidcAuth',
  setup() {
    const router = useRouter();

    onMounted(async () => {
      store.dispatch('auth/logout');
      router.push('/login');
    });

    return {
      router,
    };
  },
};
</script>
