const state = () => ({
  organisations: [{}],
  organisationsWithBasicInfo: [{}] //only organasation id and name
});

const getters = {};

const actions = {};

const mutations = {
  updateOrganisations(state, val) {
    state.organisations = val;
  },
  updateOrganisationsWithBasicInfo(state, val) {
    state.organisationsWithBasicInfo = val;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
