import moment from "moment";
import SchemeUtils from "../../utils/schemes";
import store from "../index";

const state = () => ({
  grants: [],
  selectedGrant: [{}],
  shortIntro: "",
  shareGrantItem: false,
  shareGrantsPage: false,
  grantToShare: [],
  schemeToDownload: [],
  schemeCustomers: [],
  grantsPageToShare: [],
  folderToDownload: [],
  nameOfDownloadFolder: "",
  selectedResults: [],
  selectedResultsCount: 0,
  selectedGrantMax: 0,
  selectedGrantMin: 0,
  newsInLastYear: [],
  olderNews: [],
  hasOlderNews: false,
  activeOpenings: [],
  pastOpenings: [],
  futureOpenings: [],
  isSearchPageLoading: true,
  isSideBarLoading: true,
  numberOfResultsOnPage: 0,
  downloadScheme: false,
  downloadSchemeReport: false,
  downloadFolderReport: false,
  downloadSchemesPage: false,
  areGrantDetailsLoading: true,
  hasModalError: false,
  modalErrorMessage: "",
  newsInLastYearReverse: [],
  newestGrants: [],
  deadlineGrants: [],
  significantChangesGrants: [],
  activeLength: 0,
  futureLength: 0,
  pastLength: 0,
  fundingCopy: [],
  administratingCopy: [],
  advisingCopy: [],
  recipientCopy: [],
  organisationsList: [],
  sortedLinksAndDocs: [],
  importantLinks: [],
  isDisplayDownloadMessage: false,
  labelForSaveModal: "Items opslaan",
  selectedTransBody: "",
  selectedTransCriteria: "",
  selectedTransApplicationProcedure: "",
  selectedTransIntroduction: "",
  selectedTransFinancialinformation: "",
  selectedTransName: "",
  translateScheme: false,
  selectedTransApplicationInformation: "",
  selectAllSchemesCheckBox: false,
});

const getters = {
  getAllGrants: (state) => {
    return state.grants;
  },

  getNumberOfRecords: (state) => {
    return state.grants.totalRecords;
  },
};

const actions = {};

const mutations = {
  updateSelectAllSchemesCheckbox(state, val) {
    state.selectAllSchemesCheckBox = val;
  },
  updateLabelForSaveModal(state, val) {
    state.labelForSaveModal =
      state.selectedResultsCount > 1 ? "Items opslaan" : val;
  },
  async updateisDisplayDownloadMessage(state) {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    state.isDisplayDownloadMessage = true;
    await delay(5000);
    state.isDisplayDownloadMessage = false;
  },
  updateNewestGrants(state, val) {
    state.newestGrants = val;
  },

  updateDeadlineGrants(state, val) {
    state.deadlineGrants = val;
  },

  updateSignificantChangesGrants(state, val) {
    state.significantChangesGrants = val;
  },
  enableDownloadScheme(state) {
    state.downloadScheme = true;
  },
  enableDownloadFolderReport(state, value) {
    var id = parseInt(value.id);
    state.nameOfDownloadFolder = value.name;
    state.folderToDownload = id;
    state.downloadFolderReport = true;
  },
  nameOfDownloadFolder(state, value) {
    state.nameOfDownloadFolder = value;
  },
  disableDownloadFolderReport(state) {
    state.downloadFolderReport = false;
  },
  enableDownloadSchemeReport(state, index) {
    var id = parseInt(index);
    state.downloadSchemeReport = true;
    state.schemeToDownload = id;
  },
  disableDownloadSchemeReport(state) {
    state.downloadSchemeReport = false;
  },
  disableDownloadScheme(state) {
    state.downloadScheme = false;
  },
  enableDownloadSchemesPage(state) {
    state.downloadSchemesPage = true;
  },
  disableDownloadSchemesPage(state) {
    state.downloadSchemesPage = false;
  },

  addSelected(state, index) {
    var id = parseInt(index);
    const wasSelected =
      state.selectedResults.filter((r) => r.schemeId == id).length > 0;

    if (!wasSelected) {
      state.selectedResults.push({
        schemeId: parseInt(id),
      });
    } else {
      const idx = state.selectedResults.map((r) => r.schemeId).indexOf(id);
      state.selectedResults.splice(idx, 1);
    }
    state.selectedResultsCount = state.selectedResults.length;
    store.commit("grantItems/updateLabelForSaveModal");
  },

  enableGrantDetailsLoading(state) {
    state.areGrantDetailsLoading = true;
  },

  disableGrantDetailsLoading(state) {
    state.areGrantDetailsLoading = false;
  },

  enableSearchPageLoading(state) {
    state.isSearchPageLoading = true;
  },
  disableSearchPageLoading(state) {
    state.isSearchPageLoading = false;
  },
  enableSideBarLoading(state) {
    state.isSideBarLoading = true;
  },
  disableSideBarLoading(state) {
    state.isSideBarLoading = false;
  },

  clearSelectedResults(state) {
    state.selectedResults.length = 0;
    state.selectedResultsCount = 0;
    state.selectAllSchemesCheckBox = false;
    store.commit("grantItems/updateLabelForSaveModal");
  },

  selectAllFromPage(state) {
    for (let i = 0; i < state.grants.length; i++) {
      const wasSelected =
        state.selectedResults.filter((r) => r.schemeId == state.grants[i].id)
          .length > 0;
      if (!wasSelected)
        state.selectedResults.push({
          schemeId: parseInt(state.grants[i].id),
        });
    }
    state.selectedResultsCount = state.selectedResults.length;
    store.commit("grantItems/updateLabelForSaveModal");
  },

  deselectAllFromPage(state) {
    for (let i = 0; i < state.grants.length; i++) {
      const wasSelected =
        state.selectedResults.filter((r) => r.schemeId == state.grants[i].id)
          .length > 0;
      if (wasSelected) {
        const idx = state.selectedResults
          .map((r) => r.schemeId)
          .indexOf(parseInt(state.grants[i].id));

        state.selectedResults.splice(idx, 1);
      }
    }
    state.selectedResultsCount = state.selectedResults.length;
    store.commit("grantItems/updateLabelForSaveModal");
    console.log("state is ", state.selectedResults);
  },

  enableShareGrant(state, grant) {
    state.shareGrantItem = true;
    state.grantToShare = grant;
  },

  disableShareGrantItem(state, val) {
    state.shareGrantItem = val;
  },

  enableShareGrantsPage(state) {
    state.shareGrantsPage = true;
    state.grantsPageToShare = state.grants;
  },

  disableShareGrantsPage(state, val) {
    state.shareGrantsPage = val;
  },

  updateGrants(state, grantsData) {
    state.grants = grantsData.results;
  },

  updateSchemeCustomers(state, val) {
    state.schemeCustomers = val;
  },
  removeElementFromAdvisingCopy(state, id) {
    for (let i = 0; i < state.advisingCopy.length; i++) {
      if (state.advisingCopy[i].id == id) state.advisingCopy.splice(i, 1);
    }
  },
  removeElementFromARecipientCopy(state, id) {
    for (let i = 0; i < state.recipientCopy.length; i++) {
      if (state.recipientCopy[i].id == id) state.recipientCopy.splice(i, 1);
    }
  },
  removeElementFromAdministratingCopy(state, id) {
    for (let i = 0; i < state.administratingCopy.length; i++) {
      if (state.administratingCopy[i].id == id)
        state.administratingCopy.splice(i, 1);
    }
  },

  updateOrgsList(state, val) {
    state.organisationsList = val;
  },

  updateSelectedGrantLinksAndDocs(state, val) {
    var combinedArray = [...val.schemeLinks, ...val.schemeDocuments];
    combinedArray.sort((a, b) => (a.documentType > b.documentType ? 1 : -1));
    state.sortedLinksAndDocs = combinedArray;
  },
  updateSelectedGrant(state, val) {
    state.selectedGrant = val;
    if (val.budget && val.budget.length > 130)
      state.selectedGrant.shortBudget = val.budget.substring(0, 130);
    store.commit("grantItems/updateSelectedGrantLinksAndDocs", val);
    state.fundingCopy = val.fundingOrganisations;
    state.advisingCopy = val.advisingOrganisations;
    state.recipientCopy = val.recipientOrganisations;
    state.administratingCopy = val.administratingOrganisations;
    state.selectedGrantMax = SchemeUtils.formatEuro(val.maxAmount);
    state.selectedGrantMin = SchemeUtils.formatEuro(val.minAmount);

    if (state.newsInLastYear.length > 0) state.newsInLastYear = [];
    if (state.olderNews.length > 0) state.olderNews = [];

    for (let i = 0; i < val.news.length; i++) {
      let isInLastYear = false;
      let isOlder = false;
      var today = new Date();
      var dateLimitLastYear = new Date().setDate(today.getDate() - 365);
      var newsDate = moment(val.news[i].date).format("YYYY-MM-DD");
      var limit = moment(dateLimitLastYear).format("YYYY-MM-DD");

      if (moment(newsDate).isSameOrAfter(moment(limit))) isInLastYear = true;
      else {
        isOlder = true;
        state.hasOlderNews = true;
      }
      if (isInLastYear) state.newsInLastYear.push(val.news[i]);
      else if (isOlder) state.olderNews.push(val.news[i]);
    }

    state.activeOpenings = [];
    state.futureOpenings = [];
    state.pastOpenings = [];

    for (let j = 0; j < val.children.length; j++) {
      var now = new Date();
      var callStartDate = moment(val.children[j].callStart).format(
        "YYYY-MM-DD"
      );
      var callEndDate = moment(val.children[j].callEnd).format("YYYY-MM-DD");

      if (moment(now).isBefore(callStartDate, "day"))
        state.futureOpenings.push(val.children[j]);
      else if (moment(now).isAfter(callEndDate, "day"))
        state.pastOpenings.push(val.children[j]);
      else state.activeOpenings.push(val.children[j]);
    }

    // sort descending related calls and application periods by call start date
    state.pastOpenings.sort((left, right) =>
      moment.utc(left.callEnd).diff(moment.utc(right.callEnd))
    );
    state.pastOpenings.reverse();

    state.activeLength = "Actueel (" + state.activeOpenings.length + ")";
    state.pastLength = "Verleden (" + state.pastOpenings.length + ")";
    state.futureLength = "Toekomstig (" + state.futureOpenings.length + ")";

    state.newsInLastYear.reverse();
    state.olderNews.reverse();
    state.selectedTransBody = val.transBody;
    state.selectedTransApplicationProcedure = val.transApplicationProcedure;
    state.selectedTransIntroduction = val.transIntroduction;
    state.selectedTransCriteria = val.transCriteria;
    state.selectedTransFinancialinformation = val.transFinancialinformation;
    state.selectedTransName = val.transName;
    state.selectedTransApplicationInformation = val.transApplicationInformation;
  },
  updateTranslateScheme(state, val) {
    state.translateScheme = val;
  },
  enableModalError(state) {
    state.hasModalError = true;
  },
  disableModalError(state) {
    state.hasModalError = false;
  },
  updateModalError(state, message) {
    state.modalErrorMessage = message;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
