<template>
  <div class="rating-page">
    <ErrorModal />
    <div id="q-app" style="min-height: 75vh">
      <div class="q-pa-md">
        <div
          class="column"
          style="margin-top: 120px;"
        >
          <p class="title">Wil je nog gebruik maken van je Vindsubsidiesaccount?</p>
          <div class="row checkboxes">
            <q-radio v-model="deactivated" val="Yes" :label="t('search_schemes.yes')" />
            <q-radio v-model="deactivated" val="No" :label="t('search_schemes.no')" />
          </div>
          <div class="suggestion-textbox" v-show="deactivated == 'No'">
            <p class="title">{{ t('deactivation_form.explanation') }}</p>
            <q-input class="input-suggestion-field" 
            style="display:inline-flex;"
             outlined 
             v-model="suggestionText" 
             :rules="requiredFieldRule"/>
          </div>
          <q-btn
            flat
            class="orange-btn-filled submitBtn"
            color="white"
            :label="t('account_management.send')"
            @click="submitResponse()"
            no-caps
          />
        </div>
      </div>
    </div>
  </div>
  <q-dialog v-model="showInfoDialog" class="info-dialog">
    <q-card>
      <q-card-section style="max-width: 350px;">
        <h5 class="title">{{ popupText }}</h5>
      </q-card-section>
      <q-card-actions align="center">
        <q-btn
          flat
          class="orange-btn-filled"
          color="white"
          :label="t('common.ok')"
          no-caps
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import SurveyAnswersService from "../../services/surveyAnswers.service";
import ErrorModal from "../../components/userProfile-components/ErrorModal.vue";
import { useI18n } from 'vue-i18n';

export default {
  name: "DeactivationForm",
  components: {
    ErrorModal,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const token = ref("");
    const isSuccess = ref(false);
    const showInfoDialog = ref(false);
    const suggestionText = ref("");
    const popupText = ref(t('account_management.recommendation_thanks'));
    const deactivated = ref(false);

    onMounted(async () => {
      token.value = route.params.token;
    });

    const submitResponse = async () => {
      if (deactivated.value == "No") {
        popupText.value = t('deactivation_form.popup_text')
        var s = await SurveyAnswersService.deactivationFromSurvey(
          suggestionText,
          token
        );
        if (s) {
          isSuccess.value = true;
          showInfoDialog.value = true;
        }
      } else {
        isSuccess.value = true;
        showInfoDialog.value = true;
      }
    };

    return {
      t,
      token,
      isSuccess,
      popupText,
      route,
      showInfoDialog,
      suggestionText,
      submitResponse,
      deactivated,
      requiredFieldRule: [
        (val) => (val && val.length > 0) || t('common.required_field'),
      ],
    };
  },
};
</script>
