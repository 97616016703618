import store from '../index';

const state = () => ({
  pageCount: 0,
  currentPage: 1,
  searchText: '',
  dateFrom: '',
  resultsCount: 0,
  dateTo: '',
  selectedAudiences: '',
  selectedLocations: '',
  //This is just hidden for now, don't remove it:
  // selectedIndustries: '',
  selectedPurposes: '',
  selectedSchemeTypes: '',
  selectedProjectTypes: '',
  organisationsInclude: [],
  organisationsExclude: [],
  organisationsIncludeString: '',
  organisationsExcludeString: '',
  locationsSelectArray: [],
  daysRange: {
    from: '',
    to: '',
  },
  selectedSortNews: '',
  isTableView: false,
});

const getters = {};

const actions = {};

const mutations = {
  changeIsTableView(state, val) {
    state.isTableView = val;
  },

  updateSelectedSortNews(state, value) {
    state.selectedSortNews = value;
  },

  updateDaysRange(state, val) {
    state.daysRange.from = val.from;
    state.daysRange.to = val.to;
    state.dateFrom = val.from;
    state.dateTo = val.to;

    sessionStorage.setItem('newsDateFrom', JSON.stringify(val.from));
    sessionStorage.setItem('newsDateTo', JSON.stringify(val.to));
    sessionStorage.setItem('newsDaysRange', JSON.stringify(val));
  },

  updateDateFrom(state, val) {
    state.dateFrom = val;
    sessionStorage.setItem('newsDateFrom', JSON.stringify(val));
  },
  updateDateTo(state, val) {
    state.dateTo = val;
    sessionStorage.setItem('newsDateTo', JSON.stringify(val));
  },
  updateOrganisationsExclude(state, val) {
    state.organisationsExclude = val;
    sessionStorage.setItem('organisationsExclude', JSON.stringify(val));
  },

  updateOrganisationsInclude(state, val) {
    state.organisationsInclude = val;
    sessionStorage.setItem('organisationsInclude', JSON.stringify(val));
  },

  createOrganisationsIncludeString(state) {
    if (!state.organisationsInclude || state.organisationsInclude == null) {
      state.organisationsIncludeString = '';
      return;
    }
    state.organisationsIncludeString = '';
    if (state.organisationsInclude.length > 0) {
      for (let i = 0; i < state.organisationsInclude.length; i++) {
        state.organisationsIncludeString +=
          state.organisationsInclude[i].id + ',';
      }
      if (
        state.organisationsIncludeString[
          state.organisationsIncludeString.length - 1
        ] == ','
      )
        state.organisationsIncludeString =
          state.organisationsIncludeString.substring(
            0,
            state.organisationsIncludeString.length - 1
          );
    }
    sessionStorage.setItem(
      'organisationsIncludeString',
      state.organisationsIncludeString
    );
  },

  createOrganisationsExcludeString(state) {
    if (!state.organisationsExclude || state.organisationsExclude == null) {
      state.organisationsExcludeString = '';
      return;
    }
    state.organisationsExcludeString = '';

    if (state.organisationsExclude.length > 0) {
      for (let i = 0; i < state.organisationsExclude.length; i++) {
        state.organisationsExcludeString +=
          state.organisationsExclude[i].id + ',';
      }
      if (
        state.organisationsExcludeString[
          state.organisationsExcludeString.length - 1
        ] == ','
      )
        state.organisationsExcludeString =
          state.organisationsExcludeString.substring(
            0,
            state.organisationsExcludeString.length - 1
          );
    }
    sessionStorage.setItem(
      'organisationsExcludeString',
      state.organisationsExcludeString
    );
  },

  updateSelectedAudiences(state, val) {
    state.selectedAudiences = '';
    var audiences = store.state.advancedSearch.audience.options;
    var toAdd = '';
    if (val && val.length > 0) {
      for (let i = 0; i < audiences.length; i++) {
        if (audiences[i].children && audiences[i].children.length > 0) {
          var hasAllChildrenTicked = true;
          for (let j = 0; j < audiences[i].children.length; j++) {
            if (
              audiences[i].children[j].children &&
              audiences[i].children[j].children.length > 0
            ) {
              var hasAllSubChildrenTicked = true;
              for (
                let k = 0;
                k < audiences[i].children[j].children.length;
                k++
              ) {
                if (!val.includes(audiences[i].children[j].children[k].id)) {
                  hasAllSubChildrenTicked = false;
                  hasAllChildrenTicked = false;

                  let condition = true;
                  while (condition) {
                    let idx = val.indexOf(audiences[i].children[j].id);
                    if (idx > -1) val.splice(idx, 1);
                    else break;
                  }
                  state.selectedAudiences = state.selectedAudiences.replace(
                    audiences[i].children[j].id.toString(),
                    ''
                  );
                  state.selectedAudiences = state.selectedAudiences.replace(
                    audiences[i].children[j].id,
                    ''
                  );

                  break;
                }
              }
              if (
                hasAllSubChildrenTicked &&
                audiences[i].children[j].children &&
                audiences[i].children[j].children.length > 0
              ) {
                toAdd += ',' + audiences[i].children[j].id;
                val.push(audiences[i].children[j].id);
              }
            }

            if (!val.includes(audiences[i].children[j].id)) {
              hasAllChildrenTicked = false;
              continue;
            }
          }
          if (hasAllChildrenTicked) {
            toAdd += ',' + audiences[i].id;
          }
        }
      }
    }

    if (val)
      for (let i = 0; i < val.length; i++) {
        if (i != val.length - 1) state.selectedAudiences += val[i] + ',';
        else state.selectedAudiences += val[i];
      }
    if (toAdd != '' && toAdd.length > 0) state.selectedAudiences += toAdd;

    var uniqueArr = state.selectedAudiences;
    uniqueArr = Array.from(new Set(uniqueArr.split(','))).toString();
    state.selectedAudiences = uniqueArr;

    if (state.selectedAudiences[state.selectedAudiences.length - 1] == ',')
      state.selectedAudiences = state.selectedAudiences.substring(
        0,
        state.selectedAudiences.length - 1
      );

    sessionStorage.setItem('selectedAudiences', state.selectedAudiences);
  },
  updateSelectedLocations(state, val) {
    state.locationsSelectArray = val;
    sessionStorage.setItem('locationsSelectArray', JSON.stringify(val));
    store.commit('newsSearch/updateSelectedLocationsString');
  },

  updateSelectedLocationsString(state) {
    state.selectedLocations = '';

    if (state.locationsSelectArray && state.locationsSelectArray.length > 0) {
      for (let i = 0; i < state.locationsSelectArray.length; i++) {
        state.selectedLocations += state.locationsSelectArray[i].id + ',';
      }
      if (state.selectedLocations[state.selectedLocations.length - 1] == ',')
        state.selectedLocations = state.selectedLocations.substring(
          0,
          state.selectedLocations.length - 1
        );
    }
    sessionStorage.setItem('selectedLocations', state.selectedLocations);
  },

  updateSelectedPurposes(state, val) {
    state.selectedPurposes = '';
    var purposes = store.state.advancedSearch.purposes.options;
    var toAdd = '';
    if (val.length > 0) {
      for (let i = 0; i < purposes.length; i++) {
        if (purposes[i].children && purposes[i].children.length > 0) {
          var hasAllChildrenTicked = true;
          for (let j = 0; j < purposes[i].children.length; j++) {
            if (
              purposes[i].children[j].children &&
              purposes[i].children[j].children.length > 0
            ) {
              var hasAllSubChildrenTicked = true;
              for (
                let k = 0;
                k < purposes[i].children[j].children.length;
                k++
              ) {
                if (!val.includes(purposes[i].children[j].children[k].id)) {
                  hasAllSubChildrenTicked = false;
                  hasAllChildrenTicked = false;

                  let condition = true;
                  while (condition) {
                    let idx = val.indexOf(purposes[i].children[j].id);
                    if (idx > -1) val.splice(idx, 1);
                    else break;
                  }
                  state.selectedPurposes = state.selectedPurposes.replace(
                    purposes[i].children[j].id.toString(),
                    ''
                  );
                  state.selectedPurposes = state.selectedPurposes.replace(
                    purposes[i].children[j].id,
                    ''
                  );

                  break;
                }
              }
              if (
                hasAllSubChildrenTicked &&
                purposes[i].children[j].children &&
                purposes[i].children[j].children.length > 0
              ) {
                toAdd += ',' + purposes[i].children[j].id;
                val.push(purposes[i].children[j].id);
              }
            }

            if (!val.includes(purposes[i].children[j].id)) {
              hasAllChildrenTicked = false;
              continue;
            }
          }
          if (hasAllChildrenTicked) {
            toAdd += ',' + purposes[i].id;
          }
        }
      }
    }

    for (let i = 0; i < val.length; i++) {
      if (i != val.length - 1) state.selectedPurposes += val[i] + ',';
      else state.selectedPurposes += val[i];
    }
    if (toAdd != '' && toAdd.length > 0) state.selectedPurposes += toAdd;

    var uniqueArr = state.selectedPurposes;
    uniqueArr = Array.from(new Set(uniqueArr.split(','))).toString();
    state.selectedPurposes = uniqueArr;

    if (state.selectedPurposes[state.selectedPurposes.length - 1] == ',')
      state.selectedPurposes = state.selectedPurposes.substring(
        0,
        state.selectedPurposes.length - 1
      );

    sessionStorage.setItem('selectedPurposes', state.selectedPurposes);
  },

  updateSelectedSchemeTypes(state, val) {
    state.selectedSchemeTypes = '';
    var schemeTypes = store.state.advancedSearch.schemeTypes.options;
    var toAdd = '';
    if (val && val.length > 0) {
      for (let i = 0; i < schemeTypes.length; i++) {
        if (schemeTypes[i].children && schemeTypes[i].children.length > 0) {
          var hasAllChildrenTicked = true;
          for (let j = 0; j < schemeTypes[i].children.length; j++) {
            if (
              schemeTypes[i].children[j].children &&
              schemeTypes[i].children[j].children.length > 0
            ) {
              var hasAllSubChildrenTicked = true;
              for (
                let k = 0;
                k < schemeTypes[i].children[j].children.length;
                k++
              ) {
                if (!val.includes(schemeTypes[i].children[j].children[k].id)) {
                  hasAllSubChildrenTicked = false;
                  hasAllChildrenTicked = false;

                  let condition = true;
                  while (condition) {
                    let idx = val.indexOf(schemeTypes[i].children[j].id);
                    if (idx > -1) val.splice(idx, 1);
                    else break;
                  }
                  state.selectedSchemeTypes = state.selectedSchemeTypes.replace(
                    schemeTypes[i].children[j].id.toString(),
                    ''
                  );
                  state.selectedSchemeTypes = state.selectedSchemeTypes.replace(
                    schemeTypes[i].children[j].id,
                    ''
                  );

                  break;
                }
              }
              if (
                hasAllSubChildrenTicked &&
                schemeTypes[i].children[j].children &&
                schemeTypes[i].children[j].children.length > 0
              ) {
                toAdd += ',' + schemeTypes[i].children[j].id;
                val.push(schemeTypes[i].children[j].id);
              }
            }

            if (!val.includes(schemeTypes[i].children[j].id)) {
              hasAllChildrenTicked = false;
              continue;
            }
          }
          if (hasAllChildrenTicked) {
            toAdd += ',' + schemeTypes[i].id;
          }
        }
      }
    }

    for (let i = 0; i < val.length; i++) {
      if (i != val.length - 1) state.selectedSchemeTypes += val[i] + ',';
      else state.selectedSchemeTypes += val[i];
    }
    if (toAdd != '' && toAdd.length > 0) state.selectedSchemeTypes += toAdd;

    var uniqueArr = state.selectedSchemeTypes;
    uniqueArr = Array.from(new Set(uniqueArr.split(','))).toString();
    state.selectedSchemeTypes = uniqueArr;

    if (state.selectedSchemeTypes[state.selectedSchemeTypes.length - 1] == ',')
      state.selectedSchemeTypes = state.selectedSchemeTypes.substring(
        0,
        state.selectedSchemeTypes.length - 1
      );

    sessionStorage.setItem('selectedSchemeTypes', state.selectedSchemeTypes);
  },
  updateSelectedProjectTypes(state, val) {
    state.selectedProjectTypes = '';
    var projectTypes = store.state.advancedSearch.projectTypes.options;
    var toAdd = '';
    if (val.length > 0) {
      for (let i = 0; i < projectTypes.length; i++) {
        if (projectTypes[i].children && projectTypes[i].children.length > 0) {
          var hasAllChildrenTicked = true;
          for (let j = 0; j < projectTypes[i].children.length; j++) {
            if (
              projectTypes[i].children[j].children &&
              projectTypes[i].children[j].children.length > 0
            ) {
              var hasAllSubChildrenTicked = true;
              for (
                let k = 0;
                k < projectTypes[i].children[j].children.length;
                k++
              ) {
                if (!val.includes(projectTypes[i].children[j].children[k].id)) {
                  hasAllSubChildrenTicked = false;
                  hasAllChildrenTicked = false;

                  let condition = true;
                  while (condition) {
                    let idx = val.indexOf(projectTypes[i].children[j].id);
                    if (idx > -1) val.splice(idx, 1);
                    else break;
                  }
                  state.selectedProjectTypes =
                    state.selectedProjectTypes.replace(
                      projectTypes[i].children[j].id.toString(),
                      ''
                    );
                  state.selectedProjectTypes =
                    state.selectedProjectTypes.replace(
                      projectTypes[i].children[j].id,
                      ''
                    );

                  break;
                }
              }
              if (
                hasAllSubChildrenTicked &&
                projectTypes[i].children[j].children &&
                projectTypes[i].children[j].children.length > 0
              ) {
                toAdd += ',' + projectTypes[i].children[j].id;
                val.push(projectTypes[i].children[j].id);
              }
            }

            if (!val.includes(projectTypes[i].children[j].id)) {
              hasAllChildrenTicked = false;
              continue;
            }
          }
          if (hasAllChildrenTicked) {
            toAdd += ',' + projectTypes[i].id;
          }
        }
      }
    }

    for (let i = 0; i < val.length; i++) {
      if (i != val.length - 1) state.selectedProjectTypes += val[i] + ',';
      else state.selectedProjectTypes += val[i];
    }
    if (toAdd != '' && toAdd.length > 0) state.selectedProjectTypes += toAdd;

    var uniqueArr = state.selectedProjectTypes;
    uniqueArr = Array.from(new Set(uniqueArr.split(','))).toString();
    state.selectedProjectTypes = uniqueArr;

    if (
      state.selectedProjectTypes[state.selectedProjectTypes.length - 1] == ','
    )
      state.selectedProjectTypes = state.selectedProjectTypes.substring(
        0,
        state.selectedProjectTypes.length - 1
      );
  },
  updateSearchText(state, val) {
    state.searchText = val;
    sessionStorage.setItem('searchText', val);
  },

  updatePageCount(state, val) {
    state.pageCount = val;
  },

  updateCurrentPage(state, val) {
    state.currentPage = val;
  },

  updateResultsCount(state, val) {
    state.resultsCount = val;
  },
  //This is just hidden for now, don't remove it:
  /*
  updateSelectedIndustries(state, val) {
    state.selectedIndustries = '';
    var industries = store.state.advancedSearch.industries.options;
    var toAdd = '';
    for (let i = 0; i < val.length; i++) {
      if (i != val.length - 1) state.selectedIndustries += val[i] + ',';
      else state.selectedIndustries += val[i];
      for (let i = 0; i < industries.length; i++) {
        if (industries[i].children && industries[i].children.length > 0) {
          var hasAllChildrenTicked = true;
          for (let j = 0; j < industries[i].children.length; j++) {
            if (!val.includes(industries[i].children[j].id)) {
              hasAllChildrenTicked = false;
              break;
            }
          }
          if (hasAllChildrenTicked) toAdd += ',' + industries[i].id;
        }
      }
      if (toAdd != '' && toAdd.length > 0) state.selectedIndustries += toAdd;
    }
  },
  */

  resetSearchFilter(state) {
    state.searchText = '';
    state.organisationsExclude = [];
    state.organisationsInclude = [];
    state.selectedSchemeTypes = '';
    state.selectedLocations = '';
    state.locationsSelectArray = [];
    state.selectedAudiences = '';
    state.selectedPurposes = '';
    state.daysRange.from = null;
    state.daysRange.to = null;
    state.dateFrom = '';
    state.dateTo = '';
    sessionStorage.removeItem('newsDateFrom');
    sessionStorage.removeItem('newsDateTo');
    sessionStorage.removeItem('selectedAudiences');
    sessionStorage.removeItem('selectedPurposes');
    sessionStorage.removeItem('selectedLocations');
    sessionStorage.removeItem('locationsSelectArray');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
