import store from '../store/index';
import { computed } from 'vue';
class OrganisationsUtils {
  generateOrganisationsForDetailsPage() {
    const fundingCopy = computed({
      get: () => store.state.grantItems.fundingCopy,
    });
    const advisingCopy = computed({
      get: () => store.state.grantItems.advisingCopy,
    });
    const recipientCopy = computed({
      get: () => store.state.grantItems.recipientCopy,
    });

    const administratingCopy = computed({
      get: () => store.state.grantItems.administratingCopy,
    });

    var schemeOrgsCopy = [];
    for (let i = 0; i < fundingCopy.value.length; i++) {
      let newObj = {
        id: fundingCopy.value[i].id,
        name: fundingCopy.value[i].name,
        email: fundingCopy.value[i].email,
        phone: fundingCopy.value[i].phone,
        url: fundingCopy.value[i].url,
        type: 'Verstrekkend',
      };

      let idx = advisingCopy.value.findIndex(
        (x) => x.id == fundingCopy.value[i].id
      );
      if (idx != -1) {
        newObj.type += ', Adviserend';
        store.commit(
          'grantItems/removeElementFromAdvisingCopy',
          advisingCopy.value[idx].id
        );
      }

      idx = recipientCopy.value.findIndex(
        (x) => x.id == fundingCopy.value[i].id
      );
      if (idx != -1) {
        newObj.type += ', Ontvangend';
        store.commit(
          'grantItems/removeElementFromARecipientCopy',
          recipientCopy.value[idx].id
        );
      }

      idx = administratingCopy.value.findIndex(
        (x) => x.id == fundingCopy.value[i].id
      );
      if (idx != -1) {
        newObj.type += ', Behandelend';
        store.commit(
          'grantItems/removeElementFromAdministratingCopy',
          administratingCopy.value[idx].id
        );
      }

      let pos = newObj.type.lastIndexOf(',');
      if (pos > 0)
        newObj.type =
          newObj.type.substring(0, pos) +
          ' en' +
          newObj.type.substring(pos + 1);

      schemeOrgsCopy.push(newObj);
    }

    for (let i = 0; i < advisingCopy.value.length; i++) {
      let newObj = {
        id: advisingCopy.value[i].id,
        name: advisingCopy.value[i].name,
        email: advisingCopy.value[i].email,
        phone: advisingCopy.value[i].phone,
        url: advisingCopy.value[i].url,
        type: 'Adviserend',
      };

      let idx = recipientCopy.value.findIndex(
        (x) => x.id == advisingCopy.value[i].id
      );
      if (idx != -1) {
        newObj.type += ', Ontvangend';
        store.commit(
          'grantItems/removeElementFromARecipientCopy',
          recipientCopy.value[idx].id
        );
      }

      idx = administratingCopy.value.findIndex(
        (x) => x.id == advisingCopy.value[i].id
      );
      if (idx != -1) {
        newObj.type += ', Behandelend';
        store.commit(
          'grantItems/removeElementFromAdministratingCopy',
          administratingCopy.value[idx].id
        );
      }
      let pos = newObj.type.lastIndexOf(',');
      if (pos > 0)
        newObj.type =
          newObj.type.substring(0, pos) +
          ' en' +
          newObj.type.substring(pos + 1);
      schemeOrgsCopy.push(newObj);
    }

    for (let i = 0; i < recipientCopy.value.length; i++) {
      let newObj = {
        id: recipientCopy.value[i].id,
        name: recipientCopy.value[i].name,
        email: recipientCopy.value[i].email,
        phone: recipientCopy.value[i].phone,
        url: recipientCopy.value[i].url,
        type: 'Ontvangend',
      };

      let idx = administratingCopy.value.findIndex(
        (x) => x.id == recipientCopy.value[i].id
      );
      if (idx != -1) {
        newObj.type += ', Behandelend';
        store.commit(
          'grantItems/removeElementFromAdministratingCopy',
          administratingCopy.value[idx].id
        );
      }
      let pos = newObj.type.lastIndexOf(',');
      if (pos > 0)
        newObj.type =
          newObj.type.substring(0, pos) +
          ' en' +
          newObj.type.substring(pos + 1);
      schemeOrgsCopy.push(newObj);
    }

    for (let i = 0; i < administratingCopy.value.length; i++) {
      let newObj = {
        id: administratingCopy.value[i].id,
        name: administratingCopy.value[i].name,
        email: administratingCopy.value[i].email,
        phone: administratingCopy.value[i].phone,
        url: administratingCopy.value[i].url,
        type: 'Behandelend',
      };

      schemeOrgsCopy.push(newObj);
    }

    store.commit('grantItems/updateOrgsList', schemeOrgsCopy);
  }
}

export default new OrganisationsUtils();
