<template>
  <div class="portal-search-bar">
    <div class="left-row lg-screen-searchbar newsportal-width">
      <div class="row">
        <q-input
          dense
          borderless
          v-model="searchText"
          placeholder="Zoeken op trefwoord(en)"
          class="search-input input-portalNews"
          @keydown.enter="searchNews()"
          style="height: 36px"
        >
          <template v-slot:append>
            <q-icon
              name="search"
              class="search-icon"
              style="margin-right: 12px"
            />
          </template>
        </q-input>

        <q-btn
          dense
          no-caps
          class="search-btn bold-font"
          color="white"
          flat
          @click="searchNews()"
          style="height: 36px"
        >
          <q-icon
            left="true"
            name="search"
            class="q-mr-sm"
            size="22px"
          ></q-icon>
          {{ t('search_schemes.search') }}</q-btn
        >
      </div>

      <div class="column sort-column">
        <span>{{ resultsCount }} {{ t('search_schemes.results') }}</span>
      </div>
    </div>

    <div class="left-row-sm sm-screen-searchbar">
      <div class="column">
        <div class="row sm-screen-input">
          <q-input
            dense
            borderless
            v-model="searchText"
            placeholder="Zoeken op trefwoord(en)"
            class="search-input"
            @keydown.enter="searchNews()"
            style="height: 36px"
          >
            <template v-slot:append>
              <q-icon
                name="search"
                class="search-icon"
                style="margin-right: 12px"
              />
            </template>
          </q-input>
        </div>

        <div class="row btns-row justify-between">
          <q-btn
            dense
            class="filters-btn bold-font"
            color="white"
            flat
            no-caps
            style="height: 36px"
            @click="enableDrawerLeftNews"
          >
            <q-icon size="14px" class="chevron-icon">
              <img
                class="white-icon q-mr-sm"
                src="../../assets/icons/filter-list-solid.svg"
              />
            </q-icon>
            {{ t('search_schemes.filter_results') }}</q-btn
          >

          <q-btn
            dense
            no-caps
            class="search-btn bold-font"
            color="white"
            flat
            @click="searchNews()"
            style="height: 36px"
          >
            <q-icon
              left="true"
              name="search"
              class="q-mr-sm"
              size="22px"
            ></q-icon>
            {{ t('search_schemes.search') }}</q-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../../store/index';
import { computed } from 'vue';
/* import GrantsService from '../../services/grants.service';
 */ import NewsUtils from '../../utils/news';
 import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t, locale } = useI18n();

    const searchText = computed({
      get: () =>
        store.state.advancedSearch.searchText != ''
          ? store.state.advancedSearch.searchText
          : sessionStorage.getItem('searchText'),
      set: (val) => {
        store.commit('advancedSearch/updateSearchText', val);
        store.commit("advancedSearch/updateSelectedSort", t('search_schemes.relevance'))
        store.commit('newsSearch/updateSearchText', val);
        store.commit("newsSearch/updateSelectedSortNews", t('search_news.newest'))
      },
    });

    /*     watch(
      () => searchText.value,
      async (next, prev) => {
        if (prev && prev.length > 0 && next.length == 0) {
          await GrantsService.getSearchResults(
            searchText,
            statusBoxes,
            audiences,
            locations,
            //This is just hidden for now, don't remove it
            //industries,
            purposes,

            scopesString,
            selectedSort
          );
        }
      }
    ); */

    const sortOptions = ['Datum', 'Relevantie', 'Scope'];
    /* 
    const statusBoxes = computed({
      get: () => sessionStorage.getItem('statusesString'),
    });

    const audiences = computed({
      get: () =>
        store.state.advancedSearch.selectedAudiences != ''
          ? store.state.advancedSearch.selectedAudiences
          : sessionStorage.getItem('selectedAudiences'),
    });
    const locations = computed({
      get: () =>
        store.state.advancedSearch.selectedLocations != ''
          ? store.state.advancedSearch.selectedLocations
          : sessionStorage.getItem('selectedLocations'),
    });
    const purposes = computed({
      get: () =>
        store.state.advancedSearch.selectedPurposes != ''
          ? store.state.advancedSearch.selectedPurposes
          : sessionStorage.getItem('selectedPurposes'),
    });

    const scopesString = computed({
      get: () => store.state.advancedFilters.scopesString,
    }); */

    /*    const selectedSort = computed({
      get: () => store.state.advancedSearch.selectedSort,
      set: async (value) => {
        store.commit('advancedSearch/updateSelectedSort', value);
        searchGrants();
      },
    }); */

    const enableDrawerLeftNews = () => {
      store.commit('advancedSearch/updatePortalNewsDrawerLeft', true);
    };

    const resultsCount = computed({
      get: () => store.state.newsSearch.resultsCount,
    });

    const searchNews = async () => {
      await NewsUtils.searchNews(locale);
    };

    return {
      t,
      locale,
      searchNews,
      enableDrawerLeftNews,
      searchText,
      resultsCount,
      sortOptions,
      /*       selectedSort,
       */
    };
  },
};
</script>
