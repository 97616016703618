<template>
  <q-checkbox
    :key="schemeId"
    v-model="isSelected"
    id="v-step-3"
    @update:model-value="selectScheme(schemeId)"
    class="grant-select-check"
    color="#0a108f"
    dense
  />
</template>
<script>
import { ref, onMounted, watch, computed } from "vue";

import store from "../store/index";
export default {
  props: {
    id: String,
    allSelected: Array,
  },
  setup(props) {
    const isSelected = ref(false);
    const schemeId = ref(props.id);

    const selectedResults = computed({
      get: () => store.state.grantItems.selectedResults,
    });

    onMounted(() => {
      isSelected.value =
        selectedResults.value.length > 0 &&
        selectedResults.value.filter((r) => r.schemeId == props.id).length > 0;
    });

    watch(selectedResults.value, () => {
      isSelected.value =
        selectedResults.value.length > 0 &&
        selectedResults.value.filter((r) => r.schemeId == parseInt(props.id))
          .length > 0;
    });

    const updateSelected = () => {
      isSelected.value =
        selectedResults.value.length > 0 &&
        selectedResults.value.filter((r) => r.schemeId == parseInt(props.id))
          .length > 0;
    };

    const selectScheme = (id) => {
      store.commit("grantItems/addSelected", id);
      isSelected.value =
        selectedResults.value.length > 0 &&
        selectedResults.value.filter((r) => r.schemeId == parseInt(props.id))
          .length > 0;
    };

    return {
      selectedResults,
      updateSelected,
      schemeId,
      selectScheme,
      isSelected,
    };
  },
};
</script>
