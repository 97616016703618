import axiosUrl from './axios.service';
import axios from 'axios';
import store from '../store/index';
import authHeader from '../helpers/auth-header';
const API_URL = axiosUrl.getAxiosUrl();

class OrganisationsService {
  getAllOrganisations() {
    return axios
      .get(API_URL + '/organisations/getAllOrganisations', {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('organisations/updateOrganisations', response.data);
      });
    
  }
  
  getAllOrganisationsWithBasicInfo() {
    return axios
      .get(API_URL + '/organisations/getAllOrganisationsWithBasicInfo', {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('organisations/updateOrganisationsWithBasicInfo', response.data);
      });
  }

  async getOrganisationAsync(id){
    return await axios
    .get(API_URL + '/organisations/getOrganisationById', {
      params: {
        orgId: id,
      },
      headers: authHeader(),
    })
    .then((response) => {
      store.commit('advancedSearch/updateOrganisationsInclude', [response.data]);
      store.commit('advancedSearch/createOrganisationsIncludeString');
      localStorage.setItem('schemeOrganisationClicked', false);
    });

  }
}

export default new OrganisationsService();
