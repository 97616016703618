import axiosUrl from "./axios.service";
import axios from "axios";
import store from "../store/index";
import authHeader from "../helpers/auth-header";
import moment from "moment";
import UserService from "./userprofile.service";
import { computed } from "vue";
const API_URL = axiosUrl.getAxiosUrl();

const currentPage = computed({
  get: () => store.state.advancedSearch.currentPage,
});

class GrantsService {
  async loadDefaultSchemes() {
    return await axios
      .get(API_URL + "/search/searchschemes", {
        headers: authHeader(),
        params: {
          PageSize: 10,
          Page: currentPage.value - 1,
          Statusses: "Open,Forthcoming,",
          Sort: "Relevantie",
        },
      })
      .then((response) => {
        store.commit("grantItems/updateGrants", response.data);
        if (response.data.resultCount % 10 != 0)
          store.commit(
            "advancedSearch/updatePageCount",
            response.data.pageCount + 1
          );
        else
          store.commit(
            "advancedSearch/updatePageCount",
            response.data.pageCount
          );
        store.commit(
          "advancedSearch/updateResultsCount",
          response.data.resultCount
        );
        store.commit("grantItems/disableSearchPageLoading");
        store.commit("advancedSearch/disableFiltersChange");
        store.commit("portalsData/updateIsPortalPageLoading", false);
      });
  }

  async downloadDocument(documentId) {
    return await axios
      .get(API_URL + `/scheme/download?documentId=${documentId}`, {
        headers: authHeader(),
        responseType: "blob",
        reportProgress: true,
      })
      .catch((error) => {
        if (error)
          store.commit("scheme/updateModalError", error.response),
            store.commit("scheme/enableModalError");
      });
  }

  async getSearchResults(
    locale,
    searchText,
    statusBoxes,
    audiences,
    locations,
    //This is just hidden for now, don't remove it:
    //industries,
    purposes,
    schemeTypes,
    projectTypes,
    smeString,
    applicationTermsString,
    minBudget,
    maxBudget,
    scopesString,
    orgsIncludeString,
    orgsExcludeString,
    startDate,
    endDate,
    sort,
    complexityRating,
    chanceOfSuccessRating,
    timeForApplicationRating
  ) {
    const start =
      startDate != null &&
      startDate.value != null &&
      startDate.value != "" &&
      startDate.value != '""' &&
      startDate.value != "null"
        ? moment(startDate.value, "DD-MM-YYYY").format("YYYY-MM-DD")
        : null;
    const end =
      endDate != null &&
      endDate.value != null &&
      endDate.value != "" &&
      endDate.value != '""' &&
      endDate.value != "null"
        ? moment(endDate.value, "DD-MM-YYYY").format("YYYY-MM-DD")
        : null;

    return await axios
      .get(API_URL + "/search/searchschemes", {
        headers: authHeader(),
        params: {
          Term: searchText.value,
          PageSize: 10,
          Page: currentPage.value - 1,
          Sort: sort.value,
          Statusses: statusBoxes.value,
          AudienceTags: audiences.value,
          //This is just hidden for now, don't remove it
          // IndustriesTags: industries.value,
          PurposesTags: purposes.value,
          SchemeTypesTags: schemeTypes.value,
          ProjectTypesTags: projectTypes.value,
          LocationsTags: locations.value,
          AppliesToSME: smeString.value,
          ApplicationTerms: applicationTermsString.value,
          MinAmount: minBudget.value != 0 ? minBudget.value : null,
          MaxAmount: maxBudget.value != 0 ? maxBudget.value : null,
          Scopes: scopesString.value,
          NotOrganisations:
            orgsExcludeString.value.length > 0 ? orgsExcludeString.value : null,
          Organisations:
            orgsIncludeString.value.length > 0 ? orgsIncludeString.value : null,
          CallStartDate: start,
          CallEndDate: end,
          ComplexityRating: complexityRating ? complexityRating.value : null,
          ChanceOfSuccessRating: chanceOfSuccessRating
            ? chanceOfSuccessRating.value
            : null,
          TimeForApplicationRating: timeForApplicationRating
            ? timeForApplicationRating.value
            : null,
          SelectedLanguageCode: locale.value
        },
      })
      .then((response) => {
        store.commit("grantItems/updateGrants", response.data);
        if (response.data.resultCount % 10 != 0)
          store.commit(
            "advancedSearch/updatePageCount",
            response.data.pageCount + 1
          );
        else
          store.commit(
            "advancedSearch/updatePageCount",
            response.data.pageCount
          );
        store.commit(
          "advancedSearch/updateResultsCount",
          response.data.resultCount
        );
        store.commit("advancedSearch/updateMaxScoreOnCurrentPage");
        store.commit("advancedSearch/updateMaxScore", response.data.topScore);
        store.commit("user/setIsProfileSearch", false);
        store.commit("advancedSearch/disableFiltersChange");
        store.commit("grantItems/disableSearchPageLoading");
        store.commit("portalsData/updateIsPortalPageLoading", false);
      });
  }

  async getResulstsByPage(
    locale,
    current,
    searchText,
    statusBoxes,
    audiences,
    locations,
    //This is just hidden for now, don't remove it:
    //industries,
    purposes,
    schemeTypes,
    projectTypes,
    smeString,
    applicationTermsString,
    minBudget,
    maxBudget,
    scopesString,
    orgsIncludeString,
    orgsExcludeString,
    startDate,
    endDate,
    sort,
    complexityRating,
    chanceOfSuccessRating,
    timeForApplicationRating
  ) {
    return await axios

      .get(API_URL + "/search/searchschemes", {
        headers: authHeader(),
        params: {
          Term: searchText.value,
          PageSize: 10,
          Page: current,
          Sort: sort.value,
          Statusses: statusBoxes.value,
          AudienceTags: audiences.value,
          //This is just hidden for now, don't remove it
          // IndustriesTags: industries.value,
          PurposesTags: purposes.value,
          SchemeTypesTags: schemeTypes.value,
          ProjectTypesTags: projectTypes.value,
          LocationsTags: locations.value,
          AppliesToSME: smeString.value,
          ApplicationTerms: applicationTermsString.value,
          MinAmount: minBudget.value != 0 ? minBudget.value : null,
          MaxAmount: maxBudget.value != 0 ? maxBudget.value : null,
          Scopes: scopesString.value,
          NotOrganisations:
            orgsExcludeString.value.length > 0 ? orgsExcludeString.value : null,
          Organisations:
            orgsIncludeString.value.length > 0 ? orgsIncludeString.value : null,
          CallStartDate:
            startDate.value != null &&
            startDate.value != '""' &&
            startDate.value != ""
              ? moment(startDate.value, "DD-MM-YYYY").format("YYYY-MM-DD")
              : null,
          CallEndDate:
            endDate.value != null &&
            endDate.value != '""' &&
            endDate.value != ""
              ? moment(endDate.value, "DD-MM-YYYY").format("YYYY-MM-DD")
              : null,
          ComplexityRating: complexityRating ? complexityRating.value : null,
          ChanceOfSuccessRating: chanceOfSuccessRating
            ? chanceOfSuccessRating.value
            : null,
          TimeForApplicationRating: timeForApplicationRating
            ? timeForApplicationRating.value
            : null,
          SelectedLanguageCode: locale.value
        },
      })
      .then((response) => {
        store.commit("grantItems/updateGrants", response.data);
        if (response.data.resultCount % 10 != 0)
          store.commit(
            "advancedSearch/updatePageCount",
            response.data.pageCount + 1
          );
        else
          store.commit(
            "advancedSearch/updatePageCount",
            response.data.pageCount
          );
        store.commit(
          "advancedSearch/updateResultsCount",
          response.data.resultCount
        );
        store.commit("advancedSearch/disableFiltersChange");
        store.commit("grantItems/disableSearchPageLoading");
        store.commit("portalsData/updateIsPortalPageLoading", false);
      });
  }

  async getSchemeById(id, includeChildren) {
    var urlParams = new URLSearchParams(window.location.search);
    return await axios
      .get(API_URL + "/scheme/getScheme", {
        params: {
          schemeId: id,
          terms: urlParams.get("t"),
          includeChildren: includeChildren,
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit("grantItems/updateSelectedGrant", response.data);
        store.commit("grantItems/disableGrantDetailsLoading");
      });
  }

  async translateScheme(schemeId) {
    return await axios
      .post(
        API_URL + `/scheme/translateScheme`,
        {
          Id: schemeId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        store.commit("grantItems/updateSelectedGrant", response.data);
      });
  }

  async saveSearch(savedSearch, schemeSearchFilters) {
    return await axios
      .post(
        API_URL + `/search/saveSchemesSearch`,
        {
          title: savedSearch.title,
          isSchemesFilter: true,
          folderId: savedSearch.folder != null ? savedSearch.folder.id : null,
          folderName:
            savedSearch.folder == null || savedSearch.folder.id == 0
              ? savedSearch.newFolderName
              : savedSearch.folder.name,
          filter: {
            term: schemeSearchFilters.searchText,
            pageSize: 10,
            page: 0,
            appliesToSME: schemeSearchFilters.smeTypes,
            scopes: schemeSearchFilters.scopesString,
            applicationTerms: schemeSearchFilters.termsString,
            statusses: schemeSearchFilters.statuses,
            callStartDate:
              schemeSearchFilters.startDate != null &&
              schemeSearchFilters.startDate != ""
                ? moment(schemeSearchFilters.startDate, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  )
                : null,
            callEndDate:
              schemeSearchFilters.endDate != null &&
              schemeSearchFilters.endDate != ""
                ? moment(schemeSearchFilters.endDate, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  )
                : null,
            audienceTags: schemeSearchFilters.audienceTags,
            purposesTags: schemeSearchFilters.purposesTags,
            //This is just hidden for now, don't remove it:
            //industriesTags: schemeSearchFilters.industriesTags,
            locationsTags: schemeSearchFilters.locationsTags,
            schemeTypesTags: schemeSearchFilters.schemeTypesTags,
            projectTypesTags: schemeSearchFilters.projectTypesTags,
            organisations: schemeSearchFilters.organisationsInclude,
            notOrganisations: schemeSearchFilters.organisationsExclude,
            minAmount: schemeSearchFilters.minBudget,
            maxAmount: schemeSearchFilters.maxBudget,
          },
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.status === 200) {
          store.commit("user/disableSave");
          store.commit("user/enableShowSavedSearchInfoDialog", res.data);
          //router.push({ name: 'UserProfile', params: { tab: 'savedItems' } });
        }
      })
      .catch((error) => {
        if (error)
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
      });
  }

  async removeSavedSearch(searchId, folderId) {
    return await axios
      .post(
        API_URL + `/search/removeSchemesSearch`,
        {
          id: searchId,
          isSchemesFilter: true,
          folderId: folderId,
        },
        {
          headers: authHeader(),
        }
      )
      .catch((error) => {
        if (error)
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
      });
  }

  async hideScheme(schemeId) {
    return axios({
      method: "post",
      url: API_URL + `/scheme/hideScheme`,
      headers: authHeader(),
      params: {
        schemeId: schemeId,
      },
    })
      .then((response) => {
        if (response.status == 200)
          store.commit("user/enableShowHiddenInfoDialog");
      })
      .catch((error) => {
        if (error)
          store.commit("scheme/updateModalError", error.response.data),
            store.commit("scheme/enableModalError");
      });
  }

  async unhideAllSchemes() {
    return axios({
      method: "post",
      url: API_URL + `/scheme/unhideAllSchemes`,
      headers: authHeader(),
    })
      .then((res) => {
        if (res.status === 200) {
          UserService.getHiddenSchemes();
        }
      })
      .catch((error) => {
        if (error)
          store.commit("scheme/updateModalError", error.response.data),
            store.commit("scheme/enableModalError");
      });
  }

  async unhideScheme(schemeId) {
    return axios({
      method: "post",
      url: API_URL + `/scheme/unhideScheme`,
      headers: authHeader(),
      params: {
        schemeId: schemeId,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          UserService.getHiddenSchemes();
        }
      })
      .catch((error) => {
        if (error)
          store.commit("scheme/updateModalError", error.response.data),
            store.commit("scheme/enableModalError");
      });
  }

  async getSchemeCustomers(id) {
    return await axios
      .get(API_URL + "/scheme/getSchemeCustomers", {
        params: {
          schemeId: id,
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit("grantItems/updateSchemeCustomers", response.data);
      });
  }

  async searchNewestSchemes(locale, page, size) {
    return await axios
      .get(API_URL + "/search/searchschemes", {
        headers: authHeader(),
        params: {
          PageSize: size,
          Page: page,
          Sort: "Datum",
          SelectedLanguageCode: locale.value
        },
      })
      .then((response) => {
        if (page > 0) {
          store.commit("homePageItems/updateGrantsNewest", response.data);
        } else {
          store.commit("homePageItems/updateGrantsNewest", response.data);

          store.commit("grantItems/updateNewestGrants", response.data);
        }
      });
  }

  async searchNewestSchemesTags(locale, page, size, audiences, purposes, locations) {
    return await axios
      .get(API_URL + "/search/searchschemes", {
        headers: authHeader(),
        params: {
          PageSize: size,
          Page: page,
          Sort: "Datum",
          AudienceTags: audiences.length > 0 ? audiences : null,
          PurposesTags: purposes.length > 0 ? purposes : null,
          LocationsTags: locations.length > 0 ? locations : null,
          SelectedLanguageCode: locale.value
        },
      })
      .then((response) => {
        if (page > 0) {
          store.commit("homePageItems/updateGrantsNewest", response.data);
        } else {
          store.commit("homePageItems/updateGrantsNewest", response.data);

          store.commit("grantItems/updateNewestGrants", response.data);
        }
      });
  }

  async searchSchemesByDeadlineTags(
    locale,
    page,
    size,
    audiences,
    purposes,
    locations
  ) {
    return await axios
      .get(API_URL + "/search/searchschemes", {
        headers: authHeader(),
        params: {
          PageSize: size,
          Page: page,
          Sort: "Deadline",
          AudienceTags: audiences.length > 0 ? audiences : null,
          PurposesTags: purposes.length > 0 ? purposes : null,
          LocationsTags: locations.length > 0 ? locations : null,
          SelectedLanguageCode: locale.value
        },
      })
      .then((response) => {
        if (page > 0) {
          store.commit("homePageItems/updateGrantsByDeadline", response.data);
        } else {
          store.commit("homePageItems/updateGrantsByDeadline", response.data);

          store.commit("grantItems/updateDeadlineGrants", response.data);
        }
      });
  }

  async searchSchemesByDeadline(locale, page, size) {
    return await axios
      .get(API_URL + "/search/searchschemes", {
        headers: authHeader(),
        params: {
          PageSize: size,
          Page: page,
          Sort: "Deadline",
          SelectedLanguageCode: locale.value
        },
      })
      .then((response) => {
        if (page > 0) {
          store.commit("homePageItems/updateGrantsByDeadline", response.data);
        } else {
          store.commit("homePageItems/updateGrantsByDeadline", response.data);

          store.commit("grantItems/updateDeadlineGrants", response.data);
        }
      });
  }

  async searchSignificantSchemes(page, size) {
    return await axios
      .get(
        API_URL +
          "/search/getSchemesWithLastSignificantUpdatesBasedOnFavoriteTags",
        {
          headers: authHeader(),
          params: {
            PageSize: size,
            Page: page,
          },
        }
      )
      .then((response) => {
        if (page > 0) {
          store.commit("homePageItems/updateGrantsSignificant", response.data);
        } else {
          store.commit("homePageItems/updateGrantsSignificant", response.data);

          store.commit(
            "grantItems/updateSignificantChangesGrants",
            response.data
          );
        }
      });
  }
}

export default new GrantsService();
