<template>
  <div class="users-stats-table">
    <q-table
      class="my-sticky-header-table"
      :rows="tableStatsRows"
      :columns="columns"
      row-key="id"
      flat
      bordered
      :separator="'cell'"
      hide-pagination
      :pagination="pagination"
      style="max-heigt: 300px; height: auto"
      virtual-scroll
      :virtual-scroll-sticky-size-start="30"
      :virtual-scroll-sticky-size-end="30"
    >
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="username" :props="props">{{ props.row.username }}</q-td>
          <q-td key="newsletterFrequency" :props="props">{{
            props.row.newsletterFrequency
          }}</q-td>
          <q-td key="isProfileSet" :props="props">
            <i v-if="props.row.isProfileSet"> {{ t('search_schemes.yes') }} </i>
            <i v-else> {{ t('search_schemes.no') }}</i>
          </q-td>
          <q-td key="visitsInLast30Days" :props="props">{{
            props.row.visitsInLast30Days
          }}</q-td>
          <q-td
            key="isActive"
            :props="props"
            :class="props.row.isActive  ? 'text-black' : 'text-red'"
          >
            <i v-if="props.row.isActive "> {{ t('search_schemes.yes') }} </i>
            <i v-else> {{ t('search_schemes.no') }}</i>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
import store from '../../store/index.js';
import userStatsService from '../../services/userStats.service';

import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();

    const tableStatsRows = computed({
      get: () => store.state.userStats.usersTable,
    });

    const columns = ref([
      {
        name: 'username',
        field: 'username',
        label: computed(() => t('account_management.user_name')),
        align: 'left',
      },
      {
        name: 'newsletterFrequency',
        label: computed(() => t('account_management.newsletter_frequency')),
        field: 'newsletterFrequency',
        align: 'left',
      },
      {
        name: 'isProfileSet',
        label: computed(() => t('account_management.profile_set')),
        field: 'isProfileSet',
        align: 'left',
      },
      {
        name: 'visitsInLast30Days',
        label: computed(() => t('account_management.number_of_visits_last_30_days')),
        field: 'visitsInLast30Days',
        align: 'left',
      },
      {
        name: 'isActive',
        label: computed(() => t('account_management.is_active')),
        field: 'isActive',
        align: 'left',
      },
    ]);
    onMounted(async () => {
      await userStatsService.getUsersTableStats();
    });

    return {
      t,
      columns,
      tableStatsRows,
      pagination: {
        rowsPerPage: 500,
        page: 1,
      },
    };
  },
};
</script>

<style lang="sass">
.my-sticky-header-table
  height: 400px

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th
    background-color: #25408f

  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0

  &.q-table--loading thead tr:last-child th
    top: 48px
</style>
