import store from '../index';
const state = () => ({
  accountsList: {},
  newsItemsCount: 0,
  currentIndex: 0,
  currentNewsItem: {},
  newsItems: [],
  showNewsDialog: false,
  selectedCustomersPerNews: [],
  customersList: [
    {
      label: 'Test',
      id: 1,
    },
  ],

  // This part is for selecting news items per customer
  salesForceCustomersList: [],
  salesForceCustomersCount: 0,
  currentSalesForceCustomer: {},
  currentCustomersIndex: 0,
  selectedNewsPerCustomer: [{}],
  currentNewsOptions: [],
  selectedNewsForCurrentCustomer: [],
  currentNewsItemPerCustomer: {},
  showNewsDialogPerCustomer: false,
  newsItemsPerCustomerListForServer: [],
});

const getters = {};

const actions = {};

const mutations = {
  resetSelectedCustomersPerNews(state) {
    state.selectedCustomersPerNews = [];
  },
  updateCustomersForNewsItem(state, val) {
    state.selectedCustomersPerNews[state.currentIndex].customers = [];
    for (let i = 0; i < val.length; i++) {
      let customer = state.customersList.find((x) => x.id == val[i]);
      state.selectedCustomersPerNews[state.currentIndex].customers.push(
        customer
      );
    }
  },
  enableNewsModal(state) {
    state.showNewsDialog = true;
  },
  disableNewsModal(state) {
    state.showNewsDialog = false;
  },
  decreaseIndex(state) {
    state.currentIndex = state.currentIndex - 1;
    store.commit('salesForce/updateCurrentNewsItem');
  },
  increaseIndex(state) {
    state.currentIndex = state.currentIndex + 1;
    store.commit('salesForce/updateCurrentNewsItem');
  },
  setIndex(state, val) {
    state.currentIndex = val;
  },

  updateCurrentNewsItem(state) {
    state.currentNewsItem = state.accountsList.newsItems[state.currentIndex];
  },
  setDefaultNewsItem(state) {
    state.currentNewsItem = state.accountsList.newsItems[0];
  },
  updateAccounts(state, data) {
    state.accountsList = data;

    state.salesForceCustomersList = [];
    for (let i = 0; i < state.accountsList.length; i++) {
      state.salesForceCustomersList.push({
        id: state.accountsList[i].id,
        name: state.accountsList[i].name,
      });
    }

    state.salesForceCustomersCount = state.accountsList.length;
  },

  // This part is for selecting news items per customer
  decreaseCustomerIndex(state) {
    state.currentCustomersIndex = state.currentCustomersIndex - 1;
    store.commit('salesForce/updateCurrentCustomerItem');
  },
  increaseCustomerIndex(state) {
    state.currentCustomersIndex = state.currentCustomersIndex + 1;
    store.commit('salesForce/updateCurrentCustomerItem');
  },
  updateCurrentCustomerItem(state) {
    state.currentSalesForceCustomer = {
      id: state.accountsList[state.currentCustomersIndex].id,
      name: state.accountsList[state.currentCustomersIndex].name,
      newsItems: state.accountsList[state.currentCustomersIndex].newsItems,
      customerTags:
        state.accountsList[state.currentCustomersIndex].customerTags,
      contacts: state.accountsList[state.currentCustomersIndex].contacts.map(
        (c) => ({
          fullName: c.fullName,
          email: c.email,
          firstName: c.firstName,
          lastName: c.lastName,
          salesforceId: c.salesforceId
        })
      ),
    };
    store.commit('salesForce/updateCurrentNewsOptions');
  },
  setDefaultCustomerItem(state) {
    state.currentSalesForceCustomer = {
      id: state.accountsList[0].id,
      name: state.accountsList[0].name,
      newsItems: state.accountsList[0].newsItems,
      customerTags: state.accountsList[0].customerTags,
    };
    store.commit('salesForce/updateCurrentNewsOptions');
  },
  updateCurrentNewsOptions(state) {
    state.currentNewsOptions = [];
    // ADD CHECK IF NEWS IS NOT EMPTY/NULL
    for (let i = 0; i < state.currentSalesForceCustomer.newsItems.length; i++) {
      state.currentNewsOptions.push({
        id: state.currentSalesForceCustomer.newsItems[i].id,
        label: state.currentSalesForceCustomer.newsItems[i].title,
        body: state.currentSalesForceCustomer.newsItems[i].body,
        introduction: state.currentSalesForceCustomer.newsItems[i].introduction,
      });
    }
  },

  resetSelectedNewsPerCustomer(state) {
    state.selectedNewsPerCustomer = [];
    for (let i = 0; i < state.accountsList.length; i++) {
      const c = {
        customerId: state.accountsList[i].id,
        customerName: state.accountsList[i].name,
        news: [],
        contacts: state.accountsList[i].contacts.map(
          (c) => ({
            fullName: c.fullName,
            email: c.email,
            firstName: c.firstName,
            salesforceId: c.salesforceId
          })
        ),
      };
      state.selectedNewsPerCustomer.push(c);

      for (let j = 0; j < state.accountsList[i].newsItems.length; j++) {
        if(state.accountsList[i].newsItems[j].isSelected)
            c.news.push(state.accountsList[i].newsItems[j]);
      }
    }
  },

  createNewsItemsPerCustomerListForServer(state) {
    state.newsItemsPerCustomerListForServer = [];
    for (let i = 0; i < state.selectedNewsPerCustomer.length; i++) {
      state.newsItemsPerCustomerListForServer.push({
        customerId: state.selectedNewsPerCustomer[i].customerId,
        newsIds: state.selectedNewsPerCustomer[i].news.map((x) => x.id),
        contacts: state.selectedNewsPerCustomer[i].contacts.map((c) => ({
          fullName: c.fullName,
          email: c.email,
          name: c.name,
          firstName: c.firstName,
          salesforceId: c.salesforceId
        })),
      });
    }
  },

  updateNewsForCustomer(state, val) {
    state.selectedNewsPerCustomer[state.currentCustomersIndex] = {
      customerId: state.accountsList[state.currentCustomersIndex].id,
      customerName: state.accountsList[state.currentCustomersIndex].name,
      news: [],
      contacts: state.accountsList[state.currentCustomersIndex].contacts.map(
        (c) => ({
          fullName: c.fullName,
          email: c.email,
          firstName: c.firstName,
          salesforceId: c.salesforceId
        })
      ),
    };

    for (let i = 0; i < val.length; i++) {
      let news = state.currentNewsOptions.find((x) => x.id == val[i]);
      state.selectedNewsPerCustomer[state.currentCustomersIndex].news.push(
        news
      );
    }
  },

  updateNewsForCurrentCustomer(state) {
    state.selectedNewsForCurrentCustomer = [];
    var arr = state.selectedNewsPerCustomer[state.currentCustomersIndex];
    if (arr && arr.news.length > 0)
      for (let i = 0; i < arr.news.length; i++) {
        state.selectedNewsForCurrentCustomer.push(arr.news[i].id);
      }
  },

  setCustomersIndex(state, val) {
    state.currentCustomersIndex = val;
  },

  updateCurrentNewsItemPerCustomer(state, id) {
    state.currentNewsItemPerCustomer = state.currentNewsOptions.find(
      (x) => x.id == id
    );
  },

  enableNewsModalPerCustomer(state) {
    state.showNewsDialogPerCustomer = true;
  },

  disableNewsModalPerCustomer(state) {
    state.showNewsDialogPerCustomer = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
