<template>
  <div class="row search-bar" v-if="isLoggedIn">
    <div class="searchbox">
      <q-input
        dense
        borderless
        v-model="searchText"
        :placeholder="t('search_schemes.search')"
        class="search-input"
        @keydown.enter="searchGrants()"
        style="width: 100%"
      >
        <template v-slot:append>
          <q-icon name="search" class="search-icon" />
        </template>
      </q-input>
      <a
        style="text-decoration: underline"
        class="operators-help"
        href="https://www.vindsubsidies.nl/faq-database/"
        target="_blank"
      >
        {{ t('search_schemes.information_about_searching_operators_label') }}
      </a>
    </div>
    <q-btn
      no-caps
      class="button-search text-weight-bold bold-font"
      text-color="white"
      @click="searchGrants()"
      :label="$q.screen.gt.sm ? t('search_schemes.search') : ''"
    >
      <q-icon left="true" name="search" class="q-mr-sm" size="22px"></q-icon>
    </q-btn>
  </div>
</template>

<script>
import store from '../../store/index.js';
import { computed, watch, ref } from 'vue';
import GrantsService from '../../services/grants.service';
import NewsUtils from '../../utils/news';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t, locale } = useI18n();
    
    const isLoggedIn = computed({
      get: () => store.state.auth.isLoggedIn,
    });

    const audiences = computed({
      get: () =>
        store.state.advancedSearch.selectedAudiences != ''
          ? store.state.advancedSearch.selectedAudiences
          : sessionStorage.getItem('selectedAudiences'),
    });
    const locations = computed({
      get: () =>
        store.state.advancedSearch.selectedLocations != ''
          ? store.state.advancedSearch.selectedLocations
          : sessionStorage.getItem('selectedLocations'),
    });
    const purposes = computed({
      get: () =>
        store.state.advancedSearch.selectedPurposes != ''
          ? store.state.advancedSearch.selectedPurposes
          : sessionStorage.getItem('selectedPurposes'),
    });
    const schemeTypes = computed({
      get: () =>
        store.state.advancedSearch.selectedSchemeTypes != ''
          ? store.state.advancedSearch.selectedSchemeTypes
          : sessionStorage.getItem('selectedSchemeTypes'),
    });
    const projectTypes = computed({
      get: () => store.state.advancedSearch.selectedProjectTypes,
    });
    const smeString = computed({
      get: () => store.state.advancedFilters.smeString,
    });
    const applicationTermsString = computed({
      get: () => store.state.advancedFilters.termsString,
    });

    const minBudget = computed({
      get: () =>
        store.state.advancedSearch.minBudget != null
          ? store.state.advancedSearch.minBudget
          : Number(sessionStorage.getItem('minBudget')),
    });

    const maxBudget = computed({
      get: () =>
        store.state.advancedSearch.maxBudget != null
          ? store.state.advancedSearch.maxBudget
          : Number(sessionStorage.getItem('maxBudget')),
    });

    const scopesString = computed({
      get: () => store.state.advancedFilters.scopesString,
    });
    const orgsIncludeString = computed({
      get: () =>
        store.state.advancedSearch.organisationsIncludeString != ''
          ? store.state.advancedSearch.organisationsIncludeString
          : sessionStorage.getItem('organisationsIncludeString'),
    });

    const orgsExcludeString = computed({
      get: () =>
        store.state.advancedSearch.organisationsExcludeString != ''
          ? store.state.advancedSearch.organisationsExcludeString
          : sessionStorage.getItem('organisationsExcludeString'),
    });

    const startDate = computed({
      get: () =>
        store.state.advancedSearch.startDate != null &&
        store.state.advancedSearch.startDate != ''
          ? store.state.advancedSearch.startDate
          : sessionStorage.getItem('startDate'),
    });

    const endDate = computed({
      get: () =>
        store.state.advancedSearch.endDate != null &&
        store.state.advancedSearch.endDate != ''
          ? store.state.advancedSearch.endDate
          : sessionStorage.getItem('endDate'),
    });

    const selectedSort = computed({
      get: () => store.state.advancedSearch.selectedSort,
    });

    //This is just hidden for now, don't remove it
    /*
    const industries = computed({
      get: () => store.state.advancedSearch.selectedIndustries,
    });
    */

    const searchGrants = async () => {
      store.commit('advancedFilters/createTermsString');
      store.commit('advancedFilters/createSMEString');
      store.commit('advancedFilters/createScopesString');
      store.commit('advancedSearch/createOrganisationsIncludeString');
      store.commit('advancedSearch/createOrganisationsExcludeString');
      const statuses = ref(sessionStorage.getItem('statusesString'));
      await GrantsService.getSearchResults(
        locale,
        searchText,
        statuses,
        audiences,
        locations,
        //This is just hidden for now, don't remove it
        //industries,
        purposes,
        schemeTypes,
        projectTypes,
        smeString,
        applicationTermsString,
        minBudget,
        maxBudget,
        scopesString,
        orgsIncludeString,
        orgsExcludeString,
        startDate,
        endDate,
        selectedSort
      );

      await NewsUtils.searchNewsWithGrantsFilter(locale);
    };

    const searchText = computed({
      get: () =>
        store.state.advancedSearch.searchText != ''
          ? store.state.advancedSearch.searchText
          : sessionStorage.getItem('searchText'),
      set: (val) => {
        store.commit('advancedSearch/updateSearchText', val);
        store.commit("advancedSearch/updateSelectedSort", t('search_schemes.relevance'));
        store.commit('newsSearch/updateSearchText', val);
        store.commit("newsSearch/updateSelectedSortNews", t('search_news.newest'));
      },
    });

    watch(
      () => searchText.value,
      async (next, prev) => {
        if (prev && prev.length > 0 && next.length == 0) {
          const statuses = ref(sessionStorage.getItem('statusesString'));
          await GrantsService.getSearchResults(
            locale,
            searchText,
            statuses,
            audiences,
            locations,
            //This is just hidden for now, don't remove it
            //industries,
            purposes,
            schemeTypes,
            projectTypes,
            smeString,
            applicationTermsString,
            minBudget,
            maxBudget,
            scopesString,
            orgsIncludeString,
            orgsExcludeString,
            startDate,
            endDate,
            selectedSort
          );
        }
      }
    );

    return {
      t,
      locale,
      scopesString,
      startDate,
      endDate,
      smeString,
      applicationTermsString,
      searchText,
      isLoggedIn,
      searchGrants,
      schemeTypes,
      projectTypes,
      purposes,
      //This is just hidden for now, don't remove it
      //industries,
      audiences,
      locations,
      minBudget,
      maxBudget,
      orgsIncludeString,
      orgsExcludeString,
      selectedSort,
    };
  },
};
</script>
