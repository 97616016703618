<template>
  <div class="rating-page">
    <ErrorModal />
    <div id="q-app" style="min-height: 75vh">
      <div class="q-pa-md column">
        <div class="q-gutter-y-md column" style="margin-top: 120px">
          <h3 class="title">Hoe beoordeel jij onze database?</h3>
          <div
            class="row"
            style="
              display: inline-flex;
              align-items: center;
              justify-content: center;
            "
          >
            <h5 class="title q-mr-md">Slecht</h5>
            <div class="custom-rating">
              <q-btn
                class="button-box"
                v-for="n in 10"
                :key="n"
                :class="{ selected: n == ratingModel }"
                @click="ratingModel = n"
              >
                {{ n }}
              </q-btn>
            </div>
            <h5 class="title q-ml-md">Uitstekend</h5>
          </div>
          <div class="q-gutter-y-md column">
            <h3 class="title">Heb je nog tips voor ons?</h3>
            <q-input
              class="input-suggestion-field q-mb-md"
              outlined
              v-model="suggestionText"
              :rules="requiredFieldRule"
            />
            <q-btn
              flat
              class="orange-btn-filled submitBtn"
              color="white"
              label="Verzenden"
              @click="submitVotes()"
              no-caps
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <q-dialog v-model="showInfoDialog" class="info-dialog">
    <q-card>
      <q-card-section>
        <h3 class="title">{{ t('account_management.rating_thanks') }}</h3>
      </q-card-section>
      <q-card-actions align="center">
        <q-btn
          flat
          class="orange-btn-filled"
          color="white"
          label="Ok"
          no-caps
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import SurveyAnswersService from "../../services/surveyAnswers.service";
import ErrorModal from "../../components/userProfile-components/ErrorModal.vue";
export default {
  name: "RatingPage",
  components: {
    ErrorModal,
  },
  setup() {
    const route = useRoute();
    const token = ref("");
    const isSuccess = ref(false);
    const currentDate = ref(new Date().toISOString());
    const ratingModel = ref(parseInt(route.params.rateValue));
    const showInfoDialog = ref(false);
    const suggestionText = ref("");

    onMounted(async () => {
      token.value = route.params.token;
    });

    const submitVotes = async () => {
      currentDate.value = new Date().toISOString();
      var r = await SurveyAnswersService.setRatings(
        ratingModel,
        currentDate,
        token,
        suggestionText
      );
      if (r) {
        isSuccess.value = true;
        showInfoDialog.value = true;
      }
    };



    return {
      token,
      ratingModel,
      isSuccess,
      route,
      showInfoDialog,
      submitVotes,
      currentDate,
      suggestionText,
      requiredFieldRule: [
        (val) => (val && val.length > 0) || "Veld is verplicht!",
      ],
    };
  },
};
</script>
