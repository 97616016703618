<template>
  <ErrorModal />
  <q-dialog v-model="show" persistent class="save-search-main">
    <q-card style="min-width: 350px" class="save-dialog">
      <q-card-section>
        <div style="color: #0a108f">{{ t('save_search.save_search') }}</div>
      </q-card-section>

      <q-separator />

      <q-card-section class="q-pt-none q-pa-md">
        <div class="row justify-around">
          <q-select
            outlined
            bg-color="white"
            v-model="savedSearch.folder"
            :options="folders"
            option-label="name"
            :label="t('save_search.select_folder')"
            stack-label
            behavior="menu"
            style="width: 100%"
            dense
            :rules="[requiredSelectRule]"
            :disable="showInput == true"
          >
          </q-select>
        </div>
        <div class="row justify-around">
          <a
            class="q-mb-md"
            v-if="savedSearch.folder == null"
            @click="showInput = true"
          >
            <i class="las la-1x la-plus"></i>
            {{ t('save_scheme.create_new_folder') }}
          </a>
          <q-input
            v-if="showInput && savedSearch.folder == null"
            dense
            v-model="savedSearch.newFolderName"
            borderless
            bg-color="white"
            stack-label
            :placeholder="t('save_scheme.enter_folder_name')"
            :rules="requiredFieldRule"
            style="width: 100%"
          />
        </div>
        <div class="row justify-around">
          <q-input
            dense
            v-model="savedSearch.title"
            borderless
            bg-color="white"
            :label="t('save_search.saved_search_title')"
            stack-label
            :placeholder="t('save_search.enter_name')"
            :rules="requiredFieldRule"
            style="width: 100%"
          />
        </div>
      </q-card-section>

      <q-separator />

      <q-card-actions align="around">
        <q-btn
          class="orange-btn-filled"
          no-caps
          flat
          :label="t('search_schemes.save')"
          @click="saveSearch()"
        />
        <q-btn
          flat
          class="orange-btn-cancel"
          :label="t('common.cancel')"
          @click="disableSave()"
          no-caps
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, onMounted, reactive, computed } from 'vue';
import store from '../../store/index.js';
import ErrorModal from '../userProfile-components/ErrorModal.vue';
import GrantsService from '../../services/grants.service.js';
import UserService from '../../services/userprofile.service';
import { useI18n } from 'vue-i18n';

export default {
  props: {
    showSave: Boolean,
  },
  components: {
    ErrorModal,
  },
  setup(props) {
    const show = ref(props.showSave);
    const showInput = ref(false);

    const savedSearch = reactive({
      title: '',
      folder: null,
      newFolderName: '',
    });

    onMounted(() => {
      UserService.getFolders();
    });

    const folders = computed({
      get: () => store.state.user.folders,
    });

    const disableSave = () => {
      store.commit('user/disableSave');
      showInput.value = false;
      savedSearch.folder = null;
      savedSearch.newFolderName = '';
    };

    const saveSearch = async () => {
      store.commit('advancedSearch/createOrganisationsIncludeString');
      store.commit('advancedSearch/createOrganisationsExcludeString');
      store.commit('user/saveSchemesSearch');

      await GrantsService.saveSearch(
        savedSearch,
        store.state.user.savedSchemesSearch
      );
    };

    const { t } = useI18n();

    return {
      t,
      disableSave,
      show,
      saveSearch,
      savedSearch,
      folders,
      showInput,
      requiredFieldRule: [
        (val) => (val && val.length > 0) || t('common.required_field'),
      ],
      requiredSelectRule(val) {
        if (val === null) {
          return t('common.required_field');
        }
      },
    };
  },
};
</script>
