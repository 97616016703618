<template>
  <ErrorModal />
  <q-dialog
    v-model="shareGrant"
    class="share-grant-main"
    style="width: 850px"
    full-width
  >
    <q-card class="share-dialog" style="width: 850px">
      <q-form
        @submit="shareSchemeEmit(recipientsRows, message, grant.id, reportType)"
      >
        <q-card-section class="header-section">
          <div
            class="text-h5 text-main bold-font"
            :class="!isVind ? 'portal-colors' : ''"
          >
            {{ t("share_and_download_schemes.share_item") }}
          </div>
        </q-card-section>

        <q-card-section :class="!isVind ? 'q-pa-none' : ''">
          <div class="share-text" :style="!isVind ? 'display:none' : ''">
            <div class="row subtext">
              <div class="column">
                <div class="row">
                  <div class="column">
                    <span class="share-info bold">
                      {{ t("share_and_download_schemes.item_to_share") }}
                    </span>

                    <span class="share-info">
                      {{ grantName }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </q-card-section>
        <div class="row recipients-container">
          <div class="add-recipient">
            <div class="share-text-sm">
              <span class="share-info share-input">
                {{ t("share_and_download_schemes.name") }}
              </span>
              <q-select
                class="q-mt-sm"
                borderless
                bg-color="white"
                :model-value="name"
                dense
                :rules="requiredFieldRule"
                :label="t('share_and_download_schemes.add_name')"
                @filter="filterNamesFn"
                :options="filterNamesOptions"
                option-value="name"
                option-label="name"
                use-input
                input-debounce="0"
                behavior="menu"
                @update:model-value="(val) => updateSelectedName(val)"
                hide-dropdown-icon
                @input-value="setName"
                fill-input
                hide-selected
              />
            </div>

            <div v-if="isVind" class="share-text-sm">
              <span class="share-info share-input">
                {{ t("share_and_download_schemes.organization_name") }}
              </span>
              <q-input
                class="q-mt-sm input-share"
                borderless
                bg-color="white"
                v-model="accountName"
                dense
                :rules="requiredFieldRule"
                :label="t('share_and_download_schemes.add_organization')"
              />
            </div>

            <div class="share-text-sm recipients">
              <span class="share-info share-input">
                {{ t("login.e-mail_address") }}
              </span>
              <q-input
                class="q-mt-sm mail-input"
                borderless
                bg-color="white"
                v-model="address"
                dense
                type="email"
                :rules="[isValidEmail, requiredFieldRule, isUniqueEmail]"
                :label="t('share_and_download_schemes.add_email_address')"
                ref="emailInput"
              />
            </div>

            <q-icon
              name="add_circle"
              size="26px"
              class="action-icon q-ml-sm"
              @click="addRecipient(name, accountName, address)"
            >
              <q-tooltip>{{
                t("share_and_download_schemes.add_recipient")
              }}</q-tooltip></q-icon
            >
          </div>
          <div class="recipients-list">
            <div
              class="add-recipient"
              v-for="recipient in recipientsRows"
              :key="recipient.name"
            >
              <div class="share-text-sm">
                <q-input
                  class="q-mt-sm"
                  outlined
                  v-model="recipient.name"
                  dense
                  :rules="requiredFieldRule"
                  :label="t('share_and_download_schemes.name')"
                  disable
                />
              </div>

              <div v-if="isVind" class="share-text-sm">
                <q-input
                  class="q-mt-sm"
                  outlined
                  v-model="recipient.organisation"
                  dense
                  :rules="requiredFieldRule"
                  :label="t('share_and_download_schemes.organization')"
                  disable
                />
              </div>

              <div class="share-text-sm recipients">
                <q-input
                  class="q-mt-sm mail-input"
                  outlined
                  v-model="recipient.email"
                  dense
                  type="email"
                  :rules="[isValidEmail, requiredFieldRule, isUniqueEmail]"
                  :label="t('login.e-mail_address')"
                  disable
                />
              </div>
              <i
                class="las la-2x la-plus-circle action-icon q-ml-sm"
                @click="addRecipient(name, accountName, address)"
              >
                <q-tooltip>{{
                  t("share_and_download_schemes.add_recipient")
                }}</q-tooltip></i
              >
            </div>
          </div>
        </div>

        <q-card-section class="middle-section" v-if="isVind">
          <div class="share-text-sm">
            <span class="share-info">
              {{ t("share_and_download_schemes.message") }} *
            </span>
            <q-input
              :rules="requiredFieldRule"
              class="q-mt-sm textarea-input"
              v-model="message"
              outlined
              type="textarea"
              bg-color="white"
            />
          </div>
        </q-card-section>

        <q-card-section class="report-type" v-if="isVind">
          <q-select
            square
            borderless
            bg-color="white"
            v-model="reportType"
            :options="options"
            :label="t('share_and_download_schemes.type_of_report')"
          />
        </q-card-section>

        <q-card-actions class="q-mt-sm bold-font" align="left">
          <q-btn
            flat
            :label="t('search_schemes.share')"
            class="orange-btn-filled"
            color="white"
            no-caps
            style="width: 70px"
            type="submit"
          />
          <q-btn
            flat
            :label="t('common.cancel')"
            no-caps
            class="orange-btn-cancel"
            v-close-popup
            style="width: 100px"
            @click="clearInputs()"
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import { computed, ref } from "vue";
import store from "../../store/index";
import MailingService from "../../services/mailing.service";
import ErrorModal from "./ErrorModal.vue";
import SuggestionsService from "../../services/suggestions.service";
import { useI18n } from "vue-i18n";

export default {
  components: {
    ErrorModal,
  },
  setup() {
    const { t } = useI18n();
    const shareGrant = computed({
      get: () => store.state.grantItems.shareGrantItem,
      set: (val) => store.commit("grantItems/disableShareGrantItem", val),
    });

    const grant = computed({
      get: () => store.state.grantItems.grantToShare,
    });
    const grantName = computed(() => {
      return grant.value.name.replace(/<\/?span[^>]*>/g, "");
    });

    const shareSchemeEmit = async (recipientsRows, message, id, reportType) => {
      var r = [];
      if (recipientsRows.length == 0) {
        r.push({
          receiverName: name.value,
          receiverOrganisationName: accountName.value,
          receiverEmail: address.value,
        });
      }
      for (let i = 0; i < recipientsRows.length; i++) {
        r.push({
          receiverName: recipientsRows[i].name,
          receiverOrganisationName: recipientsRows[i].organisation,
          receiverEmail: recipientsRows[i].email,
        });
      }
      await MailingService.shareSchemeMultiple(r, message, id, reportType);
      clearInputs();
    };

    const address = ref("");
    const message = ref("");
    const accountName = ref("");
    const name = ref("");

    const reportType = ref({
      label: "Volledig rapport",
      value: 0,
    });
    const emailInput = ref(null);
    const recipientsList = ref([]);
    const addRecipient = (recieverName, organisation, email) => {
      const emailPattern =
        /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
      var emailExists = false;
      for (let i = 0; i < recipientsRows.value.length; i++) {
        if (recipientsRows.value[i].email == email) {
          emailExists = true;
          break;
        }
      }
      if (
        isVind.value &&
        !emailExists &&
        emailPattern.test(email) &&
        recieverName.length > 0 &&
        organisation.length > 0
      ) {
        recipientsRows.value.push({
          name: recieverName,
          organisation: organisation,
          email: email,
        });

        address.value = "";
        name.value = "";
        accountName.value = "";
      } else if (
        !isVind.value &&
        !emailExists &&
        emailPattern.test(email) &&
        recieverName.length > 0
      ) {
        recipientsRows.value.push({
          name: recieverName,
          email: email,
        });
        address.value = "";
        name.value = "";
      }
    };

    const recipientsColumns = ref([
      {
        name: "name",
        align: "left",
        label: computed(() => t("share_and_download_schemes.name")),
        field: "name",
      },
      {
        name: "organisation",
        align: "left",
        label: computed(() =>
          t("share_and_download_schemes.organization_name")
        ),
        field: "organisation",
      },
      {
        name: "email",
        align: "left",
        label: computed(() => t("login.e-mail")),
        field: "email",
      },
      { name: "actions", align: "center" },
    ]);

    const recipientsRows = ref([]);

    const userId = computed({
      get: () => store.state.user.userId,
    });

    const nameOptions = computed({
      get: () => store.state.suggestions.suggestions,
    });

    const filterNamesOptions = ref([]);
    const updateSelectedName = (val) => {
      if (val != null) {
        if (val.name && val.name.length > 0) {
          name.value = val.name;
          accountName.value = val.organisation;
          address.value = val.email;
        } else if (val && val.length > 0) {
          name.value = val;
        }
      }
    };
    const isVind = computed({
      get: () => store.state.auth.env === 0 || store.state.auth.env === 40,
    });
    const clearInputs = () => {
      recipientsRows.value = [];
      message.value = "";
      address.value = "";
      accountName.value = "";
      name.value = "";
    };

    const setName = (val) => {
      name.value = val;
    };

    const options = ref([
      {
        label: computed(() => t("share_and_download_schemes.full_report")),
        value: 0,
      },
      {
        label: computed(() => t("share_and_download_schemes.only_core_data")),
        value: 10,
      },
      {
        label: computed(() =>
          t("share_and_download_schemes.key_data_and_summaries")
        ),
        value: 20,
      },
    ]);

    return {
      options,
      t,
      setName,
      clearInputs,
      updateSelectedName,
      filterNamesOptions,
      isVind,
      nameOptions,
      userId,
      emailInput,
      recipientsColumns,
      recipientsRows,
      recipientsList,
      addRecipient,
      reportType,
      name,
      accountName,
      message,
      address,
      shareSchemeEmit,
      shareGrant,
      grant,
      grantName,

      requiredFieldRule: [
        (val) => {
          if (recipientsRows.value.length == 0)
            return (val && val.length > 0) || t("common.required_field");
        },
      ],
      isValidEmail(val) {
        const emailPattern =
          /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
        if (recipientsRows.value.length == 0)
          return emailPattern.test(val) || t("common.invalid_email_address");
      },
      isUniqueEmail(val) {
        var emailExists = false;
        for (let i = 0; i < recipientsRows.value.length; i++) {
          if (recipientsRows.value[i].email == val) {
            emailExists = true;
            break;
          }
        }
        return (
          emailExists == false || t("news_details.existing_email_validation")
        );
      },

      filterNamesFn(val, update) {
        update(() => {
          if (val && val.length > 0) {
            const needle = val.toLowerCase();
            SuggestionsService.getSuggestions(val);

            filterNamesOptions.value = nameOptions.value.filter(
              (v) => v.name.toLowerCase().indexOf(needle) > -1
            );
          } else {
            filterNamesOptions.value = [];
          }
        });
      },
    };
  },
};
</script>
