import axios from "axios";
import authHeader from "../helpers/auth-header";
import axiosUrl from "./axios.service";
import store from "../store/index";
import authService from "./auth.service";


const API_URL = axiosUrl.getAxiosUrl();
class SurveyAnswersService {
    async setRatings(ratingValue, ratingDateTime, token, suggestionText) {
        return axios
          .post(
            API_URL + "/surveyAnswers/setRatings",
            {
              token: token.value,
              ratingValue: ratingValue.value,
              ratingDateTime: ratingDateTime.value,
              suggestion: suggestionText.value,
            },
            {
              headers: authHeader(),
            }
          )
          .then((res) => {
            if (res.data.success) {
              return true;
            } else {
              store.commit("user/updateModalError", res.data.message),
                store.commit("user/enableModalError");
              return false;
            }
          })
          .catch((error) => {
            if (error)
              store.commit("user/updateModalError", error.response.data),
                store.commit("user/enableModalError");
          });
      }

      async setReactivationSuggestion(suggestion, token){
        return axios
        .post(
          API_URL + "/surveyAnswers/setReactivationSuggestion",
          {
            token: token.value,
            suggestion: suggestion.value,
          },
          {
            headers: authHeader(),
          }
        )
        .then((res) =>{
          if (res.data.success) {
            return true;
          } else {
            store.commit("user/updateModalError", res.data.message),
              store.commit("user/enableModalError");
            return false;
          }
        })
        .catch((error) => {
          if (error)
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
        });
      }

      async deactivationFromSurvey(suggestion,token){
        return axios
        .post(
          API_URL + "/surveyAnswers/deactivationFromSurvey",
          {
            token: token.value,
            suggestion: suggestion.value,
          },
          {
            headers: authHeader(),
          }
        )
        .then((res) =>{
          if (res.data.success) {
            authService.logout();
            return true;
          } else {
            store.commit("user/updateModalError", res.data.message),
              store.commit("user/enableModalError");
            return false;
          }
        })
        .catch((error) => {
          if (error)
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
        });
      }
      async registerFromSurvey(token, registerDto){
        return axios
        .post(
          API_URL + "/surveyAnswers/registerFromSurvey",
          {
            token: token.value,
            registerSurvey: registerDto,
          },
          {
            headers: authHeader(),
          }
        )
        .then((res) =>{
          if (res.data.success) {
            return true;
          } else {
            store.commit("user/updateModalError", res.data.message),
              store.commit("user/enableModalError");
            return false;
          }
        })
        .catch((error) => {
          if (error)
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
        });
      }
}
export default new SurveyAnswersService();