import { createI18n } from 'vue-i18n';
import authService from '../services/auth.service';
import filtersService from '../services/filters.service';
import schemeUtils from "../utils/schemes";

const supportedLocales = ['nl', 'de'];

let i18nInstance = null;

async function initializeI18n() {
  try {
    const defaultLocale = await authService.getLanguageCode(); // Fetch the locale from the server
    const messages = await loadMessages(defaultLocale);

    i18nInstance = createI18n({
      locale: defaultLocale, // Set the locale to the fetched locale
      fallbackLocale: defaultLocale == 'nl' ? 'de' : 'nl',
      legacy: false, // you must set `false` to use Composition API,
      messages:{
        [defaultLocale]: messages.default
      }
    });

    return i18nInstance;
  } catch (error) {
    console.error('Error initializing i18n', error);
    return createFallbackI18n();
  }
}

async function loadMessages(locale) {
    const messages = await import(`./locales/${locale}.json`);
    return messages;
}

function createFallbackI18n() {
  // Return a fallback i18n instance with default settings if dynamic loading fails
  return createI18n({
    locale: 'nl',
    fallbackLocale: 'nl',
    legacy: false,
    messages: {}
  });
}

// Function to switch language
async function switchLanguage(newLocale) {
  try {
    const messages = await loadMessages(newLocale); // Loading of translation files before the locale is switched
    i18nInstance.global.setLocaleMessage(newLocale, messages); // Set messages for the new locale
    i18nInstance.global.locale.value = newLocale; // Update the locale
    localStorage.setItem("user-locale", newLocale); // Save the chosen locale into local storage, will be useful if we implement 'guessing' the preferred locale
    await filtersService.getDefaultFilters(newLocale);
    await schemeUtils.searchSchemes(i18nInstance.global.locale);
  } catch (error) {
    console.error('Error switching language', error);
  }
}

export { initializeI18n, supportedLocales, switchLanguage, loadMessages };
