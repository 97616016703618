<template>
  <div class="news-item">
    <div v-if="title">
      <div class="column news-item-top">
        <router-link
          :to="{ name: 'NewsDetails', params: { newsId: id } } "
          target="_blank"
          style="text-decoration: none"
        >
          <div class="title bold-font"><span v-html="title"></span></div>
        </router-link>
        <div class="row">
          <span class="date">Gepubliceerd op: {{ formatDate(date) }}</span>
        </div>
      </div>

      <div v-html="introduction" class="desc-short" v-if="introduction"></div>

      <div v-if="showMore && Body">
        <q-separator />
        <div v-html="Body" class="desc-body"></div>
      </div>

      <span v-if="showMore" @click="toggleShowMore()" class="show-more"
        >{{ t('search_news.read_less') }}
        <q-icon size="14px" class="heading-icon">
          <img
            class="svg-icon"
            src="../../assets/icons/chevron-left-solid.svg"
          /> </q-icon
      ></span>
      <span v-if="!showMore" @click="toggleShowMore()" class="show-more"
        >{{ t('search_news.read_more') }}
        <q-icon size="14px" class="heading-icon">
          <img
            class="svg-icon"
            src="../../assets/icons/chevron-right-solid.svg"
          /> </q-icon
      ></span>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import moment from 'moment';
import newsService from '../../services/news.service';
export default {
  props: {
    newsTitle: String,
    intro: String,
    id: String,
    newsItem: Object,
    body: String,
  },
  setup(props) {
    const title = computed(() => {
      return props.newsTitle;
    });
    const introduction = computed(() => {
      return props.intro;
    });

    const date =
      props.newsItem.date != null
        ? moment(props.newsItem.date).format('YYYY-MM-DD')
        : null;
    const Body = ref(props.body);

    const showMore = ref(false);

    const toggleShowMore = () => {
      if (!showMore.value) {
        logNewsVisit();
      }
      showMore.value = !showMore.value;
    };

    const formatDate = (stringDate) => {
      const dateN = new Date(stringDate);
      return moment(dateN).format('DD-MM-YYYY');
    };

    const logNewsVisit = () => {
      newsService.recordVisitToNews(props.id);
    };
    return {
      logNewsVisit,
      formatDate,
      title,
      introduction,
      showMore,
      toggleShowMore,
      date,
      Body,
    };
  },
};
</script>
