<template>
  <q-dialog class="error-dialog" v-model="small">
    <q-card style="width: 300px">
      <q-card-section>
        <div class="text-h6">{{ t('common.wrong') }}</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <p class="error-message">{{ errorMessage }}</p>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn class="orange-btn-filled" flat label="OK" @click="disableModal()" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, computed } from 'vue';
import store from '../../store/index';

export default {
  setup() {
    const errorMessage = computed({
      get: () => store.state.user.modalErrorMessage,
    });

    const isModalActive = computed({
      get: () => store.state.user.hasModalError,
    });

    const disableModal = () => {
      store.commit('user/disableModalError');
    };

    return {
      small: ref(isModalActive),
      errorMessage,
      isModalActive,
      disableModal,
    };
  },
};
</script>
