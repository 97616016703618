import store from '../index';
import moment from 'moment';

const state = () => ({
  schemeStatusHeading: '',
  sortByOptions: [],
  general: [],
  showSchemes: true,
  showCalls: false,
  resultTypesString: '',
  showOpen: true,
  showForthcoming: true,
  showClosed: true,
  showDiscontinued: false,
  statusesString: '',
  pageCount: 0,
  numberOfResults: 0,
  currentPage: 1,
  searchText: '',
  audience: { options: [], label: '' },
  //This is just hidden for now, don't remove it:
  //industries: { options: [], label: '' },
  locations: { options: [], label: '' },
  purposes: { options: [], label: '' },
  schemeTypes: { options: [], label: '' },
  projectTypes: { options: [], label: '' },
  selectedAudiences: '',
  selectedLocations: '',
  //This is just hidden for now, don't remove it:
  // selectedIndustries: '',
  selectedPurposes: '',
  selectedSchemeTypes: '',
  selectedProjectTypes: '',
  searchIdentifier: '',
  searchAccronym: '',
  searchIntroduction: '',
  minBudget: null,
  maxBudget: null,
  organisationsInclude: [],
  organisationsExclude: [],
  startDate: '',
  endDate: '',
  organisationsIncludeString: '',
  organisationsExcludeString: '',
  locationsOptions: [{}],
  locationsSelectArray: [],
  locationsOptionsStrings: [],
  selectedLocationsStringsArray: [],
  activeFiltersCount: 0,
  selectedSort: '',
  daysRange: {
    from: '',
    to: '',
  },
  areFiltersChanged: true,
  applySavedSearchPause: false,
  isResetSearch: false,
  isApplyingFiltersReset: false,
  isSearchNewsWithSchemesFilters: false,
  displayNumberOfNewsResults: false,
  maxScoreOnCurrentPage: 0,
  maxScore: 0,
  portalDrawerLeft: false,

  // new portal filters
  // difficulty (Moeilijkheid)
  showVeryDifficult: false,
  showDifficult: false,
  showAverageDiff: false,
  showSimpleDiff: false,
  showVerySimpleDiff: false,
  difficultiesString: '',
  // chance of success (Slaagkans)
  showVerySmallChance: false,
  showSmallChance: false,
  showAverageChance: false,
  showBigChance: false,
  showVeryBigChance: false,
  chancesString: '',
  // time for application (Tijdsuur)
  showVerySlow: false,
  showSlow: false,
  showAverageApplicationTime: false,
  showFast: false,
  showVeryFast: false,
  applicationTimeString: '',
  portalNewsDrawerLeft: false,
});

const getters = {
  showAdvancedSearch: (state) => {
    return state.showAdvancedSearch;
  },
  getAllFilters: (state) => {
    return state.filterActivities;
  },

  getSchemeStatuses: (state) => {
    return state.schemeStatuses;
  },
};

const actions = {};

const mutations = {
  // 5 methods i string za application rating
  updateVerySlowApplicationTime(state, value) {
    state.showVerySlow = value;
    sessionStorage.setItem('showVerySlow', value.toString());
    store.commit('advancedSearch/createApplicationTimeString');
  },
  updateSlowApplicationTime(state, value) {
    state.showSlow = value;
    sessionStorage.setItem('showSlow', value.toString());
    store.commit('advancedSearch/createApplicationTimeString');
  },
  updateShowAverageApplicationTime(state, value) {
    state.showAverageApplicationTime = value;
    sessionStorage.setItem('showAverageApplicationTime', value.toString());
    store.commit('advancedSearch/createApplicationTimeString');
  },
  updateShowFastApplicationTime(state, value) {
    state.showFast = value;
    sessionStorage.setItem('showFast', value.toString());
    store.commit('advancedSearch/createApplicationTimeString');
  },
  updateShowVeryFastApplicationTime(state, value) {
    state.showVeryFast = value;
    sessionStorage.setItem('showVeryFast', value.toString());
    store.commit('advancedSearch/createApplicationTimeString');
  },
  createApplicationTimeString(state) {
    state.applicationTimeString = '';
    if (state.showVeryFast) {
      state.applicationTimeString += '1,';
    }
    if (state.showFast) {
      state.applicationTimeString += '2,';
    }
    if (state.showAverageApplicationTime) {
      state.applicationTimeString += '3,';
    }
    if (state.showSlow) {
      state.applicationTimeString += '4,';
    }
    if (state.showVerySlow) {
      state.applicationTimeString += '5,';
    }
    state.applicationTimeString.split(' ').join(';');

    sessionStorage.setItem(
      'applicationTimeString',
      state.applicationTimeString
    );

    // FOR PORTAL UPDATE FILTERS COUNT -- in updat emethod check if portal for these
    store.commit('advancedSearch/updateActiveFiltersCount');
    // update active filters count (only for portal!)
  },

  updateShowVerySmallChance(state, value) {
    state.showVerySmallChance = value;
    sessionStorage.setItem('showVerySmallChance', value.toString());
    store.commit('advancedSearch/createChancesString');
  },
  updateShowSmallChance(state, value) {
    state.showSmallChance = value;
    sessionStorage.setItem('showSmallChance', value.toString());
    store.commit('advancedSearch/createChancesString');
  },
  updateAverageChance(state, value) {
    state.showAverageChance = value;
    sessionStorage.setItem('showAverageChance', value.toString());
    store.commit('advancedSearch/createChancesString');
  },
  updateBigChance(state, value) {
    state.showBigChance = value;
    sessionStorage.setItem('showBigChance', value.toString());
    store.commit('advancedSearch/createChancesString');
  },
  updateVeryBigChance(state, value) {
    state.showVeryBigChance = value;
    sessionStorage.setItem('showVeryBigChance', value.toString());
    store.commit('advancedSearch/createChancesString');
  },
  createChancesString(state) {
    state.chancesString = '';
    if (state.showVeryBigChance) {
      state.chancesString += '5,';
    }
    if (state.showBigChance) {
      state.chancesString += '4,';
    }
    if (state.showAverageChance) {
      state.chancesString += '3,';
    }
    if (state.showSmallChance) {
      state.chancesString += '2,';
    }
    if (state.showVerySmallChance) {
      state.chancesString += '1,';
    }
    state.chancesString.split(' ').join(';');

    sessionStorage.setItem('chancesString', state.chancesString);

    // FOR PORTAL UPDATE FILTERS COUNT -- in updat emethod check if portal for these

    store.commit('advancedSearch/updateActiveFiltersCount');
    // update active filters count (only for portal!)
  },

  updateShowVeryDifficult(state, value) {
    state.showVeryDifficult = value;
    sessionStorage.setItem('showVeryDifficult', value.toString());
    store.commit('advancedSearch/createDifficultyString');
  },
  updateShowDifficult(state, value) {
    state.showDifficult = value;
    sessionStorage.setItem('showDifficult', value.toString());
    store.commit('advancedSearch/createDifficultyString');
  },
  updateShowAverageDiff(state, value) {
    state.showAverageDiff = value;
    sessionStorage.setItem('showAverageDiff', value.toString());
    store.commit('advancedSearch/createDifficultyString');
  },

  updateShowSimpleDiff(state, value) {
    state.showSimpleDiff = value;
    sessionStorage.setItem('showSimpleDiff', value.toString());
    store.commit('advancedSearch/createDifficultyString');
  },

  updateShowVerySimpleDiff(state, value) {
    state.showVerySimpleDiff = value;
    sessionStorage.setItem('showVerySimpleDiff', value.toString());
    store.commit('advancedSearch/createDifficultyString');
  },

  createDifficultyString(state) {
    state.difficultiesString = '';
    if (state.showVeryDifficult) {
      state.difficultiesString += '5,';
    }
    if (state.showDifficult) {
      state.difficultiesString += '4,';
    }
    if (state.showAverageDiff) {
      state.difficultiesString += '3,';
    }
    if (state.showSimpleDiff) {
      state.difficultiesString += '2,';
    }
    if (state.showVerySimpleDiff) {
      state.difficultiesString += '1,';
    }
    state.difficultiesString.split(' ').join(';');

    sessionStorage.setItem('difficultiesString', state.difficultiesString);

    store.commit('advancedSearch/updateActiveFiltersCount');
    // update active filters count (only for portal!)
  },

  updatePortalDrawerLeft(state, val) {
    state.portalDrawerLeft = val;
  },
  updatePortalNewsDrawerLeft(state, val) {
    state.portalNewsDrawerLeft = val;
  },

  updateDisplayNumberOfNewsResults(state, val) {
    state.displayNumberOfNewsResults = val;
  },
  updateIsSearchNewsWithSchemesFilters(state, val) {
    state.isSearchNewsWithSchemesFilters = val;
  },

  updateIsApplyingFiltersReset(state, val) {
    state.isApplyingFiltersReset = val;
  },
  updateIsResetSearch(state, val) {
    state.isResetSearch = val;
  },
  updateapplySavedSearchPause(state, val) {
    state.applySavedSearchPause = val;
  },

  disableFiltersChange(state) {
    state.areFiltersChanged = false;
  },
  enableFiltersChange(state) {
    state.areFiltersChanged = true;
  },

  updateDaysRange(state, val) {
    state.daysRange.from = val.from;
    state.daysRange.to = val.to;
    state.startDate = val.from;
    state.endDate = val.to;

    sessionStorage.setItem('startDate', JSON.stringify(val.from));
    sessionStorage.setItem('endDate', JSON.stringify(val.to));
    sessionStorage.setItem('daysRange', JSON.stringify(val));

    store.commit('advancedSearch/updateActiveFiltersCount');
  },

  updateSelectedSort(state, value) {
    state.selectedSort = value;
  },

  updateActiveFiltersCount(state) {
    state.activeFiltersCount = 0;
    //This is just hidden for now, don't remove it:
    // if (state.selectedIndustries.length > 0) state.activeFiltersCount += 1;
    if (
      state.selectedAudiences.length > 0 ||
      (sessionStorage.getItem('selectedAudiences') != null &&
        sessionStorage.getItem('selectedAudiences').length > 0)
    )
      state.activeFiltersCount += 1;
    if (
      state.selectedPurposes.length > 0 ||
      (sessionStorage.getItem('selectedPurposes') != null &&
        sessionStorage.getItem('selectedPurposes').length > 0)
    )
      state.activeFiltersCount += 1;
    if (
      state.selectedLocations.length > 0 ||
      (sessionStorage.getItem('selectedLocations') != null &&
        sessionStorage.getItem('selectedLocations').length > 0)
    )
      state.activeFiltersCount += 1;
    if (
      state.selectedSchemeTypes.length > 0 ||
      (sessionStorage.getItem('selectedSchemeTypes') != null &&
        sessionStorage.getItem('selectedSchemeTypes').length > 0)
    )
      state.activeFiltersCount += 1;

    if (state.selectedProjectTypes.length > 0) state.activeFiltersCount += 1;
    if (
      sessionStorage.getItem('statusesString') != null &&
      sessionStorage.getItem('statusesString').length > 0
    )
      state.activeFiltersCount += 1;
    if (
      (state.startDate != '' && state.startDate != null) ||
      (JSON.parse(sessionStorage.getItem('startDate')) != '' &&
        JSON.parse(sessionStorage.getItem('startDate')) != null) ||
      (state.endDate != '' && state.endDate != null) ||
      (JSON.parse(sessionStorage.getItem('endDate')) != '' &&
        JSON.parse(sessionStorage.getItem('endDate')) != null)
    )
      state.activeFiltersCount += 1;
    if (
      (state.minBudget != null && state.minBudget > 0) ||
      (sessionStorage.getItem('minBudget') != null &&
        Number(sessionStorage.getItem('minBudget')) > 0) ||
      (state.maxBudget != null && state.maxBudget > 0) ||
      (sessionStorage.getItem('maxBudget') != null &&
        Number(sessionStorage.getItem('maxBudget')) > 0)
    )
      state.activeFiltersCount += 1;

    if (
      (state.organisationsExclude && state.organisationsExclude.length > 0) ||
      (JSON.parse(sessionStorage.getItem('organisationsExclude')) != null &&
        JSON.parse(sessionStorage.getItem('organisationsExclude')).length >
          0) ||
      (state.organisationsInclude && state.organisationsInclude.length > 0) ||
      (JSON.parse(sessionStorage.getItem('organisationsInclude')) != null &&
        JSON.parse(sessionStorage.getItem('organisationsInclude')).length > 0)
    )
      state.activeFiltersCount += 1;

    if (store.state.advancedFilters.sme || store.state.advancedFilters.notSme)
      state.activeFiltersCount += 1;

    if (
      store.state.advancedFilters.before ||
      store.state.advancedFilters.after ||
      store.state.advancedFilters.beforeAndAfter
    )
      state.activeFiltersCount += 1;

    if (
      store.state.advancedFilters.regional ||
      store.state.advancedFilters.national ||
      // store.state.advancedFilters.local ||
      store.state.advancedFilters.european
    )
      state.activeFiltersCount += 1;

    if (
      sessionStorage.getItem('chancesString') != null &&
      sessionStorage.getItem('chancesString').length > 0
    )
      state.activeFiltersCount += 1;

    if (
      sessionStorage.getItem('difficultiesString') != null &&
      sessionStorage.getItem('difficultiesString').length > 0
    )
      state.activeFiltersCount += 1;

    if (
      sessionStorage.getItem('applicationTimeString') != null &&
      sessionStorage.getItem('applicationTimeString').length > 0
    )
      state.activeFiltersCount += 1;
  },

  clearSearchText(state) {
    state.searchText = '';
  },

  clearNewsletterFilters(state) {
    state.selectedLocations = '';
    state.selectedLocationsStringsArray = [];
    sessionStorage.removeItem('selectedLocations');
    sessionStorage.removeItem('locationsSelectArray');
    state.selectedAudiences = '';
    sessionStorage.removeItem('selectedAudiences');
    state.selectedPurposes = '';
    sessionStorage.removeItem('selectedPurposes');
    //This is just hidden for now, don't remove it:
    // state.selectedIndustries = '';
  },

  resetSearchFilter(state, clear) {
    state.searchText = '';
    state.minBudget = null;
    state.maxBudget = null;
    state.organisationsExclude = [];
    state.organisationsInclude = [];
    state.startDate = null;
    state.endDate = null;
    state.selectedSchemeTypes = '';
    state.selectedProjectTypes = '';
    state.showVeryDifficult = false;
    state.showDifficult = false;
    state.showAverageDiff = false;
    state.showSimpleDiff = false;
    state.showVerySimpleDiff = false;
    state.difficultiesString = '';
    state.showVerySmallChance = false;
    state.showSmallChance = false;
    state.showAverageChance = false;
    state.showBigChance = false;
    state.showVeryBigChance = false;
    state.chancesString = '';
    state.showVerySlow = false;
    state.showSlow = false;
    state.showAverageApplicationTime = false;
    state.showFast = false;
    state.showVeryFast = false;
    state.applicationTimeString = '';

    sessionStorage.clear();

    if (clear) {
      store.commit('advancedSearch/updateShowOpen', false);
      store.commit('advancedSearch/updateShowClosed', false);
      store.commit('advancedSearch/updateShowForthcoming', false);
      store.commit('advancedSearch/updateShowDiscontinued', false);
    } else {
      store.commit('advancedSearch/updateShowOpen', true);
      store.commit('advancedSearch/updateShowClosed', true);
      store.commit('advancedSearch/updateShowForthcoming', true);
      store.commit('advancedSearch/updateShowDiscontinued', false);
    }
    store.state.advancedFilters.sme = false;
    store.state.advancedFilters.notSme = false;
    store.state.advancedFilters.after = false;
    store.state.advancedFilters.before = false;
    store.state.advancedFilters.beforeAndAfter = false;
    store.state.advancedFilters.european = false;
    store.state.advancedFilters.regional = false;
    store.state.advancedFilters.national = false;
    state.selectedLocations = '';
    state.selectedLocationsStringsArray = [];
    state.selectedAudiences = '';
    state.selectedPurposes = '';
    //This is just hidden for now, don't remove it:
    // state.selectedIndustries = '';
    // }
  },
  fillFiltersWithSavedValues(state, val) {
    state.statusesString = val.statusses;
    sessionStorage.setItem('statusesString', val.statusses);
    sessionStorage.setItem(
      'minBudget',
      val.minAmount != null ? val.minAmount.toString() : '0'
    );
    sessionStorage.setItem(
      'maxBudget',
      val.maxAmount != null ? val.maxAmount.toString() : '0'
    );
    sessionStorage.setItem(
      'startDate',
      val.callStartDate == null || val.callStartDate == ''
        ? null
        : JSON.stringify(moment(val.callStartDate).format('DD-MM-YYYY'))
    );
    sessionStorage.setItem(
      'endDate',
      val.callEndDate == null || val.callEndDate == ''
        ? null
        : JSON.stringify(moment(val.callEndDate).format('DD-MM-YYYY'))
    );
    let range = {
      from:
        val.callStartDate == null
          ? null
          : JSON.stringify(moment(val.callStartDate).format('DD-MM-YYYY')),
      to:
        val.callEndDate == null
          ? null
          : JSON.stringify(moment(val.callEndDate).format('DD-MM-YYYY')),
    };
    sessionStorage.setItem('daysRange', JSON.stringify(range));

    if (!val.statusses.includes('Closed')) {
      sessionStorage.setItem('showClosed', 'false');
      state.showClosed = false;
    } else {
      state.showClosed = true;
      sessionStorage.setItem('showClosed', 'true');
    }

    if (!val.statusses.includes('Forthcoming')) {
      state.showForthcoming = false;
      sessionStorage.setItem('showForthcoming', 'false');
    } else {
      state.showForthcoming = true;
      sessionStorage.setItem('showForthcoming', 'true');
    }

    if (!val.statusses.includes('Open')) {
      state.showOpen = false;
      sessionStorage.setItem('showOpen', 'false');
    } else {
      state.showOpen = true;
      sessionStorage.setItem('showOpen', 'true');
    }

    if (!val.statusses.includes('Discontinued')) {
      state.showDiscontinued = false;
      sessionStorage.setItem('showDiscontinued', 'false');
    } else {
      state.showDiscontinued = true;
      sessionStorage.setItem('showDiscontinued', 'true');
    }

    state.minBudget = val.minAmount;
    state.maxBudget = val.maxAmount;
    state.startDate = val.callStartDate;
    state.endDate = val.callEndDate;
    state.daysRange.from =
      val.callStartDate == null || val.callStartDate == ''
        ? null
        : moment(val.callStartDate).format('DD-MM-YYYY');
    state.daysRange.to =
      val.callEndDate == null || val.callEndDate == ''
        ? null
        : moment(val.callEndDate).format('DD-MM-YYYY');
    state.startDate =
      val.callStartDate == null || val.callStartDate == ''
        ? null
        : moment(val.callStartDate).format('DD-MM-YYYY');
    state.endDate =
      val.callEndDate == null || val.callEndDate == ''
        ? null
        : moment(val.callEndDate).format('DD-MM-YYYY');
    state.searchText = val.term;
  },
  updateSelectedLocations(state, val) {
    state.locationsSelectArray = val;
    sessionStorage.setItem('locationsSelectArray', JSON.stringify(val));
    store.commit('advancedSearch/updateSelectedLocationsString');
    store.commit('advancedSearch/updateActiveFiltersCount');
  },

  updateSelectedLocationsStringArray(state, val) {
    state.selectedLocationsStringsArray = val;
    store.commit('advancedSearch/updateSelectedLocationsString');
  },

  updateSelectedLocationsString(state) {
    state.selectedLocations = '';

    if (state.locationsSelectArray && state.locationsSelectArray.length > 0) {
      for (let i = 0; i < state.locationsSelectArray.length; i++) {
        state.selectedLocations += state.locationsSelectArray[i].id + ',';
      }
      if (state.selectedLocations[state.selectedLocations.length - 1] == ',')
        state.selectedLocations = state.selectedLocations.substring(
          0,
          state.selectedLocations.length - 1
        );
    }

    store.commit('advancedSearch/updateActiveFiltersCount');
    sessionStorage.setItem('selectedLocations', state.selectedLocations);
  },

  createLocationsOptions(state) {
    state.locationsOptions = [];
    for (let i = 0; i < state.locations.options.length; i++) {
      state.locationsOptions.push({
        id: state.locations.options[i].id,
        label: state.locations.options[i].label,
      });
      if (
        state.locations.options[i].children &&
        state.locations.options[i].children.length
      ) {
        for (let j = 0; j < state.locations.options[i].children.length; j++)
          state.locationsOptions.push({
            id: state.locations.options[i].children[j].id,
            label:
              state.locations.options[i].label +
              ',' +
              state.locations.options[i].children[j].label,
            parent: state.locations.options[i],
          });
      }
    }
  },

  createLocationsOptionsString(state) {
    state.locationsOptionsStrings = [];
    for (let i = 0; i < state.locations.options.length; i++) {
      state.locationsOptionsStrings.push(state.locations.options[i].label);
      if (
        state.locations.options[i].children &&
        state.locations.options[i].children.length
      ) {
        for (let j = 0; j < state.locations.options[i].children.length; j++)
          state.locationsOptionsStrings.push(
            state.locations.options[i].children[j].label
          );
      }
    }
  },

  updateStartDate(state, val) {
    state.startDate = val;
    sessionStorage.setItem('startDate', JSON.stringify(val));
    store.commit('advancedSearch/updateActiveFiltersCount');
  },
  updateEndDate(state, val) {
    state.endDate = val;
    sessionStorage.setItem('endDate', JSON.stringify(val));
    store.commit('advancedSearch/updateActiveFiltersCount');
  },
  createOrganisationsIncludeString(state) {
    if (!state.organisationsInclude || state.organisationsInclude == null) {
      state.organisationsIncludeString = '';
      return;
    }
    state.organisationsIncludeString = '';
    if (state.organisationsInclude.length > 0) {

      for (let i = 0; i < state.organisationsInclude.length; i++) {
        state.organisationsIncludeString +=
          state.organisationsInclude[i].id + ',';
      }
      if (
        state.organisationsIncludeString[
          state.organisationsIncludeString.length - 1
        ] == ','
      )
        state.organisationsIncludeString =
          state.organisationsIncludeString.substring(
            0,
            state.organisationsIncludeString.length - 1
          );
    }

    sessionStorage.setItem(
      'organisationsIncludeString',
      state.organisationsIncludeString
    );


  },

  createOrganisationsExcludeString(state) {
    if (!state.organisationsExclude || state.organisationsExclude == null) {
      state.organisationsExcludeString = '';
      return;
    }
    state.organisationsExcludeString = '';

    if (state.organisationsExclude.length > 0) {
      for (let i = 0; i < state.organisationsExclude.length; i++) {
        state.organisationsExcludeString +=
          state.organisationsExclude[i].id + ',';
      }
      if (
        state.organisationsExcludeString[
          state.organisationsExcludeString.length - 1
        ] == ','
      )
        state.organisationsExcludeString =
          state.organisationsExcludeString.substring(
            0,
            state.organisationsExcludeString.length - 1
          );
    }
    sessionStorage.setItem(
      'organisationsExcludeString',
      state.organisationsExcludeString
    );
  },

  updateMinBudget(state, val) {
    state.minBudget = val;
    sessionStorage.setItem('minBudget', val != null ? val.toString() : '0');
    store.commit('advancedSearch/updateActiveFiltersCount');
  },
  updateMaxBudget(state, val) {
    state.maxBudget = val;
    sessionStorage.setItem('maxBudget', val != null ? val.toString() : '0');
    store.commit('advancedSearch/updateActiveFiltersCount');
  },
  updateSearchIdentifier(state, val) {
    state.searchIdentifier = val;
  },
  updateSearchAccronym(state, val) {
    state.searchAccronym = val;
  },

  updateSearchIntroduction(state, val) {
    state.searchIntroduction = val;
  },

  updateOrganisationsExclude(state, val) {
    state.organisationsExclude = val;
    sessionStorage.setItem('organisationsExclude', JSON.stringify(val));
    store.commit('advancedSearch/updateActiveFiltersCount');
  },

  updateOrganisationsInclude(state, val) {
    state.organisationsInclude = val;
    sessionStorage.setItem('organisationsInclude', JSON.stringify(val));
    store.commit('advancedSearch/updateActiveFiltersCount');
  },

  updateSelectedAudiences(state, val) {
    state.selectedAudiences = '';
    var audiences = state.audience.options;
    var toAdd = '';
    if (val.length > 0) {
      for (let i = 0; i < audiences.length; i++) {
        if (audiences[i].children && audiences[i].children.length > 0) {
          var hasAllChildrenTicked = true;
          for (let j = 0; j < audiences[i].children.length; j++) {
            if (
              audiences[i].children[j].children &&
              audiences[i].children[j].children.length > 0
            ) {
              var hasAllSubChildrenTicked = true;
              for (
                let k = 0;
                k < audiences[i].children[j].children.length;
                k++
              ) {
                if (!val.includes(audiences[i].children[j].children[k].id)) {
                  hasAllSubChildrenTicked = false;
                  hasAllChildrenTicked = false;

                  let condition = true;
                  while (condition) {
                    let idx = val.indexOf(audiences[i].children[j].id);
                    if (idx > -1) val.splice(idx, 1);
                    else break;
                  }
                  state.selectedAudiences = state.selectedAudiences.replace(
                    audiences[i].children[j].id.toString(),
                    ''
                  );
                  state.selectedAudiences = state.selectedAudiences.replace(
                    audiences[i].children[j].id,
                    ''
                  );

                  break;
                }
              }
              if (
                hasAllSubChildrenTicked &&
                audiences[i].children[j].children &&
                audiences[i].children[j].children.length > 0
              ) {
                toAdd += ',' + audiences[i].children[j].id;
                val.push(audiences[i].children[j].id);
              }
            }

            if (!val.includes(audiences[i].children[j].id)) {
              hasAllChildrenTicked = false;
              continue;
            }
          }
          if (hasAllChildrenTicked) {
            toAdd += ',' + audiences[i].id;
          }
        }
      }
    }

    for (let i = 0; i < val.length; i++) {
      if (i != val.length - 1) state.selectedAudiences += val[i] + ',';
      else state.selectedAudiences += val[i];
    }
    if (toAdd != '' && toAdd.length > 0) state.selectedAudiences += toAdd;

    var uniqueArr = state.selectedAudiences;
    uniqueArr = Array.from(new Set(uniqueArr.split(','))).toString();
    state.selectedAudiences = uniqueArr;

    if (state.selectedAudiences[state.selectedAudiences.length - 1] == ',')
      state.selectedAudiences = state.selectedAudiences.substring(
        0,
        state.selectedAudiences.length - 1
      );

    store.commit('advancedSearch/updateActiveFiltersCount');
    sessionStorage.setItem('selectedAudiences', state.selectedAudiences);
  },
  //This is just hidden for now, don't remove it:
  /*
  updateSelectedIndustries(state, val) {
    state.selectedIndustries = '';
    var industries = state.industries.options;
    var toAdd = '';
    if (val.length > 0) {
      for (let i = 0; i < val.length; i++) {
        if (i != val.length - 1) state.selectedIndustries += val[i] + ',';
        else state.selectedIndustries += val[i];
      }
      for (let i = 0; i < industries.length; i++) {
        if (industries[i].children && industries[i].children.length > 0) {
          var hasAllChildrenTicked = true;
          for (let j = 0; j < industries[i].children.length; j++) {
            if (!val.includes(industries[i].children[j].id)) {
              hasAllChildrenTicked = false;
              break;
            }
          }
          if (hasAllChildrenTicked) toAdd += ',' + industries[i].id;
        }
      }
      if (toAdd != '' && toAdd.length > 0) state.selectedIndustries += toAdd;
    }

    var uniqueArr = state.selectedIndustries;
    uniqueArr = Array.from(new Set(uniqueArr.split(','))).toString();
    state.selectedIndustries = uniqueArr;
    store.commit('advancedSearch/updateActiveFiltersCount');
  },
  */

  updateSelectedPurposes(state, val) {
    state.selectedPurposes = '';
    var purposes = state.purposes.options;
    var toAdd = '';
    if (val.length > 0) {
      for (let i = 0; i < purposes.length; i++) {
        if (purposes[i].children && purposes[i].children.length > 0) {
          var hasAllChildrenTicked = true;
          for (let j = 0; j < purposes[i].children.length; j++) {
            if (
              purposes[i].children[j].children &&
              purposes[i].children[j].children.length > 0
            ) {
              var hasAllSubChildrenTicked = true;
              for (
                let k = 0;
                k < purposes[i].children[j].children.length;
                k++
              ) {
                if (!val.includes(purposes[i].children[j].children[k].id)) {
                  hasAllSubChildrenTicked = false;
                  hasAllChildrenTicked = false;

                  let condition = true;
                  while (condition) {
                    let idx = val.indexOf(purposes[i].children[j].id);
                    if (idx > -1) val.splice(idx, 1);
                    else break;
                  }
                  state.selectedPurposes = state.selectedPurposes.replace(
                    purposes[i].children[j].id.toString(),
                    ''
                  );
                  state.selectedPurposes = state.selectedPurposes.replace(
                    purposes[i].children[j].id,
                    ''
                  );

                  break;
                }
              }
              if (
                hasAllSubChildrenTicked &&
                purposes[i].children[j].children &&
                purposes[i].children[j].children.length > 0
              ) {
                toAdd += ',' + purposes[i].children[j].id;
                val.push(purposes[i].children[j].id);
              }
            }

            if (!val.includes(purposes[i].children[j].id)) {
              hasAllChildrenTicked = false;
              continue;
            }
          }
          if (hasAllChildrenTicked) {
            toAdd += ',' + purposes[i].id;
          }
        }
      }
    }

    for (let i = 0; i < val.length; i++) {
      if (i != val.length - 1) state.selectedPurposes += val[i] + ',';
      else state.selectedPurposes += val[i];
    }
    if (toAdd != '' && toAdd.length > 0) state.selectedPurposes += toAdd;

    var uniqueArr = state.selectedPurposes;
    uniqueArr = Array.from(new Set(uniqueArr.split(','))).toString();
    state.selectedPurposes = uniqueArr;

    if (state.selectedPurposes[state.selectedPurposes.length - 1] == ',')
      state.selectedPurposes = state.selectedPurposes.substring(
        0,
        state.selectedPurposes.length - 1
      );

    store.commit('advancedSearch/updateActiveFiltersCount');
    sessionStorage.setItem('selectedPurposes', state.selectedPurposes);
  },
  updateSelectedSchemeTypes(state, val) {
    state.selectedSchemeTypes = '';
    var schemeTypes = state.schemeTypes.options;
    var toAdd = '';
    if (val && val.length > 0) {
      for (let i = 0; i < schemeTypes.length; i++) {
        if (schemeTypes[i].children && schemeTypes[i].children.length > 0) {
          var hasAllChildrenTicked = true;
          for (let j = 0; j < schemeTypes[i].children.length; j++) {
            if (
              schemeTypes[i].children[j].children &&
              schemeTypes[i].children[j].children.length > 0
            ) {
              var hasAllSubChildrenTicked = true;
              for (
                let k = 0;
                k < schemeTypes[i].children[j].children.length;
                k++
              ) {
                if (!val.includes(schemeTypes[i].children[j].children[k].id)) {
                  hasAllSubChildrenTicked = false;
                  hasAllChildrenTicked = false;

                  let condition = true;
                  while (condition) {
                    let idx = val.indexOf(schemeTypes[i].children[j].id);
                    if (idx > -1) val.splice(idx, 1);
                    else break;
                  }
                  state.selectedSchemeTypes = state.selectedSchemeTypes.replace(
                    schemeTypes[i].children[j].id.toString(),
                    ''
                  );
                  state.selectedSchemeTypes = state.selectedSchemeTypes.replace(
                    schemeTypes[i].children[j].id,
                    ''
                  );

                  break;
                }
              }
              if (
                hasAllSubChildrenTicked &&
                schemeTypes[i].children[j].children &&
                schemeTypes[i].children[j].children.length > 0
              ) {
                toAdd += ',' + schemeTypes[i].children[j].id;
                val.push(schemeTypes[i].children[j].id);
              }
            }

            if (!val.includes(schemeTypes[i].children[j].id)) {
              hasAllChildrenTicked = false;
              continue;
            }
          }
          if (hasAllChildrenTicked) {
            toAdd += ',' + schemeTypes[i].id;
          }
        }
      }
    }

    if (val)
      for (let i = 0; i < val.length; i++) {
        if (i != val.length - 1) state.selectedSchemeTypes += val[i] + ',';
        else state.selectedSchemeTypes += val[i];
      }
    if (toAdd != '' && toAdd.length > 0) state.selectedSchemeTypes += toAdd;

    var uniqueArr = state.selectedSchemeTypes;
    uniqueArr = Array.from(new Set(uniqueArr.split(','))).toString();
    state.selectedSchemeTypes = uniqueArr;

    if (state.selectedSchemeTypes[state.selectedSchemeTypes.length - 1] == ',')
      state.selectedSchemeTypes = state.selectedSchemeTypes.substring(
        0,
        state.selectedSchemeTypes.length - 1
      );
    store.commit('advancedSearch/updateActiveFiltersCount');
    sessionStorage.setItem('selectedSchemeTypes', state.selectedSchemeTypes);
  },

  updateSelectedProjectTypes(state, val) {
    state.selectedProjectTypes = '';
    var projectTypes = state.projectTypes.options;
    var toAdd = '';
    if (val.length > 0) {
      for (let i = 0; i < projectTypes.length; i++) {
        if (projectTypes[i].children && projectTypes[i].children.length > 0) {
          var hasAllChildrenTicked = true;
          for (let j = 0; j < projectTypes[i].children.length; j++) {
            if (
              projectTypes[i].children[j].children &&
              projectTypes[i].children[j].children.length > 0
            ) {
              var hasAllSubChildrenTicked = true;
              for (
                let k = 0;
                k < projectTypes[i].children[j].children.length;
                k++
              ) {
                if (!val.includes(projectTypes[i].children[j].children[k].id)) {
                  hasAllSubChildrenTicked = false;
                  hasAllChildrenTicked = false;

                  let condition = true;
                  while (condition) {
                    let idx = val.indexOf(projectTypes[i].children[j].id);
                    if (idx > -1) val.splice(idx, 1);
                    else break;
                  }
                  state.selectedProjectTypes =
                    state.selectedProjectTypes.replace(
                      projectTypes[i].children[j].id.toString(),
                      ''
                    );
                  state.selectedProjectTypes =
                    state.selectedProjectTypes.replace(
                      projectTypes[i].children[j].id,
                      ''
                    );

                  break;
                }
              }
              if (
                hasAllSubChildrenTicked &&
                projectTypes[i].children[j].children &&
                projectTypes[i].children[j].children.length > 0
              ) {
                toAdd += ',' + projectTypes[i].children[j].id;
                val.push(projectTypes[i].children[j].id);
              }
            }

            if (!val.includes(projectTypes[i].children[j].id)) {
              hasAllChildrenTicked = false;
              continue;
            }
          }
          if (hasAllChildrenTicked) {
            toAdd += ',' + projectTypes[i].id;
          }
        }
      }
    }

    for (let i = 0; i < val.length; i++) {
      if (i != val.length - 1) state.selectedProjectTypes += val[i] + ',';
      else state.selectedProjectTypes += val[i];
    }
    if (toAdd != '' && toAdd.length > 0) state.selectedProjectTypes += toAdd;

    var uniqueArr = state.selectedProjectTypes;
    uniqueArr = Array.from(new Set(uniqueArr.split(','))).toString();
    state.selectedProjectTypes = uniqueArr;

    if (
      state.selectedProjectTypes[state.selectedProjectTypes.length - 1] == ','
    )
      state.selectedProjectTypes = state.selectedProjectTypes.substring(
        0,
        state.selectedProjectTypes.length - 1
      );

    store.commit('advancedSearch/updateActiveFiltersCount');
  },
  updateSearchText(state, val) {
    state.searchText = val;
    sessionStorage.setItem('searchText', val);
  },

  updatePageCount(state, val) {
    state.pageCount = val;
  },

  updateResultsCount(state, val) {
    state.numberOfResults = val;
  },

  updateMaxScoreOnCurrentPage(state) {
    var maxScore = Math.max.apply(
      null,
      store.state.grantItems.grants.map((g) => g.score)
    );
    state.maxScoreOnCurrentPage = maxScore;
  },

  updateMaxScore(state, val) {
    state.maxScore = val;
  },

  updateCurrentPage(state, val) {
    state.currentPage = val;
  },

  updateShowSchemes(state, value) {
    state.showSchemes = value;
  },
  updateShowCalls(state, value) {
    state.showCalls = value;
  },
  updateShowOpen(state, value) {
    state.showOpen = value;
    sessionStorage.setItem('showOpen', value.toString());
    store.commit('advancedSearch/createStatusesString');
  },
  updateShowClosed(state, value) {
    state.showClosed = value;

    sessionStorage.setItem('showClosed', value.toString());
    store.commit('advancedSearch/createStatusesString');
  },
  updateShowForthcoming(state, value) {
    state.showForthcoming = value;
    sessionStorage.setItem('showForthcoming', value.toString());
    store.commit('advancedSearch/createStatusesString');
  },
  updateShowDiscontinued(state, value) {
    state.showDiscontinued = value;
    sessionStorage.setItem('showDiscontinued', value.toString());
    store.commit('advancedSearch/createStatusesString');
  },

  createStatusesString(state) {
    state.statusesString = '';
    let string = '';
    if (state.showOpen && (sessionStorage.getItem('showOpen') === null || (sessionStorage.getItem('showOpen') !== null && JSON.parse(sessionStorage.getItem('showOpen')) == true))) {
      state.statusesString += 'Open,';
      string += 'Open,';
    }
    if (state.showForthcoming && (sessionStorage.getItem('showForthcoming') === null || (sessionStorage.getItem('showForthcoming') !== null && JSON.parse(sessionStorage.getItem('showForthcoming')) == true))) {
      state.statusesString += 'Forthcoming,';
      string += 'Forthcoming,';
    }
    if (state.showClosed && (sessionStorage.getItem('showClosed') === null || (sessionStorage.getItem('showClosed') !== null && JSON.parse(sessionStorage.getItem('showClosed')) == true))) {
      state.statusesString += 'Closed,';
      string += 'Closed,';
    }
    if (state.showDiscontinued && (sessionStorage.getItem('showDiscontinued') === null || (sessionStorage.getItem('showDiscontinued') !== null && JSON.parse(sessionStorage.getItem('showDiscontinued')) == true))) {
      state.statusesString += 'Discontinued,';
      string += 'Discontinued,';
    }
    state.statusesString.split(' ').join(';');
    string = string.split(' ').join(';');
    sessionStorage.setItem('statusesString', string);
    store.commit('advancedSearch/updateActiveFiltersCount');
  },

  createResultTypesString(state) {
    state.resultTypesString = '';
    if (state.showSchemes && state.showCalls) state.resultTypesString = '0, 1';
    if (state.showSchemes && !state.showCalls) state.resultTypesString = '0';
    if (!state.showSchemes && state.showCalls) state.resultTypesString = '1';
  },

  toggleAdvancedSearch(state) {
    state.showAdvancedSearch = !state.showAdvancedSearch;
  },
  updateMinAmount(state, amount) {
    state.selectedMinAmount = amount;
  },
  updateMaxAmount(state, amount) {
    state.selectedMaxAmount = amount;
  },
  updateSelectedDate(state, date) {
    state.selectedEndDate = date;
  },

  updateFilterActivities(state, activities) {
    state.general = activities;
    state.audience = activities.audience;
    //This is just hidden for now, don't remove it:
    //state.industries = activities.industries;
    state.locations = activities.locations;
    state.purposes = activities.purposes;
    state.schemeTypes = activities.schemeTypes;
    state.projectTypes = activities.projectTypes;
  },

  updateCheckedContributionType(state, newData) {
    state.contributionTypeOptions.options = newData;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
