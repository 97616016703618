const state = () => ({
  grantsByDeadline: [],
  currentPageDeadlines: 1,
  pageCountDeadlines: 0,
  newestGrants: [],
  currentPageNewest: 1,
  pageCountNewest: 0,
  latestNews: [],
  pageCountLatestNews: 0,
  currentPageNews: 0,
  significantSchemes: [],
  pageCountSignificant: 0,
  currentPageSignificant: 1,


});

const getters = {};

const actions = {};

const mutations = {
  updateGrantsByDeadline(state, val) {
    state.grantsByDeadline = val.results;
    state.pageCountDeadlines = val.pageCount + 1;
  },


  updateGrantsNewest(state, val) {
    state.newestGrants = val.results;
    state.pageCountNewest = val.pageCount + 1;
  },

  updateGrantsSignificant(state, val) {
    state.significantSchemes = val.items;
    state.pageCountSignificant = val.pages;
  },

  updateCurrentPageSignificant(state, val) {
    state.currentPageSignificant = val;
  },

  updateCurrentPageDeadlines(state, val) {
    state.currentPageDeadlines = val;
  },

  updatePageCountDeadlines(state, val) {
    state.pageCountDeadlines = val;
  },

  updatecurrentPageNewest(state, val) {
    state.currentPageNewest = val;
  },

  updatePageCountNewest(state, val) {
    state.pageCountNewest = val;
  },

  updateLatestNews(state, val) {
    state.latestNews = val.results;
    state.pageCountLatestNews = val.pageCount + 1;
  },

  updateCurrentPageLatestNews(state, val) {
    state.currentPageNews = val;
  },

  updatePageCountNews(state, val) {
    state.pageCountLatestNews = val;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
