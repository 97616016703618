import store from '../index';

const localStorageUser = JSON.parse(localStorage.getItem('user'));

const state = () => ({
  isProfileComplited: false,
  isLinkValid: false,
  selectedSchemeResults: [],
  selectedFiltersPairs: {},
  selectedStatuses: [],
  savedSchemesSearch: {},
  userId: localStorageUser ? localStorageUser.id : 0,
  userFomo: false,
  showSave: false,
  showSaveScheme: false,
  showSaveSchemePortals: false,
  showAddNew: false,
  activeUserManagementTab: '',
  showConfirmation: false,
  clearAddUserFields:false,
  user: {
    id: null,
    username: '',
    email: '',
    person: {
      firstName: '',
      lastName: '',
      customer: '',
      portalOrganisation: '',
      isAccountManager: false,
    },
    themeType: localStorageUser ? localStorageUser.themeType : '',
    searchInterest: localStorageUser ? localStorageUser.searchInterest : '',
    isInformedAboutNews: localStorageUser ? localStorageUser.isInformedAboutNews : '',
  },
  usersProfession: '',
  isProfessionSelected: false,
  customer: {
    id: null,
    name: '',
    emailSuffix: '',
    numberOfLicences: 0,
    persons: [],
    customerPortalSignatureName: '',
    startDate: '',
    isFomoAccess: false,
  },
  folderSchemeItem: {
    id: null,
    schemeId: null,
    folderId: null,
    individualSchemeStatus: '',
  },
  individualSchemeStatuses: [],
  isProfileSearch: false,
  savedAudienceTags: '',
  savedPurposesTags: '',
  //This is just hidden for now, don't remove it:
  // savedIndustriesTags: '',
  savedSchemeTypesTags: '',
  savedProjectTypesTags: '',
  savedOrganisationsExclude: '',
  savedOrganisationsInclude: '',
  savedLocationsTags: '',
  selectedFavoriteLocations: [],
  selectedFavoritePurposes: [],
  selectedFavoriteAudience: [],
  favoriteAudienceTagsString: '',
  //This is just hidden for now, don't remove it:
  //favoriteIndustriesTagsString: '',
  favoritePurposesTagsString: '',
  favoriteAudiencesIdsArray: [],
  //This is just hidden for now, don't remove it:
  //favoriteIndustriesIdsArray: [],
  favoritePurposesIdsArray: [],
  newsletterFrequencyType: '',
  schemeChangesInNewsletterStatus: false,
  openDetailsInNewTab: false,
  hideHiddenItemsDialog: false,
  showSavedInfoDialog: false,
  showSavedSearchInfoDialog: false,
  showHiddenInfoDialog: false,
  showProfileNotSetWarning: false,
  showAppliedFiltersInfo: false,
  foldersList: [
    {
      name: '',
      savedSchemesFolderItems: [{}],
      savedSearchesFolderItems: [{}],
    },
  ],
  folders: [{}],
  folderSchemes: [{}],
  folderSearches: [{}],
  filteredArray: [{}],
  filteredArraySearches: [{}],
  hiddenSchemesFilter: [{}],
  firstFolderId: 0,
  hiddenSchemesList: [{}],
  hasModalError: false,
  modalErrorMessage: '',
  isApplyingFilters: false,
  receiveAlertForSavedSearch: false,
  savedSearchFolderItemId: 0,
  receiveAlertForSavedItems: false,
  savedSchemesFolderItemsIds: [],
  showAddUser: false,
  salutationsList: [],
  showEmailSentDialog: false,
  showUserCreatedDialog: false,
  showEditUserDialog: false,
  selectedPersonForEdit: {
    userId: 0,
    email: '',
    firstName: '',
    lastName: '',
    department: '',
    isAccountManager: false,
  },
  usersListDefaultEnv: [],
  customersFilter: [{}],
  hasComeFromNewsletter: false,
  newsletterParams: [],
  newsLetterId: 0,
  showRenameFolderDialog: false,
  pickedFolderName: '',
  isTourActive: false,
  // isSelectedThemeContrast: localStorage.getItem('themeContrast'),
  hasBeenRedirectedToProfile: false,
  hasSetProfileAfterLogin: false,
  showLeavingProfileSetupWarning: false,
  hasConfirmedLeave: false,
  toPath: '',
  toPathTab: '',
  isSelectedThemeContrast: false,
  userRolesList: [],
  isClickedSavedItems: false,
  isClickedUserManagement: false,
  isClickedNewsletter: false,
  isClickedMenuItemUsers: false,
  isClickedMenuItemCustomersBasic: false,
  isClickedMenuItemUserStats: false,
  isClickedMenuItemSchemes: false,
  isClickedMenuItemSearches: false,
  isClickedMenuItemAudiences: false,
  isClickedMenuItemPurposes: false,
  isClickedMenuItemLocations: false,
  isSuperAdmin: localStorageUser && localStorageUser.id === 128 ? true : false,
});

const actions = {};

const getters = {};

const mutations = {
  setIsLinkValid(state, val){
    state.isLinkValid = val;
  },
  updateIsClickedUserManagement(state, val) {
    state.isClickedUserManagement = val;
  },
  updateIsClickedNewsletter(state, val) {
    state.isClickedNewsletter = val;
  },
  updateIsClickedSavedItems(state, val) {
    state.isClickedSavedItems = val;
  },
  updateIsClickedMenuItemSearches(state,val){
    state.isClickedMenuItemSearches = val;
  },
  updateIsClickedMenuItemSchemes(state,val){
    state.isClickedMenuItemSchemes = val;
  },
  updateIsClickedMenuItemAudiences(state,val){
    state.isClickedMenuItemAudiences = val;
  },
  updateIsClickedMenuItemPurposes(state,val){
    state.isClickedMenuItemPurposes = val;
  },
  updateIsClickedMenuItemLocations(state,val){
    state.isClickedMenuItemLocations = val;
  },
  updateIsSuperAdmin(state, val){
    state.isSuperAdmin = val;
  },
  setUserRolesList(state, val) {
    if (val != [] && val.roles && val.roles.length > 0) {
      for (let i = 0; i < val.roles.length; i++) {
        state.userRolesList.push(val.roles[i].name);
      } 
    } else state.userRolesList = [];
  },
  setToPathTab(state, val) {
    state.toPathTab = val;
  },
  setHasConfirmedLeave(state, val) {
    state.hasConfirmedLeave = val;
  },
  setToPath(state, val) {
    state.toPath = val;
  },
  updateHasBeenRedirectedToProfile(state, val) {
    state.hasBeenRedirectedToProfile = val;
  },
  updateShowLeavingProfileModal(state, val) {
    state.showLeavingProfileSetupWarning = val;
  },
  updateHasSetProfile(state) {
    state.hasSetProfileAfterLogin = true;
  },
  setIsSelectedThemeContrast(state, val) {
    state.isSelectedThemeContrast = val;
    localStorage.setItem('themeContrast', val);
  },
  updateIsTourActive(state, val) {
    state.isTourActive = val;
  },
  setIsFomoUser(state, val){
    state.userFomo = val;
  },
  updatePortalOrganisation(state,val){
    state.user.person.portalOrganisation = val;
  },
  updateIsProfileComplited(state,val){
    state.isProfileComplited = val;
  },
  setHasComeFromNewsletter(state, val) {
    state.hasComeFromNewsletter = val;
  },
  updateActiveUserManagementTab(state, val) {
    state.activeUserManagementTab = val;
  },
  updateIsClickedMenuItemUsers(state,val){
    state.isClickedMenuItemUsers = val;
  },
  updateIsClickedMenuItemCustomersBasic(state,val){
    state.isClickedMenuItemCustomersBasic = val;
  },

  updateIsClickedMenuItemUserStats(state,val){
    state.isClickedMenuItemUserStats = val;
  },
  updateUsersListDefaultEnv(state, users) {
    state.usersListDefaultEnv = users;
  },

  updateClearAddUserFields(state,val){
   state.clearAddUserFields = val;
  },
  updateThemeType(state, val){
    state.user.themeType = val;
  },
  updateSearchInterest(state,val){
    state.user.searchInterest = val;
  },
  updateProfessionType(state,val){
    state.usersProfession = val;
  },
  updateIsProfessionSelected(state, val){
    state.isProfessionSelected = val;
  },
  updateIsInformedAboutNews(state,val){
    state.user.isInformedAboutNews = val;
  },
  setNewsletterParams(state, val) {
    state.newsletterParams = val;
    state.newsLetterId = parseInt(val.newsId);
  },
  setIsApplyingFilters(state, val) {
    state.isApplyingFilters = val;
  },
  updateCustomers(state, val) {
    state.customersFilter = val;
  },
  setReceiveAlertForSavedSearch(state, val) {
    state.receiveAlertForSavedSearch = val;
  },
  setReceiveAlertForSavedItems(state, val) {
    state.receiveAlertForSavedItems = val;
  },
  updateSelectedSchemeResults(state, val) {
    const index = state.selectedSchemeResults.indexOf(val);
    if (index > -1) {
      state.selectedSchemeResults.splice(index, 1);
    } else {
      state.selectedSchemeResults.push(val);
    }
  },
  enableSave(state) {
    state.showSave = true;
  },
  enableSaveScheme(state) {
    state.showSaveScheme = true;
  },
  enableSavePortalsScheme(state) {
    state.showSaveSchemePortals = true;
  },
  enableAddNew(state) {
    state.showAddNew = true;
  },
  enableConfirmation(state) {
    state.showConfirmation = true;
  },
  enableShowSavedInfoDialog(state, savedSchemesFolderItemsIds) {
    state.showSavedInfoDialog = true;

    if (state.savedSchemesFolderItemsIds.length > 0)
      state.savedSchemesFolderItemsIds = [];

    for (let i = 0; i < savedSchemesFolderItemsIds.length; i++)
      state.savedSchemesFolderItemsIds.push(savedSchemesFolderItemsIds[i]);
  },
  enableShowSavedSearchInfoDialog(state, savedSearchFolderItemId) {
    state.showSavedSearchInfoDialog = true;
    state.savedSearchFolderItemId = savedSearchFolderItemId;
  },
  enableShowProfileNotSetWarning(state) {
    state.showProfileNotSetWarning = true;
  },
  enableShowHiddenInfoDialog(state) {
    if (state.hideHiddenItemsDialog == false) state.showHiddenInfoDialog = true;
  },
  enableShowAppliedFiltersInfo(state) {
    state.showAppliedFiltersInfo = true;
  },
  disableConfirmation(state) {
    state.showConfirmation = false;
  },
  disableShowSavedInfoDialog(state) {
    state.showSavedInfoDialog = false;
    state.receiveAlertForSavedItems = false;
    state.savedSchemesFolderItemsIds = [];
  },
  disableShowSavedSearchInfoDialog(state) {
    state.showSavedSearchInfoDialog = false;
    state.receiveAlertForSavedSearch = false;
    state.savedSearchFolderItemId = 0;
  },
  disableShowHiddenInfoDialog(state) {
    state.showHiddenInfoDialog = false;
  },
  disableShowProfileNotSetWarning(state) {
    state.showProfileNotSetWarning = false;
  },
  disableShowAppliedFiltersInfo(state) {
    state.showAppliedFiltersInfo = false;
  },
  disableSave(state) {
    state.showSave = false;
  },
  disableSaveScheme(state) {
    state.showSaveScheme = false;
  },
  disableSavePortalsScheme(state) {
    state.showSaveSchemePortals = false;
  },
  disableAddNew(state) {
    state.showAddNew = false;
  },
  setIsProfileSearch(state, val) {
    state.isProfileSearch = val;
  },
  setIndividualSchemeStatus(state,val){
    state.individualSchemeStatuses = val;
  },
  
  updateAudiencesPairs(state, val) {
    state.selectedFiltersPairs.audiences = val;
  },
  //This is just hidden for now, don't remove it:
  // updateIndustriesPairs(state, val) {
  //   state.selectedFiltersPairs.industries = val;
  // },

  updatePurposesPairs(state, val) {
    state.selectedFiltersPairs.purposes = val;
  },

  updateSchemeTypesPairs(state, val) {
    state.selectedFiltersPairs.schemeTypes = val;
  },

  updateProjectTypesPairs(state, val) {
    state.selectedFiltersPairs.projectTypes = val;
  },

  updateFavoriteAudiencesIdsArray(state, val) {
    if (state.favoriteAudiencesIdsArray.length > 0)
      state.favoriteAudiencesIdsArray = [];
    for (let i = 0; i < val.length; i++)
      state.favoriteAudiencesIdsArray.push(val[i]);

    state.favoriteAudienceTagsString = String(state.favoriteAudiencesIdsArray);
  },
  //This is just hidden for now, don't remove it:
  /*
  updateFavoriteIndustriesIdsArray(state, val) {
    if (state.favoriteIndustriesIdsArray.length > 0)
      state.favoriteIndustriesIdsArray = [];
    for (let i = 0; i < val.length; i++)
      state.favoriteIndustriesIdsArray.push(val[i]);
  },
  */
  updateFavoritePurposesIdsArray(state, val) {
    if (state.favoritePurposesIdsArray.length > 0)
      state.favoritePurposesIdsArray = [];
    for (let i = 0; i < val.length; i++)
      state.favoritePurposesIdsArray.push(val[i]);

    state.favoritePurposesTagsString = String(state.favoritePurposesIdsArray);
  },

  updateSelectedFavoriteLocations(state, val) {
    state.selectedFavoriteLocations = val;
  },

  updateSelectedFavoritePurposes(state, val) {
    state.selectedFavoritePurposes = val;
  },

  updateSelectedFavoriteAudience(state, val) {
    state.selectedFavoriteAudience = val;
  },

  updateNewsletterFrequencyType(state, val) {
    state.newsletterFrequencyType = val;
  },

  updateSchemeChangesInNewsletterStatus(state, val) {
    state.schemeChangesInNewsletterStatus = val;
  },

  updateHideHiddenItemsDialog(state, val) {
    state.hideHiddenItemsDialog = val;
  },

  updateOpenDetailsInNewTab(state, val) {
    state.openDetailsInNewTab = val;
  },

  saveSchemesSearch(state) {
    state.savedSchemesSearch = {
      audienceTags:
        store.state.advancedSearch.selectedAudiences != ''
          ? store.state.advancedSearch.selectedAudiences
          : sessionStorage.getItem('selectedAudiences'),
      purposesTags:
        store.state.advancedSearch.selectedPurposes != ''
          ? store.state.advancedSearch.selectedPurposes
          : sessionStorage.getItem('selectedPurposes'),
      //This is just hidden for now, don't remove it:
      //industriesTags: store.state.advancedSearch.selectedIndustries,
      schemeTypesTags:
        store.state.advancedSearch.selectedSchemeTypes != ''
          ? store.state.advancedSearch.selectedSchemeTypes
          : sessionStorage.getItem('selectedSchemeTypes'),
      projectTypesTags: store.state.advancedSearch.selectedProjectTypes,
      statuses: sessionStorage.getItem('statusesString'),
      organisationsInclude:
        store.state.advancedSearch.organisationsIncludeString != ''
          ? store.state.advancedSearch.organisationsIncludeString
          : sessionStorage.getItem('organisationsIncludeString'),
      organisationsExclude:
        store.state.advancedSearch.organisationsExcludeString != ''
          ? store.state.advancedSearch.organisationsExcludeString
          : sessionStorage.getItem('organisationsExcludeString'),
      startDate:
        store.state.advancedSearch.startDate != null &&
        store.state.advancedSearch.startDate != ''
          ? store.state.advancedSearch.startDate
          : JSON.parse(sessionStorage.getItem('startDate')),
      endDate:
        store.state.advancedSearch.endDate != null &&
        store.state.advancedSearch.endDate != ''
          ? store.state.advancedSearch.endDate
          : JSON.parse(sessionStorage.getItem('endDate')),
      minBudget:
        store.state.advancedSearch.minBudget != null
          ? store.state.advancedSearch.minBudget
          : Number(sessionStorage.getItem('minBudget')),
      maxBudget:
        store.state.advancedSearch.maxBudget != null
          ? store.state.advancedSearch.maxBudget
          : Number(sessionStorage.getItem('maxBudget')),
      locationsTags:
        store.state.advancedSearch.selectedLocations != ''
          ? store.state.advancedSearch.selectedLocations
          : sessionStorage.getItem('selectedLocations'),
      smeTypes: store.state.advancedFilters.smeString,
      scopesString: store.state.advancedFilters.scopesString,
      termsString: store.state.advancedFilters.termsString,
      searchText:
        store.state.advancedSearch.searchText != ''
          ? store.state.advancedSearch.searchText
          : sessionStorage.getItem('searchText'),
    };
  },

  fillUserObject(state, val) {
    (state.user.id = val.id), (state.user.username = val.username);
    state.user.email = val.email;
    state.user.person.firstName = val.person.firstName;
    state.user.person.lastName = val.person.lastName;
    state.user.person.customer = val.person.customer;
    state.user.person.portalOrganisation = val.person.portalOrganisation;
  //  state.user.themeType = val.person.personProfile
  },

  fillCustomerObject(state, val) {
    state.customer.id = val.id;
    state.customer.name = val.name;
    state.customer.emailSuffix = val.emailSuffix;
    state.customer.numberOfLicences = val.numberOfLicences;
    state.customer.customerPortalSignatureName = val.customerPortalSignatureName;
    state.customer.startDate = val.startDate;
    state.customer.isFomoAccess = val.isFomoAccess;

    if (state.customer.persons && state.customer.persons.length > 0)
      state.customer.persons = [];
    state.customersFilter = [];

    for (let i = 0; i < val.persons.length; i++) {
      state.customer.persons.push(val.persons[i]);
      state.customersFilter.push(val.persons[i]);
    }
  },

  fillFiltersWithSavedValues(state, val) {
    state.savedAudienceTags = val.audienceTags;
    var savedAudienceTagsArray = val.audienceTags
      ? val.audienceTags.split(',')
      : [];
    store.commit(
      'advancedSearch/updateSelectedAudiences',
      savedAudienceTagsArray
    );

    //This is just hidden for now, don't remove it:
    /*
    state.savedIndustriesTags = val.industriesTags;
    var savedIndustriesTagsArray = val.industriesTags.split(',');
    store.commit(
      'advancedSearch/updateSelectedIndustries',
      savedIndustriesTagsArray
    );
    */
    state.savedPurposesTags = val.purposesTags;
    var savedPurposesTagsArray = val.purposesTags
      ? val.purposesTags.split(',')
      : [];
    store.commit(
      'advancedSearch/updateSelectedPurposes',
      savedPurposesTagsArray
    );

    state.savedSchemeTypesTags = val.schemeTypesTags;
    var savedSchemeTypesTagsArray = val.schemeTypesTags
      ? val.schemeTypesTags.split(',')
      : [];
    store.commit(
      'advancedSearch/updateSelectedSchemeTypes',
      savedSchemeTypesTagsArray
    );

    state.savedProjectTypesTags =
      val.projectTypesTags === null ? '' : val.projectTypesTags;
    var savedProjectTypesTagsArray =
      val.projectTypesTags == null ? [] : val.projectTypesTags.split(',');
    store.commit(
      'advancedSearch/updateSelectedProjectTypes',
      savedProjectTypesTagsArray
    );

    state.savedOrganisationsInclude = val.organisations;
    state.savedOrganisationsExclude = val.notOrganisations;
    state.savedLocationsTags = val.locationsTags;

    if (
      state.savedOrganisationsInclude != null &&
      state.savedOrganisationsInclude.length > 0
    ) {
      var includeIds = new Array();
      includeIds = state.savedOrganisationsInclude.split(',');
      var includeOrgsArray = new Array();

      let allOrganisations =
        store.state.organisations.organisationsWithBasicInfo;

      for (let i = 0; i < allOrganisations.length; i++) {
        if (includeIds.includes(allOrganisations[i].id.toString()))
          includeOrgsArray.push(allOrganisations[i]);
      }
      store.commit(
        'advancedSearch/updateOrganisationsInclude',
        includeOrgsArray
      );
    } else {
      store.commit('advancedSearch/updateOrganisationsInclude', []);
    }

    if (
      state.savedOrganisationsExclude != null &&
      state.savedOrganisationsExclude.length > 0
    ) {
      var excludeIds = new Array();
      excludeIds = state.savedOrganisationsExclude.split(',');
      var excludeOrgsArray = new Array();

      let allOrganisations =
        store.state.organisations.organisationsWithBasicInfo;

      for (let i = 0; i < allOrganisations.length; i++) {
        if (excludeIds.includes(allOrganisations[i].id.toString()))
          excludeOrgsArray.push(allOrganisations[i]);
      }
      store.commit(
        'advancedSearch/updateOrganisationsExclude',
        excludeOrgsArray
      );
    } else {
      store.commit('advancedSearch/updateOrganisationsExclude', []);
    }

    if (
      state.savedLocationsTags != null &&
      state.savedLocationsTags.length > 0
    ) {
      var locationsIds = new Array();
      locationsIds = state.savedLocationsTags.split(',');
      var locationsArray = new Array();

      let allLocations = store.state.advancedSearch.locationsOptions;

      for (let i = 0; i < allLocations.length; i++) {
        if (locationsIds.includes(allLocations[i].id.toString())) {
          locationsArray.push(allLocations[i]);
        }
      }
      store.commit('advancedSearch/updateSelectedLocations', locationsArray);
    } else {
      store.commit('advancedSearch/updateSelectedLocations', []);
    }
  },
  fillFolderSchemeItem(state,val){
  state.folderSchemeItem.id = val.id;
  state.folderSchemeItem.schemeId = val.schemeId;
  state.folderSchemeItem.folderId = val.folderId;
  state.folderSchemeItem.individualSchemeStatus = val.individualSchemeStatus;
  },

  updateUsername(state, val) {
    state.user.username = val;
  },
  updateEmail(state, val) {
    state.user.email = val;
  },
  updateFirstName(state, val) {
    state.user.person.firstName = val;
  },

  updateFilteredArray(state, val) {
    state.filteredArray = val;
  },

  updateFilteredArraySearches(state, val) {
    state.filteredArraySearches = val;
  },

  updateLastName(state, val) {
    state.user.person.lastName = val;
  },
  updateCustomerName(state, val) {
    state.customer.name = val;
  },
  enableModalError(state) {
    state.hasModalError = true;
  },
  disableModalError(state) {
    state.hasModalError = false;
  },
  updateModalError(state, message) {
    state.modalErrorMessage = message;
  },
  fillFolderItems(state, val) {
    if (state.folderSchemes.length > 0) {
      state.folderSchemes = [];
      state.filteredArray = [];
  
    }
    if (state.folderSearches.length > 0) {
      state.folderSearches = [];
      state.filteredArraySearches = [];
    }

    if (val.folderItems.length > 0) {
      var schemesItems = val.folderItems.filter(
        (item) => item.schemeId != null && item.schemeId != 0
      );
      var savedSearchesItems = val.folderItems.filter(
        (item) => item.searchId != null && item.searchId != 0
      );

      if (schemesItems.length > 0) {
        for (let i = 0; i < schemesItems.length; i++) {
          schemesItems.selectedSchemeForSharing =  false;  
          state.folderSchemes.push(schemesItems[i]);
          state.filteredArray.push(schemesItems[i]);
        }
      }
      if (savedSearchesItems.length > 0) {
        for (let j = 0; j < savedSearchesItems.length; j++) {
          state.folderSearches.push(savedSearchesItems[j]);
          state.filteredArraySearches.push(savedSearchesItems[j]);
        }
      }
    }
  },
  fillFolders(state, val) {
    if (state.folders.length > 0) state.folders = [];
    for (let i = 0; i < val.length; i++) state.folders.push(val[i]);
  },
  fillFoldersWithItems(state, val) {
    if (state.foldersList.length > 0) state.foldersList = [];
    for (let i = 0; i < val.length; i++) {
      state.foldersList[i] = {
        id: val[i].id,
        name: val[i].name,
        savedSchemesFolderItems: [],
        savedSearchesFolderItems: [],
      };

      if (val[i].folderItems.length > 0) {
        var schemesItems = val[i].folderItems.filter(
          (item) => item.schemeId != null && item.schemeId != 0
        );
        var savedSearchesItems = val[i].folderItems.filter(
          (item) => item.searchId != null && item.searchId != 0
        );
        if (schemesItems.length > 0) {
          for (let j = 0; j < schemesItems.length; j++) {
            state.foldersList[i].savedSchemesFolderItems.push(
              schemesItems[j].scheme
            );
          }
        }
        if (savedSearchesItems.length > 0) {
          for (let k = 0; k < savedSearchesItems.length; k++) {
            state.foldersList[i].savedSearchesFolderItems.push(
              savedSearchesItems[k].savedSearch
            );
          }
        }
      }
    }

    if (state.foldersList.length > 0)
      state.firstFolderId = state.foldersList[0].id;
  },
  fillHiddenSchemesList(state, val) {
    if (state.hiddenSchemesList.length > 0) {
      state.hiddenSchemesList = [];
      state.hiddenSchemesFilter = [];
    }
    for (let i = 0; i < val.length; i++) {
      state.hiddenSchemesList.push(val[i]);
      state.hiddenSchemesFilter.push(val[i]);
    }
  },
  enableAddUser(state) {
    state.showAddUser = true;
  },
  disableAddUser(state) {
    state.showAddUser = false;
  },
  updateSalutationsList(state, value) {
    state.salutationsList = value;
  },
  enableEmailSentDialog(state) {
    state.showEmailSentDialog = true;
  },
  disableEmailSentDialog(state) {
    state.showEmailSentDialog = false;
  },
  enableUserCreatedDialog(state) {
    state.showUserCreatedDialog = true;
  },
  disableUserCreatedDialog(state) {
    state.showUserCreatedDialog = false;
  },
  enableEditUser(state, personObject) {
    state.selectedPersonForEdit.userId = personObject.userId;
    state.selectedPersonForEdit.email = personObject.email;
    state.selectedPersonForEdit.firstName = personObject.firstName;
    state.selectedPersonForEdit.lastName = personObject.lastName;
    state.selectedPersonForEdit.department = personObject.department;
    state.selectedPersonForEdit.isAccountManager = personObject.isAccountManager;
    state.showEditUserDialog = true;
  },
  disableEditUser(state) {
    state.showEditUserDialog = false;
  },
  updateHiddenSchemesListFilter(state, val) {
    state.hiddenSchemesFilter = val;
  },
  updateSelectedUserEmail(state, val) {
    state.selectedPersonForEdit.email = val;
  },
  updateSelectedUserFirstName(state, val) {
    state.selectedPersonForEdit.firstName = val;
  },
  updateSelectedUserLastName(state, val) {
    state.selectedPersonForEdit.lastName = val;
  },
  updateSelectedUserIsAccountManager(state, val){
    state.selectedPersonForEdit.isAccountManager = val;
  },
  updateSelectedUserDepartment(state, val) {
    state.selectedPersonForEdit.department = val;
  },
  updatePickedFolderName(state, val) {
    state.pickedFolderName = val;
  },
  enableRenameFolderDialog(state, pickedFolderName) {
    state.pickedFolderName = pickedFolderName;
    state.showRenameFolderDialog = true;
  },
  disableRenameFolderDialog(state) {
    state.showRenameFolderDialog = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
