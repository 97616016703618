<template>
  <div
    class="latest-news-page homepage-main"
    :class="isContrast != 'false' && isContrast != false ? 'high-contrast' : ''"
  >
    <span class="curr-nav row">{{ t('navbar.dashboard')}} &#62; {{ t('my_dashboard.latest_news') }}</span>

    <div class="news-container">
      <div class="pagination top-pag row justify-between">
        <span class="news-heading bold-font"> {{ t('my_dashboard.latest_news') }} </span>
        <q-pagination
          class="pagination-item"
          v-model="currentPage"
          :max="maxPages"
          direction-links
          :max-pages="6"
          @click="goToPage(currentPage)"
          color="grey"
          active-color="green-7"
          text-color="white"
          size="13px"
        ></q-pagination>
      </div>
      <NewsItem
        v-for="news in latestNews"
        :key="news.id"
        :newsTitle="news.title"
        :newsItem="news"
        :intro="news.introduction"
        :id="news.id"
        :body="news.body"
        class="news-item"
      />
      <div class="pagination top-pag row justify-end bot-pag">
        <q-pagination
          class="pagination-item"
          v-model="currentPage"
          :max="maxPages"
          direction-links
          :max-pages="6"
          @click="goToPage(currentPage)"
          color="grey"
          active-color="green-7"
          text-color="white"
          size="13px"
        ></q-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import NewsItem from '../news-components/NewsItem.vue';
import store from '../../store/index';
import { computed, onMounted, ref } from 'vue';
import NewsUtils from '../../utils/news';
import UserService from '../../services/userprofile.service';
import { useI18n } from 'vue-i18n';

export default {
  name: 'LatestNews',

  components: {
    NewsItem,
  },
  setup() {
    const { locale } = useI18n();
    const latestNews = computed({
      get: () => store.state.homePageItems.latestNews,
    });

    const currentPage = computed({
      get: () => store.state.homePageItems.currentPageNews,
      set: (val) =>
        store.commit('homePageItems/updateCurrentPageLatestNews', val),
    });

    const maxPages = computed({
      get: () => store.state.homePageItems.pageCountLatestNews,
    });

    const favoriteAudience = computed({
      get: () => store.state.user.favoriteAudiencesIdsArray,
    });
    const favoritePurposes = computed({
      get: () => store.state.user.favoritePurposesIdsArray,
    });
    const favoriteLocations = computed({
      get: () => store.state.user.selectedFavoriteLocations,
    });

    onMounted(async () => {
      await UserService.getProfileConfiguration();
      await UserService.getFavoriteAudienceTags();
      await UserService.getFavoritePurposesTags();
      await UserService.getFavoriteLocationsTags();

      window.scrollTo(0, 0);
      checkForTagsUpdate();

      var locationsStr = '';
      for (let i = 0; i < favoriteLocations.value.length; i++) {
        locationsStr += favoriteLocations.value[i].id + ',';
      }
      locationsStr = locationsStr.substring(0, locationsStr.length - 1);

      if (!hasProfileTags.value) {
        await NewsUtils.searchLatestnews(locale, 0, 10);
      } else {
        await NewsUtils.searchLatestnewsWithTags(
          locale,
          0,
          10,
          favoriteAudience.value,
          favoritePurposes.value,
          locationsStr
        );
      }
    });

    const goToPage = async (page) => {
      if (!hasProfileTags.value) {
        await NewsUtils.searchLatestnews(locale, page - 1, 10);
      } else {
        var locationsStr = '';
        for (let i = 0; i < favoriteLocations.value.length; i++) {
          locationsStr += favoriteLocations.value[i].id + ',';
        }
        locationsStr = locationsStr.substring(0, locationsStr.length - 1);

        await NewsUtils.searchLatestnewsWithTags(
          locale,
          page - 1,
          10,
          favoriteAudience.value,
          favoritePurposes.value,
          locationsStr
        );
      }

      window.scrollTo(0, 0);
    };

    const hasProfileTags = ref(false);

    const checkForTagsUpdate = () => {
      if (
        favoriteAudience.value.length > 0 ||
        favoritePurposes.value.length > 0 ||
        favoriteLocations.value.length > 0
      ) {
        hasProfileTags.value = true;
      }
    };

    const isContrast = computed({
      get: () => store.state.user.isSelectedThemeContrast,
    });
    return {
      locale,
      isContrast,
      maxPages,
      currentPage,
      goToPage,
      latestNews,
      favoriteAudience,
      favoritePurposes,
      favoriteLocations,
      checkForTagsUpdate,
    };
  },
};
</script>
