<template>
  <div
    :class="isContrast != 'false' && isContrast != false ? 'high-contrast' : ''"
  >
    <div class="homepage-main-container homepage-main">
      <span class="curr-nav">{{ t('navbar.dashboard') }} </span>
      <span class="homepage-title bold-font row">{{ t('navbar.dashboard') }}</span>
      <div class="homepage-container">
        <div class="row justify-between">
          <SchemesList
            :schemesProp="newestSchemes.results"
            :title="t('my_dashboard.latest_schemes')"
          />

          <SchemesList
            :schemesProp="deadlineSchemes.results"
            :title="t('my_dashboard.upcoming_deadlines')"
          />
        </div>

        <div class="row">
          <NewsList :newsProp="latestNews.results" :title="t('my_dashboard.latest_news')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SchemesList from '../components/homepage-components/SchemesList.vue';
import { onMounted, computed, ref } from 'vue';
import SchemesUtils from '../utils/schemes';
import NewsUtils from '../utils/news';
import store from '../store/index';
import NewsList from '../components/homepage-components/NewsList.vue';
import UserService from '../services/userprofile.service';
import { useI18n } from 'vue-i18n';

export default {
  name: 'HomePage',

  components: {
    SchemesList,
    NewsList,
  },
  setup() {
    const { locale } = useI18n();
    const newestSchemes = computed({
      get: () => store.state.grantItems.newestGrants,
    });

    const latestNews = computed({
      get: () => store.state.news.latestNews,
    });

    const deadlineSchemes = computed({
      get: () => store.state.grantItems.deadlineGrants,
    });

    const significantChangesShemes = computed({
      get: () => store.state.grantItems.significantChangesGrants,
    });

    const favoriteAudience = computed({
      get: () => store.state.user.favoriteAudiencesIdsArray,
    });
    const favoritePurposes = computed({
      get: () => store.state.user.favoritePurposesIdsArray,
    });
    const favoriteLocations = computed({
      get: () => store.state.user.selectedFavoriteLocations,
    });

    onMounted(async () => {
      await UserService.getProfileConfiguration();
      await UserService.getFavoriteAudienceTags();
      await UserService.getFavoritePurposesTags();
      await UserService.getFavoriteLocationsTags();

      var locationsStr = '';
      for (let i = 0; i < favoriteLocations.value.length; i++) {
        locationsStr += favoriteLocations.value[i].id + ',';
      }
      locationsStr = locationsStr.substring(0, locationsStr.length - 1);

      checkForTagsUpdate();
      if (!hasProfileTags.value) {
        await SchemesUtils.searchNewestSchemes(locale, 0, 20);
        await SchemesUtils.searchDeadlineSchemes(locale, 0, 20);
        await NewsUtils.searchLatestnews(locale, 0, 20);
        await SchemesUtils.searchSignificantSchemes(0, 20);
      } else {
        await SchemesUtils.searchNewestSchemesWithTags(
          locale,
          0,
          20,
          favoriteAudience.value,
          favoritePurposes.value,
          locationsStr
        );

        await SchemesUtils.searchDeadlineSchemesWithTags(
          locale,
          0,
          20,
          favoriteAudience.value,
          favoritePurposes.value,
          locationsStr
        );
        await NewsUtils.searchLatestnewsWithTags(
          locale,
          0,
          20,
          favoriteAudience.value,
          favoritePurposes.value,
          locationsStr
        );
        await SchemesUtils.searchSignificantSchemes(0, 20);
      }
    });

    const hasProfileTags = ref(false);

    const checkForTagsUpdate = () => {
      if (
        favoriteAudience.value.length > 0 ||
        favoritePurposes.value.length > 0 ||
        favoriteLocations.value.length > 0
      ) {
        hasProfileTags.value = true;
      }
    };

    const isContrast = computed({
      get: () => store.state.user.isSelectedThemeContrast,
    });
    return {
      locale,
      isContrast,
      newestSchemes,
      latestNews,
      deadlineSchemes,
      favoriteAudience,
      favoritePurposes,
      favoriteLocations,
      hasProfileTags,
      significantChangesShemes,
    };
  },
};
</script>
