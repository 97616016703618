<template>
  <div class="portals-news-column">
    <span class="news-column-heading bold-font"> Laatste nieuws </span>
    <q-btn
      no-caps
      flat
      color="white"
      dense
      class="show-news-btn bold-font"
      @click="goToNewsPage()"
      style="width: 180px"
      >Bekijk al het nieuws</q-btn
    >
    <div class="column news-items">
      <div class="main-item">
        <img class="newsitem-img" src="../../assets/newsitem-defaultimg.jpg" alt="Image not found" />
        <div class="column">
          <router-link
            :to="{
              name: 'NewsDetails',
              params: { newsId: mainNewsItem.id },
            }"
            target="_blank"
            style="text-decoration: none"
          >
            <span class="title bold-font">
              {{ mainNewsItem.title }}
            </span>
          </router-link>
          <span class="date">
            {{ formatDate(mainNewsItem.date) }}
          </span>

          <span class="mainitem-introduction q-mt-sm">
            {{ mainNewsItem.introduction }}
          </span>

          <router-link
            :to="{
              name: 'NewsDetails',
              params: { newsId: mainNewsItem.id },
            }"
            target="_blank"
          >
            <span class="viewmore-link q-mt-sm"
              >Lees meer
              <q-icon size="12px" class="chevron-icon">
                <img
                  class="svg-icon"
                  src="../../assets/icons/chevron-right-solid.svg"
                />
              </q-icon>
            </span>
          </router-link>
        </div>
      </div>
    </div>

    <div class="column other-news">
      <ul>
        <li v-for="news in newsItems" :key="news.id" class="item">
          <router-link
            :to="{
              name: 'NewsDetails',
              params: { newsId: news.id },
            }"
            target="_blank"
          >
            <div class="title bold-font">
              {{ news.title }}
            </div>
            <div class="date">{{ formatDate(news.date) }}</div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import store from '../../store/index';
import NewsUtils from '../../utils/news';
import UserService from '../../services/userprofile.service';
import { useRouter } from 'vue-router';
import moment from 'moment';
export default {
  setup() {
    onMounted(async () => {
      await UserService.getProfileConfiguration();
      await UserService.getFavoriteAudienceTags();
      await UserService.getFavoritePurposesTags();
      checkForTagsUpdate();

      if (!hasProfileTags.value) {
        await NewsUtils.searchLatestnews("", 0, 5);
      } else {
        await NewsUtils.searchLatestnewsWithTags(
          "",
          0,
          20,
          favoriteAudience.value,
          favoritePurposes.value,
          []
        );
      }
    });

    const router = useRouter();
    const hasProfileTags = ref(false);

    const favoriteAudience = computed({
      get: () => store.state.user.favoriteAudiencesIdsArray,
    });
    const favoritePurposes = computed({
      get: () => store.state.user.favoritePurposesIdsArray,
    });

    const checkForTagsUpdate = () => {
      if (
        favoriteAudience.value.length > 0 ||
        favoritePurposes.value.length > 0
      ) {
        hasProfileTags.value = true;
      }
    };

    const goToNewsPage = () => {
      router.push({
        name: 'News',
      });
    };

    const mainNewsItem = computed({
      get: () => store.state.portalsData.grantsPageMainNewsItem,
    });

    const newsItems = computed({
      get: () => store.state.portalsData.grantsPageNewsItems,
    });

    const formatDate = (date) => {
      return moment(date).format('DD-MM-YYYY');
    };
    return {
      formatDate,
      goToNewsPage,
      favoriteAudience,
      favoritePurposes,
      mainNewsItem,
      newsItems,
    };
  },
};
</script>
