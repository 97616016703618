<template>
  <div class="news-select-home">
    <h1 class="heading row">Welkom {{ user.firstName }}</h1>
    <div class="column">
      <p class="main-text row">
        Er staan een aantal nieuwsberichten klaar voor jouw klanten. Selecteer
        de berichten die je naar elke klant wilt sturen.
      </p>
      <q-btn no-caps class="btn-vind-dark btn-go" @click="goToNewsSelect()">
        Start selectie
      </q-btn>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import store from '../../store/index.js';
import { useRouter } from 'vue-router';

export default {
  name: 'NewsSelectHome',
  setup() {
    const user = computed({
      get: () => store.state.auth.user,
    });

    const goToNewsSelect = () => {
      router.push({
        name: 'NewsSelectPage',
      });
    };

    const router = useRouter();

    return {
      goToNewsSelect,
      user,
      router,
    };
  },
};
</script>
