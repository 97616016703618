<template>
  <ErrorModal />
  <div class="ob-export-page">
    <q-splitter
      class="splitter-menu-desktop"
      style="min-height: calc(100vh - 768px); height: 81vh"
    >
      <template v-slot:before>
        <div class="avatar">
          <q-avatar class="profile-avatar" rounded size="75px">
            {{ firstName.charAt(0).toUpperCase() }}
          </q-avatar>
        </div>
        <q-tabs v-model="tab" vertical>
          <div class="menu-left">
            <router-link
              :to="{
                name: 'OnzeExportPage',
                params: { tab: 'stats' },
              }"
              style="text-decoration: none"
            >
              <q-tab
                name="stats"
                style="justify-content: initial; color: #191d96"
                no-caps
                @click="goToTab('stats')"
              >
                Stats
              </q-tab>
            </router-link>
            <router-link
              :to="{
                name: 'OnzeExportPage',
                params: { tab: 'export' },
              }"
              style="text-decoration: none"
            >
              <q-tab
                name="myBasicDetails"
                style="justify-content: initial; color: #191d96"
                no-caps
                @click="goToTab('export')"
              >
                Rapportages OB
              </q-tab>
            </router-link>
          </div>
        </q-tabs>
      </template>

      <template v-slot:after>
        <q-tab-panels v-model="tab">
          <q-tab-panel name="export">
            <h2>Selecteer filters om een rapport te downloaden</h2>
            <q-select
              outlined
              bg-color="white"
              v-model="selectedCustomer"
              :options="filterCustomersList"
              option-label="name"
              label="Selecteer klant"
              stack-label
              behavior="menu"
              dense
              option-value="id"
              class="customer-select"
              @filter="filterCustomersListFn"
              use-input
              input-debounce="0"
              clearable
            >
            </q-select>
            <q-input
              class="customer-select q-mt-lg"
              v-model="daysRangeModel"
              outlined
              dense
              @click="$refs.qDateProxy.show()"
              :update="updateRange()"
              label="Selecteer periode"
              stack-label
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer datepicker-icon">
                  <q-popup-proxy
                    ref="qDateProxy"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      :locale="myLocale"
                      range
                      v-model="days"
                      mask="DD-MM-YYYY"
                    >
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Sluiten"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
            <q-btn
              flat
              no-caps
              label="Maak rapport"
              class="orange-btn-filled q-mb-xl"
              @click="exportOnzeBevindingen()"
            ></q-btn>
          </q-tab-panel>
          <q-tab-panel name="stats"
            ><div class="ob-stats-page">
              <div class="column">
                <div class="filters row">
                  <q-select
                    class="select-filter"
                    borderless
                    bg-color="white"
                    v-model="selectedTeam"
                    label="Select team"
                    :options="departments"
                    behavior="menu"
                    option-value="name"
                    option-label="name"
                    clearable
                    style="width: 220px; height: min-content"
                  ></q-select>
                  <div style="align-self: center">
                    <q-btn
                      class="orange-btn bold-font q-ml-md"
                      no-caps
                      label="Search"
                      @click="dataFilters()"
                    />
                  </div>
                </div>
                <span class="week-heading bold-font">
                  Week {{ latestWeek }}
                </span>
                <div class="stats-boxes row q-mb-lg" style="max-width: 74vw">
                  <div class="stat-section">
                    <span class="stat-section-data">
                      {{ numberOfUniqueMessagesSent }}
                    </span>
                    <span class="stat-section-text">
                      aantal unieke berichten gestuurd
                    </span>
                  </div>
                  <div class="stat-section">
                    <span class="stat-section-data">
                      {{ numberOfBounces }}
                    </span>
                    <span class="stat-section-text"> aantal bounces </span>
                  </div>
                  <div class="stat-section">
                    <span class="stat-section-data">
                      {{ numberOfContactsOBHasOpened }}
                    </span>
                    <span class="stat-section-text">
                      aantal contacten dat OB heeft geopend
                    </span>
                  </div>

                  <div class="stat-section">
                    <span class="stat-section-data">
                      {{ numberOfContactsWhoClicked }}
                    </span>
                    <span class="stat-section-text">
                      aantal contacten dat heeft geklikt
                    </span>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="row justify-center">
                  <OnzeNewSelectedMixed
                    class="q-ml-lg scheme-visits-bar-row"
                    style="width: 42%"
                  />
                  <OnzeMixedSecondData
                    class="q-ml-lg q-mt-lg scheme-visits-bar-row"
                    style="width: 42%"
                  />
                </div>
                <OnzeMixed class="q-ml-lg q-mt-lg" />
              </div>
              <div class="row">
                <span
                  class="week-heading bold-font q-mt-xl"
                  style="margin-left: 64px"
                >
                  Week {{ latestWeek }}
                </span>
                <div class="tables q-mt-xl">
                  <q-table
                    class="my-sticky-header-table q-mb-lg"
                    row-key="name"
                    flat
                    bordered
                    :separator="'cell'"
                    :pagination="pagination"
                    :rows="activeConsultants"
                    :columns="consultantsColumns"
                    :rows-per-page-options="[]"
                    title="Actieve consultants"
                  >
                    <template v-slot:body="props">
                      <q-tr :props="props">
                        <q-td key="name" :props="props">
                          {{ props.row.name }}
                        </q-td>
                        <q-td key="department" :props="props">
                          {{ props.row.department }}
                        </q-td>
                      </q-tr>
                    </template>
                  </q-table>

                  <q-table
                    class="my-sticky-header-table q-mb-lg"
                    row-key="name"
                    flat
                    bordered
                    :separator="'cell'"
                    :pagination="pagination"
                    :rows="inactiveConsultants"
                    :columns="consultantsColumns"
                    :rows-per-page-options="[]"
                    title="Niet actieve consultants"
                  >
                    <template v-slot:body="props">
                      <q-tr :props="props">
                        <q-td key="name" :props="props">
                          {{ props.row.name }}
                        </q-td>
                        <q-td key="department" :props="props">
                          {{ props.row.department }}
                        </q-td>
                      </q-tr>
                    </template>
                  </q-table>
                </div>
              </div>
            </div>
          </q-tab-panel>
        </q-tab-panels>
      </template>
    </q-splitter>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import userService from "../services/userprofile.service";
import store from "../store/index";
import axiosUrl from "../services/axios.service";
import axios from "axios";
import authHeader from "../helpers/auth-header";
import moment from "moment";
import ErrorModal from "./ErrorModal.vue";
import { useRouter, useRoute } from "vue-router";
import salesForceService from "../services/salesForceService";
const API_URL = axiosUrl.getAxiosUrl();
import userStatsService from "../services/userStats.service";
import OnzeMixed from "../components/ob-components/OnzeMixed.vue";
import OnzeMixedSecondData from "../components/ob-components/OnzeMixedSecondData.vue";
import OnzeNewSelectedMixed from "../components/ob-components/OnzeNewSelectedMixed.vue";
import { useI18n } from "vue-i18n";

export default {
  name: "OnzeExportPage",
  components: {
    ErrorModal,
    OnzeNewSelectedMixed,
    OnzeMixed,
    OnzeMixedSecondData,
  },
  setup() {
    const { t } = useI18n();

    const route = useRoute();
    const tab = ref(route.params.tab);

    const router = useRouter();

    let user = JSON.parse(localStorage.getItem("user"));

    const hasObRole =
      user && user.roles
        ? user.roles.some((element) => {
            if (element.name == "Vindsubsidies user") {
              return true;
            }

            return false;
          })
        : false;

    onMounted(async () => {
      await salesForceService.getAccountsSalesforce();

      if (!hasObRole) {
        router.push({
          name: "ErrorPage",
        });
      }
      userService.getUser();
      userService.getOnzeCustomers();
      await userStatsService.getOnzeConsultants();
      await userStatsService.getDepartments();
      await userStatsService.getOBData();
    });

    const exportOnzeBevindingen = () => {
      const from =
        daysFrom.value != null && daysFrom.value != '""' && daysFrom.value != ""
          ? moment(daysFrom.value, "DD-MM-YYYY").format("YYYY-MM-DD")
          : null;

      const to =
        daysTo.value != null && daysTo.value != '""' && daysTo.value != ""
          ? moment(daysTo.value, "DD-MM-YYYY").format("YYYY-MM-DD")
          : null;

      axios
        .get(
          API_URL +
            `/salesforce/ExportOnzeBevindingen?selectedCustomer=${selectedCustomer.value.id}&dateFrom=${from}&dateTo=${to}`,
          {
            headers: authHeader(),
            responseType: "blob",
            reportProgress: true,
          }
        )
        .then((response) => {
          if (response == null) {
            throw "Er is een onbekende fout opgetreden. Vernieuw de pagina en probeer het opnieuw.";
          }

          var fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: response.data.type })
          );
          var a = document.createElement("a");
          a.setAttribute("style", "display:none;");
          document.body.appendChild(a);
          a.download = "onze-bevindingen-rapport.docx";
          a.href = fileURL;
          a.target = "_blank";
          a.click();
          document.body.removeChild(a);
        })
        .catch((error) => {
          if (error)
            store.commit(
              "auth/updateModalError",
              "Er is een onbekende fout opgetreden. Vernieuw de pagina en probeer het opnieuw."
            ),
              store.commit("auth/enableModalError");
        });
    };

    const customersList = computed({
      get: () => store.state.obExport.onzeCustomersList,
    });

    const selectedCustomer = computed({
      get: () => store.state.obExport.selectedCustomer,
      set: (val) => store.commit("obExport/updateSelectedCustomer", val),
    });
    const daysRangeModel = ref(null);

    const updateRange = () => {
      daysRangeModel.value = [daysFrom.value, daysTo.value].join(" - ");
    };

    const daysFrom = computed({
      get: () => store.state.obExport.daysRange.from,
    });

    const daysTo = computed({
      get: () => store.state.obExport.daysRange.to,
    });

    const days = computed({
      get: () => store.state.obExport.daysRange,
      set: (val) => store.commit("obExport/updateDaysRange", val),
    });

    const selectedCustomerId = computed({
      get: () => store.state.obExport.selectedCustomerId,
    });

    const firstName = computed({
      get: () => store.state.user.user.person.firstName,
    });

    const filterCustomersList = ref(customersList.value);

    const goToTab = (tabName) => {
      router.push({
        name: "OnzeExportPage",
        params: { tab: tabName },
      });

      // tab = tab;
    };

    const numberOfBounces = computed({
      get: () => store.state.onzeStats.numberOfBounces,
    });

    const numberOfUniqueMessagesSent = computed({
      get: () => store.state.onzeStats.numberOfUniqueMessagesSent,
    });

    const numberOfContactsOBHasOpened = computed({
      get: () => store.state.onzeStats.numberOfContactsOBHasOpened,
    });

    const numberOfContactsWhoClicked = computed({
      get: () => store.state.onzeStats.numberOfContactsWhoClicked,
    });

    const activeConsultants = computed({
      get: () => store.state.onzeStats.onzeActiveConsultantsList,
    });

    const inactiveConsultants = computed({
      get: () => store.state.onzeStats.onzeInactiveConsultantsList,
    });

    const consultantsColumns = [
      {
        name: "name",
        field: "name",
        label: "Naam",
        align: "left",
        sortable: true,
      },
      {
        name: "department",
        field: "department",
        label: "Team",
        align: "left",
        sortable: true,
      },
    ];

    const departments = computed({
      get: () => store.state.onzeStats.departments,
    });

    const selectedTeam = computed({
      get: () => store.state.onzeStats.selectedTeam,
      set: (val) => {
        store.commit("onzeStats/updateSelectedTeam", val);
        selectedTeam.value != null
          ? userStatsService.getOnzeConsultants(selectedTeam.value)
          : userStatsService.getOnzeConsultants("");
      },
    });

    const dataFilters = async () => {
      userStatsService.getOBData(selectedTeam);
    };

    const latestWeek = computed({
      get: () => store.state.onzeStats.latestWeek,
    });

    return {
      latestWeek,
      t,
      dataFilters,
      selectedTeam,
      departments,
      consultantsColumns,
      numberOfBounces,
      numberOfContactsOBHasOpened,
      numberOfContactsWhoClicked,
      activeConsultants,
      inactiveConsultants,
      numberOfUniqueMessagesSent,
      goToTab,
      user,
      hasObRole,
      filterCustomersList,
      selectedCustomerId,
      firstName,
      days,
      updateRange,
      daysRangeModel,
      tab,
      selectedCustomer,
      customersList,
      exportOnzeBevindingen,
      myLocale: {
        pluralDay: "dagen",
        months:
          "Januari_Februari_Maart_April_Mei_Juni_Juli_Augustus_September_Oktober_November_December".split(
            "_"
          ),
        daysShort: "ma_di_wo_do_vr_za_zo".split("_"),
      },

      filterCustomersListFn(val, update) {
        update(() => {
          if (val === "") {
            filterCustomersList.value = customersList.value;
          } else {
            const needle = val.toLowerCase();
            filterCustomersList.value = customersList.value.filter(
              (v) => v.name.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
    };
  },
};
</script>
