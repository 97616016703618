<template>
  <ErrorModal />
  <q-dialog v-model="saveSchemePortals" persistent class="save-scheme-main">
    <q-card style="min-width: 350px" class="save-dialog-scheme">
      <q-card-section class="q-pb-none">
        <div class="main-text bold-font" >
          Toevoegen aan favorieten
        </div>
        <p class="subtitle">Zet de voor jou interessante subsidies in je favorieten en volg de status</p>
      </q-card-section>
      <q-card-section class="q-pt-none q-pa-md">
        <div class="column justify-around">
          <span class="headline-save-scheme">Naam subsidie </span>
          <span class="scheme-name-portal q-mb-md">{{ selectedGrant.name }}</span>

          <span class="headline-save-scheme">Status</span>
          <q-select
            bg-color="white"
            v-model="individualSchemeStatus"
            :options="individualSchemeStatuses"
            style="width: 217px"
            borderless
            dense
          ></q-select>
        </div>
      </q-card-section>
      <q-card-actions class="bold-font q-ml-sm q-mb-md" align="left">
        <q-btn
          color="white"
          class="orange-btn-filled"
          flat
          label="Toevoegen"
          style="width: 90px"
          no-caps
          @click="saveSchemes(individualSchemeStatus)"
        />
        <q-btn
          flat
          class="orange-btn-cancel"
          label="Annuleer"
          no-caps
          style="width: 90px"
          @click="disableSaveScheme()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import store from "../../store/index";
import UserService from "../../services/userprofile.service";
import ErrorModal from "../../components/userProfile-components/ErrorModal.vue";

export default {
  components: {
    ErrorModal,
  },

  setup() {
    const individualSchemeStatus = ref(null);
    onMounted(async () => {
      await UserService.getIndividualSchemeStatus();
      individualSchemeStatus.value = individualSchemeStatuses.value[0];
    });

    const individualSchemeStatuses = computed({
      get: () => store.state.user.individualSchemeStatuses,
    });


    const saveSchemePortals = computed({
      get: () => store.state.user.showSaveSchemePortals,
    });
    const folders = computed({
      get: () => store.state.user.folders,
    });

    const enableSaveScheme = (id) => {
      store.commit("user/enableSavePortalsScheme");
      store.commit("grantItems/addSelected", id);
    };

    const disableSaveScheme = () => {
      store.commit("user/disableSavePortalsScheme");
      individualSchemeStatus.value = individualSchemeStatuses.value[0];  
    };

    const selectedResults = computed({
      get: () => store.state.grantItems.selectedResults,
    });

    const selectedGrant = computed({
      get: () => store.state.grantItems.selectedGrant,
    });

    const folderSchemeItem = computed({
        get: () => store.state.user.folderSchemeItem,
      });

    const saveSchemes = async (individualScheme) => {
      const folderWithoutItems = {
        folder: folders.value[0],
      };
      await UserService.saveSchemes(folderWithoutItems, selectedResults.value);
      await UserService.getFolderSchemeItem(folderWithoutItems.folder.id, selectedGrant.value.id);
      await UserService.updateIndividualStatus(folderSchemeItem.value.id, individualScheme);
      individualSchemeStatus.value = individualSchemeStatuses.value[0];
    };

    return {
      folderSchemeItem,
      selectedGrant,
      individualSchemeStatus,
      saveSchemes,
      selectedResults,
      disableSaveScheme,
      folders,
      enableSaveScheme,
      saveSchemePortals,
      individualSchemeStatuses,
    };
  },
};
</script>
