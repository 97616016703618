<template>
  <ErrorModal />
  <q-dialog v-model="showAddUser" persistent class="add-new-user-dialog">
    <q-card style="min-width: 200px">
      <q-card-section>
        <div>
          <span>
            <i class="las la-2x la-user la-fw"></i>
            {{ t('account_management.new_user') }}
          </span>
        </div>
      </q-card-section>

      <q-separator />

      <q-card-section>
        <div class="row justify-around input-group">
          <div class="col-md-6">
            <q-input
              style="margin-top: 33px; margin-left: 17px"
              v-model="user.email"
              filled
              type="email"
              label="Email"
              stack-label
              :rules="[isValidEmail, requiredFieldRule]"
            >
              <template v-slot:prepend>
                <q-icon name="mail" small />
              </template>
            </q-input>
          </div>
          <div class="col-md-6">
            <q-select
              class="q-pa-md"
              style="margin-bottom: 20px"
              filled
              v-model="user.person.salutation"
              use-input
              input-debounce="0"
              label="Meneer/mevrouw"
              :options="salutationOptions"
              @filter="salutationFilterFn"
              behavior="menu"
              stack-label
              :rules="requiredFieldRule"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">
                    {{ t('profile.no_results') }}
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
        </div>

        <div class="row justify-around q-mb-lg input-group">
          <q-input
            class="q-pa-md voornaam-input"
            dense
            v-model="user.person.firstName"
            label="Voornaam"
            stack-label
            :rules="requiredFieldRule"
            borderless
          />
          <q-input
            class="q-pa-md"
            dense
            v-model="user.person.lastName"
            label="Achternaam"
            stack-label
            borderless
            :rules="requiredFieldRule"
          />
        </div>
        <div class="row q-mb-lg input-group">
          <q-input
            class="q-pa-md"
            dense
            v-model="user.person.department"
            label="Afdeling"
            stack-label
            borderless
          />
          <q-checkbox
            v-show="isSuperAdmin"
            v-model="user.person.isAccountManager"
            label="Accountmanager"
            color="indigo-10"
          >
            <q-tooltip> This user is AccountManager. </q-tooltip>
          </q-checkbox>
        </div>
      </q-card-section>

      <q-separator />

      <q-card-actions align="around">
        <q-btn
          class="orange-btn-filled"
          no-caps
          flat
          :label="t('search_schemes.save')"
          @click="createNewUser()"
        />
        <q-btn
          no-caps
          flat
          class="orange-btn bg-gray-button"
          outline
          :label="t('common.cancel')"
          @click="disableAddUser()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, onMounted, computed, reactive } from "vue";
import store from "../../store/index.js";
import { useRouter } from "vue-router";
import ErrorModal from "./ErrorModal.vue";
import UserService from "../../services/userprofile.service";
import { useI18n } from 'vue-i18n';

export default {
  components: {
    ErrorModal,
  },
  setup() {
    const router = useRouter();

    const showAddUser = computed({
      get: () => store.state.user.showAddUser,
    });

    const customerName = computed({
      get: () => store.state.user.customer.name,
    });

    const customerId = computed({
      get: () => store.state.user.customer.id,
    });

    const user = reactive({
      email: "",
      person: {
        firstName: "",
        lastName: "",
        salutation: "",
        customer: customerName,
        customerId: customerId,
        department: "",
        isAccountManager: false,
      },
    });

    const salutationOptions = ref(salutations);
    const dismissButton = ref(false);
    onMounted(() => {
      UserService.getAllSalutations();
    });

    const disableAddUser = () => {
      dismissButton.value = true;
      store.commit("user/disableAddUser");
      revertAddNewUserFields();
    };

    const isSuperAdmin = computed({
      get: () => store.state.user.isSuperAdmin,
    });

    const createNewUser = async () => {
      await UserService.addNewUser(user);
      if (clearAddUserFields.value) revertAddNewUserFields();
    };

    const salutations = computed({
      get: () => store.state.user.salutationsList,
    });
    const clearAddUserFields = computed({
      get: () => store.state.user.clearAddUserFields,
    });
    const revertAddNewUserFields = () => {
      if (clearAddUserFields.value == true || dismissButton.value == true) {
        user.email = "";
        user.person.firstName = "";
        user.person.lastName = "";
        user.person.salutation = "";
        user.person.department = "";
        user.person.isAccountManager = false;
      }
    };

    const { t } = useI18n();

    return {
      t,
      dismissButton,
      clearAddUserFields,
      revertAddNewUserFields,
      isSuperAdmin,
      showAddUser,
      customerName,
      disableAddUser,
      createNewUser,
      user,
      salutations,
      salutationOptions,
      router,
      customerId,
      salutationFilterFn(val, update) {
        if (val === "") {
          update(() => {
            salutationOptions.value = salutations.value;
          });
          return;
        }
        update(() => {
          const needle = val.toLowerCase();
          salutationOptions.value = salutations.value.filter(
            (v) => v.toLowerCase().indexOf(needle) > -1
          );
        });
      },
      requiredFieldRule: [
        (val) => (val && val.length > 0) || t('common.required_field'),
      ],
      isValidEmail(val) {
        const emailPattern =
          /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
        return emailPattern.test(val) || t('common.invalid_email');
      },
    };
  },
};
</script>
