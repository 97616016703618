import axios from "axios";
import axiosUrl from "./axios.service";
import authHeader from "../helpers/auth-header";
import store from "../store/index";
import router from "../router";
import UserService from "./userprofile.service";
import { computed } from "vue";

const API_URL = axiosUrl.getAxiosUrl();
const isVind = computed({
  get: () => store.state.auth.env === 0 || store.state.auth.env === 40,
});
const env = computed({
  get: () => store.state.auth.env,
});

class UserProfileService {
  async getFoldersWithItems() {
    return await axios
      .get(API_URL + "/users/getFoldersWithItems", {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          store.commit("user/fillFoldersWithItems", response.data);
        }
      });
  }

  async getHiddenSchemes() {
    return await axios
      .get(API_URL + "/scheme/getHiddenSchemes", {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          store.commit("user/fillHiddenSchemesList", response.data);
        }
      });
  }

  async getFolders() {
    return await axios
      .get(API_URL + "/users/getFolders", {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          store.commit("user/fillFolders", response.data);
        }
      });
  }

  async getFolderItems(folderId) {
    return await axios
      .get(API_URL + "/users/getFolder", {
        headers: authHeader(),
        params: {
          folderId: folderId,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          store.commit("user/fillFolderItems", response.data);
        }
      });
  }

  async getFirstFolderName(folderId) {
    return await axios
      .get(API_URL + "/users/getFolderName", {
        headers: authHeader(),
        params: {
          folderId: folderId,
        },
      })
      .catch((error) => {
        if (error)
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
      });
  }
  async getFolderSchemeItem(folderId, schemeId) {
    return await axios
      .get(API_URL + "/users/getFolderSchemeItem", {
        headers: authHeader(),
        params: {
          folderId: folderId,
          schemeId: schemeId,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          store.commit("user/fillFolderSchemeItem", response.data);
        }
      });
  }

  async saveSchemes(folderWithoutItems, selectedResults) {
    return axios
      .post(
        API_URL + "/users/saveFolderItems",
        {
          id:
            folderWithoutItems.folder != null
              ? folderWithoutItems.folder.id
              : null,
          name:
            (folderWithoutItems.folder == null ||
            folderWithoutItems.folder.id == 0) && !isVind.value
              ? "favouriteSchemesFolder"
              : folderWithoutItems.newFolderName,
          folderItems: selectedResults,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status === 200) {
          store.commit("user/disableSaveScheme");
          store.commit("user/enableShowSavedInfoDialog", response.data);
          store.commit("user/disableSavePortalsScheme");
          // router.push({ name: 'UserProfile', params: { tab: 'savedItems' } });
        }
      })
      .catch((error) => {
        if (error)
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
      });
  }

  async addNewFolder(newFolderName) {
    return axios
      .post(
        API_URL + "/users/createFolder",
        {
          name: newFolderName,
        },
        {
          headers: authHeader(),
        }
      )
      .catch((error) => {
        if (error)
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
      });
  }

  async removeSavedScheme(itemId) {
    return axios({
      method: "post",
      url: API_URL + "/users/deleteFolderItem",
      headers: authHeader(),
      params: {
        folderItemId: itemId,
      },
    }).catch((error) => {
      if (error)
        store.commit("user/updateModalError", error.response.data),
          store.commit("user/enableModalError");
    });
  }

  async updateFolderItemAlert(itemId, hasAlert) {
    return axios({
      method: "post",
      url: API_URL + "/users/updateAlert",
      headers: authHeader(),
      params: {
        hasAlert: hasAlert,
        folderItemId: itemId,
      },
    }).catch((error) => {
      if (error)
        store.commit("user/updateModalError", error.response.data),
          store.commit("user/enableModalError");
    });
  }

  async updateFolderItemsAlert(itemsIds, hasAlert) {
    return axios
      .post(
        API_URL + "/users/updateAlerts",
        {
          hasAlert: hasAlert,
          folderItemsIds: itemsIds,
        },
        {
          headers: authHeader(),
        }
      )
      .catch((error) => {
        if (error)
          store.commit("scheme/updateModalError", error.response.data),
            store.commit("scheme/enableModalError");
      });
  }

  async getUser() {
    return await axios
      .get(API_URL + "/users/getUser", {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          store.commit("user/fillUserObject", response.data);
          if (!isVind.value) {
            if (
              response.data.person.portalOrganisation != "" &&
              response.data.themeType > 0 &&
              response.data.searchInterest > 0 &&
              response.data.searchInterest != 6
            ) {
              store.commit("user/updateIsProfileComplited", true);
            }
            if (response.data.searchInterest == 6) {
              store.commit("user/updateIsProfileComplited", true);
            }
            if (
              response.data.person.portalOrganisation == "" &&
              response.data.themeType == 0 &&
              response.data.searchInterest == 0
            ) {
              store.commit("user/updateIsProfileComplited", false);
            }
            if(env.value == 30){
              if(response.data.professionType == 0){
                store.commit("user/updateIsProfessionSelected", false);
              }
              else  store.commit("user/updateIsProfessionSelected", true);
            }
          }
        }
      });
  }
  async getUserRoles() {
    return await axios
      .get(API_URL + "/users/getUserRoles", {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          if(response.data.length > 0){
            if(response.data.includes("Subi viewer")){
              store.commit("user/setIsFomoUser", true);
            } else  store.commit("user/setIsFomoUser", false);
          }
        }
      });
  }
  async getCustomerByUser() {
    return await axios
      .get(API_URL + "/users/getCustomerByUser", {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          store.commit("user/fillCustomerObject", response.data);
        }
      });
  }

  async getFavoriteAudienceTags() {
    return await axios
      .get(API_URL + "/Tags/GetFavoriteTags?tagType=1", {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          store.commit("user/updateFavoriteAudiencesIdsArray", response.data);
        }
      });
  }

  async getAllCustomers() {
    return await axios
      .get(API_URL + "/users/getAllCustomers", {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          store.commit("obExport/updateCustomersList", response.data);
        }
      });
  }

  async getOnzeCustomers() {
    return await axios
      .get(API_URL + "/salesforce/getOnzeCustomers", {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          store.commit("obExport/updateOnzeCustomers", response.data);
        }
      });
  }

  async getFavoritePurposesTags() {
    return await axios
      .get(API_URL + "/Tags/GetFavoriteTags?tagType=2", {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          store.commit("user/updateFavoritePurposesIdsArray", response.data);
        }
      });
  }
  //This is just hidden for now, don't remove it:
  /*
  async getFavoriteIndustriesTags() {
    return await axios
      .get(API_URL + '/Tags/GetFavoriteTags?tagType=4', {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          store.commit('user/updateFavoriteIndustriesIdsArray', response.data);
        }
      });
  }
*/
  async getFavoriteLocationsTags() {
    return await axios
      .get(API_URL + "/Tags/GetFavoriteLocationsTags", {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          store.commit("user/updateSelectedFavoriteLocations", response.data);
        }
      });
  }

  async getNewsletterFrequency() {
    return await axios
      .get(API_URL + "/users/getFrequencyType", {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          store.commit("user/updateNewsletterFrequencyType", response.data);
        }
      });
  }

  async getProfileConfiguration() {
    return await axios
      .get(API_URL + "/users/getProfileConfiguration", {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          store.commit(
            "user/updateSchemeChangesInNewsletterStatus",
            response.data.schemeChangesInNewsletter
          );
          store.commit(
            "user/updateOpenDetailsInNewTab",
            response.data.openDetailsInNewTab
          );
          store.commit(
            "user/updateHideHiddenItemsDialog",
            response.data.hideHiddenItemsDialog
          );
          store.commit(
            "user/setIsApplyingFilters",
            response.data.isApplyFilter
          );
          store.commit(
            "user/updateIsInformedAboutNews",
            response.data.isInformedAboutNews
          );
          store.commit("user/updateThemeType", response.data.themeType);
          store.commit(
            "user/updateSearchInterest",
            response.data.searchInterest
          );
        }
      });
  }

  async loadFilter(searchId) {
    return await axios
      .get(API_URL + "/search/loadSearch", {
        headers: authHeader(),
        params: {
          id: searchId,
          isSchemesSearch: true,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          // ugasi apply filters (store.commit i na backend da ode)
          // resetuj sve filtere (store.commmit tako ici)

          store.commit("user/setIsApplyingFilters", false);
          store.commit("advancedSearch/resetSearchFilter", false);
          store.commit("user/setIsProfileSearch", true);
          store.commit(
            "advancedSearch/fillFiltersWithSavedValues",
            response.data
          );
          store.commit("user/fillFiltersWithSavedValues", response.data);
          store.commit(
            "advancedFilters/fillFiltersWithSavedValues",
            response.data
          );
          router.push({ name: "SearchGrants" });
        }
      });
  }

  async editUser(user) {
    return axios
      .post(
        API_URL + "/users/edit",
        {
          username: user.username.value,
          email: user.email.value,
          person: {
            firstName: user.firstName.value,
            lastName: user.lastName.value,
            portalOrganisation: user.portalOrganisation.value,
          },
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        store.commit("user/fillUserObject", response.data);
        // router.go();
      })
      .catch((error) => {
        if (error)
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
      });
  }

  async editUserPortalOrganisation(portalOrganisation) {
    return axios
      .post(
        API_URL + "/users/editUserPortalOrganisation",
        {
          person: {
            portalOrganisation: portalOrganisation,
          },
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        store.commit(
          "user/updatePortalOrganisation",
          response.data.person.portalOrganisation
        );
        // router.go();
      })
      .catch((error) => {
        if (error)
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
      });
  }

  async setFavoriteTags(favoriteTags, type) {
    return axios
      .post(
        API_URL + "/Tags/SetFavoriteTags",
        {
          tags: favoriteTags,
          type: type,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status == 200) {
          if (type == "Voor wie zoekt u subsidies?")
            UserService.getFavoriteAudienceTags();
          if (type == "Onderwerp") UserService.getFavoritePurposesTags();
          //This is just hidden for now, don't remove it:
          //if (type == 'Industrieën') UserService.getFavoriteIndustriesTags();
          if (type == "Locatie") UserService.getFavoriteLocationsTags();
        }
      })
      .catch((error) => {
        if (error)
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
      });
  }

  async setNewsletterFrequency(frequency) {
    return axios
      .post(
        API_URL + "/users/setNewsletterFrequency",
        {
          frequencyType: frequency,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status == 200) {
          UserService.getNewsletterFrequency();
          store.commit("user/enableConfirmation");
        }
      })
      .catch((error) => {
        if (error)
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
      });
  }

  async setSchemeChangesInNewsletterStatus(status) {
    return axios
      .post(
        API_URL + "/users/setSchemeChangesInNewsletter",
        {
          schemeChangesInNewsletter: status,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status == 200) {
          UserService.getProfileConfiguration();
        }
      })
      .catch((error) => {
        if (error)
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
      });
  }

  async setOpenDetailsInNewTab(status) {
    return axios
      .post(
        API_URL + "/users/setOpenDetailsInNewTab",
        {
          openDetailsInNewTab: status,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status == 200) {
          UserService.getProfileConfiguration();
        }
      })
      .catch((error) => {
        if (error)
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
      });
  }

  async setHideHiddenItemsDialog(value) {
    return axios
      .post(
        API_URL + "/users/setHideHiddenItemsDialog",
        {
          hideHiddenItemsDialog: value,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status == 200) {
          UserService.getProfileConfiguration();
        }
      })
      .catch((error) => {
        if (error)
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
      });
  }

  async setApplyFilter(isApply) {
    return axios
      .post(
        API_URL + "/users/setApplyFilters",
        {
          IsApplyFilter: isApply,
        },
        {
          headers: authHeader(),
        }
      )
      .catch((error) => {
        if (error)
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
      });
  }

  async requestResetPassword(username) {
    return axios
      .post(
        API_URL + "/users/requestResetPassword",
        {
          username: username.value,
        },
        {
          headers: authHeader(),
        }
      )
      .then(() => {})
      .catch((error) => {
        if (error)
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
      });
  }

  async setNewPassword(password, confirmationPassword, token) {
    return axios
      .post(
        API_URL + "/users/setNewPassword",
        {
          newPassword: password.value,
          confirmPassword: confirmationPassword.value,
          token: token.value,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.data.success) {
          return true;
        } else {
          store.commit("user/updateModalError", res.data.message),
            store.commit("user/enableModalError");
          return false;
        }
      })
      .catch((error) => {
        if (error)
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
      });
  }

  async checkIsValidLink(token){
     return axios
     .get(
      API_URL + "/users/expirationToken", {
        headers: authHeader(),
        params: {
          passwordToken: token.value,
        },
      }
     )
     .then((response) => {
        store.commit("user/setIsLinkValid", response.data);
     })
  }
  async updateCustomerName(customer) {
    return axios
      .post(
        API_URL + "/users/updateCustomerName",
        {
          id: customer.id.value,
          name: customer.name.value,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        store.commit("user/fillCustomerObject", response.data);
      })
      .catch((error) => {
        if (error)
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
      });
  }

  getAllSalutations() {
    return axios
      .get(API_URL + "/users/getAllSalutations", {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit("user/updateSalutationsList", response.data);
      })
      .catch((error) => {
        if (error)
          store.commit("user/updateModalError", error),
            store.commit("user/enableModalError");
      });
  }

  async addNewUser(user) {
    return await axios
      .post(
        API_URL + "/users/addNewUser",
        {
          // username should always be the same as email
          username: user.email,
          email: user.email,
          person: {
            firstName: user.person.firstName,
            lastName: user.person.lastName,
            salutation: user.person.salutation,
            customer: user.person.customer,
            customerId: user.person.customerId,
            department: user.person.department,
            isAccountManager: user.person.isAccountManager,
          },
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status === 200) {
          store.commit("user/enableUserCreatedDialog");
          store.commit("user/disableAddUser");
          store.commit("user/updateClearAddUserFields", true);
        }
        this.getCustomerByUser();
      })
      .catch((error) => {
        if (error) {
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
          store.commit("user/enableAddUser");
          store.commit("user/updateClearAddUserFields", false);
        }
      });
  }

  async sendPasswordSetupMail(selectedUserId) {
    return await axios
      .post(
        API_URL + "/users/sendPasswordSetupMail",
        {
          id: selectedUserId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status === 200) store.commit("user/enableEmailSentDialog");
      })
      .catch((error) => {
        if (error)
          store.commit("user/updateModalError", error),
            store.commit("user/enableModalError");
      });
  }

  async deactivateUser(selectedUserId) {
    return await axios
      .post(
        API_URL + "/users/deactivateUser",
        {
          id: selectedUserId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status === 200) this.getCustomerByUser();
      })
      .catch((error) => {
        if (error) {
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
        }
      });
  }

  async reactivateUser(selectedUserId) {
    return await axios
      .post(
        API_URL + "/users/reactivateUser",
        {
          id: selectedUserId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status === 200) this.getCustomerByUser();
      })
      .catch((error) => {
        if (error) {
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
        }
      });
  }

  async editFrontOfficeUser(user) {
    return axios
      .post(
        API_URL + "/users/editUser",
        {
          id: user.id.value,
          username: user.email.value,
          email: user.email.value,
          person: {
            firstName: user.firstName.value,
            lastName: user.lastName.value,
            department: user.department.value,
            isAccountManager: user.isAccountManager.value,
          },
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status === 200) this.getCustomerByUser();
      })
      .catch((error) => {
        if (error) {
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
        }
      });
  }

  async updateThemeType(themeType) {
    return await axios
      .post(
        API_URL + "/users/updateThemeType",
        {
          ThemeType: themeType,
        },
        {
          headers: authHeader(),
        }
      )
      .catch((error) => {
        if (error) {
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
        }
      });
  }
  async updateProfessionType(profession) {
    return await axios
      .post(
        API_URL + "/users/updateProfession",
        {
          professionType: profession,
        },
        {
          headers: authHeader(),
        }
      )
      .catch((error) => {
        if (error) {
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
        }
      });
  }
  async updateInformedAboutNews(isInformedAboutNews) {
    return await axios
      .post(
        API_URL + "/users/updateIsInformedAboutNews",
        {
          isInformedAboutNews: isInformedAboutNews,
        },
        {
          headers: authHeader(),
        }
      )
      .catch((error) => {
        if (error) {
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
        }
      });
  }
  async updateUnsubscriptionFromNewsReason(reasonForUnsubscribing) {
    return await axios
      .post(
        API_URL + "/users/updateUnsubscriptionFromNewsReason",
        {
          unsubscriptionNewsReason: reasonForUnsubscribing,
        },
        {
          headers: authHeader(),
        }
      )
      .catch((error) => {
        if (error) {
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
        }
      });
  }
  async updateSearchInterest(searchInterest) {
    return await axios
      .post(
        API_URL + "/users/updateSearchInterest",
        {
          searchInterest: searchInterest,
        },
        {
          headers: authHeader(),
        }
      )
      .catch((error) => {
        if (error) {
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
        }
      });
  }
  async getIndividualSchemeStatus() {
    return await axios
      .get(API_URL + "/users/getIndividualSchemeStatuses")
      .then((response) => {
        store.commit("user/setIndividualSchemeStatus", response.data);
      });
  }

  async updateIndividualStatus(id, individualSchemeStatus) {
    return await axios
      .post(
        API_URL + "/users/updateIndividualSchemeStatus",
        {
          folderItemId: id,
          individualStatus: individualSchemeStatus,
        },
        {
          headers: authHeader(),
        }
      )
      .catch((error) => {
        if (error) {
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
        }
      });
  }

  async renameFolder(folderName, folderId) {
    return axios
      .post(
        API_URL + "/users/renameFolder",
        {
          id: folderId.value,
          name: folderName.value,
        },
        {
          headers: authHeader(),
        }
      )
      .catch((error) => {
        if (error) {
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
        }
      });
  }

  async removeFolder(folderId) {
    return axios
      .post(
        API_URL + "/users/removeFolder",
        {
          id: folderId,
        },
        {
          headers: authHeader(),
        }
      )
      .catch((error) => {
        if (error) {
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
        }
      });
  }

  async completeOnboarding(id) {
    return axios
      .post(
        API_URL + "/users/completeOnboardingProcess",
        { userId: id },
        {
          headers: authHeader(),
        }
      )
      .then(() => {
        store.commit("user/updateHasSetProfile", true);
        const localStorageUser = JSON.parse(localStorage.getItem("user"));
        localStorageUser.hasCompletedProfileSetup = true;
        localStorage.setItem("user", JSON.stringify(localStorageUser));
      })
      .catch((error) => {
        if (error)
          store.commit("user/updateModalError", error.response.data),
            store.commit("user/enableModalError");
      });
  }
}

export default new UserProfileService();
