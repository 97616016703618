const state = () => ({
  currentPage: 1,
  allNews: [{}],
  maxPages: 1,
  totalResults: 0,
  selectedNewsItem: {},
  selectedIntroduction: '',
  selectedBody: '',
  selectedTitle: '',
  selectedNewsAudienceTags: [{}],
  selectedNewsPurposesTags: [{}],
  //This is just hidden for now, don't remove it:
  // selectedNewsIndustriesTags: [{}],
  selectedNewsLocationsTags: [{}],
  selectedNewsSchemeTypesTags: [{}],
  selectedNewsKeywordsTags: [{}],
  selectedNewsLinks: [{}],
  selectedNewsDate: '',
  publicationStatuses: [],
  selectedNewsStatus: '',
  schemesForSelect: [{}],
  selectedNewsSchemes: [{}],
  allSchemes: [{}],
  newsOrganisations: [{}],
  selectedNewsTags: [{}],
  newsItemToShare: [],
  shareNewsItem: false,
  isNewsPageLoading: true,
  isNewsSideBarLoading: true,
  isNewsDetailsLoading: true,
  latestNews: [],
  latestNewsLoginPage: [],
});

const getters = {};

const actions = {};

const mutations = {
  updateLatestNews(state, val) {
    state.latestNews = val;
  },
  updateLatestNewsLoginPage(state, val) {
    state.latestNewsLoginPage = val;
    state.latestNewsLoginPage.results = state.latestNewsLoginPage.results.slice(
      0,
      8
    );
  },

  enableIsNewsPageLoading(state) {
    state.isNewsPageLoading = true;
  },

  disableIsNewsPageLoading(state) {
    state.isNewsPageLoading = false;
  },

  enableNewsDetailsLoading(state) {
    state.isNewsDetailsLoading = true;
  },

  disableNewsDetailsLoading(state) {
    state.isNewsDetailsLoading = false;
  },

  enableIsNewsSideBarLoading(state) {
    state.isNewsSideBarLoading = true;
  },

  disableIsNewsSideBarLoading(state) {
    state.isNewsSideBarLoading = false;
  },

  enableShareNews(state, news) {
    state.newsItemToShare = news;
    state.shareNewsItem = true;
  },

  disableShareNews(state, val) {
    state.shareNewsItem = val;
  },

  updateSearchResults(state, val) {
    state.allNews = val.results;
  },

  updateCurrentPage(state, val) {
    state.currentPage = val;
  },

  updateAllNews(state, val) {
    state.allNews = val.results;
    state.maxPages = val.pageCount;
    state.totalResults = val.resultCount;
  },

  fixBodyAnchorTags(state, val) {
    state.selectedBody = val.split('<a').join('<a target="_blank"');
  },

  updateSelectedNews(state, val) {
    state.selectedNewsItem = val;
    state.selectedIntroduction = val.introduction;
    state.selectedBody = val.body;
    this.commit("news/fixBodyAnchorTags", val.body)
    state.selectedTitle = val.title;
    state.selectedNewsAudienceTags = val.tags.filter((tag) => tag.type === 1);
    state.selectedNewsPurposesTags = val.tags.filter((tag) => tag.type === 2);
    //This is just hidden for now, don't remove it:
    // state.selectedNewsIndustriesTags = val.tags.filter((tag) => tag.type === 4);
    state.selectedNewsLocationsTags = val.tags.filter((tag) => tag.type === 8);
    state.selectedNewsSchemeTypesTags = val.tags.filter(
      (tag) => tag.type === 16
    );
    state.selectedNewsProjectTypesTags = val.tags.filter(
      (tag) => tag.type === 512
    );
    state.selectedNewsKeywordsTags = val.tags.filter((tag) => tag.type === 32);

    state.selectedNewsTags = val.tags;
    state.selectedNewsDate = val.date;
    state.selectedNewsStatus = val.status;
    state.selectedNewsSchemes = val.schemes;
    state.newsOrganisations = val.organisations;
  },

  updateStatus(state, val) {
    state.selectedNewsStatus = val;
  },

  updateOrganisations(state, val) {
    state.newsOrganisations = val;
  },

  updateSchemesForSelect(state, val) {
    state.allSchemes = val;
  },

  updateSelectedSchemes(state, val) {
    state.selectedNewsSchemes = val;
  },

  updateIntroduction(state, val) {
    state.selectedIntroduction = val;
  },
  updateBody(state, val) {
    state.selectedBody = val;
  },
  updateTitle(state, val) {
    state.selectedTitle = val;
  },

  updateSelectedDate(state, val) {
    state.selectedNewsDate = val;
  },

  updatePublicationStatuses(state, val) {
    state.publicationStatuses = val;
  },

  updateNewsAudienceTags(state, value) {
    state.selectedNewsAudienceTags = value;
  },
  updateNewsPurposesTags(state, value) {
    state.selectedNewsPurposesTags = value;
  },
  updateNewsLocationsTags(state, value) {
    state.selectedNewsLocationsTags = value;
  },

  updateNewsScopesTags(state, value) {
    state.selectedNewsScopesTags = value;
  },
  //This is just hidden for now, don't remove it:
  /*
  updateNewsIndustriesTags(state, value) {
    state.selectedNewsIndustriesTags = value;
  },
  */
  enableModalError(state) {
    state.hasModalError = true;
  },
  disableModalError(state) {
    state.hasModalError = false;
  },
  updateModalError(state, message) {
    state.modalErrorMessage = message;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
