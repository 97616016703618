<template>
  <div class="search-bar-news">
    <div class="container-news">
      <div class="input-group searchbox-news">
        <q-input
          dense
          borderless
          v-model="searchText"
          :placeholder="t('search_schemes.search')"
          class="search-input"
          @keydown.enter="searchNews()"
          style="width: 100%"
        >
          <template v-slot:append>
            <q-icon name="search" class="search-icon" />
          </template>
        </q-input>
        <a
          style="text-decoration: underline"
          class="operators-help"
          href="https://www.vindsubsidies.nl/faq-database/"
          target="_blank"
        >
          {{ t('search_news.learn_more_about_searching_operators') }}
        </a>
      </div>
      <q-btn
        :label="$q.screen.gt.sm ? t('search_schemes.search') : ''"
        class="orange-btn-filled bold-font"
        @click="searchNews()"
        no-caps
      >
        <q-icon left="true" name="search" class="q-mr-sm" size="22px"></q-icon>

        <!--   <span style="font-weight: bold">Zoeken</span> -->
      </q-btn>
    </div>
  </div>
</template>

<script>
import store from '../../store/index.js';
import { computed } from 'vue';
import NewsUtils from '../../utils/news';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t, locale } = useI18n();
    
    const dateFrom = computed({
      get: () =>
        store.state.newsSearch.dateFrom != ''
          ? store.state.newsSearch.dateFrom
          : JSON.parse(sessionStorage.getItem('newsDateFrom')),
    });
    const dateTo = computed({
      get: () =>
        store.state.newsSearch.dateTo != ''
          ? store.state.newsSearch.dateTo
          : JSON.parse(sessionStorage.getItem('newsDateTo')),
    });

    const audienceTags = computed({
      get: () =>
        store.state.newsSearch.selectedAudiences != ''
          ? store.state.newsSearch.selectedAudiences
          : sessionStorage.getItem('selectedAudiences'),
    });

    const locationsTags = computed({
      get: () =>
        store.state.newsSearch.selectedLocations != ''
          ? store.state.newsSearch.selectedLocations
          : sessionStorage.getItem('selectedLocations'),
    });

    const purposesTags = computed({
      get: () =>
        store.state.newsSearch.selectedPurposes != ''
          ? store.state.newsSearch.selectedPurposes
          : sessionStorage.getItem('selectedPurposes'),
    });

    const schemesTags = computed({
      get: () =>
        store.state.newsSearch.selectedSchemeTypes != ''
          ? store.state.newsSearch.selectedSchemeTypes
          : sessionStorage.getItem('selectedSchemeTypes'),
    });

    //This is just hidden for now, don't remove it
    /*
    const industriesTags = computed({
      get: () => store.state.newsSearch.selectedIndustries,
    });
    */

    const searchNews = async () => {
      store.commit('newsSearch/createOrganisationsIncludeString');
      store.commit('newsSearch/createOrganisationsExcludeString');
      await NewsUtils.searchNews(locale);
    };

    const orgsIncludeString = computed({
      get: () =>
        store.state.newsSearch.organisationsIncludeString != ''
          ? store.state.newsSearch.organisationsIncludeString
          : sessionStorage.getItem('organisationsIncludeString'),
    });

    const orgsExcludeString = computed({
      get: () =>
        store.state.newsSearch.organisationsExcludeString != ''
          ? store.state.newsSearch.organisationsExcludeString
          : sessionStorage.getItem('organisationsExcludeString'),
    });

    const searchText = computed({
      get: () =>
        store.state.newsSearch.searchText != ''
          ? store.state.newsSearch.searchText
          : sessionStorage.getItem('searchText'),
      set: (val) => {
        store.commit('newsSearch/updateSearchText', val);
        store.commit("newsSearch/updateSelectedSortNews", t('search_news.newest'))
        store.commit('advancedSearch/updateSearchText', val);
        store.commit("advancedSearch/updateSelectedSort", t('search_schemes.relevance'))
      },
    });

    const allNews = computed({
      get: () => store.state.news.allNews,
    });

    return {
      t,
      locale,
      allNews,
      searchText,
      //This is just hidden for now, don't remove it
      //industriesTags,
      schemesTags,
      purposesTags,
      audienceTags,
      locationsTags,
      searchNews,
      dateFrom,
      dateTo,
      orgsIncludeString,
      orgsExcludeString,
    };
  },
};
</script>
