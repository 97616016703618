<template>
  <div class="test-mailing-page" style="margin-top: 150px">
    <q-form>
      <div class="row justify-between">
        <div class="row">
          <q-btn
            no-caps
            class="orange-btn-filled bold-font"
            @click="sendTestMailing()"
            >Send test mailing</q-btn
          >
        </div>
        <q-btn
          no-caps
          class="orange-btn-filled bold-font"
          @click="sendMailing()"
          >Send mailing</q-btn
        >
      </div>
      <div class="row" style="margin-top: 50px">
        <div class="col-2">Testadressen</div>
        <div class="col">
          <q-input
            placeholder="Vul de testmailadressen in gescheiden door ,"
            dense
            borderless
            class="mailing-input bot-input"
            v-model="receiverAddresses"
          >
          </q-input>
        </div>
      </div>
      <div class="row" style="margin-top: 50px">
        <div class="col-2">Onderwerp</div>
        <div class="col">
          <q-input
            placeholder="Type het onderwerp hier"
            dense
            borderless
            class="mailing-input bot-input"
            v-model="purpose"
            :rules="requiredFieldRule"
          >
          </q-input>
        </div>
      </div>
      <div class="row" style="margin-top: 50px">
        <div class="col-2">Mail tekst</div>
        <div class="col">
          <ckeditor :editor="editorRef" v-model="mailText"></ckeditor>
        </div>
      </div>
    </q-form>
  </div>

  <ErrorModal />
</template>

<script>
import { ref, onMounted } from 'vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import mailingService from '../services/mailing.service';
import ErrorModal from '../components/share-components/ErrorModal.vue';
import { useRouter } from 'vue-router';
export default {
  components: {
    ErrorModal,
  },
  name: 'Mailing',
  setup() {
    const editorRef = ClassicEditor;
    const mailText = ref('');
    const receiverAddresses = ref('');
    const purpose = ref('');
    let user = JSON.parse(localStorage.getItem('user'));
    const router = useRouter();

    const sendMailing = async () => {
      await mailingService.sendMailing(
        receiverAddresses.value,
        purpose.value,
        mailText.value
      );
    };

    const sendTestMailing = async () => {
      await mailingService.sendTestMailing(
        receiverAddresses.value,
        purpose.value,
        mailText.value
      );
    };

    onMounted(() => {
      if (user.id != 127 && user.id != 128 && user.id != 7723) {
        router.push({
          name: 'ErrorPage',
        });
      }
    });

    return {
      router,
      user,
      purpose,
      sendMailing,
      sendTestMailing,
      receiverAddresses,
      editorRef,
      editorData: '<p></p>',
      mailText,

      requiredFieldRule: [
        (val) => (val && val.length > 0) || 'Veld is verplicht!',
      ],

      validEmailsRule: [
        (val) => {
          let emails = val.replace(/\s/g, '').split(',');
          const emailPattern =
            /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;

          var isCorrectInput = true;

          for (var i = 0; i < emails.length; i++) {
            if (emails[i] == '' || !emailPattern.test(emails[i])) {
              isCorrectInput = false;
            }
          }
          return isCorrectInput ? isCorrectInput : 'Emails input not correct';
        },
      ],
    };
  },
};
</script>
