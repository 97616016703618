import store from '../index';

const state = () => ({
  showAdvancedFilters: true,
  sme: false,
  notSme: false,
  before: false,
  after: false,
  beforeAndAfter: false,
  smeOptions: [{}],
  termOptions: [{}],
  termsString: '',
  scopesString: '',
  smeString: '',
  regional: false,
  national: false,
  european: false,
});

const getters = {};

const actions = {};

const mutations = {
  enableAdvanced(state) {
    state.showAdvancedFilters = true;
  },

  disableAdvanced(state) {
    state.showAdvancedFilters = false;
  },

  updateNational(state, val) {
    state.national = val;
    store.commit('advancedSearch/updateActiveFiltersCount');
  },
  updateRegional(state, val) {
    state.regional = val;
    store.commit('advancedSearch/updateActiveFiltersCount');
  },
  updateEuropean(state, val) {
    state.european = val;
    store.commit('advancedSearch/updateActiveFiltersCount');
  },
  // updateLocal(state, val) {
  //   state.local = val;
  //   store.commit('advancedSearch/updateActiveFiltersCount');

  // },

  updateSme(state, val) {
    state.sme = val;
    store.commit('advancedSearch/updateActiveFiltersCount');
  },
  updateNotSme(state, val) {
    state.notSme = val;
    store.commit('advancedSearch/updateActiveFiltersCount');
  },

  updateTermBoth(state, val) {
    state.beforeAndAfter = val;
    store.commit('advancedSearch/updateActiveFiltersCount');
  },
  updateTermAfter(state, val) {
    state.after = val;
    store.commit('advancedSearch/updateActiveFiltersCount');
  },
  updateTermBefore(state, val) {
    state.before = val;
    store.commit('advancedSearch/updateActiveFiltersCount');
  },

  updateTerms(state, val) {
    state.termOptions = val;
  },

  createTermsString(state) {
    state.termsString = '';
    if (state.before) state.termsString += '10,';
    if (state.after) state.termsString += '20,';
    if (state.beforeAndAfter) state.termsString += '30';
    if (state.termsString[state.termsString.length - 1] == ',')
      state.termsString = state.termsString.substring(
        0,
        state.termsString.length - 1
      );
  },

  createSMEString(state) {
    state.smeString = '';
    if (state.sme) state.smeString += '10,';
    if (state.notSme) state.smeString += '20,';
    if (state.smeString[state.smeString.length - 1] == ',')
      state.smeString = state.smeString.substring(
        0,
        state.smeString.length - 1
      );
  },

  createScopesString(state) {
    state.scopesString = '';
    if (state.european) state.scopesString += '10,';
    if (state.national) state.scopesString += '20,';
    if (state.regional) state.scopesString += '30,';
    // if (state.local) state.scopesString += '40';
    if (state.scopesString[state.scopesString.length - 1] == ',')
      state.scopesString = state.scopesString.substring(
        0,
        state.scopesString.length - 1
      );
  },
  fillFiltersWithSavedValues(state, val) {
    if (val.appliesToSME != '') {
      const smeValue = '10';
      const notSmeValue = '20';

      if (val.appliesToSME.includes(smeValue))
        store.commit('advancedFilters/updateSme', true);
      else store.commit('advancedFilters/updateSme', false);

      if (val.appliesToSME.includes(notSmeValue))
        store.commit('advancedFilters/updateNotSme', true);
      else store.commit('advancedFilters/updateNotSme', false);
    }

    if (val.scopes != '') {
      const europeanValue = '10';
      const nationalValue = '20';
      const regionalValue = '30';

      if (val.scopes.includes(europeanValue))
        store.commit('advancedFilters/updateEuropean', true);
      else store.commit('advancedFilters/updateEuropean', false);

      if (val.scopes.includes(nationalValue))
        store.commit('advancedFilters/updateNational', true);
      else store.commit('advancedFilters/updateNational', false);

      if (val.scopes.includes(regionalValue))
        store.commit('advancedFilters/updateRegional', true);
      else store.commit('advancedFilters/updateRegional', false);
    }

    if (val.applicationTerms != '') {
      const beforeValue = '10';
      const afterValue = '20';
      const beforeAndAfterValue = '30';

      if (val.applicationTerms.includes(beforeValue))
        store.commit('advancedFilters/updateTermBefore', true);
      else store.commit('advancedFilters/updateTermBefore', false);

      if (val.applicationTerms.includes(afterValue))
        store.commit('advancedFilters/updateTermAfter', true);
      else store.commit('advancedFilters/updateTermAfter', false);

      if (val.applicationTerms.includes(beforeAndAfterValue))
        store.commit('advancedFilters/updateTermBoth', true);
      else store.commit('advancedFilters/updateTermBoth', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
