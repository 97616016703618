<template>
  <div>
    <div class="homepage-main-container homepage-main">
      <span>Een ogenblik aub</span>
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue';
import router from '../router/index';

export default {
  name: 'HomePage',
  setup() {
    onMounted(async () => {
      router.push({
        name: 'NewsSelectPage',
      });
    });
  },
};
</script>
