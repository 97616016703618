<template>
  <q-dialog v-model="downloadSchemesPage" class="share-grants-page">
    <q-card class="share-dialog">
      <q-card-section>
        <div class="text-h5 text-main bold-font">
          {{ t('share_and_download_schemes.download_selected_items_as_a_report') }}
        </div>
      </q-card-section>
      <q-card-section>
        <div class="row justify-between top-section-responsive">
          <div class="column column-width">
            <span class="input-heading"> {{ t('share_and_download_schemes.report_title') }} * </span>
            <q-input
              class="q-mt-sm input-share"
              borderless
              bg-color="white"
              v-model="reportTitle"
              dense
              :rules="requiredFieldRule"
            />

            <span class="input-heading q-mt-md"> {{ t('share_and_download_schemes.description') }} </span>
            <q-input
              class="q-mt-sm input-share"
              borderless
              bg-color="white"
              v-model="description"
              type="textarea"
            />
          </div>
        </div>
      </q-card-section>

      <q-card-section class="report-type">
        <q-select
          square
          borderless
          bg-color="white"
          v-model="reportType"
          :options="options"
          :label="t('share_and_download_schemes.type_of_report')"
          style="width: 47%"
        />
      </q-card-section>

      <q-card-actions class="q-mt-sm bold-font" align="left">
        <q-btn
          flat
          no-caps
          :label="t('search_schemes.download')"
          class="orange-btn-filled"
          @click="
            downloadSchemePageEmit(
              reportTitle,
              description,
              customerName,
              reportType
            )
          "
        />
        <q-btn
          no-caps
          flat
          :label="t('common.cancel')"
          class="orange-btn-cancel"
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { computed, ref } from 'vue';
import store from '../../store/index';
import authHeader from '../../helpers/auth-header';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    
    const downloadSchemesPage = computed({
      get: () => store.state.grantItems.downloadSchemesPage,
      set: (val) => store.commit('grantItems/disableDownloadSchemesPage', val),
    });

    const grant = computed({
      get: () => store.state.grantItems.grantToShare,
    });

    const downloadSchemePageEmit = async (
      reportTitle,
      description,
      customerName,
      reportType
    ) => {
      if (reportTitle == '') {
        return;
      }
      const ids = store.state.grantItems.selectedResults
        .map((d) => {
          return d.schemeId;
        })
        .join(',');
      let form = document.createElement('form');
      form.method = 'post';
      form.target = '_blank';
      form.action = `./api/download/downloadSchemes`;
      form.innerHTML = `'<input type="hidden" name="jwtToken" value="${
        authHeader().Authorization
      }">
                         <input type="hidden" name="reportType" value="${
                           reportType.value
                         }">
                         <input type="hidden" name="ids" value="${ids}">
                         <input type="hidden" name="reportTitle" value="${reportTitle}">
                         <input type="hidden" name="reportDescription" value="${description}">
                         <input type="hidden" name="customerName" value="${customerName}">'`;
      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
      store.commit('grantItems/disableDownloadSchemesPage');
      await store.commit('grantItems/updateisDisplayDownloadMessage');
    };

    const address = ref('');
    const message = ref('');

    const reportTitle = ref('');
    const description = ref('');
    const customerName = ref('');
    const reportTemplate = ref('');
    const templateOptions = ['Template 1', 'Template 2'];
    const searchCriteria = ref('');
    const sendWithinOrg = ref(false);

    const reportType = ref({
      label: computed(() => t('share_and_download_schemes.full_report')),
      value: 0,
    });

    const isVind = computed({
      get: () => store.state.auth.env === 0 || store.state.auth.env === 40,
    });

    const custid = computed({
      get: () => store.state.auth.custid,
    });

    const reportOptions = ref([
      {
        label: computed(() => t('share_and_download_schemes.full_report')),
        value: 0,
      },
      {
        label: computed(() => t('share_and_download_schemes.only_core_data')),
        value: 10,
      },
      {
        label: computed(() => t('share_and_download_schemes.key_data_and_summaries')),
        value: 20,
      },
    ]);
    
    if (isVind.value && custid.value == 2) {
      reportOptions.value.push({
        label: t('share_and_download_schemes.screening_report'),
        value: 30,
      });
    }

    return {
      t,
      options: reportOptions.value,
      reportType,
      sendWithinOrg,
      searchCriteria,
      templateOptions,
      reportTemplate,
      customerName,
      description,
      reportTitle,
      message,
      address,
      downloadSchemePageEmit,
      downloadSchemesPage,
      grant,
      isVind,

      requiredFieldRule: [
        (val) => (val && val.length > 0) || t('common.required_field')
,
      ],
      isValidEmail(val) {
        const emailPattern =
          /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
        return emailPattern.test(val) || t('common.invalid_email_address')
;
      },
    };
  },
};
</script>
