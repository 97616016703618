<template>
  <div class="rating-page">
    <ErrorModal />
    <div id="q-app" style="min-height: 75vh">
      <div class="q-pa-md">
        <div class="q-gutter-y-md column" style="margin-top: 120px">
          <h3>Heb je nog tips voor ons?</h3>
          <q-input
            class="input-suggestion-field"
            outlined
            v-model="suggestionText"
            :rules="requiredFieldRule"
          />
          <q-btn
            flat
            class="orange-btn-filled submitBtn"
            color="white"
            label="Verzenden"
            @click="submitResponse()"
            no-caps
          />
        </div>
      </div>
    </div>
  </div>
  <q-dialog v-model="showInfoDialog" class="info-dialog">
    <q-card>
      <q-card-section>
        <h3 class="title">
          {{ t("account_management.recommendation_thanks") }}
        </h3>
      </q-card-section>
      <q-card-actions align="center">
        <q-btn
          flat
          class="orange-btn-filled"
          color="white"
          label="Ok"
          no-caps
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import SurveyAnswersService from "../../services/surveyAnswers.service";
import ErrorModal from "../../components/userProfile-components/ErrorModal.vue";
export default {
  name: "ReactivationSuggestionPage",
  components: {
    ErrorModal,
  },
  setup() {
    const route = useRoute();
    const token = ref("");
    const isSuccess = ref(false);
    const showInfoDialog = ref(false);
    const suggestionText = ref("");

    onMounted(async () => {
      token.value = route.params.token;
    });

    const submitResponse = async () => {
      var s = await SurveyAnswersService.setReactivationSuggestion(
        suggestionText,
        token
      );
      if (s) {
        isSuccess.value = true;
        showInfoDialog.value = true;
      }
    };

    return {
      token,
      isSuccess,
      route,
      showInfoDialog,
      suggestionText,
      submitResponse,
      requiredFieldRule: [
        (val) => (val && val.length > 0) || "Veld is verplicht!",
      ],
    };
  },
};
</script>
