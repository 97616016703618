<template>
  <main class="main-tag">
    <div class="register-page" :class="!isDefaultEnvironment ? 'portal-register' : ''">
      <div class="background-img" v-if="!isDefaultEnvironment"></div>

      <q-form @submit="onSubmit" @reset="onReset" class="login-form" v-if="!registrationFormSubmitted">
        <div v-if="!isDefaultEnvironment" class="column q-pt-xl">
          <h4 class="q-mb-md welcome-heading-text">
            Welkom op Vindsubsidies loket {{ customerPortalSignatureName }}
          </h4>
          <p class="sub-welcome-text">
            Vind relevante subsidies door je te registeren
          </p>
        </div>
        <h4 v-else>Registreer</h4>
        <div class="inputs">
          <div class="name-inputs">
            <q-input dense bg-color="white" borderless v-model="register.firstName" label="Voornaam"
              style="width: 263px" lazy-rules :rules="requiredFieldRule" />
            <q-input dense bg-color="white" v-model="register.lastName" label="Achternaam" borderless
              style="width: 263px" lazy-rules :rules="requiredFieldRule" />
            <q-input dense bg-color="white" v-model="register.email" borderless label="E-mailadres" lazy-rules :rules="[
      (val) => !!val || 'Vul een correct emailadres in',
      isValidEmail,
    ]" style="width: 263px" />
            <q-select v-if="env === 30" dense bg-color="white" label="Wie ben je?" borderless
              v-model="register.professionType" :options="professionTypes" option-label="name" style="min-width: 263px"
              lazy-rules :rules="requiredFieldRule" />
            <q-input v-if="isDefaultEnvironment" dense bg-color="white" v-model="register.confirmEmail"
              label="Emailconfirm" borderless lazy-rules :rules="[
      (val) => !!val || 'Vul een correct emailadres in',
      isValidEmail,
      isConfirmedEmail,
    ]" style="width: 263px" />
          </div>
        </div>
        <div class="terms-and-conditions">
          <q-list v-if="isDefaultEnvironment">
            <q-item>
              <q-item-section>
                <q-item-label style="text-align: left"><strong>Voorwaarden</strong></q-item-label>
                <q-item-label style="text-align: left" caption class="font-dark">
                  We willen u laten weten hoe Vindsubsidies namens
                  {{ customerPortalName }} werkt en waarom we uw
                  registratiegegevens nodig hebben (al uw persoonlijke
                  registratiegegevens staan hierboven vermeld).
                  <br />
                  (<a
                    href="https://www.vindsubsidies.nl/media/1mtnfbzk/gebruiksvoorwaarden-subsidie-informatiedienst.pdf">Bekijk
                    gebruiksvoorwaarden</a>)
                </q-item-label>

                <q-field :model-value="register.terms" :rules="[acceptedTerms]" borderless dense>
                  <template v-slot:control>
                    <q-checkbox class="q-pt-sm" dense v-model="register.terms" color="primary"
                      label="Ik accepteer de algemene voorwaarden" />
                  </template>
                </q-field>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label style="text-align: left"><strong>Uw persoonlijke informatie</strong></q-item-label>
                <q-item-label caption style="text-align: left" class="font-dark">
                  Volgens de nieuwe Algemene Verordening Gegevensbescherming
                  (AVG) moet u instemmen met uw gegevens (al uw persoonlijke
                  registratiegegevens staan hierboven vermeld) die worden
                  opgeslagen en gebruikt om u de diensten van Vindsubsidies
                  namens
                  {{ customerPortalName }} aan te bieden. Wij gebruiken deze
                  gegevens alleen voor de doeleinden van:
                  <ul>
                    <li>Toegang te verschaffen tot deze portal</li>
                    <li>
                      E-mails te versturen met betrekking tot het opnieuw
                      instellen van uw wachtwoord
                    </li>
                    <li>
                      E-mails te versturen met betrekking tot wijzigingen in
                      subsidieregelingen, op basis van het door u opgeslagen
                      bewaarde zoekactie
                    </li>
                    <li>
                      Om uw contactgegevens en site gebruik door te geven aan
                      {{ customerPortalName }}
                    </li>
                  </ul>
                </q-item-label>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label style="text-align: left"><strong>Toestemming</strong></q-item-label>
                <q-field :model-value="register.permission" :rules="[acceptedPermission]" borderless dense>
                  <template v-slot:control>
                    <q-checkbox class="q-pt-sm" dense v-model="register.permission" color="primary"
                      label="Ik stem ermee in dat mijn gegevens gebruikt worden voor de bovengenoemde doeleinden" />
                  </template>
                </q-field>
              </q-item-section>
            </q-item>
          </q-list>
          <div class="terms-checkbox q-my-md" v-else>
            <q-checkbox dense v-model="register.terms" :label="termsAndConditionsLabel" class="checkbox-terms" />
          </div>
        </div>
        <div class="q-pb-md">
          <q-btn label="Registreer" type="submit" class="bold-font orange-btn-filled q-mb-md" no-caps />
          <!-- <q-btn
          label="Reset"
          type="reset"
          color="primary"
          flat
          no-caps
          class="q-ml-sm"
        /> -->
          <div class="register-btn" v-if="!isDefaultEnvironment">
            <p><strong>Heb je al een account?</strong></p>
            <q-btn no-caps class="bold-font orange-btn q-mb-md" style="max-width: 124px; text-decoration: none"
              label="Inloggen" :to="{ name: 'Login' }" />
          </div>
        </div>
      </q-form>
      <div v-else class="submitted-page">
        <div class="row justify-center align-center">
          <div class="col-6">
            <div class="column submit-heading">
              <div class="text-h5 q-pb-xl">Bedankt voor het registreren!</div>
              <label class="submit-label q-pt-md">
                U ontvangt binnen 2 minuten de registratielink.<br />
                <strong>Let op! </strong> De mail is afkomstig van
                Vindsubsidies. Indien deze niet binnen komt, check dan ook uw spam.
              </label>
              <q-btn no-caps class="bold-font orange-btn-filled q-my-lg" style="max-width: 124px; text-decoration: none"
                label="Verder" :to="{ name: 'Login' }" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { reactive, onMounted, computed, ref } from 'vue';
import authService from '../services/auth.service';
import store from '../store/index';
export default {
  setup() {
    const registrationFormSubmitted = ref(false);

    const register = reactive({
      firstName: '',
      lastName: '',
      email: '',
      confirmEmail: '',
      organisation: null,
      terms: false,
      permission: false,
      professionType: '',
    });

    onMounted(async () => {
      await authService.getEnvironment();
      await authService.getPortalInfo();
      await authService.getPortalTypes();
      await authService.getSearchInterests();
      await authService.getProfessionTypes();
    });

    const customerPortalName = computed({
      get: () => store.state.auth.customer.customerPortalName,
    });

    const customerPortalSignatureName = computed({
      get: () => store.state.auth.customer.customerPortalSignatureName,
    });

    const portalThemeTypes = computed({
      get: () => store.state.auth.portalThemeTypes,
    });

    const professionTypes = computed({
      get: () => store.state.auth.professionTypes,
    });
    const searchInterest = computed({
      get: () => store.state.auth.searchInterests,
    });

    const isThemeEmpty = () => {
      return portalThemeTypes.value.length <= 0;
    };

    /*  const noUpperCasePortalName = customerPortalName.value
      ? customerPortalName.value.charAt(0).toLowerCase() +
        customerPortalName.value.slice(1)
      : ''; */
    const noUpperCasePortalName = computed({
      get: () => store.state.auth.customerPortalNameNoUpper,
    });

    const isDefaultEnvironment = computed({
      get: () => store.state.auth.env === 0 || store.state.auth.env === 40,
    });

    const env = computed({
      get: () => store.state.auth.env,
    });

    const termsAndConditionsLabel = ref(
      'Ja, ik geef toestemming voor de verwerking van mijn gegevens en ik wil op de hoogte gehouden worden over subsidies in ' +
      noUpperCasePortalName.value +
      'over *geselecteerd onderwerp*.'
    );

    return {
      termsAndConditionsLabel,
      customerPortalSignatureName,
      register,
      env,
      professionTypes,
      isDefaultEnvironment,
      portalThemeTypes,
      searchInterest,
      customerPortalName,
      noUpperCasePortalName,
      registrationFormSubmitted,
      isThemeEmpty,
      requiredFieldRule: [
        (val) => (val && val.length > 0) || 'Veld is verplicht!',
      ],
      isValidEmail(val) {
        const emailPattern =
          /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
        return emailPattern.test(val) || 'Ongeldig e-mailadres';
      },

      isConfirmedEmail(val) {
        return val === register.email || 'Emailadressen zijn niet gelijk';
      },
      acceptedTerms(val) {
        return (
          val ||
          'Je dient akkoord te geven op de verwerking van je gegevens en de algemene voorwaarden.'
        );
      },
      acceptedPermission(val) {
        return (
          val ||
          'Je dient akkoord te geven op de verwerking van je gegevens en de algemene voorwaarden.'
        );
      },

      async onSubmit() {
        await authService.register(register);
        registrationFormSubmitted.value = true;
      },

      onReset() {
        register.email = '';
        register.confirmEmail = '';
        register.organisation = null;
        register.firstName = '';
        register.lastName = '';
        register.terms = false;
        register.permission = false;
        register.professionType = '';
      },
    };
  },
};
</script>
