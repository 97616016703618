import axios from 'axios';
import axiosUrl from './axios.service';
import authHeader from '../helpers/auth-header';
import store from '../store/index';

const API_URL = axiosUrl.getAxiosUrl();

class UserStatsService {
  async getGeneralUserStats() {
    return await axios
      .get(API_URL + '/stats/getGeneralUserStats', {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit(
          'userStats/updateGeneralUsersStatsFrontOffice',
          response.data.userStatsBoxes
        );
      });
  }

  async getUsersTableStats() {
    return await axios
      .get(API_URL + '/stats/getUsersTableStatsFront', {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('userStats/updateTableStats', response.data.userStats);
      });
  }

  async getGeneralStatsFront() {
    return await axios
      .get(API_URL + '/stats/getGeneralStatsFront', {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('userStats/updateGeneralStatsFront', response.data);
      });
  }

  async getOBData(team) {
    return await axios
      .get(API_URL + '/stats/getOBData', {
        headers: authHeader(),
        params: {
          department: team && team.value ? team.value : null,
        },
      })
      .then((response) => {
        store.commit('onzeStats/updateOnzeBarData', response.data);
      });
  }

  async getOnzeConsultants(team) {
    return await axios
      .get(API_URL + '/stats/getConsultants', {
        headers: authHeader(),
        params: {
          team: team,
        },
      })
      .then((response) => {
        store.commit('onzeStats/updateConsultants', response.data);
      });
  }

  async getDepartments() {
    return await axios
      .get(API_URL + '/stats/getDepartments', {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('onzeStats/updateDepartments', response.data);
      });
  }
}
export default new UserStatsService();
