import SchemesService from '../services/grants.service';
import store from '../store/index';
import { computed } from 'vue';
import grantsService from '../services/grants.service';

class SchemesUtils {
  async checkIsNew(id) {
    await SchemesService.getSchemeById(id, true);
    return true;
  }

  translateStatus(statusEng) {
    if (statusEng == 'Closed') return 'Gesloten';
    else if (statusEng == 'Forthcoming' || statusEng == 'Future')
      return 'Aangekondigd';
    else if (statusEng == 'Discontinued') return 'Gestopt';
    else if (statusEng == 'Sleeping') return 'Slapend';
    else return 'Open';
  }

  formatEuro(amount) {
    var euroScale = Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0,
    });
    return euroScale.format(amount);
  }

  checkIsDefaultSearch() {
    const audiences = computed({
      get: () =>
        store.state.advancedSearch.selectedAudiences != ''
          ? store.state.advancedSearch.selectedAudiences
          : sessionStorage.getItem('selectedAudiences'),
    });
    const locations = computed({
      get: () =>
        store.state.advancedSearch.selectedLocations != ''
          ? store.state.advancedSearch.selectedLocations
          : sessionStorage.getItem('selectedLocations'),
    });
    //This is just hidden for now, don't remove it:
    /*
    const industries = computed({
      get: () => store.state.advancedSearch.selectedIndustries,
    });
    */
    const purposes = computed({
      get: () =>
        store.state.advancedSearch.selectedPurposes != ''
          ? store.state.advancedSearch.selectedPurposes
          : sessionStorage.getItem('selectedPurposes'),
    });
    const schemeTypes = computed({
      get: () =>
        store.state.advancedSearch.selectedSchemeTypes != ''
          ? store.state.advancedSearch.selectedSchemeTypes
          : sessionStorage.getItem('selectedSchemeTypes'),
    });
    const projectTypes = computed({
      get: () => store.state.advancedSearch.selectedProjectTypes,
    });
    const smeString = computed({
      get: () => store.state.advancedFilters.smeString,
    });
    const applicationTermsString = computed({
      get: () => store.state.advancedFilters.termsString,
    });

    const minBudget = computed({
      get: () =>
        store.state.advancedSearch.minBudget != null
          ? store.state.advancedSearch.minBudget
          : Number(sessionStorage.getItem('minBudget')),
    });

    const maxBudget = computed({
      get: () =>
        store.state.advancedSearch.maxBudget != null
          ? store.state.advancedSearch.maxBudget
          : Number(sessionStorage.getItem('maxBudget')),
    });

    const scopesString = computed({
      get: () => store.state.advancedFilters.scopesString,
    });
    const orgsIncludeString = computed({
      get: () =>
        store.state.advancedSearch.organisationsIncludeString != ''
          ? store.state.advancedSearch.organisationsIncludeString
          : sessionStorage.getItem('organisationsIncludeString'),
    });

    const orgsExcludeString = computed({
      get: () =>
        store.state.advancedSearch.organisationsExcludeString != ''
          ? store.state.advancedSearch.organisationsExcludeString
          : sessionStorage.getItem('organisationsExcludeString'),
    });

    const startDate = computed({
      get: () =>
        store.state.advancedSearch.startDate != null &&
        store.state.advancedSearch.startDate != ''
          ? store.state.advancedSearch.startDate
          : JSON.parse(sessionStorage.getItem('startDate')),
    });

    const endDate = computed({
      get: () =>
        store.state.advancedSearch.endDate != null &&
        store.state.advancedSearch.endDate != ''
          ? store.state.advancedSearch.endDate
          : JSON.parse(sessionStorage.getItem('endDate')),
    });

    const searchText = computed({
      get: () => store.state.advancedSearch.searchText,
    });

    const isApplyingFilters = computed({
      get: () => store.state.user.isApplyingFilters,
    });

    if (
      audiences.value == '' &&
      locations.value == '' &&
      //This is just hidden for now, don't remove it:
      //industries.value == '' &&
      purposes.value == '' &&
      schemeTypes.value == '' &&
      projectTypes.value == '' &&
      smeString.value == '' &&
      applicationTermsString.value == '' &&
      maxBudget.value == null &&
      minBudget.value == null &&
      scopesString.value == '' &&
      orgsIncludeString.value == '' &&
      orgsExcludeString.value == '' &&
      startDate.value == '' &&
      endDate.value == '' &&
      searchText.value == '' &&
      isApplyingFilters.value == false
    )
      return true;
    return false;
  }

  async searchSchemes(locale) {
    store.commit('advancedSearch/updateapplySavedSearchPause', true);
    store.commit('advancedSearch/disableFiltersChange');
    store.commit('grantItems/disableSearchPageLoading');

    const statusBoxes = computed({
      get: () => sessionStorage.getItem('statusesString'),
    });

    const audiences = computed({
      get: () =>
        store.state.advancedSearch.selectedAudiences != ''
          ? store.state.advancedSearch.selectedAudiences
          : sessionStorage.getItem('selectedAudiences'),
    });
    const locations = computed({
      get: () =>
        store.state.advancedSearch.selectedLocations != ''
          ? store.state.advancedSearch.selectedLocations
          : sessionStorage.getItem('selectedLocations'),
    });
    //This is just hidden for now, don't remove it:
    /*
    const industries = computed({
      get: () => store.state.advancedSearch.selectedIndustries,
    });
    */
    const purposes = computed({
      get: () =>
        store.state.advancedSearch.selectedPurposes != ''
          ? store.state.advancedSearch.selectedPurposes
          : sessionStorage.getItem('selectedPurposes'),
    });
    const schemeTypes = computed({
      get: () =>
        store.state.advancedSearch.selectedSchemeTypes != ''
          ? store.state.advancedSearch.selectedSchemeTypes
          : sessionStorage.getItem('selectedSchemeTypes'),
    });
    const projectTypes = computed({
      get: () => store.state.advancedSearch.selectedProjectTypes,
    });
    const smeString = computed({
      get: () => store.state.advancedFilters.smeString,
    });
    const applicationTermsString = computed({
      get: () => store.state.advancedFilters.termsString,
    });

    const minBudget = computed({
      get: () =>
        store.state.advancedSearch.minBudget != null
          ? store.state.advancedSearch.minBudget
          : Number(sessionStorage.getItem('minBudget')),
    });

    const maxBudget = computed({
      get: () =>
        store.state.advancedSearch.maxBudget != null
          ? store.state.advancedSearch.maxBudget
          : Number(sessionStorage.getItem('maxBudget')),
    });

    const scopesString = computed({
      get: () => store.state.advancedFilters.scopesString,
    });
    const orgsIncludeString = computed({
      get: () =>
        store.state.advancedSearch.organisationsIncludeString != ''
          ? store.state.advancedSearch.organisationsIncludeString
          : sessionStorage.getItem('organisationsIncludeString'),
    });

    const orgsExcludeString = computed({
      get: () =>
        store.state.advancedSearch.organisationsExcludeString != ''
          ? store.state.advancedSearch.organisationsExcludeString
          : sessionStorage.getItem('organisationsExcludeString'),
    });

    const startDate = computed({
      get: () =>
        store.state.advancedSearch.startDate != null &&
        store.state.advancedSearch.startDate != ''
          ? store.state.advancedSearch.startDate
          : JSON.parse(sessionStorage.getItem('startDate')),
    });

    const endDate = computed({
      get: () =>
        store.state.advancedSearch.endDate != null &&
        store.state.advancedSearch.endDate != ''
          ? store.state.advancedSearch.endDate
          : JSON.parse(sessionStorage.getItem('endDate')),
    });

    const searchText = computed({
      get: () => store.state.advancedSearch.searchText,
    });

    const selectedSort = computed({
      get: () => store.state.advancedSearch.selectedSort,
    });

    store.commit('advancedFilters/createTermsString');
    store.commit('advancedFilters/createSMEString');
    store.commit('advancedFilters/createScopesString');
    store.commit('advancedSearch/createOrganisationsIncludeString');
    store.commit('advancedSearch/createOrganisationsExcludeString');
    store.commit('advancedSearch/createDifficultyString');
    const isApplyingFilters = computed({
      get: () => store.state.user.isApplyingFilters,
    });
    if (isApplyingFilters.value && this.checkIsDefaultSearch()) return;

    const complexityRating = computed({
      get: () => store.state.advancedSearch.difficultiesString,
    });

    const timeForApplicationRating = computed({
      get: () => store.state.advancedSearch.applicationTimeString,
    });

    const chanceOfSuccessRating = computed({
      get: () => store.state.advancedSearch.chancesString,
    });

    await SchemesService.getSearchResults(
      locale,
      searchText,
      statusBoxes,
      audiences,
      locations,
      //This is just hidden for now, don't remove it:
      //industries,
      purposes,
      schemeTypes,
      projectTypes,
      smeString,
      applicationTermsString,
      minBudget,
      maxBudget,
      scopesString,
      orgsIncludeString,
      orgsExcludeString,
      startDate,
      endDate,
      selectedSort,
      complexityRating,
      chanceOfSuccessRating,
      timeForApplicationRating
    ).then(() => {
      store.commit('advancedSearch/updateapplySavedSearchPause', false);
    });
  }

  async searchSchemesFavorites(locale) {
    const statusBoxes = computed({
      get: () => sessionStorage.getItem('statusesString'),
    });

    const audiences = computed({
      get: () => store.state.user.favoriteAudienceTagsString,
    });
    const locations = computed({
      get: () =>
        store.state.advancedSearch.selectedLocations != ''
          ? store.state.advancedSearch.selectedLocations
          : sessionStorage.getItem('selectedLocations'),
    });
    //This is just hidden for now, don't remove it:
    /*
    const industries = computed({
      get: () => store.state.advancedSearch.selectedIndustries,
    });
    */
    const purposes = computed({
      get: () => store.state.user.favoritePurposesTagsString,
    });
    const schemeTypes = computed({
      get: () =>
        store.state.advancedSearch.selectedSchemeTypes != ''
          ? store.state.advancedSearch.selectedSchemeTypes
          : sessionStorage.getItem('selectedSchemeTypes'),
    });
    const projectTypes = computed({
      get: () => store.state.advancedSearch.selectedProjectTypes,
    });
    const smeString = computed({
      get: () => store.state.advancedFilters.smeString,
    });
    const applicationTermsString = computed({
      get: () => store.state.advancedFilters.termsString,
    });

    const minBudget = computed({
      get: () =>
        store.state.advancedSearch.minBudget != null
          ? store.state.advancedSearch.minBudget
          : Number(sessionStorage.getItem('minBudget')),
    });

    const maxBudget = computed({
      get: () =>
        store.state.advancedSearch.maxBudget != null
          ? store.state.advancedSearch.maxBudget
          : Number(sessionStorage.getItem('maxBudget')),
    });

    const scopesString = computed({
      get: () => store.state.advancedFilters.scopesString,
    });
    const orgsIncludeString = computed({
      get: () =>
        store.state.advancedSearch.organisationsIncludeString != ''
          ? store.state.advancedSearch.organisationsIncludeString
          : sessionStorage.getItem('organisationsIncludeString'),
    });

    const orgsExcludeString = computed({
      get: () =>
        store.state.advancedSearch.organisationsExcludeString != ''
          ? store.state.advancedSearch.organisationsExcludeString
          : sessionStorage.getItem('organisationsExcludeString'),
    });

    const startDate = computed({
      get: () =>
        store.state.advancedSearch.startDate != null &&
        store.state.advancedSearch.startDate != ''
          ? store.state.advancedSearch.startDate
          : JSON.parse(sessionStorage.getItem('startDate')),
    });

    const endDate = computed({
      get: () =>
        store.state.advancedSearch.endDate != null &&
        store.state.advancedSearch.endDate != ''
          ? store.state.advancedSearch.endDate
          : JSON.parse(sessionStorage.getItem('endDate')),
    });

    const searchText = computed({
      get: () => store.state.advancedSearch.searchText,
    });

    const selectedSort = computed({
      get: () => store.state.advancedSearch.selectedSort,
    });

    store.commit('advancedFilters/createTermsString');
    store.commit('advancedFilters/createSMEString');
    store.commit('advancedFilters/createScopesString');
    store.commit('advancedSearch/createOrganisationsIncludeString');
    store.commit('advancedSearch/createOrganisationsExcludeString');
    await SchemesService.getSearchResults(
      locale,
      searchText,
      statusBoxes,
      audiences,
      locations,
      //This is just hidden for now, don't remove it:
      //industries,
      purposes,
      schemeTypes,
      projectTypes,
      smeString,
      applicationTermsString,
      minBudget,
      maxBudget,
      scopesString,
      orgsIncludeString,
      orgsExcludeString,
      startDate,
      endDate,
      selectedSort
    );
  }

  async searchNewestSchemes(locale, page, size) {
    await grantsService.searchNewestSchemes(locale, page, size);
  }

  async searchDeadlineSchemes(locale, page, size) {
    await grantsService.searchSchemesByDeadline(locale, page, size);
  }

  async searchNewestSchemesWithTags(
    locale,
    page,
    size,
    audiences,
    purposes,
    locations
  ) {
    var audiencesStr = audiences;
    var purposesStr = purposes;
    audiencesStr = audiences.join(',');
    purposesStr = purposes.join(',');
    await grantsService.searchNewestSchemesTags(
      locale,
      page,
      size,
      audiencesStr,
      purposesStr,
      locations
    );
  }

  async searchDeadlineSchemesWithTags(
    locale,
    page,
    size,
    audiences,
    purposes,
    locations
  ) {
    var audiencesStr = audiences;
    var purposesStr = purposes;
    audiencesStr = audiences.join(',');
    purposesStr = purposes.join(',');
    await grantsService.searchSchemesByDeadlineTags(
      locale,
      page,
      size,
      audiencesStr,
      purposesStr,
      locations
    );
  }

  async searchSignificantSchemesWithTags(
    locale,
    page,
    size,
    audiences,
    purposes,
    locations
  ) {
    var audiencesStr = audiences;
    var purposesStr = purposes;
    var locationsStr = locations;
    audiencesStr = audiences.join(',');
    purposesStr = purposes.join(',');
    locationsStr = locations.join(',');
    await grantsService.searchNewestSchemesTags(
      locale,
      page,
      size,
      audiencesStr,
      purposesStr,
      locationsStr
    );
  }

  async searchSignificantSchemes(page, size) {
    await grantsService.searchSignificantSchemes(page, size);
  }
}

export default new SchemesUtils();
