import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import VueFinalModal from 'vue-final-modal';
import VCalendar from 'v-calendar';
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';
import axios from 'axios';
import SocialSharing from 'vue-social-sharing';
import VueTour from 'v3-tour';
import AuthService from './services/auth.service';
import CKEditor from '@ckeditor/ckeditor5-vue';
import CountryFlag from 'vue-country-flag-next';
import { initializeI18n } from './i18n';

require('v3-tour/dist/vue-tour.css');
initializeI18n().then(i18n => {
  const app = createApp(App)
    .use(router)
    .use(CKEditor)
    .use(Quasar, quasarUserOptions)
    .use(VueFinalModal())
    .use(VCalendar, {})
    .use(SocialSharing)
    .use(VueTour)
    .use(i18n);

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 401 || error.response.status === 403) {
        // auto logout if 401 or 403 response returned from api
        AuthService.logout();
        router.go();
      }
      return Promise.reject(error);
    }
  );

  // Set globalProperties to include i18n's global composer instance
  const { t } = i18n.global;
  app.config.globalProperties.t = t;

  app.config.globalProperties.axios = axios;

  app.mount('#app');
  app.component('country-flag', CountryFlag);
});
