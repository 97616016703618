<template>
  <main class="main-tag">3
    <div class="login-page login-page-default row" id="login-page">

      <div class="col-md-4 offset-md-4 col-sm-8 offset-sm-2 text-left">
        <h4 class="welcome-heading-text q-mb-sm">
          Er is een fout opgetreden met uw account
        </h4>
        <div>
          <ul class="q-mb-lg">
            <li>Uw organisatie heeft geen Single-Sign-On account &oacute;f</li>
            <li>Uw organisatie heeft geen actief account &oacute;f</li>
            <li>Uw persoonlijke account is inactief</li>
          </ul>
          Mocht dit onterecht zijn of heeft u vragen, neem dan contact met ons op via
          <a href="mailto:info@vindsubsidies.nl">info@vindsubsidies.nl</a> of bel
          <a href="tel:0882020401">088 - 2020 401</a>
        </div>
        <div class="q-mt-lg">
          Terug naar <a href="/login">login</a>
        </div>
      </div>
    </div>
  </main>
</template>

<style>
.margin-l-e {
  margin-left: 24px;
}
</style>