const state = () => ({
  suggestions: [],
});

const getters = {};

const actions = {};

const mutations = {
  updateSuggestions(state, val) {
    state.suggestions = val;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
