/* import SchemesService from '../services/grants.service'; */
import NewsService from '../services/news.service';
import store from '../store/index';
import { computed } from 'vue';
class NewsUtils {
  formatEuro(amount) {
    var euroScale = Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0,
    });
    return euroScale.format(amount);
  }

  checkIsDefaultSearch() {
    const audiences = computed({
      get: () => store.state.newsSearch.selectedAudiences != '' ? store.state.newsSearch.selectedAudiences : sessionStorage.getItem('selectedAudiences'),
    });
    const locations = computed({
      get: () => store.state.newsSearch.selectedLocations != '' ? store.state.newsSearch.selectedLocations : sessionStorage.getItem('selectedLocations'),
    });
    //This is just hidden for now, don't remove it:
    /*
    const industries = computed({
      get: () => store.state.advancedSearch.selectedIndustries,
    });
    */
    const purposes = computed({
      get: () => store.state.newsSearch.selectedPurposes != '' ? store.state.newsSearch.selectedPurposes : sessionStorage.getItem('selectedPurposes'),
    });

    const schemeTypes = computed({
      get: () => store.state.newsSearch.selectedSchemeTypes != '' ? store.state.newsSearch.selectedSchemeTypes : sessionStorage.getItem('selectedSchemeTypes'),
    });

    const projectsTypes = computed({
      get: () => store.state.newsSearch.selectedProjectTypes,
    });

    const orgsIncludeString = computed({
      get: () => store.state.advancedSearch.organisationsIncludeString != '' ? store.state.advancedSearch.organisationsIncludeString : sessionStorage.getItem('organisationsIncludeString'),
    });

    const orgsExcludeString = computed({
      get: () => store.state.advancedSearch.organisationsExcludeString  != '' ? store.state.advancedSearch.organisationsExcludeString : sessionStorage.getItem('organisationsExcludeString'),
    });

    const startDate = computed({
      get: () => store.state.advancedSearch.startDate != null && store.state.advancedSearch.startDate != '' ? store.state.advancedSearch.startDate : JSON.parse(sessionStorage.getItem('startDate')),
    });

    const endDate = computed({
      get: () => store.state.advancedSearch.endDate != null && store.state.advancedSearch.endDate != '' ? store.state.advancedSearch.endDate : JSON.parse(sessionStorage.getItem('endDate')),
    });

    const searchText = computed({
      get: () => store.state.newsSearch.searchText,
    });

    const page = computed({
      get: () => store.state.newsSearch.currentPage,
    });

    if (
      audiences.value == '' &&
      locations.value == '' &&
      //This is just hidden for now, don't remove it:
      //industries.value == '' &&
      purposes.value == '' &&
      schemeTypes.value == '' &&
      projectsTypes.value == '' &&
      orgsIncludeString.value == '' &&
      orgsExcludeString.value == '' &&
      startDate.value == '' &&
      endDate.value == '' &&
      searchText.value == '' &&
      page.value == 1
    )
      return true;
    return false;
  }

  async searchNews(locale) {
    const dateFrom = computed({
      get: () => store.state.newsSearch.dateFrom != '' ? store.state.newsSearch.dateFrom : JSON.parse(sessionStorage.getItem('newsDateFrom')),
    });
    const dateTo = computed({
      get: () => store.state.newsSearch.dateTo != '' ? store.state.newsSearch.dateTo : JSON.parse(sessionStorage.getItem('newsDateTo')),
    });

    const audienceTags = computed({
      get: () => store.state.newsSearch.selectedAudiences != '' ? store.state.newsSearch.selectedAudiences : sessionStorage.getItem('selectedAudiences'),
    });

    const locationsTags = computed({
      get: () => store.state.newsSearch.selectedLocations != '' ? store.state.newsSearch.selectedLocations : sessionStorage.getItem('selectedLocations'),
    });

    const purposesTags = computed({
      get: () => store.state.newsSearch.selectedPurposes != '' ? store.state.newsSearch.selectedPurposes : sessionStorage.getItem('selectedPurposes'),
    });

    const schemesTags = computed({
      get: () => store.state.newsSearch.selectedSchemeTypes != '' ? store.state.newsSearch.selectedSchemeTypes : sessionStorage.getItem('selectedSchemeTypes'),
    });

    const projectTypes = computed({
      get: () => store.state.newsSearch.selectedProjectTypes,
    });

    //This is just hidden for now, don't remove it:
    /*
    const industriesTags = computed({
      get: () => store.state.newsSearch.selectedIndustries,
    });
    */

    const orgsIncludeString = computed({
      get: () => store.state.newsSearch.organisationsIncludeString  != '' ? store.state.newsSearch.organisationsIncludeString : sessionStorage.getItem('organisationsIncludeString'),
    });

    const orgsExcludeString = computed({
      get: () => store.state.newsSearch.organisationsExcludeString  != '' ? store.state.newsSearch.organisationsExcludeString : sessionStorage.getItem('organisationsExcludeString'),
    });

    const searchText = computed({
      get: () => store.state.newsSearch.searchText,
    });

    const selectedSort = computed({
      get: () => store.state.newsSearch.selectedSortNews,
    });

    store.commit('newsSearch/createOrganisationsIncludeString');
    store.commit('newsSearch/createOrganisationsExcludeString');

    const isTableView = computed({
      get: () => store.state.newsSearch.isTableView,
    });

    const page = computed({
      get: () => store.state.news.currentPage,
    });

    if (!isTableView.value)
      await NewsService.getSearchResults(
        locale,
        searchText,
        dateFrom,
        dateTo,
        audienceTags,
        schemesTags,
        projectTypes,
        locationsTags,
        purposesTags,
        //This is just hidden for now, don't remove it:
        //industriesTags,
        orgsIncludeString,
        orgsExcludeString,
        selectedSort,
        page
      );
    else
      await NewsService.getSearchResultsTable(
        locale,
        searchText,
        dateFrom,
        dateTo,
        audienceTags,
        schemesTags,
        projectTypes,
        locationsTags,
        purposesTags,
        //This is just hidden for now, don't remove it:
        //industriesTags,
        orgsIncludeString,
        orgsExcludeString,
        selectedSort,
        page
      );
  }

  async searchNewsWithGrantsFilter(locale) {
    store.commit('advancedSearch/updateIsSearchNewsWithSchemesFilters', true);
    const audienceTags = computed({
      get: () => store.state.advancedSearch.selectedAudiences != '' ? store.state.advancedSearch.selectedAudiences : sessionStorage.getItem('selectedAudiences'),
    });
    const locationsTags = computed({
      get: () => store.state.advancedSearch.selectedLocations != '' ? store.state.advancedSearch.selectedLocations : sessionStorage.getItem('selectedLocations'),
    });
    const purposesTags = computed({
      get: () => store.state.advancedSearch.selectedPurposes != '' ? store.state.advancedSearch.selectedPurposes : sessionStorage.getItem('selectedPurposes'),
    });

    const schemesTags = computed({
      get: () => store.state.advancedSearch.selectedSchemeTypes  != '' ? store.state.advancedSearch.selectedSchemeTypes : sessionStorage.getItem('selectedSchemeTypes'),
    });

    const projectTypes = computed({
      get: () => store.state.advancedSearch.selectedProjectTypes,
    });
    //This is just hidden for now, don't remove it:
    /*
    const industriesTags = computed({
      get: () => store.state.newsSearch.selectedIndustries,
    });
    */

    const orgsIncludeString = computed({
      get: () => store.state.advancedSearch.organisationsIncludeString != '' ? store.state.advancedSearch.organisationsIncludeString : sessionStorage.getItem('organisationsIncludeString'),
    });

    const orgsExcludeString = computed({
      get: () => store.state.advancedSearch.organisationsExcludeString != '' ? store.state.advancedSearch.organisationsExcludeString : sessionStorage.getItem('organisationsExcludeString'),
    });

    const searchText = computed({
      get: () => store.state.advancedSearch.searchText,
    });

    const selectedSort = computed({
      get: () => store.state.advancedSearch.selectedSort,
    });

    store.commit('newsSearch/createOrganisationsIncludeString');
    store.commit('newsSearch/createOrganisationsExcludeString');

    const isTableView = computed({
      get: () => store.state.newsSearch.isTableView,
    });

    const dateFrom = computed({
      get: () => store.state.advancedSearch.startDate != null && store.state.advancedSearch.startDate != '' ? store.state.advancedSearch.startDate : JSON.parse(sessionStorage.getItem('startDate')),
    });

    const dateTo = computed({
      get: () => store.state.advancedSearch.endDate != null && store.state.advancedSearch.endDate != '' ? store.state.advancedSearch.endDate : JSON.parse(sessionStorage.getItem('endDate')),
    });

    if (!isTableView.value)
      await NewsService.getSearchResults(
        locale,
        searchText,
        dateFrom,
        dateTo,
        audienceTags,
        schemesTags,
        projectTypes,
        locationsTags,
        purposesTags,
        //This is just hidden for now, don't remove it:
        //industriesTags,
        orgsIncludeString,
        orgsExcludeString,
        selectedSort,
        0
      );
    else
      await NewsService.getSearchResultsTable(
        locale,
        searchText,
        dateFrom,
        dateTo,
        audienceTags,
        schemesTags,
        projectTypes,
        locationsTags,
        purposesTags,
        //This is just hidden for now, don't remove it:
        //industriesTags,
        orgsIncludeString,
        orgsExcludeString,
        selectedSort,
        0
      );

    store.commit('advancedSearch/updateDisplayNumberOfNewsResults', true);
  }

  async searchNewsByPage(locale) {
    const dateFrom = computed({
      get: () => store.state.newsSearch.dateFrom != '' ? store.state.newsSearch.dateFrom : JSON.parse(sessionStorage.getItem('newsDateFrom')),
    });
    const dateTo = computed({
      get: () => store.state.newsSearch.dateTo != '' ? store.state.newsSearch.dateTo : JSON.parse(sessionStorage.getItem('newsDateTo')),
    });

    const audienceTags = computed({
      get: () => store.state.newsSearch.selectedAudiences != '' ? store.state.newsSearch.selectedAudiences : sessionStorage.getItem('selectedAudiences'),
    });

    const locationsTags = computed({
      get: () => store.state.newsSearch.selectedLocations != '' ? store.state.newsSearch.selectedLocations : sessionStorage.getItem('selectedLocations'),
    });

    const purposesTags = computed({
      get: () => store.state.newsSearch.selectedPurposes != '' ? store.state.newsSearch.selectedPurposes : sessionStorage.getItem('selectedPurposes'),
    });

    const schemesTags = computed({
      get: () => store.state.newsSearch.selectedSchemeTypes != '' ? store.state.newsSearch.selectedSchemeTypes : sessionStorage.getItem('selectedSchemeTypes'),
    });

    const projectTypes = computed({
      get: () => store.state.newsSearch.selectedProjectTypes,
    });

    //This is just hidden for now, don't remove it:
    /*
    const industriesTags = computed({
      get: () => store.state.newsSearch.selectedIndustries,
    });
    */

    const orgsIncludeString = computed({
      get: () => store.state.newsSearch.organisationsIncludeString != '' ? store.state.newsSearch.organisationsIncludeString : sessionStorage.getItem('organisationsIncludeString'),
    });

    const orgsExcludeString = computed({
      get: () => store.state.newsSearch.organisationsExcludeString != '' ? store.state.newsSearch.organisationsExcludeString : sessionStorage.getItem('organisationsExcludeString'),
    });

    const searchText = computed({
      get: () => store.state.newsSearch.searchText,
    });

    const selectedSort = computed({
      get: () => store.state.newsSearch.selectedSortNews,
    });

    store.commit('newsSearch/createOrganisationsIncludeString');
    store.commit('newsSearch/createOrganisationsExcludeString');

    const isTableView = computed({
      get: () => store.state.newsSearch.isTableView,
    });

    const current = computed({
      get: () => store.state.news.currentPage,
    });

    if (!isTableView.value)
      await NewsService.getResulstsByPage(
        locale,
        current.value - 1,
        searchText,
        dateFrom,
        dateTo,
        audienceTags,
        schemesTags,
        projectTypes,
        locationsTags,
        purposesTags,
        //This is just hidden for now, don't remove it:
        //industriesTags,
        orgsIncludeString,
        orgsExcludeString,
        selectedSort
      );
    else
      await NewsService.getResulstsByPageTable(
        locale,
        current.value - 1,
        searchText,
        dateFrom,
        dateTo,
        audienceTags,
        schemesTags,
        projectTypes,
        locationsTags,
        purposesTags,
        //This is just hidden for now, don't remove it:
        //industriesTags,
        orgsIncludeString,
        orgsExcludeString,
        selectedSort
      );
  }

  async searchLatestnews(locale, page, size) {
    await NewsService.searchLatestNews(locale, page, size);
  }

  async searchLatestnewsWithTags(locale, page, size, audiences, purposes, locations) {
    var audiencesStr = audiences;
    var purposesStr = purposes;

    audiencesStr = audiences.join(',');
    purposesStr = purposes.join(',');
    await NewsService.searchLatestNewsWithTags(
      locale,
      page,
      size,
      audiencesStr,
      purposesStr,
      locations
    );
  }
}

export default new NewsUtils();
