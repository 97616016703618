<template>
  <div class="filter-box statuses-checkbox">
    <q-checkbox
      dense
      v-model="verySlow"
      :label="t('scheme_details.long_time')"
      class="status-checkbox"
    />
    <q-checkbox dense v-model="slow" :label="t('scheme_details.slow')" class="status-checkbox" />
    <q-checkbox
      dense
      v-model="average"
      :label="t('scheme_details.average')"
      class="status-checkbox"
    />
    <q-checkbox dense v-model="fast" :label="t('scheme_details.fast')" class="status-checkbox" />
    <q-checkbox
      dense
      v-model="veryFast"
      :label="t('scheme_details.very_quick')"
      class="status-checkbox"
    />
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import store from '../../store/index';
import SchemeUtils from '../../utils/schemes';

export default {
  setup() {
    onMounted(() => {});
    const locale = "";
    const verySlow = computed({
      get: () =>
        sessionStorage.getItem('showVerySlow') == null ||
        store.state.advancedSearch.showVerySlow ===
          (sessionStorage.getItem('showVerySlow') === 'true')
          ? store.state.advancedSearch.showVerySlow
          : sessionStorage.getItem('showVerySlow') === 'true',
      set: async (value) => {
        store.commit('advancedSearch/updateVerySlowApplicationTime', value);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });
    const slow = computed({
      get: () =>
        sessionStorage.getItem('showSlow') == null ||
        store.state.advancedSearch.showSlow ===
          (sessionStorage.getItem('showSlow') === 'true')
          ? store.state.advancedSearch.showSlow
          : sessionStorage.getItem('showSlow') === 'true',
      set: async (value) => {
        store.commit('advancedSearch/updateSlowApplicationTime', value);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });
    const average = computed({
      get: () =>
        sessionStorage.getItem('showAverageApplicationTime') == null ||
        store.state.advancedSearch.showAverageApplicationTime ===
          (sessionStorage.getItem('showAverageApplicationTime') === 'true')
          ? store.state.advancedSearch.showAverageApplicationTime
          : sessionStorage.getItem('showAverageApplicationTime') === 'true',
      set: async (value) => {
        store.commit('advancedSearch/updateShowAverageApplicationTime', value);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });
    const fast = computed({
      get: () =>
        sessionStorage.getItem('showFast') == null ||
        store.state.advancedSearch.showFast ===
          (sessionStorage.getItem('showFast') === 'true')
          ? store.state.advancedSearch.showFast
          : sessionStorage.getItem('showFast') === 'true',
      set: async (value) => {
        store.commit('advancedSearch/updateShowFastApplicationTime', value);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });
    const veryFast = computed({
      get: () =>
        sessionStorage.getItem('showVeryFast') == null ||
        store.state.advancedSearch.showVeryFast ===
          (sessionStorage.getItem('showVeryFast') === 'true')
          ? store.state.advancedSearch.showVeryFast
          : sessionStorage.getItem('showVeryFast') === 'true',
      set: async (value) => {
        store.commit('advancedSearch/updateShowVeryFastApplicationTime', value);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });

    const resetSelected = () => {
      store.commit('advancedSearch/updateShowOpen', false);
      store.commit('advancedSearch/updateShowClosed', false);
      store.commit('advancedSearch/updateShowForthcoming', false);
      store.commit('advancedSearch/updateShowDiscontinued', false);
      if (!isSearchPaused.value) SchemeUtils.searchSchemes(locale);
    };
    const showDiscontinuedCheckbox = store.state.auth.env !== 20;
    const isSearchPaused = computed({
      get: () => store.state.advancedSearch.applySavedSearchPause,
    });
    return {
      locale,
      isSearchPaused,
      resetSelected,
      group: ref([]),
      verySlow,
      fast,
      closed,
      veryFast,
      showDiscontinuedCheckbox,
      average,
      slow,
    };
  },
};
</script>

<style></style>
