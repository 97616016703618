<template>
  <div
    class="calls-section"
    :class="isContrast != 'false' && isContrast != false ? 'high-contrast' : ''"
  >
    <q-splitter v-model="splitterModel">
      <template v-slot:before>
        <q-tabs
          v-model="callsTab"
          dense
          vertical
          class="text-neutral-950 calls-tabs"
          active-class="colorUnderline"
          indicator-color="primary"
          align="justify"
          narrow-indicator
        >
          <q-tab
            style="text-transform: capitalize"
            name="active"
            :label="activeLabel"
            class="label-title"
          ></q-tab>
          <q-tab
            style="text-transform: capitalize"
            name="future"
            :label="futureLabel"
            class="label-title"
          ></q-tab>
          <q-tab
            style="text-transform: capitalize"
            name="past"
            :label="pastLabel"
            class="label-title"
          ></q-tab>
        </q-tabs>
      </template>

      <template v-slot:after>
        <q-tab-panels
          v-model="callsTab"
          vertical
          animated
          :class="
            isContrast != 'false' && isContrast != false ? 'high-contrast' : ''
          "
        >
          <q-tab-panel class="q-pt-none" name="active">
            <p
              v-if="!activeOpenings || activeOpenings.length == 0"
              class="text-left"
            >
              Er zijn geen openstaande openstellingen of huidige deadlines.
            </p>
            <div v-if="activeOpenings && activeOpenings.length > 0">
              <div
                class="q-mb-md tab-panel-calls"
                v-for="item in activeOpenings"
                :key="item.id"
              >
                <div class="column abccc">
                  <div class="row wrap title-height">
                    <span
                      class="title text-left"
                      v-if="item.itemType == 2"
                      v-html="translateScheme ? item.transName : item.name"
                    ></span>
                    <router-link
                      v-else
                      style="text-decoration: none"
                      :to="{
                        name: 'GrantDetails',
                        params: { grantId: item.id },
                      }"
                      v-bind:target="openDetailsInNewTab ? '_blank' : null"
                    >
                      <span class="title text-left" v-html="translateScheme ? item.transName : item.name"></span>
                    </router-link>
                    <q-space />
                    <q-badge label="Gerelateerde openstelling"></q-badge>
                  </div>
                  <div class="dates-column column">
                    <div class="date-item text-left" v-if="!item.isAlwaysOpen">
                      <span class="date-label">Datum start: &nbsp;</span>
                      <span class="date-value">{{
                        formatDate(item.callStart)
                      }}</span>
                    </div>
                    <div class="date-item deadline-date">
                      <span class="date-label">Deadline: &nbsp;</span>
                      <span v-if="!item.isAlwaysOpen" class="date-value">{{
                        formatDate(item.callEnd)
                      }}</span>
                      <span v-else class="date-value">
                        <i class="las la-infinity" style="font-size: 1.6em"
                          ><q-tooltip>Doorlopend open</q-tooltip>
                        </i>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="body" v-html="translateScheme ? item.transBody : item.body"></div>
                <div
                  v-if="item.itemType == 2"
                  class="application-period-additional-info"
                >
                  <div
                    v-if="
                      isVind &&
                      ((item.schemeLinks && item.schemeLinks.length > 0) ||
                        (item.schemeDocuments &&
                          item.schemeDocuments.length > 0))
                    "
                    class="lg-item-width"
                  >
                    <span class="box-title-small"> Externe informatie </span>
                    <div
                      class="scheme-links-sorted"
                      v-if="
                        sortedLinksAndDocs(
                          item.schemeLinks,
                          item.schemeDocuments
                        ) &&
                        sortedLinksAndDocs(
                          item.schemeLinks,
                          item.schemeDocuments
                        ).length > 0
                      "
                    >
                      <div
                        v-for="link in sortedLinksAndDocs(
                          item.schemeLinks,
                          item.schemeDocuments
                        )"
                        :key="link.id"
                        class="links-list-sorted"
                      >
                        <div
                          v-if="
                            link.url &&
                            link.url.length > 0 &&
                            link.documentType == 'Useful links'
                          "
                          class="sorted-link row"
                        >
                          <i class="las la-2x la-link link-icon"></i>

                          <span
                            class="doc-type q-ml-sm"
                            v-if="link.documentType == 'Useful links'"
                          >
                            Nuttige links
                          </span>
                          <span class="doc-type q-ml-sm" v-else>
                            {{ link.documentType }}
                          </span>
                          <a
                            class="link-item q-ml-md"
                            :href="link.url"
                            target="_blank"
                          >
                            {{ link.text }}
                          </a>
                        </div>
                      </div>
                    </div>
                    <span class="box-title-small q-mt-lg">
                      Overige links en bestanden
                    </span>
                    <div
                      class="scheme-links-sorted"
                      v-if="
                        sortedLinksAndDocs(
                          item.schemeLinks,
                          item.schemeDocuments
                        ) &&
                        sortedLinksAndDocs(
                          item.schemeLinks,
                          item.schemeDocuments
                        ).length > 0
                      "
                    >
                      <div
                        v-for="link in sortedLinksAndDocs(
                          item.schemeLinks,
                          item.schemeDocuments
                        )"
                        :key="link.id"
                        class="links-list-sorted"
                      >
                        <div
                          v-if="
                            link.url &&
                            link.url.length > 0 &&
                            link.documentType != 'Useful links' &&
                            link.documentType != 'Visual Ping links' &&
                            !link.documentType.includes('Vervallen')
                          "
                          class="sorted-link row"
                        >
                          <i class="las la-2x la-link link-icon"></i>
                          <span class="doc-type q-ml-sm">
                            {{ link.documentType }}
                          </span>

                          <a
                            class="link-item q-ml-md"
                            :href="link.url"
                            target="_blank"
                          >
                            {{ link.text }}
                          </a>
                        </div>
                        <div
                          v-if="
                            !link.url &&
                            link.documentType != 'Useful links' &&
                            link.documentType != 'Visual Ping links' &&
                            !link.documentType.includes('Vervallen')
                          "
                          class="sorted-doc row"
                        >
                          <i
                            :class="iconBasedOnExtension(link.extension)"
                            class="link-icon"
                          ></i>
                          <span class="doc-type q-ml-sm">
                            {{ link.documentType }}
                          </span>

                          <div
                            class="link-item docname q-ml-md"
                            @click="download(link.id, link.name)"
                          >
                            {{ link.name }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <q-btn
                      @click="updateSeeDeprecatedItems(true)"
                      v-if="!seeDeprecatedItems"
                      class="deprecated-btn row q-mt-sm"
                      flat
                      no-caps
                      label="Zie vervallen"
                      ><i class="las la-angle-down q-ml-xs"></i
                    ></q-btn>

                    <q-btn
                      @click="updateSeeDeprecatedItems(false)"
                      v-else
                      class="deprecated-btn row q-mt-sm"
                      flat
                      no-caps
                      label="Verberg vervallen"
                    >
                      <i class="las la-angle-up q-ml-xs"></i
                    ></q-btn>
                    <div
                      class="scheme-links-sorted"
                      v-if="
                        sortedLinksAndDocs(
                          item.schemeLinks,
                          item.schemeDocuments
                        ) &&
                        sortedLinksAndDocs(
                          item.schemeLinks,
                          item.schemeDocuments
                        ).length > 0 &&
                        seeDeprecatedItems
                      "
                    >
                      <div
                        v-for="link in sortedLinksAndDocs(
                          item.schemeLinks,
                          item.schemeDocuments
                        )"
                        :key="link.id"
                        class="links-list-sorted"
                      >
                        <div
                          v-if="
                            link.url &&
                            link.url.length > 0 &&
                            link.documentType != 'Useful links' &&
                            link.documentType != 'Visual Ping links' &&
                            link.documentType.includes('Vervallen')
                          "
                          class="sorted-link row"
                        >
                          <i class="las la-2x la-link link-icon"></i>
                          <span class="doc-type q-ml-sm">
                            {{ link.documentType }}
                          </span>

                          <a
                            class="link-item q-ml-md"
                            :href="link.url"
                            target="_blank"
                          >
                            {{ link.text }}
                          </a>
                        </div>
                        <div
                          v-if="
                            !link.url &&
                            link.documentType != 'Useful links' &&
                            link.documentType != 'Visual Ping links' &&
                            link.documentType.includes('Vervallen')
                          "
                          class="sorted-doc row"
                        >
                          <i
                            :class="iconBasedOnExtension(link.extension)"
                            class="link-icon"
                          ></i>
                          <span class="doc-type q-ml-sm">
                            {{ link.documentType }}
                          </span>

                          <div
                            class="link-item docname q-ml-md"
                            @click="download(link.id, link.name)"
                          >
                            {{ link.name }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <q-separator />
              </div>
            </div>
          </q-tab-panel>

          <q-tab-panel name="future">
            <p
              v-if="!futureOpenings || futureOpenings.length == 0"
              class="text-left"
            >
              Er zijn geen toekomst openstellingen.
            </p>
            <div v-if="futureOpenings && futureOpenings.length > 0">
              <div
                class="q-mb-md tab-panel-calls"
                v-for="futureItem in futureOpenings"
                :key="futureItem.id"
              >
                <div class="row no-wrap title-height">
                  <router-link
                    style="text-decoration: none"
                    :to="{
                      name: 'GrantDetails',
                      params: { grantId: futureItem.id },
                    }"
                    v-bind:target="openDetailsInNewTab ? '_blank' : null"
                  >
                    <span
                      class="title text-left"
                      v-html="futureItem.name"
                    ></span>
                  </router-link>
                  <q-space />
                  <q-badge label="Gerelateerde openstelling"></q-badge>
                </div>
                <div class="date-item text-left">
                  <span class="date-label">Datum start: &nbsp;</span>
                  <span class="date-value">{{
                    formatDate(futureItem.callStart)
                  }}</span>
                </div>
                <div class="date-item">
                  <span class="date-label">Deadline: &nbsp;</span>
                  <span class="date-value">{{
                    formatDate(futureItem.callEnd)
                  }}</span>
                </div>
                <div class="body" v-html="futureItem.body"></div>
                <div
                  v-if="futureItem.itemType == 2"
                  class="application-period-additional-info"
                >
                  <div
                    v-if="
                      isVind &&
                      ((futureItem.schemeLinks &&
                        futureItem.schemeLinks.length > 0) ||
                        (futureItem.schemeDocuments &&
                          futureItem.schemeDocuments.length > 0))
                    "
                    class="lg-item-width"
                  >
                    <span class="box-title-small"> Externe informatie </span>
                    <div
                      class="scheme-links-sorted"
                      v-if="
                        sortedLinksAndDocs(
                          futureItem.schemeLinks,
                          futureItem.schemeDocuments
                        ) &&
                        sortedLinksAndDocs(
                          futureItem.schemeLinks,
                          futureItem.schemeDocuments
                        ).length > 0
                      "
                    >
                      <div
                        v-for="link in sortedLinksAndDocs(
                          futureItem.schemeLinks,
                          futureItem.schemeDocuments
                        )"
                        :key="link.id"
                        class="links-list-sorted"
                      >
                        <div
                          v-if="
                            link.url &&
                            link.url.length > 0 &&
                            link.documentType == 'Useful links'
                          "
                          class="sorted-link row"
                        >
                          <i class="las la-2x la-link link-icon"></i>

                          <span
                            class="doc-type q-ml-sm"
                            v-if="link.documentType == 'Useful links'"
                          >
                            Nuttige links
                          </span>
                          <span class="doc-type q-ml-sm" v-else>
                            {{ link.documentType }}
                          </span>
                          <a
                            class="link-item q-ml-md"
                            :href="link.url"
                            target="_blank"
                          >
                            {{ link.text }}
                          </a>
                        </div>
                      </div>
                    </div>
                    <span class="box-title-small q-mt-lg">
                      Overige links en bestanden
                    </span>
                    <div
                      class="scheme-links-sorted"
                      v-if="
                        sortedLinksAndDocs(
                          futureItem.schemeLinks,
                          futureItem.schemeDocuments
                        ) &&
                        sortedLinksAndDocs(
                          futureItem.schemeLinks,
                          futureItem.schemeDocuments
                        ).length > 0
                      "
                    >
                      <div
                        v-for="link in sortedLinksAndDocs(
                          futureItem.schemeLinks,
                          futureItem.schemeDocuments
                        )"
                        :key="link.id"
                        class="links-list-sorted"
                      >
                        <div
                          v-if="
                            link.url &&
                            link.url.length > 0 &&
                            link.documentType != 'Useful links' &&
                            link.documentType != 'Visual Ping links' &&
                            !link.documentType.includes('Vervallen')
                          "
                          class="sorted-link row"
                        >
                          <i class="las la-2x la-link link-icon"></i>
                          <span class="doc-type q-ml-sm">
                            {{ link.documentType }}
                          </span>

                          <a
                            class="link-item q-ml-md"
                            :href="link.url"
                            target="_blank"
                          >
                            {{ link.text }}
                          </a>
                        </div>
                        <div
                          v-if="
                            !link.url &&
                            link.documentType != 'Useful links' &&
                            link.documentType != 'Visual Ping links' &&
                            !link.documentType.includes('Vervallen')
                          "
                          class="sorted-doc row"
                        >
                          <i
                            :class="iconBasedOnExtension(link.extension)"
                            class="link-icon"
                          ></i>
                          <span class="doc-type q-ml-sm">
                            {{ link.documentType }}
                          </span>

                          <div
                            class="link-item docname q-ml-md"
                            @click="download(link.id, link.name)"
                          >
                            {{ link.name }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <q-btn
                      @click="updateSeeDeprecatedItems(true)"
                      v-if="!seeDeprecatedItems"
                      class="deprecated-btn row q-mt-sm"
                      flat
                      no-caps
                      label="Zie vervallen"
                      ><i class="las la-angle-down q-ml-xs"></i
                    ></q-btn>

                    <q-btn
                      @click="updateSeeDeprecatedItems(false)"
                      v-else
                      class="deprecated-btn row q-mt-sm"
                      flat
                      no-caps
                      label="Verberg vervallen"
                    >
                      <i class="las la-angle-up q-ml-xs"></i
                    ></q-btn>
                    <div
                      class="scheme-links-sorted"
                      v-if="
                        sortedLinksAndDocs(
                          futureItem.schemeLinks,
                          futureItem.schemeDocuments
                        ) &&
                        sortedLinksAndDocs(
                          futureItem.schemeLinks,
                          futureItem.schemeDocuments
                        ).length > 0 &&
                        seeDeprecatedItems
                      "
                    >
                      <div
                        v-for="link in sortedLinksAndDocs(
                          futureItem.schemeLinks,
                          futureItem.schemeDocuments
                        )"
                        :key="link.id"
                        class="links-list-sorted"
                      >
                        <div
                          v-if="
                            link.url &&
                            link.url.length > 0 &&
                            link.documentType != 'Useful links' &&
                            link.documentType != 'Visual Ping links' &&
                            link.documentType.includes('Vervallen')
                          "
                          class="sorted-link row"
                        >
                          <i class="las la-2x la-link link-icon"></i>
                          <span class="doc-type q-ml-sm">
                            {{ link.documentType }}
                          </span>

                          <a
                            class="link-item q-ml-md"
                            :href="link.url"
                            target="_blank"
                          >
                            {{ link.text }}
                          </a>
                        </div>
                        <div
                          v-if="
                            !link.url &&
                            link.documentType != 'Useful links' &&
                            link.documentType != 'Visual Ping links' &&
                            link.documentType.includes('Vervallen')
                          "
                          class="sorted-doc row"
                        >
                          <i
                            :class="iconBasedOnExtension(link.extension)"
                            class="link-icon"
                          ></i>
                          <span class="doc-type q-ml-sm">
                            {{ link.documentType }}
                          </span>

                          <div
                            class="link-item docname q-ml-md"
                            @click="download(link.id, link.name)"
                          >
                            {{ link.name }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <q-separator />
              </div>
            </div>
          </q-tab-panel>

          <q-tab-panel name="past">
            <p
              v-if="!pastOpenings || pastOpenings.length == 0"
              class="text-left"
            >
              Er zijn geen verleden openstellingen.
            </p>
            <div v-if="pastOpenings && pastOpenings.length > 0">
              <div
                class="q-mb-md tab-panel-calls"
                v-for="pastItem in pastOpenings"
                :key="pastItem.id"
              >
                <div class="row no-wrap title-height">
                  <router-link
                    style="text-decoration: none"
                    :to="{
                      name: 'GrantDetails',
                      params: { grantId: pastItem.id },
                    }"
                    v-bind:target="openDetailsInNewTab ? '_blank' : null"
                  >
                    <span class="title text-left" v-html="pastItem.name"></span>
                  </router-link>
                  <q-space />
                  <q-badge label="Gerelateerde openstelling"></q-badge>
                </div>
                <div class="date-item text-left">
                  <span class="date-label">Datum start: &nbsp;</span>
                  <span class="date-value">{{
                    formatDate(pastItem.callStart)
                  }}</span>
                </div>

                <div class="date-item">
                  <span class="date-label">Deadline: &nbsp;</span>
                  <span class="date-value">{{
                    formatDate(pastItem.callEnd)
                  }}</span>
                </div>
                <div class="body" v-html="pastItem.body"></div>
                <div
                  v-if="pastItem.itemType == 2"
                  class="application-period-additional-info"
                >
                  <div
                    v-if="
                      isVind &&
                      ((pastItem.schemeLinks &&
                        pastItem.schemeLinks.length > 0) ||
                        (pastItem.schemeDocuments &&
                          pastItem.schemeDocuments.length > 0))
                    "
                    class="lg-item-width"
                  >
                    <span class="box-title-small"> Externe informatie </span>
                    <div
                      class="scheme-links-sorted"
                      v-if="
                        sortedLinksAndDocs(
                          pastItem.schemeLinks,
                          pastItem.schemeDocuments
                        ) &&
                        sortedLinksAndDocs(
                          pastItem.schemeLinks,
                          pastItem.schemeDocuments
                        ).length > 0
                      "
                    >
                      <div
                        v-for="link in sortedLinksAndDocs(
                          pastItem.schemeLinks,
                          pastItem.schemeDocuments
                        )"
                        :key="link.id"
                        class="links-list-sorted"
                      >
                        <div
                          v-if="
                            link.url &&
                            link.url.length > 0 &&
                            link.documentType == 'Useful links'
                          "
                          class="sorted-link row"
                        >
                          <i class="las la-2x la-link link-icon"></i>

                          <span
                            class="doc-type q-ml-sm"
                            v-if="link.documentType == 'Useful links'"
                          >
                            Nuttige links
                          </span>
                          <span class="doc-type q-ml-sm" v-else>
                            {{ link.documentType }}
                          </span>
                          <a
                            class="link-item q-ml-md"
                            :href="link.url"
                            target="_blank"
                          >
                            {{ link.text }}
                          </a>
                        </div>
                      </div>
                    </div>
                    <span class="box-title-small q-mt-lg">
                      Overige links en bestanden
                    </span>
                    <div
                      class="scheme-links-sorted"
                      v-if="
                        sortedLinksAndDocs(
                          pastItem.schemeLinks,
                          pastItem.schemeDocuments
                        ) &&
                        sortedLinksAndDocs(
                          pastItem.schemeLinks,
                          pastItem.schemeDocuments
                        ).length > 0
                      "
                    >
                      <div
                        v-for="link in sortedLinksAndDocs(
                          pastItem.schemeLinks,
                          pastItem.schemeDocuments
                        )"
                        :key="link.id"
                        class="links-list-sorted"
                      >
                        <div
                          v-if="
                            link.url &&
                            link.url.length > 0 &&
                            link.documentType != 'Useful links' &&
                            link.documentType != 'Visual Ping links' &&
                            !link.documentType.includes('Vervallen')
                          "
                          class="sorted-link row"
                        >
                          <i class="las la-2x la-link link-icon"></i>
                          <span class="doc-type q-ml-sm">
                            {{ link.documentType }}
                          </span>

                          <a
                            class="link-item q-ml-md"
                            :href="link.url"
                            target="_blank"
                          >
                            {{ link.text }}
                          </a>
                        </div>
                        <div
                          v-if="
                            !link.url &&
                            link.documentType != 'Useful links' &&
                            link.documentType != 'Visual Ping links' &&
                            !link.documentType.includes('Vervallen')
                          "
                          class="sorted-doc row"
                        >
                          <i
                            :class="iconBasedOnExtension(link.extension)"
                            class="link-icon"
                          ></i>
                          <span class="doc-type q-ml-sm">
                            {{ link.documentType }}
                          </span>

                          <div
                            class="link-item docname q-ml-md"
                            @click="download(link.id, link.name)"
                          >
                            {{ link.name }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <q-btn
                      @click="updateSeeDeprecatedItems(true)"
                      v-if="!seeDeprecatedItems"
                      class="deprecated-btn row q-mt-sm"
                      flat
                      no-caps
                      label="Zie vervallen"
                      ><i class="las la-angle-down q-ml-xs"></i
                    ></q-btn>

                    <q-btn
                      @click="updateSeeDeprecatedItems(false)"
                      v-else
                      class="deprecated-btn row q-mt-sm"
                      flat
                      no-caps
                      label="Verberg vervallen"
                    >
                      <i class="las la-angle-up q-ml-xs"></i
                    ></q-btn>
                    <div
                      class="scheme-links-sorted"
                      v-if="
                        sortedLinksAndDocs(
                          pastItem.schemeLinks,
                          pastItem.schemeDocuments
                        ) &&
                        sortedLinksAndDocs(
                          pastItem.schemeLinks,
                          pastItem.schemeDocuments
                        ).length > 0 &&
                        seeDeprecatedItems
                      "
                    >
                      <div
                        v-for="link in sortedLinksAndDocs(
                          pastItem.schemeLinks,
                          pastItem.schemeDocuments
                        )"
                        :key="link.id"
                        class="links-list-sorted"
                      >
                        <div
                          v-if="
                            link.url &&
                            link.url.length > 0 &&
                            link.documentType != 'Useful links' &&
                            link.documentType != 'Visual Ping links' &&
                            link.documentType.includes('Vervallen')
                          "
                          class="sorted-link row"
                        >
                          <i class="las la-2x la-link link-icon"></i>
                          <span class="doc-type q-ml-sm">
                            {{ link.documentType }}
                          </span>

                          <a
                            class="link-item q-ml-md"
                            :href="link.url"
                            target="_blank"
                          >
                            {{ link.text }}
                          </a>
                        </div>
                        <div
                          v-if="
                            !link.url &&
                            link.documentType != 'Useful links' &&
                            link.documentType != 'Visual Ping links' &&
                            link.documentType.includes('Vervallen')
                          "
                          class="sorted-doc row"
                        >
                          <i
                            :class="iconBasedOnExtension(link.extension)"
                            class="link-icon"
                          ></i>
                          <span class="doc-type q-ml-sm">
                            {{ link.documentType }}
                          </span>

                          <div
                            class="link-item docname q-ml-md"
                            @click="download(link.id, link.name)"
                          >
                            {{ link.name }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <q-separator />
              </div>
            </div>
          </q-tab-panel>
        </q-tab-panels>
      </template>
    </q-splitter>
  </div>
</template>

<script>
import { ref, computed, inject } from 'vue';
import store from '../store/index';
import moment from 'moment';
import GrantsSevice from '../services/grants.service';

export default {
  name: 'Calls',

  setup() {
    const isVind = inject('isVind');
    const callsTab = ref('active');

    const activeOpenings = computed({
      get: () => store.state.grantItems.activeOpenings,
    });

    const futureOpenings = computed({
      get: () => store.state.grantItems.futureOpenings,
    });

    const pastOpenings = computed({
      get: () => store.state.grantItems.pastOpenings,
    });

    const activeLabel = computed({
      get: () => store.state.grantItems.activeLength,
    });
    const futureLabel = computed({
      get: () => store.state.grantItems.futureLength,
    });
    const pastLabel = computed({
      get: () => store.state.grantItems.pastLength,
    });

    const formatDate = (stringDate) => {
      const date = new Date(stringDate);
      if (date.getFullYear() < 1000) return 'Onbepaald';
      else if (stringDate == null) return '-';
      return moment(date).format('DD-MM-YYYY');
    };

    const openDetailsInNewTab = computed({
      get: () => store.state.user.openDetailsInNewTab,
    });

    const seeDeprecatedItems = ref(false);

    const updateSeeDeprecatedItems = (val) => {
      seeDeprecatedItems.value = val;
    };

    const download = async (documentId, documentName) => {
      await GrantsSevice.downloadDocument(documentId).then((response) => {
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: response.data.type })
        );
        var a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        a.download = documentName;
        a.href = fileURL;
        a.target = '_blank';
        a.click();
        document.body.removeChild(a);
      });
    };
    const isContrast = computed({
      get: () => store.state.user.isSelectedThemeContrast,
    });

    const translateScheme =  computed({
      get: () => store.state.grantItems.translateScheme,
    });

    return {
      translateScheme,
      isContrast,
      activeLabel,
      futureLabel,
      pastLabel,
      openDetailsInNewTab,
      callsTab,
      activeOpenings,
      futureOpenings,
      pastOpenings,
      formatDate,
      splitterModel: ref(20),
      isVind,
      seeDeprecatedItems,
      updateSeeDeprecatedItems,
      download,
      sortedLinksAndDocs(links, documents) {
        var combinedArray = [...links, ...documents];
        combinedArray.sort((a, b) =>
          a.documentType > b.documentType ? 1 : -1
        );
        return combinedArray;
      },
      iconBasedOnExtension(extension) {
        switch (extension) {
          case '.pdf':
            return 'las la-2x la-file-pdf';
          case '.doc':
            return 'las la-2x la-file-word';
          case '.docx':
            return 'las la-2x la-file-word';
          case '.xls':
            return 'las la-2x la-file-excel';
          case '.xlsx':
            return 'las la-2x la-file-excel';
          case '.csv':
            return 'las la-2x la-file-csv';
          case '.txt':
            return 'las la-2x la-file';
          default:
            return 'las la-2x la-file';
        }
      },
    };
  },
};
</script>
