import axiosUrl from './axios.service';
import axios from 'axios';
import store from '../store/index';
import authHeader from '../helpers/auth-header';
import moment from 'moment';
import { computed } from 'vue';

const API_URL = axiosUrl.getAxiosUrl();
const isTableView = computed({
  get: () => store.state.newsSearch.isTableView,
});

const isVind = computed({
  get: () => store.state.auth.env === 0 || store.state.auth.env === 40,
});

class NewsService {
  async loadDefaultNews(locale) {
    return await axios
      .get(API_URL + '/search/searchnews', {
        headers: authHeader(),
        params: {
          PageSize: isTableView.value ? 25 : 10,
          Page: 0,
          Sort: 'Nieuwste',
          ShowDeletedItems: false,
          SelectedLanguageCode: locale.value
        },
      })
      .then((response) => {
        store.commit('newsSearch/updateCurrentPage', 1);
        store.commit('news/updateSearchResults', response.data);
        if (response.data.resultCount % 10 != 0)
          store.commit(
            'newsSearch/updatePageCount',
            response.data.pageCount + 1
          );
        else
          store.commit('newsSearch/updatePageCount', response.data.pageCount);
        store.commit(
          'newsSearch/updateResultsCount',
          response.data.resultCount
        );
        store.commit('news/updateAllNews', response.data);
        store.commit('news/disableIsNewsPageLoading');
      });
  }

  async getSearchResults(
    locale,
    searchText,
    dateFrom,
    dateTo,
    audienceTags,
    schemesTags,
    projectTypes,
    locationsTags,
    purposesTags,
    //This is just hidden for now, don't remove it:
    //industriesTags,
    orgsIncludeString,
    orgsExcludeString,
    selectedSort,
    page
  ) {
    return await axios
      .get(API_URL + '/search/searchnews', {
        headers: authHeader(),
        params: {
          Term: searchText.value,
          PageSize: 10,
          Page: page != null && page.value != null ? page.value - 1 : 0,
          DateFrom:
            dateFrom.value != null && dateFrom.value != ''
              ? moment(dateFrom.value, 'DD-MM-YYYY').format('YYYY-MM-DD')
              : null,
          DateTo:
            dateTo.value != null && dateTo.value != ''
              ? moment(dateTo.value, 'DD-MM-YYYY').format('YYYY-MM-DD')
              : null,
          AudienceTags: audienceTags.value,
          PurposesTags: purposesTags.value,
          SchemeTypesTags: schemesTags.value,
          ProjectTypesTags: projectTypes.value,
          LocationsTags: locationsTags.value,
          //This is just hidden for now, don't remove it
          //IndustriesTags: industriesTags.value,
          NotOrganisations:
            orgsExcludeString.value.length > 0 ? orgsExcludeString.value : null,
          Organisations:
            orgsIncludeString.value.length > 0 ? orgsIncludeString.value : null,
          Sort: selectedSort.value,
          ShowDeletedItems: false,
          SelectedLanguageCode: locale.value
        },
      })
      .then((response) => {
        store.commit(
          'newsSearch/updateCurrentPage',
          page != null && page.value != null ? page.value - 1 : 0
        );
        store.commit('news/updateSearchResults', response.data);
        if (response.data.resultCount % 10 != 0)
          store.commit(
            'newsSearch/updatePageCount',
            response.data.pageCount + 1
          );
        else
          store.commit('newsSearch/updatePageCount', response.data.pageCount);
        store.commit(
          'newsSearch/updateResultsCount',
          response.data.resultCount
        );
        store.commit('news/updateAllNews', response.data);
        store.commit('news/disableIsNewsPageLoading');
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getResulstsByPage(
    locale,
    current,
    searchText,
    dateFrom,
    dateTo,
    audienceTags,
    schemesTags,
    projectTypes,
    locationsTags,
    purposesTags,
    //This is just hidden for now, don't remove it:
    //industriesTags,
    orgsIncludeString,
    orgsExcludeString,
    selectedSort
  ) {
    return await axios
      .get(API_URL + '/search/searchnews', {
        headers: authHeader(),
        params: {
          Term: searchText.value,
          PageSize: isTableView.value ? 25 : 10,
          Page: current,
          DateFrom:
            dateFrom.value != null && dateFrom.value != ''
              ? moment(dateFrom.value, 'DD-MM-YYYY').format('YYYY-MM-DD')
              : null,
          DateTo:
            dateTo.value != null && dateFrom.value != ''
              ? moment(dateTo.value, 'DD-MM-YYYY').format('YYYY-MM-DD')
              : null,
          AudienceTags: audienceTags.value,
          PurposesTags: purposesTags.value,
          SchemeTypesTags: schemesTags.value,
          ProjectTypesTags: projectTypes.value,
          LocationsTags: locationsTags.value,
          //This is just hidden for now, don't remove it
          //IndustriesTags: industriesTags.value,
          NotOrganisations:
            orgsExcludeString.value.length > 0 ? orgsExcludeString.value : null,
          Organisations:
            orgsIncludeString.value.length > 0 ? orgsIncludeString.value : null,
          Sort: selectedSort.value,
          ShowDeletedItems: false,
          SelectedLanguageCode: locale.value
        },
      })
      .then((response) => {
        store.commit('news/updateSearchResults', response.data);
        if (response.data.resultCount % 10 != 0)
          store.commit(
            'newsSearch/updatePageCount',
            response.data.pageCount + 1
          );
        else
          store.commit('newsSearch/updatePageCount', response.data.pageCount);
        store.commit(
          'newsSearch/updateResultsCount',
          response.data.resultCount
        );
        store.commit('news/updateAllNews', response.data);
        store.commit('news/disableIsNewsPageLoading');
      });
  }

  async getResulstsByPageTable(
    locale,
    current,
    searchText,
    dateFrom,
    dateTo,
    audienceTags,
    purposesTags,
    schemesTags,
    projectTypes,
    locationsTags,
    //This is just hidden for now, don't remove it:
    //industriesTags,
    orgsIncludeString,
    orgsExcludeString,
    selectedSort
  ) {
    return await axios
      .get(API_URL + '/search/searchnews', {
        headers: authHeader(),
        params: {
          Term: searchText.value,
          PageSize: 25,
          Page: current,
          DateFrom:
            dateFrom.value != null && dateFrom.value != ''
              ? moment(dateFrom.value, 'DD-MM-YYYY').format('YYYY-MM-DD')
              : null,
          DateTo:
            dateTo.value != null && dateFrom.value != ''
              ? moment(dateTo.value, 'DD-MM-YYYY').format('YYYY-MM-DD')
              : null,
          AudienceTags: audienceTags.value,
          PurposesTags: purposesTags.value,
          SchemeTypesTags: schemesTags.value,
          ProjectTypesTags: projectTypes.value,
          LocationsTags: locationsTags.value,
          //This is just hidden for now, don't remove it
          //IndustriesTags: industriesTags.value,
          NotOrganisations:
            orgsExcludeString.value.length > 0 ? orgsExcludeString.value : null,
          Organisations:
            orgsIncludeString.value.length > 0 ? orgsIncludeString.value : null,
          Sort: selectedSort.value,
          ShowDeletedItems: false,
          SelectedLanguageCode: locale.value
        },
      })
      .then((response) => {
        store.commit('news/updateSearchResults', response.data);
        if (response.data.resultCount % 10 != 0)
          store.commit(
            'newsSearch/updatePageCount',
            response.data.pageCount + 1
          );
        else
          store.commit('newsSearch/updatePageCount', response.data.pageCount);
        store.commit(
          'newsSearch/updateResultsCount',
          response.data.resultCount
        );
        store.commit('news/updateAllNews', response.data);
        store.commit('news/disableIsNewsPageLoading');
      });
  }

  async getSearchResultsTable(
    locale,
    searchText,
    dateFrom,
    dateTo,
    audienceTags,
    schemesTags,
    projectTypes,
    locationsTags,
    purposesTags,
    //This is just hidden for now, don't remove it:
    //industriesTags,
    orgsIncludeString,
    orgsExcludeString,
    selectedSort,
    page
  ) {
    return await axios
      .get(API_URL + '/search/searchnews', {
        headers: authHeader(),
        params: {
          Term: searchText.value,
          PageSize: 25,
          Page: page.value - 1,
          DateFrom:
            dateFrom.value != null && dateFrom.value != ''
              ? moment(dateFrom.value, 'DD-MM-YYYY').format('YYYY-MM-DD')
              : null,
          DateTo:
            dateTo.value != null && dateTo.value != ''
              ? moment(dateTo.value, 'DD-MM-YYYY').format('YYYY-MM-DD')
              : null,
          AudienceTags: audienceTags.value,
          PurposesTags: purposesTags.value,
          SchemeTypesTags: schemesTags.value,
          ProjectTypesTags: projectTypes.value,
          LocationsTags: locationsTags.value,
          //This is just hidden for now, don't remove it
          //IndustriesTags: industriesTags.value,
          NotOrganisations:
            orgsExcludeString.value.length > 0 ? orgsExcludeString.value : null,
          Organisations:
            orgsIncludeString.value.length > 0 ? orgsIncludeString.value : null,
          Sort: selectedSort.value,
          ShowDeletedItems: false,
          SelectedLanguageCode: locale.value
        },
      })
      .then((response) => {
        store.commit('newsSearch/updateCurrentPage', page.value);
        store.commit('news/updateSearchResults', response.data);
        if (response.data.resultCount % 10 != 0)
          store.commit(
            'newsSearch/updatePageCount',
            response.data.pageCount + 1
          );
        else
          store.commit('newsSearch/updatePageCount', response.data.pageCount);
        store.commit(
          'newsSearch/updateResultsCount',
          response.data.resultCount
        );
        store.commit('news/updateAllNews', response.data);
        store.commit('news/disableIsNewsPageLoading');
      });
  }

  async getNewsById(id, statisticType) {
    return await axios
      .get(API_URL + '/news/getNewsById', {
        params: {
          newsId: id,
          statisticType: statisticType,
        },
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('news/updateSelectedNews', response.data);
        store.commit('news/disableNewsDetailsLoading');
      });
  }

  async searchLatestNews(locale, page, size) {
    return await axios
      .get(API_URL + '/search/searchnews', {
        headers: authHeader(),
        params: {
          PageSize: size,
          Page: page,
          Sort: 'Nieuwste',
          ShowDeletedItems: false,
          SelectedLanguageCode: locale.value
        },
      })
      .then((response) => {
        if (!isVind.value) {
          store.commit(
            'portalsData/updateGrantsNewsItems',
            response.data.results
          );
        }
        if (page > 0) {
          store.commit('homePageItems/updateLatestNews', response.data);
        } else {
          store.commit('news/updateLatestNews', response.data);
          store.commit('homePageItems/updateLatestNews', response.data);
        }
      });
  }

  async searchLatestNewsWithTags(locale, page, size, audiences, purposes, locations) {
    return await axios
      .get(API_URL + '/search/searchnews', {
        headers: authHeader(),
        params: {
          PageSize: size,
          Page: page,
          Sort: 'Nieuwste',
          AudienceTags: audiences.length > 0 ? audiences : null,
          PurposesTags: purposes.length > 0 ? purposes : null,
          LocationsTags: locations.length > 0 ? locations : null,
          ShowDeletedItems: false,
          SelectedLanguageCode: locale.value
        },
      })
      .then((response) => {
        if (!isVind.value) {
          store.commit(
            'portalsData/updateGrantsNewsItems',
            response.data.results
          );
        }
        if (page > 0) {
          store.commit('homePageItems/updateLatestNews', response.data);
        } else {
          store.commit('news/updateLatestNews', response.data);
          store.commit('homePageItems/updateLatestNews', response.data);
        }
      });
  }

  async getNewsForLoginPage() {
    return await axios
      .get(API_URL + '/newslogin/searchNewsForLoginPage')
      .then((response) => {
        store.commit('news/updateLatestNewsLoginPage', response.data);
      });
  }

  async recordVisitToNews(id) {
    return await axios.get(API_URL + '/news/recordVisitToNews', {
      headers: authHeader(),
      params: { newsId: id },
    });
  }

  async getOnzeNewsItemsList() {
    const urlParams = new URLSearchParams(window.location.search);
    return await axios
      .get(API_URL + '/news/getScreeningNewsList', {
        headers: authHeader(),
        params: { id: urlParams.get('id'), code: urlParams.get('code') },
      })
      .then((response) => {
        store.commit('publicNews/updateOnzeNewsList', response.data);
      });
  }
}

export default new NewsService();
