<template>
  <div
    class="deadlines-page homepage-main"
    :class="isContrast != 'false' && isContrast != false ? 'high-contrast' : ''"
  >
    <ShareScheme />
    <SaveScheme />
    <DownloadScheme />
    <span class="curr-nav row">{{ t('navbar.dashboard') }} &#62; {{ t('my_dashboard.latest_schemes') }}</span>
    <div class="grant-cards-container grants-container">
      <div class="pagination top-pag row justify-between">
        <span class="newest-heading bold-font"> {{ t('my_dashboard.latest_schemes') }} </span>
        <q-pagination
          class="pagination-item"
          v-model="currentPage"
          :max="maxPages"
          direction-links
          :max-pages="6"
          @click="goToPage(currentPage)"
          color="grey"
          active-color="green-7"
          text-color="white"
          size="13px"
        ></q-pagination>
      </div>
      <div class="cards">
        <GrantCard
          v-for="grant in grants"
          :key="grant.id"
          :title="grant.name"
          :grant="grant"
          :id="grant.id"
          :introduction="grant.introduction"
          class="item q-mt-md"
        />
      </div>
      <div class="pagination top-pag row justify-end bot-pag">
        <q-pagination
          class="pagination-item"
          v-model="currentPage"
          :max="maxPages"
          direction-links
          :max-pages="6"
          @click="goToPage(currentPage)"
          color="grey"
          active-color="green-7"
          text-color="white"
          size="13px"
        ></q-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, computed, ref } from 'vue';
import SchemesUtils from '../../utils/schemes';
import store from '../../store/index';
import GrantCard from '../GrantCard.vue';
import ShareScheme from '../share-components/ShareScheme.vue';
import DownloadScheme from '../share-components/DownloadScheme.vue';
import SaveScheme from '../SaveScheme.vue';
import UserService from '../../services/userprofile.service';
import { useI18n } from 'vue-i18n';

export default {
  name: 'NewestSchemes',
  components: {
    GrantCard,
    ShareScheme,
    SaveScheme,
    DownloadScheme,
  },
  setup() {
    const { locale } = useI18n();
    const grants = computed({
      get: () => store.state.homePageItems.newestGrants,
    });

    const currentPage = computed({
      get: () => store.state.homePageItems.currentPageNewest,
      set: (val) => store.commit('homePageItems/updatecurrentPageNewest', val),
    });

    const maxPages = computed({
      get: () => store.state.homePageItems.pageCountNewest,
    });

    const goToPage = async (page) => {
      if (!hasProfileTags.value) {
        await SchemesUtils.searchNewestSchemes(locale, page - 1, 10);
      } else {
        var locationsStr = '';
        for (let i = 0; i < favoriteLocations.value.length; i++) {
          locationsStr += favoriteLocations.value[i].id + ',';
        }
        locationsStr = locationsStr.substring(0, locationsStr.length - 1);
        await SchemesUtils.searchNewestSchemesWithTags(
          locale,
          page - 1,
          10,
          favoriteAudience.value,
          favoritePurposes.value,
          locationsStr
        );
      }
      window.scrollTo(0, 0);
    };

    onMounted(async () => {
      await UserService.getProfileConfiguration();
      await UserService.getFavoriteAudienceTags();
      await UserService.getFavoritePurposesTags();
      await UserService.getFavoriteLocationsTags();

      window.scrollTo(0, 0);
      checkForTagsUpdate();

      var locationsStr = '';
      for (let i = 0; i < favoriteLocations.value.length; i++) {
        locationsStr += favoriteLocations.value[i].id + ',';
      }
      locationsStr = locationsStr.substring(0, locationsStr.length - 1);

      if (!hasProfileTags.value) {
        await SchemesUtils.searchNewestSchemes(locale, 0, 10);
      } else {
        await SchemesUtils.searchNewestSchemesWithTags(
          locale,
          0,
          10,
          favoriteAudience.value,
          favoritePurposes.value,
          locationsStr
        );
      }
    });

    const favoriteAudience = computed({
      get: () => store.state.user.favoriteAudiencesIdsArray,
    });
    const favoritePurposes = computed({
      get: () => store.state.user.favoritePurposesIdsArray,
    });
    const favoriteLocations = computed({
      get: () => store.state.user.selectedFavoriteLocations,
    });

    const hasProfileTags = ref(false);

    const checkForTagsUpdate = () => {
      if (
        favoriteAudience.value.length > 0 ||
        favoritePurposes.value.length > 0 ||
        favoriteLocations.value.length > 0
      ) {
        hasProfileTags.value = true;
      }
    };

    const isContrast = computed({
      get: () => store.state.user.isSelectedThemeContrast,
    });
    return {
      locale,
      isContrast,
      grants,
      currentPage,
      maxPages,
      goToPage,
      favoriteAudience,
      favoritePurposes,
      favoriteLocations,
      checkForTagsUpdate,
    };
  },
};
</script>
