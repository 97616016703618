<template>
  <div class="news-select-final">
    <h1 class="heading row justify-center">
      You've finished selecting news items to be sent via newsletter.
    </h1>
    <div v-for="item in selectedCustomersPerNews" :key="item.newsId">
      <p class="news-title">
        {{ item.newsTitle }}
      </p>
      <div v-for="c in item.customers" :key="c.id" class="customer-name">
        {{ c.label }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import store from '../../store/index.js';

export default {
  name: 'NewsSelectFinal',
  setup() {
    const selectedCustomersPerNews = computed({
      get: () => store.state.salesForce.selectedCustomersPerNews,
    });
    return {
      selectedCustomersPerNews,
    };
  },
};
</script>
