const state = () => ({
  grantsPageNewsItems: [],
  grantsPageMainNewsItem: {},
  isPortalsPageLoading: false,
  isPortalsSideBarLoading: false,
});

const getters = {};

const actions = {};

const mutations = {
  updateGrantsNewsItems(state, val) {
    state.grantsPageNewsItems = [];

    for (let i = 0; i < val.length; i++) {
      i == 0
        ? (state.grantsPageMainNewsItem = val[i])
        : state.grantsPageNewsItems.push(val[i]);
    }
  },
  updateIsPortalPageLoading(state, val) {
    state.isPortalsPageLoading = val;
  },
  updateIsPortalsSideBarLoading(state, val) {
    state.isPortalsSideBarLoading = val;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
