<template>
  <div class="news-list-page-container">
    <span class="container-heading q-mt-xl bold-font row">{{ title }}</span>

    <div class="homepage-news q-mb-md">
      <div class="news-list-container">
        <q-list>
          <q-item v-for="news in newsProp" :key="news.id">
            <div class="column" style="width: 100%">
              <q-item-section class="title">
                <q-item-label>
                  <router-link
                    style="text-decoration: none"
                    :to="{ name: 'NewsDetails', params: { newsId: news.id } }"
                    v-bind:target="openDetailsInNewTab ? '_blank' : null"
                  >
                    <span class="title bold-font"> {{ news.title }} </span>
                  </router-link></q-item-label
                >

                <span class="deadline-name"
                  >Gepubliceerd op: {{ formatDate(news.date) }}</span
                >
                <q-separator class="separator"></q-separator>
              </q-item-section>
            </div>
          </q-item>
        </q-list>
      </div>
      <div class="more-btn-container">
        <router-link
          style="text-decoration: none"
          :to="{ name: 'LatestNews' }"
          v-bind:target="openDetailsInNewTab ? '_blank' : null"
          class="q-mt-lg"
        >
          <q-btn class="see-more-btn" no-caps>
            {{ t('my_dashboard.view_more') }}
            <q-icon name="east" size="18px" class="q-ml-sm"></q-icon>
          </q-btn>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import moment from 'moment';
import store from '../../store/index';
export default {
  props: {
    newsProp: Array,
    title: String,
  },
  setup() {
    const formatDate = (date) => {
      return moment(date).format('DD-MM-YYYY');
    };

    const openDetailsInNewTab = computed({
      get: () => store.state.user.openDetailsInNewTab,
    });

    return {
      formatDate,
      openDetailsInNewTab,
    };
  },
};
</script>
