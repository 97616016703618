<template>
  <div class="filter-box statuses-checkbox">
    <q-checkbox dense v-model="open" label="Open" class="status-checkbox" />
    <q-checkbox
      dense
      v-model="forthcoming"
      label="Aangekondigd"
      class="status-checkbox"
    />
    <q-checkbox
      dense
      v-model="closed"
      label="Gesloten"
      class="status-checkbox"
    />
    <q-checkbox
      dense
      v-model="discontinued"
      v-if="showDiscontinuedCheckbox && isVind"
      label="Gestopt"
      class="status-checkbox"
    />
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import store from '../../store/index';
import SchemeUtils from '../../utils/schemes';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    onMounted(() => {
      if (sessionStorage.getItem('showOpen') !== null)
        store.commit('advancedSearch/updateShowOpen', JSON.parse(sessionStorage.getItem('showOpen')));
      if (sessionStorage.getItem('showClosed') !== null)
        store.commit('advancedSearch/updateShowClosed', JSON.parse(sessionStorage.getItem('showClosed')));
      if (sessionStorage.getItem('showForthcoming') !== null)
        store.commit('advancedSearch/updateShowForthcoming', JSON.parse(sessionStorage.getItem('showForthcoming')));
      if (sessionStorage.getItem('showDiscontinued') !== null)
        store.commit('advancedSearch/updateShowDiscontinued', JSON.parse(sessionStorage.getItem('showDiscontinued')));
     });
    
    const { locale } = useI18n();
    const open = computed({
      get: () => 
        sessionStorage.getItem('showOpen') == null ||
        store.state.advancedSearch.showOpen ===
          (sessionStorage.getItem('showOpen') === 'true')
          ? store.state.advancedSearch.showOpen
          : sessionStorage.getItem('showOpen') === 'true',
      set: async (value) => {
        store.commit('advancedSearch/updateShowOpen', value);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });
    const forthcoming = computed({
      get: () => 
        sessionStorage.getItem('showForthcoming') == null ||
        store.state.advancedSearch.showForthcoming ===
          (sessionStorage.getItem('showForthcoming') === 'true')
          ? store.state.advancedSearch.showForthcoming
          : sessionStorage.getItem('showForthcoming') === 'true',
      set: async (value) => {
        store.commit('advancedSearch/updateShowForthcoming', value);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });
    const closed = computed({
      get: () => 
        sessionStorage.getItem('showClosed') == null ||
        store.state.advancedSearch.showClosed ===
          (sessionStorage.getItem('showClosed') === 'true')
          ? store.state.advancedSearch.showClosed
          : sessionStorage.getItem('showClosed') === 'true',
      set: async (value) => {
        store.commit('advancedSearch/updateShowClosed', value);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });
    const discontinued = computed({
      get: () => 
        sessionStorage.getItem('showDiscontinued') == null ||
        store.state.advancedSearch.showDiscontinued ===
          (sessionStorage.getItem('showDiscontinued') === 'true')
          ? store.state.advancedSearch.showDiscontinued
          : sessionStorage.getItem('showDiscontinued') === 'true',
      set: async (value) => {
        store.commit('advancedSearch/updateShowDiscontinued', value);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });
    const resetSelected = () => {
      store.commit('advancedSearch/updateShowOpen', false);
      store.commit('advancedSearch/updateShowClosed', false);
      store.commit('advancedSearch/updateShowForthcoming', false);
      store.commit('advancedSearch/updateShowDiscontinued', false);
      if (!isSearchPaused.value) SchemeUtils.searchSchemes(locale);
    };
    const showDiscontinuedCheckbox = store.state.auth.env !== 20;
    const isSearchPaused = computed({
      get: () => store.state.advancedSearch.applySavedSearchPause,
    });

    const isVind = computed({
      get: () => store.state.auth.env === 0 || store.state.auth.env === 40,
    });
    return {
      locale,
      isVind,
      isSearchPaused,
      resetSelected,
      group: ref([]),
      open,
      forthcoming,
      closed,
      discontinued,
      showDiscontinuedCheckbox,
    };
  },
};
</script>

<style></style>
