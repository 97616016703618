<template>
  <main class="main-tag">
    <DownloadScheme />
  <DownloadSchemeButton />
  <div class="spinner-page" v-if="areGrantDetailsLoading">
    <q-spinner class="spinner" color="primary" size="3em" />
  </div>
  <div
    v-else
    class="grant-details-page portal-details-page"
    :class="isContrast != 'false' && isContrast != false ? 'high-contrast' : ''"
  >
    <ShareGrant />
    <SaveScheme />
    <PortalSaveScheme />
    <q-dialog v-model="showSavedInfoDialog" class="saved-items-dialog">
      <q-card>
        <q-card-section class="row items-center">
          <q-icon
            class="info-icon-searchgrants"
            size="28px"
            name="fa-solid fa-circle-info"
            @click="hideScheme(scheme.Id)"
          >
          </q-icon>
          <span class="q-ml-sm"> Item is opgeslagen in je &nbsp; </span
          ><a href="/profile/savedItemsSchemes">Favorieten</a>
        </q-card-section>
        <q-card-section style="padding-top: 0; padding-left: 12px">
          <q-toggle
            v-model="receiveAlertForSavedItems"
            label="Wil je alerts ontvangen voor deze regeling?"
          />
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            flat
            class="orange-btn-filled"
            color="white"
            label="Ok"
            no-caps
            @click="disableShowSavedInfoDialog()"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <div class="page-details">
      <div class="column pagetop">
        <q-icon
          name="fa-solid fa-arrow-left"
          size="18px"
          class="back-icon"
          @click="router.go(-1)"
        ></q-icon>

        <div class="column">
          <div class="row heading-scheme">
            <div class="column scheme-name-and-org bold-font">
              <div class="title-and-action-btns">
                <span class="title" v-html="scheme.name"></span>
                <div class="column" style="margin-top: 50px">
                  <q-btn
                    dense
                    no-caps
                    class="orange-btn-filled bold-font"
                    color="white"
                    flat
                    @click="enableSaveSchemePortals(scheme.id)"
                    style="width: 147px; height: 36px"
                  >
                    <q-icon
                      left="true"
                      name="fa-solid fa-floppy-disk"
                      class="q-mr-sm"
                      size="18px"
                    ></q-icon>
                    Opslaan</q-btn
                  >
                  <q-btn
                    dense
                    no-caps
                    class="orange-btn bold-font q-mt-md"
                    color="green"
                    @click="shareGrant()"
                    flat
                    style="width: 147px; height: 36px"
                  >
                    <q-icon
                      left="true"
                      name="fa-solid fa-share-nodes"
                      class="q-mr-sm"
                      size="18px"
                    ></q-icon>
                    {{ t('search_schemes.share') }}</q-btn
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-between" style="width: 65vw">
            <div class="row q-mt-sm icons-row" v-if="isVind">
              <q-icon
                name="fa-solid fa-floppy-disk"
                size="20px"
                class="action-icon"
                @click="enableSaveScheme(scheme.id)"
              >
                <q-tooltip> Opslaan </q-tooltip></q-icon
              >
              <q-icon
                name="fa-solid fa-share-nodes"
                size="20px"
                class="action-icon q-ml-md"
                @click="shareGrant()"
              >
                <q-tooltip> {{ t('search_schemes.share') }} </q-tooltip></q-icon
              >
              <q-icon
                name="fa-solid fa-download"
                size="20px"
                class="action-icon q-ml-md"
                @click="downloadSchemePopup(scheme.id)"
              >
                <q-tooltip> Downloaden </q-tooltip></q-icon
              >
            </div>
          </div>
        </div>
      </div>

      <div class="column main-page q-mt-md">
        <div class="top-details-bg column">
          <div class="row justify-center">
            <div class="topinfo-item">
              <span class="topinfo-item-heading bold-font">
                <q-icon size="20px" class="heading-icon">
                  <img
                    class="svg-icon"
                    src="../../assets/icons/circle-user-solid.svg"
                  />
                </q-icon>
                <span class="heading"> Verstrekker </span></span
              >
              <span class="topinfo-item-value">
                {{
                  scheme.administratingOrganisations &&
                  scheme.administratingOrganisations.length > 0
                    ? scheme.administratingOrganisations[0].name
                    : '-'
                }}
              </span>
            </div>

            <div class="topinfo-item">
              <span class="topinfo-item-heading bold-font">
                <q-icon size="20px" class="heading-icon">
                  <img
                    class="svg-icon"
                    src="../../assets/icons/calendar-days-solid.svg"
                  />
                </q-icon>
                <span class="heading"> Deadline </span></span
              >
              <span class="topinfo-item-value">{{
                scheme.currentDeadlineText ?? '-'
              }}</span>
            </div>

            <div class="topinfo-item">
              <span class="topinfo-item-heading bold-font">
                <q-icon size="20px" class="heading-icon">
                  <img
                    class="svg-icon"
                    src="../../assets/icons/spinner-solid.svg"
                  />
                </q-icon>
                <span class="heading"> Status </span></span
              >
              <span class="topinfo-item-value">{{ statusNL ?? '-' }}</span>
            </div>

            <div class="topinfo-item">
              <span class="topinfo-item-heading bold-font">
                <q-icon size="20px" class="heading-icon">
                  <img
                    class="svg-icon"
                    src="../../assets/icons/sack-solid.svg"
                  />
                </q-icon>
                <span class="heading">Budget </span></span
              >

              <div
                v-if="
                  scheme.budget && !showMoreBudget && scheme.budget.length > 130
                "
                class="topinfo-item-value-budget budget"
                style="max-width: 320px"
              >
                <div
                  class="topinfo-item-value-budget budget"
                  v-html="scheme.shortBudget"
                ></div>
                <span
                  v-if="!showMoreBudget"
                  @click="toggleShowMoreBudget()"
                  class="show-more"
                  >Lees meer
                  <q-icon size="14px" class="heading-icon">
                    <img
                      class="svg-icon"
                      src="../../assets/icons/chevron-right-solid.svg"
                    /> </q-icon
                ></span>
              </div>

              <div
                v-else-if="scheme.budget && showMoreBudget"
                class="topinfo-item-value-budget budget"
                style="max-width: 320px"
              >
                <div
                  class="topinfo-item-value-budget budget"
                  v-html="scheme.budget"
                ></div>
                <span
                  v-if="showMoreBudget"
                  @click="toggleShowMoreBudget()"
                  class="show-more"
                  >Lees minder
                  <q-icon size="14px" class="heading-icon">
                    <img
                      class="svg-icon"
                      src="../../assets/icons/chevron-left-solid.svg"
                    /> </q-icon
                ></span>
              </div>
              <div
                v-else-if="
                  scheme.budget && !showMoreBudget && scheme.budget.length < 130
                "
                class="topinfo-item-value-budget budget"
              >
                <div
                  class="topinfo-item-value-budget budget"
                  v-html="scheme.budget"
                ></div>
              </div>

              <div class="topinfo-item-value budget" v-else>-</div>
            </div>
          </div>

          <div class="row justify-center">
            <div
              class="topinfo-item"
              v-if="
                scheme.complexityRating != null && scheme.complexityRating > 0
              "
            >
              <span class="topinfo-item-heading bold-font">
                <q-icon
                  name="speed"
                  size="20px"
                  class="heading-icon"
                />Moeilijkheid</span
              >
              <div style="text-align: left" class="topinfo-item-value">
                <q-linear-progress
                  size="15px"
                  :value="(scheme.complexityRating / 10) * 2"
                  class="progress-bar"
                  :color="getRatingColor(scheme.complexityRating)"
                />

                <q-tooltip
                  anchor="bottom middle"
                  self="bottom middle"
                  class="rating-tooltip"
                  style="
                    color: black;
                    background-color: white;
                    font-size: small;
                  "
                >
                  {{ getComplexityRatingLabel(scheme.complexityRating) }}
                </q-tooltip>
              </div>
            </div>

            <div
              class="topinfo-item"
              v-if="
                scheme.timeForApplicationRating != null &&
                scheme.timeForApplicationRating > 0
              "
            >
              <span class="topinfo-item-heading bold-font">
                <q-icon
                  name="timer"
                  size="20px"
                  class="heading-icon"
                />Tijdsduur</span
              >
              <div style="text-align: left" class="topinfo-item-value">
                <q-linear-progress
                  size="15px"
                  :value="(scheme.timeForApplicationRating / 10) * 2"
                  class="progress-bar"
                  :color="getRatingColor(scheme.timeForApplicationRating)"
                />
                <q-tooltip
                  anchor="bottom middle"
                  self="bottom middle"
                  class="rating-tooltip"
                  style="
                    color: black;
                    background-color: white;
                    font-size: small;
                  "
                >
                  {{
                    getTimeForApplicationRatingLabel(
                      scheme.timeForApplicationRating
                    )
                  }}
                </q-tooltip>
              </div>
            </div>
            <div
              class="topinfo-item"
              v-if="
                scheme.chanceOfSuccessRating != null &&
                scheme.chanceOfSuccessRating > 0
              "
            >
              <span class="topinfo-item-heading bold-font">
                <q-icon size="20px" class="heading-icon">
                  <img
                    class="svg-icon"
                    src="../../assets/icons/circle-check-solid.svg"
                  />
                </q-icon>

                Slaagkans</span
              >
              <div style="text-align: left" class="topinfo-item-value">
                <q-linear-progress
                  size="15px"
                  :value="(scheme.chanceOfSuccessRating / 10) * 2"
                  class="progress-bar"
                  :color="
                    getChanceOfSuccessRatingColor(scheme.chanceOfSuccessRating)
                  "
                />
                <q-tooltip
                  anchor="bottom middle"
                  self="bottom middle"
                  class="rating-tooltip"
                  style="
                    color: black;
                    background-color: white;
                    font-size: small;
                  "
                >
                  {{
                    getChanceOfSuccessRatingLabel(scheme.chanceOfSuccessRating)
                  }}
                </q-tooltip>
              </div>
            </div>
          </div>
        </div>
        <div class="row page-lower q-mt-lg">
          <div class="column">
            <section id="body" v-if="scheme.body">
              <div class="large-item">
                <span class="box-title bold-font q-mt-lg"> Samenvatting </span>
                <div v-html="scheme.body" class="introduction"></div>
                <div id="criteria"></div>
              </div>
            </section>
            <q-separator
              class="full-width-separator"
              v-if="scheme.applicationInformation"
            />
            <section
              id="applicationInformation"
              v-if="scheme.applicationInformation"
            >
              <div class="large-item">
                <span class="box-title bold-font q-mt-lg">
                  Hoe aan te vragen?
                </span>
                <div
                  v-html="scheme.applicationInformation"
                  class="introduction"
                ></div>
                <div v-if="scheme.schemeLinks && scheme.schemeLinks.length > 0">
                  <a
                    v-for="link in scheme.schemeLinks"
                    :key="link.id"
                    :href="link.url"
                    target="_blank"
                    class="row detailspage-link"
                  >
                    {{ link.documentType == 'Useful links' ? link.text : '' }}
                  </a>
                </div>
              </div>
            </section>

            <section
              id="docsandlinks"
              v-if="
                isVind &&
                ((scheme.schemeLinks && scheme.schemeLinks.length > 0) ||
                  (scheme.schemeDocuments && scheme.schemeDocuments.length > 0))
              "
            >
              <div class="large-item lg-item-width">
                <span class="box-title bold-font"> Externe informatie </span>
                <div
                  class="scheme-links-sorted"
                  v-if="sortedLinksAndDocs && sortedLinksAndDocs.length > 0"
                >
                  <div
                    v-for="link in sortedLinksAndDocs"
                    :key="link.id"
                    class="links-list-sorted"
                  >
                    <div
                      v-if="
                        link.url &&
                        link.url.length > 0 &&
                        link.documentType == 'Useful links'
                      "
                      class="sorted-link row"
                    >
                      <i
                        class="fa-solid fa-link q-mt-xs"
                        style="color: #191d96"
                      ></i>

                      <span
                        class="doc-type q-ml-sm"
                        v-if="link.documentType == 'Useful links'"
                      >
                        Nuttige links
                      </span>
                      <span class="doc-type q-ml-sm" v-else>
                        {{ link.documentType }}
                      </span>
                      <a
                        class="link-item q-ml-md"
                        :href="link.url"
                        target="_blank"
                      >
                        {{ link.text }}
                      </a>
                    </div>
                  </div>
                </div>
                <span class="box-title bold-font q-mt-lg">
                  Overige links en bestanden
                </span>
                <div
                  class="scheme-links-sorted"
                  v-if="sortedLinksAndDocs && sortedLinksAndDocs.length > 0"
                >
                  <div
                    v-for="link in sortedLinksAndDocs"
                    :key="link.id"
                    class="links-list-sorted"
                  >
                    <div
                      v-if="
                        link.url &&
                        link.url.length > 0 &&
                        link.documentType != 'Useful links' &&
                        link.documentType != 'Visual Ping links' &&
                        !link.documentType.includes('Vervallen')
                      "
                      class="sorted-link row"
                    >
                      <i
                        class="fa-solid fa-link q-mt-xs"
                        style="color: #191d96"
                      ></i>
                      <span class="doc-type q-ml-sm">
                        {{ link.documentType }}
                      </span>

                      <a
                        class="link-item q-ml-md"
                        :href="link.url"
                        target="_blank"
                      >
                        {{ link.text }}
                      </a>
                    </div>
                    <div
                      v-if="
                        !link.url &&
                        link.documentType != 'Useful links' &&
                        link.documentType != 'Visual Ping links' &&
                        !link.documentType.includes('Vervallen')
                      "
                      class="sorted-doc row"
                    >
                      <i
                        :class="iconBasedOnExtension(link.extension)"
                        class="link-icon"
                        style="color: #191d96"
                      ></i>
                      <span class="doc-type q-ml-sm">
                        {{ link.documentType }}
                      </span>

                      <div
                        class="link-item docname q-ml-md"
                        @click="download(link.id, link.name)"
                      >
                        {{ link.name }}
                      </div>
                    </div>
                  </div>
                </div>

                <q-btn
                  @click="updateSeeDeprecatedItems(true)"
                  v-if="!seeDeprecatedItems"
                  class="deprecated-btn row q-mt-sm"
                  flat
                  no-caps
                  label="Zie vervallen"
                  ><i class="las la-angle-down q-ml-xs"></i
                ></q-btn>

                <q-btn
                  @click="updateSeeDeprecatedItems(false)"
                  v-else
                  class="deprecated-btn row q-mt-sm"
                  flat
                  no-caps
                  label="Verberg vervallen"
                >
                  <i class="las la-angle-up q-ml-xs"></i
                ></q-btn>
                <div
                  class="scheme-links-sorted"
                  v-if="
                    sortedLinksAndDocs &&
                    sortedLinksAndDocs.length > 0 &&
                    seeDeprecatedItems
                  "
                >
                  <div
                    v-for="link in sortedLinksAndDocs"
                    :key="link.id"
                    class="links-list-sorted"
                  >
                    <div
                      v-if="
                        link.url &&
                        link.url.length > 0 &&
                        link.documentType != 'Useful links' &&
                        link.documentType != 'Visual Ping links' &&
                        link.documentType.includes('Vervallen')
                      "
                      class="sorted-link row"
                    >
                      <i
                        class="fa-solid fa-link q-mt-xs"
                        style="color: #191d96"
                      ></i>
                      <span class="doc-type q-ml-sm">
                        {{ link.documentType }}
                      </span>

                      <a
                        class="link-item q-ml-md"
                        :href="link.url"
                        target="_blank"
                      >
                        {{ link.text }}
                      </a>
                    </div>
                    <div
                      v-if="
                        !link.url &&
                        link.documentType != 'Useful links' &&
                        link.documentType != 'Visual Ping links' &&
                        link.documentType.includes('Vervallen')
                      "
                      class="sorted-doc row"
                    >
                      <i
                        :class="iconBasedOnExtension(link.extension)"
                        class="link-icon"
                      ></i>
                      <span class="doc-type q-ml-sm">
                        {{ link.documentType }}
                      </span>

                      <div
                        class="link-item docname q-ml-md"
                        @click="download(link.id, link.name)"
                      >
                        {{ link.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>

    <!--     <div class="circle-container">
      <div id="pagetop" class="top-scroll" v-if="scY > 300">
        <i class="las la-2x la-angle-up up-icon" @click="toTop">
          <q-tooltip self="center left" :offset="[10, 10]"
            >Naar boven</q-tooltip
          >
        </i>
      </div>
    </div> -->
  </div>
  </main>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { onMounted, computed, ref, provide } from 'vue';
import moment from 'moment';
import GrantsService from '../../services/grants.service';
import store from '../../store/index';
import ShareGrant from '../../components/share-components/ShareScheme.vue';
import SaveScheme from '../../components/SaveScheme.vue';
import PortalSaveScheme from '../../components/portals-components/PortalSaveScheme.vue';
//import authHeader from "../helpers/auth-header";
import SchemeUtils from '../../utils/schemes';
import OrganisationsUtils from '../../utils/organisations';
import DownloadSchemeButton from '../../components/share-components/DownloadSchemeButton.vue';
import DownloadScheme from '../../components/share-components/DownloadScheme.vue';
import UserService from '../../services/userprofile.service';
export default {
  components: {
    ShareGrant,
    SaveScheme,
    DownloadScheme,
    DownloadSchemeButton,
    PortalSaveScheme,
  },
  name: 'PortalSchemeDetails',
  setup() {
    const route = useRoute();
    const router = useRouter();
    onMounted(async () => {
      await UserService.getProfileConfiguration();
      const id = route.params.grantId;
      await GrantsService.getSchemeById(id, true);
      statusNL.value = SchemeUtils.translateStatus(scheme.value.status);
      OrganisationsUtils.generateOrganisationsForDetailsPage();
      /*       window.addEventListener('scroll', handleScroll);
       */ window.scrollTo(0, 0);
    });

    const scheme = computed({
      get: () => store.state.grantItems.selectedGrant,
    });

    const shortIntro = computed({
      get: () => store.state.grantItems.shortIntro,
    });

    const showMore = ref(false);

    const columns = [
      {
        name: 'year',
        label: 'Year',
        field: 'year',
        sortable: true,
        align: 'left',
      },
      {
        name: 'amount',
        label: 'Amount (€)',
        field: 'amount',
        sortable: true,
        align: 'left',
      },
    ];

    const columnsOrganisations = [
      {
        name: 'name',
        label: 'Name',
        field: 'name',
        sortable: true,
        align: 'left',
      },
    ];
    const scTimer = ref(0);
    const scY = ref(0);
    /*   const handleScroll = () => {
      if (scTimer.value) return;
      scTimer.value = setTimeout(() => {
        scY.value = window.scrollY;
        clearTimeout(scTimer.value);
        scTimer.value = 0;
      }, 100);
    }; */

    /*   const toTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }; */

    /*  const scrollToIt = (el) => {
      const yOffset = -80;
      const element = document.getElementById(el);
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' });
    };
 */
    const download = async (documentId, documentName) => {
      await GrantsService.downloadDocument(documentId).then((response) => {
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: response.data.type })
        );
        var a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        a.download = documentName;
        a.href = fileURL;
        a.target = '_blank';
        a.click();
        document.body.removeChild(a);
      });
    };

    const schemeDeadline = computed({
      get: () =>
        store.state.grantItems.selectedGrant.currentDeadline != null
          ? moment(store.state.grantItems.selectedGrant.currentDeadline).format(
              'DD-MM-YYYY'
            )
          : null,
    });

    const hasOlderNews = ref(false);

    const isNewsInLastYear = (date) => {
      var today = new Date();
      var dateLimitLastYear = new Date().setDate(today.getDate() - 365);
      var newsDate = moment(date).format('YYYY-MM-DD');
      var limit = moment(dateLimitLastYear).format('YYYY-MM-DD');

      if (moment(newsDate).isSameOrAfter(moment(limit))) return true;

      hasOlderNews.value = true;
      return false;
    };

    const showOlderNews = () => {
      showOlderNewsToggle.value = true;
    };

    const hideOlderNews = () => {
      showOlderNewsToggle.value = false;
    };

    const seeMoreNews = (title) => {
      store.commit('newsSearch/updateSearchText', title);

      router.push({
        name: 'News',
      });
    };

    const showOlderNewsToggle = ref(false);

    const toggleShowMore = () => (showMore.value = !showMore.value);

    const shareGrant = () => {
      store.commit('grantItems/enableShareGrant', scheme);
    };

    const enableSaveScheme = (id) => {
      store.commit('grantItems/addSelected', id);
      store.commit('user/enableSaveScheme');
    };

    const enableSaveSchemePortals = (id) => {
      store.commit('user/enableSavePortalsScheme');
      store.commit('grantItems/addSelected', id);
    };

    const formattedMaxAmount = computed({
      get: () => store.state.grantItems.selectedGrantMax,
    });

    const formattedMinAmount = computed({
      get: () => store.state.grantItems.selectedGrantMin,
    });

    const newsInLastYear = computed({
      get: () => store.state.grantItems.newsInLastYear,
    });
    const olderNews = computed({
      get: () => store.state.grantItems.olderNews,
    });

    const areGrantDetailsLoading = computed({
      get: () => store.state.grantItems.areGrantDetailsLoading,
    });

    const downloadSchemePopup = (id) => {
      store.commit('grantItems/enableDownloadSchemeReport', id);
    };

    const formatDate = (stringDate) => {
      const dateN = new Date(stringDate);
      return moment(dateN).format('DD-MM-YYYY');
    };

    const openDetailsInNewTab = computed({
      get: () => store.state.user.openDetailsInNewTab,
    });

    const goToParent = () => {
      router.push({
        name: 'GrantDetails',
        params: { grantId: scheme.value.parentSchemeId },
      });
    };
    const isVind = computed({
      get: () => store.state.auth.env === 0 || store.state.auth.env === 40,
    });
    const statusNL = ref('');

    const organisationsList = computed({
      get: () => store.state.grantItems.organisationsList,
    });

    const sortedLinksAndDocs = computed({
      get: () => store.state.grantItems.sortedLinksAndDocs,
    });

    const seeDeprecatedItems = ref(false);

    const updateSeeDeprecatedItems = (val) => {
      seeDeprecatedItems.value = val;
    };

    const isContrast = computed({
      get: () => store.state.user.isSelectedThemeContrast,
    });

    provide('isVind', isVind.value);

    const showMoreBudget = ref(false);

    const toggleShowMoreBudget = () => {
      showMoreBudget.value = !showMoreBudget.value;
    };

    /*  window.addEventListener('scroll', navHighlighter); */
    /* 
    function navHighlighter() {
      let scrollY = window.pageYOffset;
      const sections = document.querySelectorAll('section[id]');
      sections.forEach((current) => {
        if (current != null && current) {
          const sectionHeight = current.offsetHeight;
          const sectionTop = current.offsetTop - 60;
          const sectionId = current.getAttribute('id');

          if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
            document
              .querySelector('.navigation a[href*=' + sectionId + ']')
              .classList.add('active');
          } else {
            document
              .querySelector('.navigation a[href*=' + sectionId + ']')
              .classList.remove('active');
          }
        }
      });
    } */

    const showSavedInfoDialog = computed({
      get: () => store.state.user.showSavedInfoDialog,
    });

    const disableShowSavedInfoDialog = () => {
      store.commit('user/disableShowSavedInfoDialog');
    };

    const receiveAlertForSavedItems = computed({
      get: () => store.state.user.receiveAlertForSavedItems,
      set: (val) => {
        store.commit('user/setReceiveAlertForSavedItems', val);

        UserService.updateFolderItemsAlert(
          savedSchemesFolderItemsIds.value,
          val
        );
      },
    });

    const savedSchemesFolderItemsIds = computed({
      get: () => store.state.user.savedSchemesFolderItemsIds,
    });

    return {
      savedSchemesFolderItemsIds,
      receiveAlertForSavedItems,
      showSavedInfoDialog,
      disableShowSavedInfoDialog,
      showMoreBudget,
      toggleShowMoreBudget,
      isContrast,
      enableSaveSchemePortals,
      updateSeeDeprecatedItems,
      seeDeprecatedItems,
      sortedLinksAndDocs,
      organisationsList,
      isVind,
      goToParent,
      router,
      openDetailsInNewTab,
      formatDate,
      statusNL,
      //downloadScheme,
      downloadSchemePopup,
      areGrantDetailsLoading,
      newsInLastYear,
      olderNews,
      formattedMaxAmount,
      formattedMinAmount,
      enableSaveScheme,
      shareGrant,
      seeMoreNews,
      showOlderNews,
      showOlderNewsToggle,
      hasOlderNews,
      hideOlderNews,
      isNewsInLastYear,
      schemeDeadline,
      download,
      /*   scrollToIt,
      handleScroll,
      toTop, */
      scTimer,
      scY,
      columns,
      route,
      scheme,
      shortIntro,
      showMore,
      toggleShowMore,
      columnsOrganisations,

      iconBasedOnExtension(extension) {
        switch (extension) {
          case '.pdf':
            return 'fa-solid fa-file-pdf';
          case '.doc':
            return 'fa-solid fa-file-word';
          case '.docx':
            return 'fa-solid fa-file-word';
          case '.xls':
            return 'fa-solid fa-file-excel';
          case '.xlsx':
            return 'fa-solid fa-file-excel';
          case '.csv':
            return 'fa-solid fa-file-csv';
          case '.txt':
            return 'fa-solid fa-file';
          default:
            return 'fa-solid fa-file';
        }
      },
      getChanceOfSuccessRatingColor(rating) {
        switch (rating) {
          case 1:
            return 'blue-10';
          case 2:
            return 'blue-9';
          case 3:
            return 'blue-7';
          case 4:
            return 'blue-5';
          case 5:
            return 'blue-3';
          default:
            return 'blue-grey-2';
        }
      },
      getRatingColor(rating) {
        switch (rating) {
          case 1:
            return 'blue-3';
          case 2:
            return 'blue-5';
          case 3:
            return 'blue-7';
          case 4:
            return 'blue-9';
          case 5:
            return 'blue-10';
          default:
            return 'blue-grey-2';
        }
      },
      getTimeForApplicationRatingLabel(rating) {
        switch (rating) {
          case 5:
            return 'Heel lang';
          case 4:
            return 'Langzaam';
          case 3:
            return 'Gemiddeld';
          case 2:
            return 'Snel';
          case 1:
            return 'Heel snel';
          default:
            return 'Tijdsduur';
        }
      },
      getChanceOfSuccessRatingLabel(rating) {
        switch (rating) {
          case 1:
            return 'Heel klein';
          case 2:
            return 'Klein';
          case 3:
            return 'Gemiddeld';
          case 4:
            return 'Groot';
          case 5:
            return 'Heel groot';
          default:
            return 'Slaagkans';
        }
      },
      getComplexityRatingLabel(rating) {
        switch (rating) {
          case 5:
            return 'Heel moeilijk';
          case 4:
            return 'Moeilijk';
          case 3:
            return 'Gemiddeld';
          case 2:
            return 'Eenvoudig';
          case 1:
            return 'Heel eenvoudig';
          default:
            return 'Moeilijkheid';
        }
      },
    };
  },
};
</script>
