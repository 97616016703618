<template>
  <transition name="fade">
    <div v-if="show" class="advanced-filters column">
      <!--This is just hidden for now,  don't remove it:-->
      <!-- <DefaultTree
        class="sidebar-margin item-width white-bg-box"
        :heading="industriesNodes.label"
        :filtering="true"
        :nodesState="industriesNodes.options"
        :profileSearchFlag="profileSearchFlag"
        :savedTagsString="savedIndustriesString"
        ref="tree1"
      /> -->
      <q-expansion-item
        v-if="isVind"
        :label="t('search_schemes.sme_organization_question')"
        dense
        dense-toggle
        expand-icon="chevron_right"
      >
        <div class="sidebar-margin item-width white-bg-box">
          <div class="column">
            <q-checkbox
              dense
              size="sm"
              v-model="sme"
              val="sm"
              :label="t('search_schemes.yes')"
              class="checkbox"
            />
            <q-checkbox
              dense
              size="sm"
              v-model="notSme"
              val="sm"
              :label="t('search_schemes.no')"
              class="checkbox"
            />
          </div>
        </div>
      </q-expansion-item>

      <q-expansion-item
        :label="t('search_schemes.submission_procedure')"
        dense
        dense-toggle
        expand-icon="chevron_right"
        v-if="isVind"
      >
        <div class="input-box">
          <div class="column">
            <q-checkbox
              dense
              size="sm"
              v-model="before"
              val="sm"
              :label="t('search_schemes.before')"
              class="checkbox"
            />
            <q-checkbox
              dense
              size="sm"
              v-model="after"
              val="sm"
              :label="t('search_schemes.after')"
              class="checkbox"
            />
            <q-checkbox
              dense
              size="sm"
              v-model="beforeAndAfter"
              val="sm"
              :label="t('search_schemes.before_and_after')"
              class="checkbox"
            />
          </div>
        </div>
      </q-expansion-item>

      <q-expansion-item
        :label="t('search_schemes.scope')"
        dense
        dense-toggle
        class="q-mb-md"
        expand-icon="chevron_right"
      >
        <div class="input-box">
          <div v-if="isVind" class="column">
            <q-checkbox
              dense
              size="sm"
              v-model="european"
              val="sm"
              :label="t('search_schemes.european')"
              class="checkbox"
            />
            <q-checkbox
              dense
              size="sm"
              v-model="national"
              val="sm"
              :label="t('search_schemes.national')"
              class="checkbox"
            />
            <q-checkbox
              dense
              size="sm"
              v-model="regional"
              val="sm"
              :label="t('search_schemes.regional')"
              class="checkbox"
            />
          </div>
          <div v-else class="column">
            <q-checkbox
              dense
              size="sm"
              v-model="regional"
              val="sm"
              :label="t('search_schemes.regional')"
              class="checkbox"
            />
            <q-checkbox
              dense
              size="sm"
              v-model="national"
              val="sm"
              :label="t('search_schemes.national')"
              class="checkbox"
            />
            <q-checkbox
              dense
              size="sm"
              :v-model="european"
              val="sm"
              :label="t('search_schemes.european')"
              class="checkbox"
            />
          </div>
        </div>
      </q-expansion-item>
    </div>
  </transition>
</template>

<script>
import { ref, computed } from 'vue';
import store from '../../store/index';
import FiltersService from '../../services/filters.service';
import SchemeUtils from '../../utils/schemes';
import { useI18n } from 'vue-i18n';
//This is just hidden for now,  don't remove it:
// import DefaultTree from '../search-components/DefaultTree.vue';

export default {
  components: {

    // This is just hidden for now,  don't remove it:
    // DefaultTree,
  },
  setup() {
    // const sme = ref(false);
    // const notSme = ref(false);
    // const before = ref(false);
    // const after = ref(false);
    // const beforeAndAfter = ref(false);
    // const european = ref(false);
    // const regional = ref(false);
    // const national = ref(false);
    const { locale } = useI18n();
    const show = computed({
      get: () => store.state.advancedFilters.showAdvancedFilters,
    });

    const sme = computed({
      get: () => store.state.advancedFilters.sme,
      set: async (val) => {
        store.commit('advancedSearch/enableFiltersChange');
        store.commit('advancedFilters/updateSme', val);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });
    const notSme = computed({
      get: () => store.state.advancedFilters.notSme,
      set: async (val) => {
        store.commit('advancedSearch/enableFiltersChange');
        store.commit('advancedFilters/updateNotSme', val);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });
    const before = computed({
      get: () => store.state.advancedFilters.before,
      set: async (val) => {
        store.commit('advancedSearch/enableFiltersChange');
        store.commit('advancedFilters/updateTermBefore', val);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });
    const after = computed({
      get: () => store.state.advancedFilters.after,
      set: async (val) => {
        store.commit('advancedSearch/enableFiltersChange');
        store.commit('advancedFilters/updateTermAfter', val);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });
    const beforeAndAfter = computed({
      get: () => store.state.advancedFilters.beforeAndAfter,
      set: async (val) => {
        store.commit('advancedSearch/enableFiltersChange');
        store.commit('advancedFilters/updateTermBoth', val);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });
    const european = computed({
      get: () => store.state.advancedFilters.european,
      set: async (val) => {
        store.commit('advancedSearch/enableFiltersChange');
        store.commit('advancedFilters/updateEuropean', val);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });
    const national = computed({
      get: () => store.state.advancedFilters.national,
      set: async (val) => {
        store.commit('advancedSearch/enableFiltersChange');
        store.commit('advancedFilters/updateNational', val);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });
    const regional = computed({
      get: () => store.state.advancedFilters.regional,
      set: async (val) => {
        store.commit('advancedSearch/enableFiltersChange');
        store.commit('advancedFilters/updateRegional', val);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });

    const tree1 = ref(null);

    const showAdvanced = () => {
      FiltersService.getApplicationTerms();
      store.commit('advancedFilters/enableAdvanced');
    };

    const hideAdvanced = () => {
      store.commit('advancedFilters/disableAdvanced');
    };

    const resetValues = async () => {
      store.commit('advancedFilters/updateSme', false);
      store.commit('advancedFilters/updateNotSme', false);
      store.commit('advancedFilters/updateTermBefore', false);
      store.commit('advancedFilters/updateTermAfter', false);
      store.commit('advancedFilters/updateTermBoth', false);
      store.commit('advancedFilters/updateEuropean', false);
      store.commit('advancedFilters/updateRegional', false);
      store.commit('advancedFilters/updateNational', false);
      if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
    };

    //This is just hidden for now,  don't remove it:
    /*
    const favoriteIndustries = computed({
      get: () => store.state.user.favoriteIndustriesIdsArray,
    });
    const refreshTree = () => {
      tree1.value.refreshTree(favoriteIndustries.value);
    };
    const resetAndRefreshTree = () => {
      tree1.value.resetTree();
      tree1.value.refreshTree(favoriteIndustries.value);
    };
    const updateTreeValues = () => {
      tree1.value.updatePairs(tree1.value.ticked);
      tree1.value.updateSelected(tree1.value.ticked);
    };
    const industriesNodes = computed({
      get: () => store.state.advancedSearch.industries,
    });
    const savedIndustriesString = computed({
      get: () => store.state.user.savedIndustriesTags,
    });
    */
    const isVind = computed({
      get: () => store.state.auth.env === 0 || store.state.auth.env === 40,
    });

    const isSearchPaused = computed({
      get: () => store.state.advancedSearch.applySavedSearchPause,
    });

    return {
      locale,
      //This is just hidden for now,  don't remove it:
      /*
      updateTreeValues,
      resetAndRefreshTree,
      industriesNodes,
      savedIndustriesString,
      */
      isVind,
      isSearchPaused,
      resetValues,
      sme,
      notSme,
      showAdvanced,
      before,
      show,
      after,
      beforeAndAfter,
      hideAdvanced,
      european,
      // local,
      national,
      regional,
      // updateLocal,
      //This is just hidden for now,  don't remove it:
      //refreshTree,
      //favoriteIndustries,
      tree1,
    };
  },
};
</script>
