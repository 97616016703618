<template>
  <div class="reset-password-page">
    <div v-if="!isResetSubmitted">
      <h1>{{ t('reset_password.recover_your_password') }}</h1>
      <div class="row input-container q-pb-lg">
        {{ t('reset_password.enter_username_hint') }} 
      </div>
      <div class="row input-container">
        <q-input
          dense
          outlined
          v-model="username"
          :label="t('reset_password.username_or_email_address')"
          class="search-input pw-reset-input"
          @keydown.enter="submitResetPassword()"
          bg-color="white"
          :rules="[isValidEmail, requiredFieldRule]"
          :bottom-slots="true"
          ref="inputBox"
        >
        </q-input>
        <q-btn
          class="q-ml-md submit-btn"
          flat
          no-caps
          color="white"
          :label="t('reset_password.recover')"
          @click="submitResetPassword"
        />
      </div>
    </div>
    <div v-else class="submitted-page">
      <div class="row justify-center align-center">
        <div class="col-6">
          <div class="submit-heading">
            <i class="las la-2x la-check-circle submitted-icon"></i>
            <label class="submit-label">
              {{ t('reset_password.notification_about_receiving_email') }}
              <br /><br />
              {{ t('reset_password.email_not_received_hint') }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import UserService from '../services/userprofile.service';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const isResetSubmitted = ref(false);
    const username = ref('');
    const inputBox = ref(null);
    const submitResetPassword = async () => {
      var isInputValid = inputBox.value.validate();

      if (isInputValid) {
        UserService.requestResetPassword(username);
        isResetSubmitted.value = true;
      }
    };

    const { t } = useI18n();

    return {
      t,
      username,
      inputBox,
      submitResetPassword,
      isResetSubmitted,

      requiredFieldRule: [
        (val) => (val && val.length > 0) || t('common.required_field'),
      ],
      isValidEmail(val) {
        const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
        return emailPattern.test(val) || t('common.invalid_email_address');
      },
    };
  },
};
</script>
