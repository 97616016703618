<template>
  <div class="grants">
    <div class="grant-card">
      <div class="grant-info">
        <div class="main-info-top">
          <div class="title-badges">
            <div class="grant-text row">
              <div class="title">
                <router-link
                  v-if="!isDummy"
                  style="text-decoration: none"
                  :to="{
                    name: 'GrantDetails',
                    params: { grantId: id },
                    query: { t: searchText },
                  }"
                  v-bind:target="openDetailsInNewTab ? '_blank' : null"
                  class="title-router-link"
                >
                  <span class="bold-font title" v-html="title"></span>
                  <div class="badges">
                    <q-badge
                      class="badge"
                      v-if="isNew"
                      rounded
                      color="primary"
                      :label="t('search_schemes.new')"
                    />
                    <q-badge
                      class="badge"
                      v-if="isRecentlyChanged && !isNew"
                      rounded
                      color="primary"
                      :label="t('search_schemes.changed')"
                    />
                  </div>
                </router-link>
                <span class="title-text" v-else v-html="title"></span>

                <GrantSelect
                  class="select-checkbox"
                  v-if="
                    route.name != 'NewestSchemes' &&
                    route.name != 'DeadlinesSchemes' &&
                    route.name != 'SignificantChanges'
                  "
                  :id="schemeId"
                  :allSelected="selectedResults"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="organisation-text">
              <span v-if="grant.adminsitratingOrganisations.length > 0">{{
                grant.adminsitratingOrganisations[0].value
              }}</span>
            </div>
            <q-space></q-space>
            <div
              class="fit justify-end q-mr-md q-mt-xl"
              style="max-width: 20%; display: none !important"
            >
              <q-slider
                v-if="isVind"
                v-model="matchingPercentageValue"
                color="light-blue-2"
                :thumb-color="
                  matchingPercentageValue === 0 ? 'grey' : 'indigo-10'
                "
                thumb-size="15px"
                inner-track-color="transparent"
                :min="0"
                :max="107"
                :markers="21.4"
                :marker-labels="arrayMarkerLabel"
                switch-marker-labels
                readonly
                marker-labels-class="small-label"
                :label-value="t('search_schemes.match_filters')"
                label-always
                dense
              ></q-slider>
            </div>
          </div>
        </div>

        <div v-html="introduction" class="desc-short"></div>

        <div class="other-details">
          <div class="text-details row">
            <div class="detail-item">
              <span class="detail-tag">{{ t('search_schemes.status') }}</span>

              <span v-if="!isDummy" class="detail-value bold-font">{{
                statusNL
              }}</span>

              <span v-else class="detail-value bold-font">{{ t('search_schemes.forthcoming') }}</span>
            </div>
            <div class="detail-item">
              <span class="detail-tag">{{ t('search_schemes.max_budget') }}</span>
              <span
                v-if="!grant.maxAmount || grant.maxAmount < 1 || isDummy"
                class="detail-value bold-font"
                >{{  t('search_schemes.indefinite') }}
              </span>

              <span
                v-if="grant.maxAmount && grant.maxAmount > 1"
                class="detail-value bold-font"
                >{{ formattedMaxAmount }}</span
              >
            </div>

            <div class="detail-item row">
              <span class="detail-tag">
                {{ grant.currentDeadlineHeaderText }}</span
              >
              <span class="detail-value bold-font">{{
                grant.currentDeadlineText
              }}</span>
            </div>
          </div>
          <div class="action-icons" v-if="isVind">
            <q-icon
              class="action-icon"
              name="fa-solid fa-floppy-disk"
              size="18px"
              @click="enableSaveScheme(schemeId)"
              id="v-step-7"
            >
              <q-tooltip> {{ t('search_schemes.save') }} </q-tooltip></q-icon
            >
            <q-icon
              class="action-icon"
              size="18px"
              name="fa-solid fa-eye-slash"
              @click="hideScheme(schemeId)"
              id="v-step-8"
            >
              <q-tooltip> {{ t('search_schemes.hide') }} </q-tooltip></q-icon
            >
            <q-icon
              class="action-icon"
              size="18px"
              name="fa-solid fa-share-nodes"
              @click="shareGrant()"
              id="v-step-9"
            >
              <q-tooltip> {{ t('search_schemes.share') }} </q-tooltip></q-icon
            >
            <q-icon
              name="fa-solid fa-download"
              size="18px"
              class="action-icon q-ml-sm"
              @click="downloadSchemePopup(schemeId)"
              id="v-step-10"
            >
              <q-tooltip> {{ t('search_schemes.download') }} </q-tooltip></q-icon
            >
          </div>
        </div>

        <div class="bottom-options" v-if="isVind">
          <!--      <div class="radiobutton-section row">
            <GrantSelect
              v-if="
                route.name != 'NewestSchemes' &&
                route.name != 'DeadlinesSchemes' &&
                route.name != 'SignificantChanges'
              "
              :id="schemeId"
              :allSelected="selectedResults"
            />
          </div> -->
          <div
            class="calls-toggle"
            v-if="!isFromHomepage && grant.calls.length > 0"
          >
            <q-btn
              v-if="!displayCalls"
              flat
              no-caps
              @click="toggleDisplayCalls()"
              class="calls-btn"
              >{{ t('search_schemes.show_calls') }} &nbsp;<i class="las la-arrow-down"></i
            ></q-btn>

            <q-btn
              v-if="displayCalls"
              flat
              no-caps
              @click="toggleDisplayCalls()"
              class="calls-btn"
              >{{ t('search_schemes.hide_calls') }} &nbsp;<i class="las la-arrow-up"></i
            ></q-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="grant-calls" v-if="displayCalls">
      <div class="grant-call calls-list">
        <SchemeCallCard
          v-for="call in grant.calls"
          :key="call.id"
          :grant="call"
          :id="call.id"
          :title="call.name"
          :introduction="call.introduction"
        />
        <div v-if="callCount > 10">
          <div class="row q-pt-sm q-pb-lg float-right">
            Nog {{ callCount - 10 }} openstellingen bekend voor deze
            regeling.&nbsp;&nbsp;&nbsp;
            <router-link
              style="text-decoration: none"
              :to="{ name: 'GrantDetails', params: { grantId: id } }"
              v-bind:target="openDetailsInNewTab ? '_blank' : null"
            >
              {{ t('search_schemes.open') }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import store from '../store/index';
import SchemeCallCard from './SchemeCallCard.vue';
import GrantsService from '../services/grants.service';
import moment from 'moment';
import SchemeUtils from '../utils/schemes';
import GrantSelect from './GrantSelectButton.vue';
import UserService from '../services/userprofile.service';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default {
  name: 'GrantCard',
  props: {
    title: String,
    introduction: String,
    currentDeadlineHeaderText: String,
    currentDeadlineText: String,
    organization: String,
    id: String,
    grant: Object,
    isDummy: Boolean,
    isFromHomepage: Boolean,
    matchingPercentage: Number,
  },
  components: {
    SchemeCallCard,
    GrantSelect,
  },
  setup(props) {
    const schemeId = ref(props.id);
    const { locale } = useI18n();

    onMounted(() => {
      checkIsNew();
      checkIsRecentlyChanged();
    });

    const searchText = computed({
      get: () => store.state.advancedSearch.searchText,
    });

    const matchingPercentageValue = computed(() => {
      return props.matchingPercentage;
    });

    const isNew = ref(false);
    const checkIsNew = () => {
      var today = new Date();
      var newDateLimit = new Date().setDate(today.getDate() - 30);
      var created = moment(props.grant.createdDate).format('YYYY-MM-DD');
      var limit = moment(newDateLimit).format('YYYY-MM-DD');
      if (moment(created).isSameOrAfter(moment(limit))) isNew.value = true;
      else isNew.value = false;
    };

    const isRecentlyChanged = ref(false);
    const checkIsRecentlyChanged = () => {
      var today = new Date();
      var newDateLimit = new Date().setDate(today.getDate() - 30);
      var recent = moment(props.grant.updatedDate).format('YYYY-MM-DD');
      var limit = moment(newDateLimit).format('YYYY-MM-DD');
      if (moment(recent).isSameOrAfter(moment(limit)))
        isRecentlyChanged.value = true;
      else isRecentlyChanged.value = false;
    };

    const hasCalls = !props.isFromHomepage && props.grant.calls.length > 0;
    const callCount = !props.isFromHomepage && ref(props.grant.callCount);

    const currentDeadline =
      props.grant.currentDeadline != null
        ? moment(props.grant.currentDeadline).format('YYYY-MM-DD')
        : null;

    const toggleDisplayCalls = () => (displayCalls.value = !displayCalls.value);

    const displayCalls = ref(false);

    const shareGrant = () => {
      store.commit('grantItems/enableShareGrant', props.grant);
    };

    const shareGrantItem = computed({
      get: () => store.state.grantItems.shareGrantItem,
    });

    const schemeDetailsUrl = ref('/grant/' + props.id + '/details');
    const selectedResults = computed({
      get: () => store.state.grantItems.selectedResults,
    });

    const enableSaveScheme = (id) => {
      store.commit('grantItems/addSelected', id);
      UserService.getFolders();
      store.commit('user/enableSaveScheme');
    };

      const hideScheme = async (schemeId) => {
      await GrantsService.hideScheme(schemeId);
      store.commit('grantItems/addSelected', schemeId);
      SchemeUtils.searchSchemes(locale);
    };

    const formattedMaxAmount = SchemeUtils.formatEuro(props.grant.maxAmount);

    const downloadSchemePopup = (id) => {
      store.commit('grantItems/enableDownloadSchemeReport', id);
    };
    /* const downloadScheme = async (schemeId) => {
      let form = document.createElement("form");
      form.method = "post";
      form.target = "_blank";
      form.action = `./api/download/downloadScheme?id=${schemeId}`;
      form.innerHTML = `'<input type="hidden" name="jwtToken" value="${
        authHeader().Authorization
      }">'`;
      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    };*/

    const openDetailsInNewTab = computed({
      get: () => store.state.user.openDetailsInNewTab,
    });

    const statusNL = SchemeUtils.translateStatus(props.grant.status);
    const route = useRoute();

    const isVind = computed({
      get: () => store.state.auth.env === 0 || store.state.auth.env === 40,
    });

    const arrayMarkerLabel = [
      { value: 0, label: '0%' },
      { value: 21.4, label: '20%' },
      { value: 42.8, label: '40%' },
      { value: 64.2, label: '60%' },
      { value: 85.6, label: '80%' },
      { value: 107, label: '100%' },
    ];
    return {
      locale,
      route,
      statusNL,
      //downloadScheme,
      formattedMaxAmount,
      selectedResults,
      schemeDetailsUrl,
      shareGrantItem,
      shareGrant,
      currentDeadline,
      isRecentlyChanged,
      checkIsRecentlyChanged,
      isNew,
      checkIsNew,
      downloadSchemePopup,
      toggleDisplayCalls,
      displayCalls,
      hasCalls,
      schemeId,
      enableSaveScheme,
      hideScheme,
      openDetailsInNewTab,
      callCount,
      searchText,
      isVind,
      matchingPercentageValue,
      arrayMarkerLabel,
    };
  },
};
</script>
