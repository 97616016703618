import AuthService from '../../services/auth.service';

const localStorageUser = JSON.parse(localStorage.getItem('user'));

const state = () => ({
  isLoggedIn: localStorageUser ? true : false,
  user: localStorageUser ? localStorageUser : null,
  showAdminPanel:
    localStorageUser != null &&
    localStorageUser.permissions != null &&
    localStorageUser.permissions.includes('Front office user management')
      ? true
      : false,
  modalErrorMessage: '',
  hasModalError: false,
  env: localStorageUser
    ? Number.parseInt(
        JSON.parse(Buffer.from(localStorageUser.token.split('.')[1], 'base64'))
          .env
      )
    : 0,
  custid: localStorageUser
    ? Number.parseInt(
        JSON.parse(Buffer.from(localStorageUser.token.split('.')[1], 'base64'))
          .custid
      )
    : 0,
  customer: {
    customerPortalName: '',
    customerPortalSignatureName: '',
    phoneNumber: '',
    emailAddress: '',
    twitterUrl: '',
    facebookUrl: '',
    instagramUrl: '',
    linkedinUrl: '',
    baseUrl: '',
  },
  hasClickedNewsOnLoginPage: false,
  portalThemeTypes: [],
  searchInterests: [],
  professionTypes: [],
  customerPortalNameNoUpper: '',
});

const getters = {
  isLoggedIn: (state) => {
    return state.isLoggedIn;
  },
};

const actions = {
  login({ commit }, user) {
    return AuthService.login(user).then(
      (user) => {
        commit('loginSuccess', user);
        return Promise.resolve(user);
      },
      (error) => {
        commit('loginFailure');
        return Promise.reject(error);
      }
    );
  },
  logout({ commit }) {
    AuthService.logout();
    commit('logout');
  },
};

const mutations = {
  updateHasClickedNewsOnLoginPage(state, val) {
    state.hasClickedNewsOnLoginPage = val;
  },

  fillPortalCustomer(state, val) {
    state.customer.customerPortalName = val.customerPortalName;
    state.customerPortalNameNoUpper =
      val.customerPortalName != null && val.customerPortalName.length > 0
        ? val.customerPortalName.charAt(0).toLowerCase() +
          val.customerPortalName.slice(1)
        : '';
    state.customer.customerPortalSignatureName =
      val.customerPortalSignatureName;
    state.customer.emailAddress = val.emailAddress;
    state.customer.phoneNumber = val.phoneNumber;
    state.customer.twitterUrl = val.twitterUrl;
    state.customer.facebookUrl = val.facebookUrl;
    state.customer.instagramUrl = val.instagramUrl;
    state.customer.linkedinUrl = val.linkedinUrl;
    state.customer.baseUrl = val.baseUrl;
    state.customer.endDate = val.endDate;
  },

  setEnvironment(state, val) {
    state.env = val;
  },

  setCustid(state, val) {
    state.custid = val;
  },
  loginSuccess(state, user) {
    state.isLoggedIn = true;
    state.user = user;
    if (user.permissions.includes('Front office user management')) {
      state.showAdminPanel = true;
    }
  },
  loginFailure(state) {
    state.isLoggedIn = false;
    state.user = null;
  },
  logout(state) {
    state.isLoggedIn = false;
    state.user = null;
    state.showAdminPanel = false;
  },
  resetModalError(state) {
    state.modalErrorMessage = '';
  },

  enableModalError(state) {
    state.hasModalError = true;
  },
  disableModalError(state) {
    state.hasModalError = false;
  },
  updateModalError(state, message) {
    state.modalErrorMessage = message;
  },
  setPortalThemeTypes(state, val) {
    state.portalThemeTypes = val;
  },
  setSearchInterests(state, val) {
    state.searchInterests = val;
  },
  setProfessionTypes(state, val) {
    state.professionTypes = val;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
