<template>
  <div class="public-news-page">
    <form id="form1" runat="server">
      <div class="wrapper">
        <header>
          <img src="https://content.vindsubsidies.nl/nieuwsbrief/images/IgniteGroup-mailing-header.jpg" width="700"
            height="176" alt="Vindsubsidies" />
          <div class="header__info">
            <div class="header">
              <div class="header__title">
                <h1 style="line-height: 1rem !important; font-size: 1.25em !important;font-weight: 500 !important;">{{
                  title }}</h1>
              </div>

            </div>
            <div class="header__socials">
              <a href="https://nl.linkedin.com/company/vindsubsidies-nl" title="Vindsubsidies LinkedIn"
                style="color: white">
                <img src="https://content.vindsubsidies.nl/nieuwsbrief/images/linkedin.png" width="21" height="21"
                  alt="LinkedIn" />
              </a>
              <a href="https://twitter.com/vindsubsidies" title="Vindsubsidies Twitter">
                <img src="https://content.vindsubsidies.nl/nieuwsbrief/images/twitter.png" width="21" height="21"
                  alt="Twitter" />
              </a>
            </div>
          </div>
        </header>
        <main>
          <article class="detail__page">
            <div class="news-items-list-onze">
              <div class="news-item-onze q-pt-md" v-for="item in newsItems" :key="item.title">
                <table>
                  <tr>
                    <td align="left" valign="top" width="24"><img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAVCAYAAABc6S4mAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAOdaVRYdFhNTDpjb20uYWRvYmUueG1wAAAAAAA8P3hwYWNrZXQgYmVnaW49Iu+7vyIgaWQ9Ilc1TTBNcENlaGlIenJlU3pOVGN6a2M5ZCI/Pg0KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNi4wLWMwMDYgNzkuZGFiYWNiYiwgMjAyMS8wNC8xNC0wMDozOTo0NCAgICAgICAgIj4NCiAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4NCiAgICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1wTU06T3JpZ2luYWxEb2N1bWVudElEPSJ4bXAuZGlkOmE2ZGNjMzM4LTg5NTYtNTQ0Yy04NmM0LTA3NzMwMDkxODE2ZSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpGQjEwQTVBOEQ1ODUxMUVCQjYxODlCODFFMDc0OUFBRiIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpGQjEwQTVBN0Q1ODUxMUVCQjYxODlCODFFMDc0OUFBRiIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgMjIuNCAoV2luZG93cykiPg0KICAgICAgPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6M2QyNTI1Y2MtMjRiMy1jYTQ3LWEzMzktY2EzOThlNDYxMDMzIiBzdFJlZjpkb2N1bWVudElEPSJhZG9iZTpkb2NpZDpwaG90b3Nob3A6YWM2ZTg4YTgtNTExMS02NTQzLWJlMTktMDk3ZGI1NjM4ZTZkIiAvPg0KICAgIDwvcmRmOkRlc2NyaXB0aW9uPg0KICA8L3JkZjpSREY+DQo8L3g6eG1wbWV0YT4NCjw/eHBhY2tldCBlbmQ9InIiPz6LaHxnAAABNUlEQVRIS2P8v9eLgZaACUrTDNDcApKCSCrBRxFIkRKm24i2AGQ4I+P/9P//GcuhQgSBuvTn20QFEdTlqaQYzsv5+/PNp7zuBC2AGQ7ElWABIgAPx++vn7+z6j9bsOU+XgvIMZyb/c/XLz9YdUGGg/g4LSDHcC72P9+//mSBGw4COC1gYvzvDaRIMfzHt58s2siGgwBWC0CuZ2f92wPlEgScbH9+Ag3XQjccBDAsABkOdM21779Y2KFCBMHP38wl2AwHARQLoIZfBbqGAypEDGj/959xK5SNAeAWgAwHpoDLQMM5oULEgHYgno3L9SAAtgBmODAFcINFiQMEDQcBRknZaYrAXHcRmDF4oWLEAKIMBwEmYHmxkxTDgeVRJ5AiynAQAPsASJNUQhJrOAiM1mgEAAMDAKRmggR+7QvEAAAAAElFTkSuQmCC"
                        width="24" height="21" alt="" /></td>
                    <td align="left" valign="top" width="8"></td>
                    <td align="left" valign="top">
                      <div class="row q-pb-sm">
                        <span class="title">
                          <a :href="item.link" target="_blank" class="black">{{ item.title }}</a>
                        </span>
                      </div>
                      <div class="row">
                        <span class="intro q-mt-xs">
                          {{ item.introduction }}
                        </span>
                      </div>
                      <div class="row">
                        <div style="margin-left: auto; margin-right: 0;">
                          <a :href="item.link" target="_blank">{{ t('search_news.read_more') }}</a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </table>
                <q-separator class="q-mb-lg"></q-separator>
              </div>
            </div>
          </article>
        </main>
        <footer class="footer-news-details">
          <address>
            <p>
              <strong>Vindsubsidies</strong>
              <br />
              <a href="https://www.vindsubsidies.nl" class="top-margin"
                style="color: white">www.vindsubsidies.nl</a><br />
              <a href="tel:0882020401" style="color: white">088 - 2020 401</a><br />
              <a href="mailto:info@vindsubsidies.nl" style="color: white">info@vindsubsidies.nl</a>
            </p>
          </address>
          <div class="footer__socials">
            <div class="footer-socials__header">Social Media</div>
            <a href="https://nl.linkedin.com/company/vindsubsidies-nl" title="Vindsubsidies LinkedIn">
              <img src="https://content.vindsubsidies.nl/nieuwsbrief/images/linkedin.png" width="21" height="21"
                alt="LinkedIn" />
            </a>
            <a href="https://twitter.com/vindsubsidies" title="Vindsubsidies Twitter">
              <img src="https://content.vindsubsidies.nl/nieuwsbrief/images/twitter.png" width="21" height="21"
                alt="Twitter" />
            </a>
          </div>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { onMounted, computed } from 'vue';
import store from '../store/index';
import newsService from '../services/news.service';

export default {
  name: 'OnzePublicNewsList',
  setup() {
    onMounted(async () => {
      await newsService.getOnzeNewsItemsList();
    });

    const title = computed({
      get: () => store.state.publicNews.onzeNewsListTitle,
    });
    const newsItems = computed({
      get: () => store.state.publicNews.onzeNewsList,
    });

    return {
      newsItems,
      title
    };
  },
};
</script>

<style>
body {
  background: #f1f1f1;
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, 'sans-serif' !important;
  line-height: 1.5;
}

.public-news-page {
  text-align: left !important;
  margin-top: 0 !important;
}

.top-margin {
  margin-top: 40px !important;
}

a,
a:visited {
  text-decoration: none;
  color: #1938e8;
}

a:hover,
a:focus {
  color: #000000;
  text-decoration: underline;
}

a.black {
  color: #000000 !important;
}

h1 {
  font-weight: 500 !important;
  font-size: 1.866em;
  line-height: 4rem !important;
  color: #000 !important;
}

h2 {
  font-weight: 500;
  font-size: 1.3em;
  margin: 1.3em 0 0;
}

@media screen and (min-width: 30rem) {
  h2 {
    font-size: 1.5em;
    margin: 1.5em 0 0;
  }
}

.button {
  display: inline-block;
  padding: 0.75rem 1.25rem;
  background: #191d96;
  color: #f1f1f1;
  white-space: nowrap;
}

.button:hover,
.button:focus {
  text-decoration: none;
  background: #f1f1f1;
  color: #191d96;
  transition: all 0.2s ease-in-out;
}

div.wrapper {
  position: relative;
  width: 100%;
  max-width: 46.75rem;
  margin: 0 auto;
  background: #ffffff;
}

header img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
}

header .header__info {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-flow: column nowrap;
  background: #292929;
  padding: 0 1.5rem;
}

@media screen and (min-width: 30rem) {
  header .header__info {
    flex-flow: row nowrap;
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

header .header__info::before {
  content: '';
  position: absolute;
  z-index: 1;
  top: -0.975rem;
  left: 0;
  right: 0;
  padding-top: 1rem;
  background: #292929;
  -webkit-clip-path: polygon(0 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 100%, 0% 100%);
}

header .header__title {
  flex-grow: 1;
  padding: 1rem 0 1rem;
}

@media screen and (min-width: 30rem) {
  header .header__title {
    padding-bottom: 1.25rem;
    min-height: 1.875rem;
  }
}

header .header__title h1 {
  margin: 0 !important;
  color: #ffffff !important;
  font-size: 1.25em !important;
}

header .header__title a {
  color: #ffffff !important;
  font-size: 0.875rem !important;
}

header .header__title a span.icon {
  font-size: 1.5em !important;
  line-height: 1 !important;
  margin-right: 0.25rem !important;
}

header .header__title a:hover,
header .header__title a:focus {
  text-decoration: none;
  color: #191d96;
}

header .header__socials {
  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 0;
  order: -1;
}

@media screen and (min-width: 30rem) {
  header .header__socials {
    order: 0;
    padding: 1.25rem 0;
    margin-left: 0.5rem;
  }
}

header .header__socials a:not(:last-child) {
  margin-right: 1rem;
}

/* main {
  padding: 0.5rem 1.5rem 0.5rem;
} */

@media screen and (min-width: 30rem) {
  main {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

article.news__item a {
  position: relative;
  display: block;
  padding: 1.5rem 0 1.5rem 2rem;
}

@media screen and (min-width: 30rem) {
  article.news__item a {
    padding-left: 2.5rem;
  }
}

article.news__item a::before {
  content: '';
  position: absolute;
  top: 1.75rem;
  left: 0;
  width: 1.5rem;
  height: 0.875rem;
  background: url('/public-news-details/news-item.png') 0 0 no-repeat;
  display: block;
}

article.news__item a {
  color: #000000;
}

article.news__item a:hover,
article.news__item a:focus {
  text-decoration: none;
}

article.news__item a:hover .news__title,
article.news__item a:focus .news__title {
  color: #191d96;
  text-decoration: underline;
}

article.news__item .news__title {
  font-size: 1em;
  font-weight: normal;
  margin-bottom: 0.25rem;
}

article.news__item .news__intro {
  font-size: 0.875em;
  color: #666666;
}

article.news__item:not(:last-child) {
  border-bottom: 0.125rem solid #f1f1f1;
}

article.detail__page {
  padding: 0.5rem 0 2rem;
  font-size: 0.9375em;
}

.detail__footer {
  margin-top: 2rem;
}

.footer-news-details {
  display: flex;
  flex-flow: column nowrap;
  background: #292929;
  color: #ffffff !important;
  padding: 0 1.5rem;
  font-size: 0.875em !important;
}

@media screen and (min-width: 30rem) {
  .footer-news-details {
    flex-direction: row;
    justify-content: space-between;
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

address {
  color: white;
  font-style: normal;
  font-size: 1em;
  font-family: 'Montserrat', 'Helvetica Neue', 'Helvetica', 'Arial',
    'sans-serif' !important;
  display: flex;
  flex-direction: column;
}

address>p {
  margin: 0;
  padding: 16px 0;
}

.footer address,
.footer__socials {
  margin-top: 16px;
}

.footer a {
  color: #ffffff;
  text-decoration: none !important;
}

.footer a:hover,
.footer a:focus {
  color: #191d96;
}

.footer p {
  margin: 0;
}

.footer address {
  flex-grow: 1;
  font-style: normal !important;
}

.footer-socials__header {
  margin-bottom: 0.5rem;
  font-size: 1em !important;
}

.footer__socials a:not(:last-child) {
  margin-right: 1rem;
}

.footer__socials a:hover,
.footer__socials a:focus {
  text-decoration: none;
}

article.news__cancel {
  padding-top: 20px;
}

article.news__cancel a {
  color: #222222;
  font-weight: normal;
  text-decoration: none;
  font-size: 12px;
  font-family: 'Montserrat', 'Helvetica Neue', 'Helvetica', 'Arial',
    'sans-serif' !important;
}
</style>
