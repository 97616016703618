<template>
  <ErrorModal />
  <q-dialog v-model="show" persistent class="save-scheme-main">
    <q-card style="min-width: 350px" class="save-dialog  welcome-dialog-onboarding">
      <q-card-section>
        <div style="color: #0a108f" class="text-main bold-font">
          {{ t('welcome_dialog.message') }}
        </div>
      </q-card-section>

      <q-separator />

      <q-card-section class="q-pt-none q-pa-md">
        <div class="row justify-around">
          {{ t('welcome_dialog.notification') }}
        </div>
      </q-card-section>

      <q-separator />

      <q-card-actions class="bold-font q-ml-sm" align="center">
        <q-btn color="white" class="orange-btn-filled" flat :label="t('common.ok')" style="width: 90px" no-caps
          @click="disableModal()" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import store from '../../store/index';
import UserService from '../../services/userprofile.service';
export default {
  setup() {
    onMounted(async () => {
      UserService.getFavoriteAudienceTags();
      UserService.getFavoritePurposesTags();
      UserService.getFavoriteLocationsTags();


      if (localStorageUser.languageCode == 'de') {
        await UserService.completeOnboarding(localStorageUser.id);

        store.commit('user/updateHasBeenRedirectedToProfile', false);
        show.value = false;
      }
    });

    const show = ref(true);

    const disableModal = async () => {
      if (
        (favoriteAudience.value && favoriteAudience.value.length > 0) ||
        (favoritePurposes.value && favoritePurposes.value.length > 0) ||
        (selectedFavoriteLocations.value &&
          selectedFavoriteLocations.value.length > 0)
      ) {
        await UserService.completeOnboarding(localStorageUser.id);

        store.commit('user/updateHasBeenRedirectedToProfile', false);
        show.value = false;
      }

      store.commit('user/updateHasBeenRedirectedToProfile', false);
      show.value = false;
    };

    const favoriteAudience = computed({
      get: () => store.state.user.favoriteAudiencesIdsArray,
    });

    const favoritePurposes = computed({
      get: () => store.state.user.favoritePurposesIdsArray,
    });

    const selectedFavoriteLocations = computed({
      get: () => store.state.user.selectedFavoriteLocations,
    });
    const localStorageUser = JSON.parse(localStorage.getItem('user'));

    return {
      localStorageUser,
      favoriteAudience,
      favoritePurposes,
      selectedFavoriteLocations,
      disableModal,
      show,
    };
  },
};
</script>
