import advancedSearch from './modules/advancedSearch';
import user from './modules/user';
import grantItems from './modules/grantItems';
import news from './modules/news';
import auth from './modules/auth';
import tags from './modules/tags';
import newsSearch from './modules/newsSearch';
import advancedFilters from './modules/advancedFilters';
import organisations from './modules/organisations';
import { createStore } from 'vuex'; //, createLogger
import homePageItems from './modules/homePageItems';
import suggestions from './modules/suggestions';
import salesForce from './modules/salesForce';
import publicNews from './modules/publicNews';
import userStats from './modules/userStats';
import obExport from './modules/obExport';
import portalsData from './modules/portalsData';
import onzeStats from './modules/onzeStats';
export default createStore({
  modules: {
    advancedSearch: advancedSearch,
    user: user,
    grantItems: grantItems,
    news: news,
    auth: auth,
    newsSearch: newsSearch,
    tags: tags,
    advancedFilters: advancedFilters,
    organisations: organisations,
    homePageItems: homePageItems,
    suggestions: suggestions,
    salesForce: salesForce,
    publicNews: publicNews,
    userStats: userStats,
    obExport: obExport,
    portalsData: portalsData,
    onzeStats: onzeStats,
  },
  strict: false,
  plugins: [],
});
