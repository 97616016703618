<template>
  <main class="main-tag">
    <div class="spinner-page" v-if="isNewsPageLoading || isNewsSideBarLoading">
      <q-spinner class="spinner" color="primary" size="3em" />
    </div>
    <div v-else class="news-page" :class="isContrast != 'false' && isContrast != false ? 'high-contrast' : ''">
      <span v-if="!isVind" class="curr-nav-portal row">{{ t('search_news.news') }}</span>

      <q-layout>
        <q-btn v-if="isVind" @click="enableDrawer()" class="drawer-btn">
          <q-icon size="18px" name="fa-solid fa-sliders"></q-icon>
        </q-btn>

        <q-drawer v-model="drawerLeft" elevated class="bg-primary text-white drawer drawer-std" v-if="isVind">
          <SideBar class="drawer-sidebar" ref="sideBar" />

          <div class="absolute drawer-close-container" style="top: 50%; right: -34px; z-index: 200">
            <q-btn dense class="drawer-close-btn" round unelevated color="accent" icon="close"
              @click="drawerLeft = false"></q-btn>
          </div>
        </q-drawer>

        <div class="drawer-news-portal">
          <q-drawer v-model="portalDrawerLeft" elevated class="text-white drawer portalnews-drawer" v-if="!isVind">
            <SideBar class="drawer-sidebar" ref="sideBar" />

            <div class="absolute drawer-close-container" style="top: 50%; right: -34px; z-index: 200">
              <q-btn dense class="drawer-close-btn" round unelevated color="accent" icon="close"
                @click="disableDrawerLeft()"></q-btn>
            </div>
          </q-drawer>
        </div>
        <div class="main" :class="(!isVind ? 'news-list-portal' : '',
        portalDrawerLeft ? 'blurred-portal' : '')
      ">
          <SideBar class="sidebar-main" />

          <div :class="!isVind ? 'portal-news-items' : ''" class="news-items">
            <span v-if="isVind" class="curr-nav row">{{ t('navbar.search_news') }}</span>

            <div class="column">
              <h3 v-if="isVind" class="searchpage-heading bold-font row">
                {{ t('navbar.search_news') }}
              </h3>
              <h3 v-else class="searchpage-heading-portal bold-font row">
                {{ t('search_news.personal_news_overview') }}
              </h3>

              <SearchBar v-if="isVind" />
              <NewsSearchBar v-if="!isVind" />
              <div class="pagination top-pag" v-if="isVind">
                <q-pagination class="pagination-item" v-model="current" :max="maxPages" direction-links :max-pages="6"
                  @click="goToPage(current)" color="grey" active-color="green-7" text-color="white"
                  size="13px"></q-pagination>
              </div>
              <div class="row top-options-row q-mt-md">
                <div v-if="isVind" class="row q-mb-md dropdown-container">
                  <p class="sort-heading q-mt-sm">{{ t('search_schemes.sort_by') }}</p>
                  <q-select class="sort-select" dense rounded v-model="selectedSort" input-debounce="0"
                    :options="sortOptions" behavior="menu" bg-color="white" outlined />
                </div>
                <div v-if="isVind" class="row view-select-icons q-pt-sm q-ml-sm">
                  <q-icon class="action-icon" name="fa-solid fa-table" size="18px" @click="enableViewAsTable()">
                    <q-tooltip> {{ t('search_news.view_as_table') }} </q-tooltip></q-icon>
                  <q-icon class="action-icon q-ml-sm" name="fa-solid fa-list" size="18px" @click="disableViewAsTable()">
                    <q-tooltip> {{ t('search_news.view_as_list') }} </q-tooltip></q-icon>
                </div>
              </div>
            </div>
            <div :class="!isVind ? 'news-portal-column' : ''" v-if="!isTableView">
              <NewsItem v-for="news in allNews" :key="news.id" :newsTitle="news.title" :newsItem="news"
                :intro="news.introduction" :id="news.id" :body="news.body" />
            </div>

            <div v-else class="table-container">
              <q-table :rows="allNews" :columns="newsTableColumns" row-key="id" :filter="filterSchemes"
                :filter-method="schemesFilter" class="news-table" :hide-pagination="true"
                :rows-per-page-label="t('search_news.results_per_page')" :rows-per-page-options="[25]">
                <template v-slot:body="props">
                  <q-tr :props="props">
                    <q-td key="titel" :props="props" class="title-row">
                      <router-link :to="{
      name: 'NewsDetails',
      params: { newsId: props.row.id },
    }" style="text-decoration: none" class="title-row">
                        <div v-html="props.row.title"></div>
                      </router-link>
                    </q-td>

                    <q-td key="datum" :props="props" class="date-row">{{
      formatDate(props.row.date)
    }}</q-td>
                  </q-tr>
                </template>
              </q-table>
            </div>

            <div class="pagination pagination-bottom">
              <q-pagination class="pagination-item" v-model="current" :max="maxPages" direction-links :max-pages="6"
                @click="goToPage(current)" color="grey" active-color="green-7" text-color="white"
                size="13px"></q-pagination>
            </div>
          </div>
        </div>

        <div class="circle-container">
          <div id="pagetop" class="top-scroll" v-if="scY > 300">
            <i class="las la-2x la-angle-up up-icon" @click="toTop">
              <q-tooltip self="center left" :offset="[10, 10]">{{ t('search_schemes.upwards') }}</q-tooltip>
            </i>
          </div>
        </div>
      </q-layout>
    </div>
  </main>
</template>

<script>
import { computed, ref, onMounted, onUnmounted } from 'vue';
import store from '../store/index';
import NewsService from '../services/news.service';
import SearchBar from '../components/news-components/NewsSearch.vue';
import NewsItem from '../components/news-components/NewsItem.vue';
import SideBar from '../components/news-components/NewsSideBarSearch.vue';
import FiltersService from '../services/filters.service';
import NewsUtils from '..//utils/news';
import moment from 'moment';
import NewsSearchBar from '../components/portals-components/NewsSearchBar.vue';
import { useI18n } from 'vue-i18n';
import { loadMessages } from '../i18n/index';
/* import PortalNewsSideBarSearch from '../components/portals-components/PortalNewsSideBarSearch.vue';
 */
export default {
  components: {
    SearchBar,
    NewsItem,
    SideBar,
    NewsSearchBar,
    /*     PortalNewsSideBarSearch,
     */
  },
  setup() {
    const { t, locale } = useI18n();

    const newsSearchTerm = computed({
      get: () => store.state.newsSearch.searchText,
    });

    const isSearchNewsWithSchemesFilters = computed({
      get: () => store.state.advancedSearch.isSearchNewsWithSchemesFilters,
    });

    onMounted(async () => {
      await loadMessages(locale.value);
      store.commit('auth/updateHasClickedNewsOnLoginPage', false);
      store.commit('newsSearch/updateSelectedSortNews', t('search_news.newest'));
      var isDefaultSearch = NewsUtils.checkIsDefaultSearch();

      if (!isSearchNewsWithSchemesFilters.value) {
        if (newsSearchTerm.value.length > 0 || !isDefaultSearch) {
          await NewsUtils.searchNews(locale);
        } else {
          await NewsService.loadDefaultNews(locale);
        }
      }

      await FiltersService.getDefaultFilters(locale.value);
      window.scrollTo(0, 0);
      window.addEventListener('scroll', handleScroll);
    });

    onUnmounted(() => {
      store.commit('advancedSearch/updateDisplayNumberOfNewsResults', false);
    });

    const maxPages = computed({
      get: () => store.state.newsSearch.pageCount,
    });

    const allNews = computed({
      get: () => store.state.news.allNews,
    });

    const searchText = computed({
      get: () => store.state.newsSearch.searchText,
    });

    const orgsIncludeString = computed({
      get: () =>
        store.state.newsSearch.organisationsIncludeString != ''
          ? store.state.newsSearch.organisationsIncludeString
          : sessionStorage.getItem('organisationsIncludeString'),
    });

    const orgsExcludeString = computed({
      get: () =>
        store.state.newsSearch.organisationsExcludeString != ''
          ? store.state.newsSearch.organisationsExcludeString
          : sessionStorage.getItem('organisationsExcludeString'),
    });

    const goToPage = async (current) => {
      store.commit('news/updateCurrentPage', current);
      store.commit('newsSearch/createOrganisationsIncludeString');
      store.commit('newsSearch/createOrganisationsExcludeString');

      await NewsUtils.searchNewsByPage(locale, isTableView.value);

      window.scrollTo(0, 0);
    };

    const currentPage = computed({
      get: () => store.state.news.currentPage,
    });

    const dateFrom = computed({
      get: () =>
        store.state.newsSearch.dateFrom != ''
          ? store.state.newsSearch.dateFrom
          : JSON.parse(sessionStorage.getItem('newsDateFrom')),
    });

    const dateTo = computed({
      get: () =>
        store.state.newsSearch.dateTo != ''
          ? store.state.newsSearch.dateTo
          : JSON.parse(sessionStorage.getItem('newsDateTo')),
    });
    const current = ref(currentPage.value);

    const audienceTags = computed({
      get: () =>
        store.state.newsSearch.selectedAudiences != ''
          ? store.state.newsSearch.selectedAudiences
          : sessionStorage.getItem('selectedAudiences'),
    });

    const locationsTags = computed({
      get: () =>
        store.state.newsSearch.selectedLocations != ''
          ? store.state.newsSearch.selectedLocations
          : sessionStorage.getItem('selectedLocations'),
    });

    const purposesTags = computed({
      get: () =>
        store.state.newsSearch.selectedPurposes != ''
          ? store.state.newsSearch.selectedPurposes
          : sessionStorage.getItem('selectedPurposes'),
    });

    const schemesTags = computed({
      get: () =>
        store.state.newsSearch.selectedSchemeTypes != ''
          ? store.state.newsSearch.selectedSchemeTypes
          : sessionStorage.getItem('selectedSchemeTypes'),
    });

    //This is just hidden for now, don't remove it:
    /*
    const industriesTags = computed({
      get: () => store.state.newsSearch.selectedIndustries,
    });
    */

    const scTimer = ref(0);
    const scY = ref(0);
    const handleScroll = () => {
      if (scTimer.value) return;
      scTimer.value = setTimeout(() => {
        scY.value = window.scrollY;
        clearTimeout(scTimer.value);
        scTimer.value = 0;
      }, 100);
    };

    const toTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };

    const isNewsPageLoading = computed({
      get: () => store.state.news.isNewsPageLoading,
    });

    const isNewsSideBarLoading = computed({
      get: () => store.state.news.isNewsSideBarLoading,
    });

    const sortOptionsKeys = ['search_news.newest', 'search_news.oldest', 'search_news.relevance'];

    // Map over the original array and apply translation function
    const sortOptions = computed(() => {
      return sortOptionsKeys.map(option => t(option));
    });

    const selectedSort = computed({
      get: () => store.state.newsSearch.selectedSortNews,
      set: (value) => {
        store.commit('newsSearch/updateSelectedSortNews', value);
        NewsUtils.searchNews(locale, isTableView.value);
      },
    });

    const newsTableColumns = ref([
      {
        name: 'titel',
        align: 'left',
        label: 'Titel',
        field: 'titel',
        sortable: false,
      },
      {
        name: 'datum',
        align: 'left',
        label: computed(() => t('search_schemes.date')),
        field: 'datum',
        sortable: false,
      },
    ]);

    const formatDate = (stringDate) => {
      const dateN = new Date(stringDate);
      return moment(dateN).format('DD-MM-YYYY');
    };

    const isTableView = computed({
      get: () => store.state.newsSearch.isTableView,
    });

    const enableViewAsTable = () => {
      store.commit('newsSearch/changeIsTableView', true);
      NewsUtils.searchNews(locale, true);
    };

    const disableViewAsTable = () => {
      store.commit('newsSearch/changeIsTableView', false);
      NewsUtils.searchNews(locale, false);
    };

    const isContrast = computed({
      get: () => store.state.user.isSelectedThemeContrast,
    });

    const drawerLeft = ref(false);
    const enableDrawer = () => {
      drawerLeft.value = true;
    };

    const portalDrawerLeft = computed({
      get: () => store.state.advancedSearch.portalNewsDrawerLeft,
    });

    const isVind = computed({
      get: () => store.state.auth.env === 0 || store.state.auth.env === 40,
    });

    const disableDrawerLeft = () => {
      store.commit('advancedSearch/updatePortalNewsDrawerLeft', false);
    };

    return {
      t,
      locale,
      disableDrawerLeft,
      portalDrawerLeft,
      isVind,
      enableDrawer,
      drawerLeft,
      isContrast,
      isSearchNewsWithSchemesFilters,
      enableViewAsTable,
      disableViewAsTable,
      isTableView,
      formatDate,
      newsTableColumns,
      sortOptions,
      selectedSort,
      isNewsSideBarLoading,
      isNewsPageLoading,
      newsSearchTerm,
      handleScroll,
      scTimer,
      scY,
      toTop,
      maxPages,
      current,
      goToPage,
      allNews,
      currentPage,
      orgsIncludeString,
      orgsExcludeString,
      searchText,
      dateFrom,
      dateTo,
      //This is just hidden for now, don't remove it:
      //industriesTags,
      schemesTags,
      purposesTags,
      audienceTags,
      locationsTags,
    };
  },
};
</script>
