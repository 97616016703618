<template>
  <FolderReport />
  <div class="saved-items-page">
    <div class="column nav-for-mobile">
      <q-splitter
        v-model="splitterModel"
       
        class="saved-items-small"
      >
        <template v-slot:before>
          <div
            v-if="isVind"
            style="background-color: #f8f8f8; min-width:300px"
            class="new-folder-container"
          >
            <q-btn no-caps @click="enableAddNew()" class="q-mb-md newFolder">
              <q-icon name="create_new_folder"></q-icon>
              <span style="font-weight: bold"
                >&nbsp;&nbsp;&nbsp;{{ t('saved_and_hidden_items.new_folder') }}</span
              >
            </q-btn>
            <div class="q-pl-md folders-list">
              <div
                class="q-mt-none row q-gutter-sm text-left folders-btns"
                v-for="folder in foldersWithItems"
                :key="folder.id"
              >
                <div class="list">
                  <a
                    class="selected-folder row"
                    v-if="folder.id == selectedFolderId"
                    @click="getItems(folder.id, folder.name)"
                  >
                    <q-icon name="fa-solid fa-folder-open" size="15px">
                    </q-icon>
                    <div class="q-ma-none q-ml-xs overflow-folder-name">
                      {{ folder.name }}
                    </div>
                    <q-tooltip>{{folder.name}}</q-tooltip>
                  </a>
                  <a
                    v-else
                    class="row non-selected-folder"
                    @click="getItems(folder.id, folder.name)"
                  >
                    <q-icon
                      class="row wrap"
                      name="fa-solid fa-folder-closed"
                      size="15px"
                      no-capst
                    >
                    </q-icon>
                    <div class="q-ma-none q-ml-xs overflow-folder-name">
                      {{ folder.name }}
                    </div>
                    <q-tooltip>{{folder.name}}</q-tooltip>
                  </a>
                </div>
                <div class="q-mr-md float-right edit-map-prompt">
                  <q-icon
                    class="edit-folders"
                    name="fa-solid fa-pen-to-square"
                    size="14px"
                    style="color: #0a108f; margin-top: -3px; margin-right: 8px"
                    @click="enableRenameFolder(folder.id, folder.name)"
                  >
                    <q-tooltip> {{ t('saved_and_hidden_items.rename_folder') }} </q-tooltip>
                  </q-icon>
                  <q-icon
                    class="delete-folders"
                    name="fa-solid fa-trash"
                    size="14px"
                    style="color: #0a108f; margin-bottom: 5px"
                    @click="
                      (confirmDeleteFolder = true), (pickedFolderId = folder.id)
                    "
                  >
                    <q-tooltip> {{ t('saved_and_hidden_items.delete_folder') }} </q-tooltip>
                  </q-icon>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:after>
          <div
            class="folderContent"
            style="display: flex; flex-direction: column"
          >
            <div v-if="selectedFolderId != null">
              <!-- <q-tabs
              v-model="savedItemsTab"
              dense
              class="text-grey"
              align="justify"
              narrow-indicator
            >
              <q-tab name="schemes" label="Opgeslagen items"> </q-tab>
              <q-tab name="searches" label="Opgeslagen zoekacties"> </q-tab>
            </q-tabs>

            <q-separator /> -->

              <div class="zoekacties">
                <q-tab-panel
                  name="schemes"
                  class="schemes"
                  :class="!isVind ? 'schemesPortals' : ''"
                >
                  <div v-if="!isVind" class="navigation-for-mobile">
                    <div class="q-ml-md row portals-nav-mobile no-wrap">
                      <router-link
                        :to="{
                          name: 'UserProfile',
                          params: { tab: 'myBasicDetails' },
                        }"
                        style="text-decoration: none"
                      >
                        <q-btn
                          class="q-px-md buttons-nav bg-grey-3 text-indigo-6"
                          no-caps
                          dense
                          >{{ t('navbar.details') }}
                        </q-btn>
                      </router-link>

                      <q-btn
                        class="q-px-md buttons-nav bg-indigo-9 text-white"
                        no-caps
                        dense
                      >
                        {{ t('navbar.saved_items') }}</q-btn
                      >
                    </div>
                  </div>
                  <q-breadcrumbs
                    v-if="isVind"
                    class="text-brown navigation-breadcrumbs"
                  >
                    <template v-slot:separator>
                      <q-icon size="1.5em" name="chevron_right"></q-icon>
                    </template>

                    <q-breadcrumbs-el :label="t('profile.profile')"></q-breadcrumbs-el>
                    <q-breadcrumbs-el
                      :label="t('navbar.saved_items')"
                    ></q-breadcrumbs-el>
                    <q-breadcrumbs-el
                      :label="t('profile.saved_schemes')"
                    ></q-breadcrumbs-el>
                  </q-breadcrumbs>
                  <div
                    class="text-h4 text-left main-caption"
                    :class="!isVind ? 'portal-colors' : ''"
                  >
                    {{ labelSavedItems }}
                  </div>
                  <!-- <div class="text-h4 text-left main-caption">
                    Opgeslagen regelingen
                  </div> -->
                  <p v-if="isVind" class="heading2">
                    {{ t('saved_and_hidden_items.select_or_create_folder_for_favorite_schemes_hint') }}
                  </p>
                  <p v-else class="heading2">
                    {{ t('saved_and_hidden_items.favorite_schemes_hint') }}
                  </p>
                  <div v-if="isVind" class="open-drawer-btn">
                    <q-btn
                      @click="leftDrawerOpen = true"
                      class="open-drawer-btn"
                    >
                      <q-icon
                        size="18px"
                        name="fa-solid fa-folder-open"
                      ></q-icon>
                    </q-btn>
                  </div>
                  <div
                    id="q-app"
                    style="min-height: 50vh"
                    v-if="folderSchemesItems.length > 0"
                  >
                    <div v-if="isVind" class="column table-for-mobile">
                      <div
                        class="column search-regelingen"
                        style="margin-left: -30px"
                      >
                        <span class="q-mb-xs q-mt-md">
                          {{ folderName }}
                          <q-icon
                            v-if="selectedFolderId != 0"
                            name="fa-solid fa-download"
                            size="18px"
                            style="color: #0a108f"
                            @click="generateFolderReportPopup(selectedFolderId)"
                          >
                            <q-tooltip> {{ t('saved_and_hidden_items.generate.report') }} </q-tooltip>
                          </q-icon>
                        </span>
                        <q-input
                          class="input-field"
                          borderless
                          dense
                          debounce="300"
                          color="primary"
                          :placeholder="t('search_schemes.search')"
                          v-model="filterSchemes"
                          @update:model-value="
                            schemesFilter(folderSchemesItems, filterSchemes)
                          "
                        >
                          <q-icon
                            class="search"
                            name="search"
                            size="16px"
                            style="margin-right: 70%"
                          ></q-icon>
                        </q-input>
                      </div>
                      <div class="expansion-list">
                        <q-list
                          v-for="x in filteredArray"
                          :key="x.id"
                          padding
                          bordered
                        >
                          <q-expansion-item
                            v-if="x && x.scheme"
                            :label="x.scheme.name"
                            dense
                            dense-toggle
                            expand-separator
                          >
                            <div class="column q-mx-md content-for-searches">
                              <div class="flex justify-between about-scheme">
                                <span class="spans">{{ t('search_schemes.status') + ':'}}</span>
                                <p class="status">
                                  {{ x.scheme.status }}
                                </p>
                              </div>
                              <div class="flex justify-between about-scheme">
                                <span class="spans">{{ t('saved_and_hidden_items.alert') + ':'}}</span>
                                <div class="alert">
                                  <div v-if="x.hasAlert == false">
                                    <q-toggle
                                      v-model="x.hasAlert"
                                      icon="clear"
                                      style="color: #191d96"
                                      @click="
                                        updateAlert(x.id, x.folderId, true)
                                      "
                                    >
                                      <q-tooltip> {{ t('saved_and_hidden_items.turn_on_alert') }} </q-tooltip>
                                    </q-toggle>
                                  </div>
                                  <div v-if="x.hasAlert == true">
                                    <q-toggle
                                      v-model="x.hasAlert"
                                      icon="check"
                                      @click="
                                        updateAlert(x.id, x.folderId, false)
                                      "
                                    >
                                      <q-tooltip> {{ t('saved_and_hidden_items.turn_on_alert') }} </q-tooltip>
                                    </q-toggle>
                                  </div>
                                </div>
                              </div>
                              <div class="flex justify-between about-scheme">
                                <span class="spans">{{ t('saved_and_hidden_items.actions') }}</span>
                                <div class="row activity-buttons">
                                  <q-btn
                                    v-if="x != null"
                                    class="details-btn"
                                    size="sm"
                                    no-caps
                                    :to="{
                                      name: 'GrantDetails',
                                      params: { grantId: x.scheme.id },
                                    }"
                                  >
                                    <q-icon
                                      name="visibility"
                                      size="15px"
                                    ></q-icon>
                                    <q-tooltip> {{ t('saved_and_hidden_items.details') }} </q-tooltip>
                                  </q-btn>
                                  <q-btn
                                    class="delete-button"
                                    v-if="x != null"
                                    @click="
                                      (confirmSchemeDelete = true),
                                        (schemeItemId = x.scheme.id),
                                        (folderId = x.id)
                                    "
                                    size="sm"
                                    no-caps
                                  >
                                    <q-icon
                                      name="delete_outline"
                                      size="15px"
                                    ></q-icon>
                                    <q-tooltip> {{ t('saved_and_hidden_items.to_delete') }} </q-tooltip>
                                  </q-btn>
                                </div>
                              </div>
                            </div>
                          </q-expansion-item>
                        </q-list>
                      </div>
                    </div>

                    <div class="column table-for-mobile" v-if="!isVind">
                      <div class="expansion-list-portals">
                        <div class="q-my-md q-ml-md">
                          <q-btn
                            no-caps
                            class="orange-btn-filled"
                            :label="t('saved_and_hidden_items.share_selected_items')"
                            icon="fa-solid fa-square-share-nodes"
                            @click="shareGrantMob()"
                            style="max-width: 350px"
                          >
                          </q-btn>
                        </div>
                        <q-list
                          v-for="x in filteredArray"
                          :key="x.id"
                          padding
                          borderless
                        >
                          <div class="q-mb-md" v-if="x && x.scheme" dense>
                            <p class="q-ml-md name-scheme">
                              {{ x.scheme.name }}
                            </p>
                            <div
                              class="column q-mx-md content-for-portals-searches"
                            >
                              <div class="flex justify-between about-scheme">
                                <q-radio
                                  dense
                                  v-model="shareScheme"
                                  :val="x"
                                  :label="t('saved_and_hidden_items.select_grant')"
                                ></q-radio>
                              </div>
                              <div class="flex about-scheme">
                                <span class="spans-status">{{ t('search_schemes.status') }}</span>
                                <q-select
                                  :aria-label="t('saved_and_hidden_items.select_individual_scheme_status')"
                                  borderless
                                  v-if="x.scheme != null"
                                  style="width: 200px"
                                  v-model="x.individualSchemeStatus"
                                  :options="individualSchemeStatuses"
                                  @update:model-value="
                                    updateIndividualSchemeStatus(
                                      x.scheme.id,
                                      x.individualSchemeStatus
                                    )
                                  "
                                  :label="t('saved_and_hidden_items.possible_to_request')"
                                />
                              </div>
                              <div class="flex about-scheme">
                                <span class="spans q-mr-md">{{ t('saved_and_hidden_items.to_delete') + ':' }}</span>
                                <div class="row activity-buttons">
                                  <q-btn
                                    class="delete-button"
                                    v-if="x != null"
                                    @click="
                                      (confirmSchemeDelete = true),
                                        (schemeItemId = x.scheme.id),
                                        (folderId = x.id)
                                    "
                                    size="sm"
                                    no-caps
                                  >
                                    <q-icon
                                      name="delete_outline"
                                      size="15px"
                                    ></q-icon>
                                    <q-tooltip> {{ t('saved_and_hidden_items.to_delete') }} </q-tooltip>
                                  </q-btn>
                                </div>
                              </div>
                            </div>
                          </div>
                          <q-separator />
                        </q-list>
                      </div>
                    </div>

                    <div
                      v-if="folderSchemesItems.length > 0 && !Object.keys(folderSchemesItems[0]).length == 0"
                      class="table-for-desktop q-pa-md"
                      :class="
                        !isVind
                          ? 'portals-table-for-desktop q-pa-md'
                          : 'non-portals-table'
                      "
                    >
                      <q-table
                        v-if="isVind"
                        :rows="folderSchemesItems"
                        :columns="savedSchemesColumns"
                        row-key="id"
                        :filter="filterSchemes"
                        :filter-method="schemesFilter"
                        :rows-per-page-label="t('search_news.results_per_page')"
                      >
                        <template v-slot:top>
                          <div class="column no-wrap" style="overflow: auto;">
                            <div class="folderName q-mb-md">
                              {{ folderName }}
                            </div>
                            <q-input
                              borderless
                              dense
                              debounce="300"
                              color="primary"
                              :placeholder="t('search_schemes.search')"
                              v-model="filterSchemes"
                            >
                              <q-icon
                                class="search"
                                name="search"
                                size="16px"
                              ></q-icon>
                            </q-input>
                          </div>
                          <q-space />
                          <q-icon
                            v-if="selectedFolderId != 0"
                            name="fa-solid fa-download"
                            size="18px"
                            style="color: #0a108f; margin-top: -60px"
                            @click="generateFolderReportPopup(selectedFolderId)"
                          >
                            <q-tooltip> {{ t('saved_and_hidden_items.generate_report') }} </q-tooltip></q-icon
                          >
                        </template>

                        <template v-slot:body="props">
                          <q-tr :props="props">
                            <q-td
                              key="name"
                              v-if="props.row.scheme != null"
                              class="scheme-name-link"
                            >
                              <router-link
                                style="text-decoration: none"
                                :to="{
                                  name: 'GrantDetails',
                                  params: { grantId: props.row.schemeId },
                                }"
                              >
                                {{
                                  props.row.scheme != null
                                    ? props.row.scheme.name
                                    : null
                                }}
                              </router-link>
                            </q-td>

                            <!--      <q-td key="identifier" :props="props">{{
                            props.row.scheme != null
                            ? props.row.scheme.identifier
                            : null
                           }}</q-td> -->
                            <q-td key="status" :props="props">{{
                              props.row.scheme != null
                                ? props.row.scheme.status
                                : null
                            }}</q-td>
                            <q-td
                              key="alert"
                              :props="props"
                              class="q-gutter-xs"
                            >
                              <div v-if="props.row.hasAlert == false">
                                <q-toggle
                                  v-model="props.row.hasAlert"
                                  icon="clear"
                                  style="color: #191d96"
                                  @click="
                                    updateAlert(
                                      props.row.id,
                                      props.row.folderId,
                                      true
                                    )
                                  "
                                >
                                  <q-tooltip> {{ t('saved_and_hidden_items.turn_on_alert') }} </q-tooltip>
                                </q-toggle>
                              </div>
                              <div v-if="props.row.hasAlert == true">
                                <q-toggle
                                  v-model="props.row.hasAlert"
                                  icon="check"
                                  @click="
                                    updateAlert(
                                      props.row.id,
                                      props.row.folderId,
                                      false
                                    )
                                  "
                                >
                                  <q-tooltip> {{ t('saved_and_hidden_items.turn_on_alert') }} </q-tooltip>
                                </q-toggle>
                              </div>
                            </q-td>
                            <q-td
                              key="actions"
                              :props="props"
                              class="q-gutter-xs"
                            >
                              <q-btn
                                v-if="props.row.scheme != null"
                                class="details-btn"
                                size="sm"
                                no-caps
                                :to="{
                                  name: 'GrantDetails',
                                  params: { grantId: props.row.schemeId },
                                }"
                              >
                                <q-icon name="visibility" size="20px"></q-icon>
                                <q-tooltip> {{ t('saved_and_hidden_items.details') }} </q-tooltip>
                              </q-btn>
                              <q-btn
                                :aria-label="t('saved_and_hidden_items.delete_button')"
                                class="delete-button"
                                v-if="props.row.scheme != null"
                                @click="
                                  (confirmSchemeDelete = true),
                                    (schemeItemId = props.row.id),
                                    (folderId = props.row.folderId)
                                "
                                size="sm"
                                no-caps
                              >
                                <q-icon
                                  name="delete_outline"
                                  size="20px"
                                ></q-icon>
                                <q-tooltip> {{ t('saved_and_hidden_items.to_delete') }}</q-tooltip>
                              </q-btn>
                            </q-td>
                          </q-tr>
                        </template>
                      </q-table>
                      <q-table
                        v-if="!isVind"
                        :rows="folderSchemesItems"
                        :columns="savedSchemesColumnsPortals"
                        row-key="id"
                        :filter="filterSchemes"
                        :filter-method="schemesFilter"
                        selection="single"
                        v-model:selected="shareSelectedSchemes"
                        :rows-per-page-label="t('search_news.results_per_page')"
                      >
                        <template v-slot:top>
                          <div class="column">
                            <q-btn
                              no-caps
                              class="orange-btn-filled"
                              :label="t('saved_and_hidden_items.share_selected_items')"
                              icon="fa-solid fa-square-share-nodes"
                              @click="shareGrant()"
                            >
                            </q-btn>
                          </div>
                          <q-space />
                        </template>
                        <template v-slot:body="props">
                          <q-tr :props="props">
                            <q-td key="selected">
                              <q-checkbox
                                :aria-label="t('saved_and_hidden_items.select_scheme_checkbox')"
                                dense
                                v-model="props.selected"
                              ></q-checkbox>
                            </q-td>
                            <q-td
                              key="name"
                              props.row.scheme.name
                              v-if="props.row.scheme != null"
                              class="scheme-name-link-portals overflow-name"
                            >
                              <router-link
                                style="text-decoration: none"
                                :to="{
                                  name: 'PortalSchemeDetails',
                                  params: { grantId: props.row.schemeId },
                                }"
                              >
                                {{
                                  props.row.scheme != null
                                    ? props.row.scheme.name
                                    : null
                                }}
                              </router-link>
                            </q-td>

                            <q-td key="status" :props="props">
                              <q-select
                                :aria-label="t('saved_and_hidden_items.select_individual_scheme_status')"
                                borderless
                                v-if="props.row.scheme != null"
                                style="width: 200px"
                                v-model="props.row.individualSchemeStatus"
                                :options="individualSchemeStatuses"
                                @update:model-value="
                                  updateIndividualSchemeStatus(
                                    props.row.id,
                                    props.row.individualSchemeStatus
                                  )
                                "
                              />
                            </q-td>
                            <q-td
                              key="alert"
                              :props="props"
                              class="q-gutter-xs"
                            >
                              <div v-if="props.row.hasAlert == false">
                                <q-toggle
                                  :aria-label="t('saved_and_hidden_items.toggle_scheme_field_warning')"
                                  v-model="props.row.hasAlert"
                                  icon="clear"
                                  style="color: #191d96"
                                  @click="
                                    updateAlert(
                                      props.row.id,
                                      props.row.folderId,
                                      true
                                    )
                                  "
                                >
                                  <q-tooltip> {{ t('saved_and_hidden_items.turn_on_alert') }} </q-tooltip>
                                </q-toggle>
                              </div>
                              <div v-if="props.row.hasAlert == true">
                                <q-toggle
                                  v-model="props.row.hasAlert"
                                  icon="check"
                                  @click="
                                    updateAlert(
                                      props.row.id,
                                      props.row.folderId,
                                      false
                                    )
                                  "
                                >
                                  <q-tooltip> {{ t('saved_and_hidden_items.turn_on_alert') }} </q-tooltip>
                                </q-toggle>
                              </div>
                            </q-td>
                            <q-td
                              key="actions"
                              :props="props"
                              class="q-gutter-xs"
                            >
                              <q-btn
                                :aria-label="t('saved_and_hidden_items.delete_button')"
                                class="delete-button"
                                v-if="props.row.scheme != null"
                                @click="
                                  (confirmSchemeDelete = true),
                                    (schemeItemId = props.row.id),
                                    (folderId = props.row.folderId)
                                "
                                size="sm"
                                no-caps
                              >
                                <q-icon
                                  name="delete_outline"
                                  size="20px"
                                ></q-icon>
                                <q-tooltip>  {{ t('saved_and_hidden_items.to_delete') }}  </q-tooltip>
                              </q-btn>
                            </q-td>
                          </q-tr>
                        </template>
                      </q-table>
                    </div>
                    <div class="none-selected-folder" v-else>
                      {{ t('saved_and_hidden_items.there_are_no_saved_items') }} 
                  </div>
                  </div>
                  <div class="none-selected-folder" v-else>
                    {{ t('saved_and_hidden_items.there_are_no_saved_items') }} 
                  </div>
                </q-tab-panel>
              </div>
            </div>
          </div>
          <q-dialog v-model="showWarning" class="profile-popup">
            <q-card>
              <q-card-section class="row items-center">
                <!--   <i class="las la-2x la-exclamation-circle"></i> -->
                <q-icon
                  name="info"
                  color="indigo-10"
                  size="32px"
                  class="popup-icon"
                ></q-icon>
                <span class="q-ml-sm bold-font popup-text">
                  {{ t('search_schemes.select_at_least_one_item') }}
                </span>
              </q-card-section>

              <q-card-actions align="right">
                <q-btn
                  :label="t('common.ok')"
                  class="orange-btn bold-font q-mr-md q-mb-sm"
                  style="width: 60px"
                  v-close-popup
                />
              </q-card-actions>
            </q-card>
          </q-dialog>
          <q-dialog
            v-model="showRenameFolderDialog"
            persistent
            class="rename-folder-dialog"
          >
            <q-card class="edit-map-prompt" style="min-width: 350px">
              <q-card-section>
                <div class="row" style="color: #0a108f">
                  <i class="las la-2x la-pen la-fw"></i>
                  <span class="edit-folder-span"> {{ t('saved_and_hidden_items.rename_folder') }} </span>
                </div>
              </q-card-section>
              <q-separator />
              <q-card-section class="q-pt-none">
                <div class="row">
                  <q-input
                    class="q-pa-md q-ml-lg"
                    style="min-width: 300px"
                    v-model="pickedFolderName"
                    autogrow
                    :placeholder="pickedFolderName"
                    :rules="requiredFieldRule"
                    borderless
                  >
                  </q-input>
                </div>
              </q-card-section>
              <q-card-actions align="right">
                <q-btn
                  style="background: #ff8500; color: white"
                  no-caps
                  class="orange-btn-filled"
                  flat
                  :label="t('search_schemes.save')"
                  @click="renameFolder(pickedFolderId, pickedFolderName)"
                />
                <q-btn
                  class="orange-btn bg-gray-button"
                  flat
                  no-caps
                  :label="t('common.cancel')"
                  @click="disableRenameFolder()"
                />
              </q-card-actions>
            </q-card>
          </q-dialog>
          <q-dialog class="delete-card" v-model="confirm" persistent>
            <q-card class="delete-prompt">
              <q-card-section class="row items-center">
                <span class="q-ml-sm"
                  >{{ t('saved_and_hidden_items.remove_saved_search_confirmation') }}</span
                >
              </q-card-section>
              <q-card-actions align="right">
                <q-btn
                  :label="t('saved_and_hidden_items.to_delete')"
                  no-caps
                  color="white"
                  class="submit-btn-profile"
                  flat
                  v-close-popup
                  @click="removeSavedSearch(searchId, folderId)"
                />
                <q-btn
                  :label="t('common.cancel')"
                  v-close-popup
                  no-caps
                  flat
                  class="orange-btn bg-gray-button"
                />
              </q-card-actions>
            </q-card>
          </q-dialog>
          <q-dialog
            class="remove-saved-schemes"
            v-model="confirmSchemeDelete"
            persistent
          >
            <q-card style="background-color: #f1f1f1" class="delete-prompt">
              <q-card-section class="row items-center">
                <span v-if="isVind" class="q-ml-sm">
                  {{ labelRemoveFavoriteItem }}
                </span>
                <span v-else class="label-env">
                  {{ labelRemoveFavoriteItem }}
                </span>
              </q-card-section>

              <q-card-actions align="center">
                <q-btn
                  :label="labelDontRemoveFavoriteItem"
                  color="white"
                  class="submit-btn-profile"
                  v-close-popup
                  no-caps
                  flat
                />
                <q-btn
                  :label="deletePromptLabel"
                  flat
                  no-caps
                  class="orange-btn bg-gray-button"
                  v-close-popup
                  @click="removeSavedScheme(schemeItemId, folderId)"
                />
              </q-card-actions>
            </q-card>
          </q-dialog>
          <q-dialog
            class="delete-folder"
            v-model="confirmDeleteFolder"
            persistent
          >
            <q-card class="delete-prompt">
              <q-card-section class="row items-center">
                <span class="q-ml-sm">
                  {{ t('saved_and_hidden_items.delete_folder_confirmation') }}</span
                >
              </q-card-section>

              <q-card-actions align="right">
                <q-btn
                  class="orange-btn-filled"
                  :label="t('saved_and_hidden_items.to_delete')"
                  no-caps
                  v-close-popup
                  @click="removeFolder(pickedFolderId)"
                  flat
                />
                <q-btn
                  class="orange-btn bg-gray-button"
                  no-caps
                  :label="t('common.cancel')"
                  v-close-popup
                  flat
                />
              </q-card-actions>
            </q-card>
          </q-dialog>
          <q-dialog class="add-new-map" v-model="showAddNew" persistent>
            <q-card class="q-px-xl">
              <q-card-section class="q-pa-none q-mb-md">
                <div class="new-map-prompt q-mt-md">{{ t('save_scheme.create_new_folder') }}</div>
              </q-card-section>
              <q-card-section class="q-pa-none">
                <div class="row justify-around q-ml-lg">
                  <q-input
                    borderless
                    v-model="newFolderName"
                    stack-label
                    :placeholder="t('save_scheme.create_new_folder')"
                    :rules="requiredFieldRule"
                    style="width: 100%; margin-left: -19px"
                    dense
                  />
                </div>
              </q-card-section>

              <q-card-actions class="q-mb-md" style="margin-left: 30px">
                <q-btn
                  :label="t('search_schemes.save')"
                  @click="addNewFolder()"
                  class="orange-btn-filled bold-font"
                  no-caps
                  flat
                  style="width: 100px"
                />
                <q-btn
                  :label="t('common.cancel')"
                  flat
                  no-caps
                  @click="disableAddNew()"
                  class="orange-btn bold-font bg-gray-button"
                  style="width: 100px"
                />
              </q-card-actions>
            </q-card>
          </q-dialog>
        </template>
      </q-splitter>
      <q-layout class="showOnMobile">
        <q-drawer v-model="leftDrawerOpen" side="left">
          <span class="row mijnMappen">{{ t('saved_and_hidden_items.my_folders') }}</span>
          <div class="row">
            <!--  :style="{zIndex: leftDrawerOpen ? 1  : 'none' }"  -->
            <div style="min-height: 15vh; min-width:300px;" class="new-folder-container">
              <q-btn no-caps @click="enableAddNew()" class="q-mb-md newFolder">
                <q-icon name="create_new_folder"></q-icon>
                <!-- treba biti outline dodati s ajdinom-->
                <span style="font-weight: bold"
                  >&nbsp;&nbsp;&nbsp;{{ t('saved_and_hidden_items.new_folder') }}</span
                >
              </q-btn>
              <div class="q-pl-md folders-list">
                <div
                  class="q-mt-none row q-gutter-sm text-left folders-btns"
                  v-for="folder in foldersWithItems"
                  :key="folder.id"
                >
                  <div class="row q-ma-none list">
                    <a
                      class="selected-folder row"
                      v-if="folder.id == selectedFolderId"
                      @click="getItems(folder.id, folder.name)"
                    >
                      <q-icon
                        name="fa-solid fa-folder-open"
                        size="15px"
                      ></q-icon>

                      <div class="q-ma-none q-ml-xs overflow-folder-name">
                        {{ folder.name }}
                      </div>
                    </a>
                    <a
                      v-else
                      class="non-selected-folder row"
                      @click="getItems(folder.id, folder.name)"
                    >
                      <q-icon
                        name="fa-solid fa-folder-closed"
                        size="15px"
                      ></q-icon>

                      <div class="q-ma-none q-ml-xs overflow-folder-name">
                        {{ folder.name }}
                      </div>
                    </a>
                    <div class="float-right edit-map-prompt">
                      <q-icon
                        name="fa-solid fa-pen-to-square"
                        size="14px"
                        style="
                          color: #0a108f;
                          margin-top: -3px;
                          margin-right: 8px;
                        "
                        @click="enableRenameFolder(folder.id, folder.name)"
                      >
                        <q-tooltip> {{ t('saved_and_hidden_items.rename_folder') }} </q-tooltip>
                      </q-icon>
                      <q-icon
                        name="fa-solid fa-trash"
                        size="14px"
                        style="color: #0a108f; margin-bottom: 5px"
                        @click="
                          (confirmDeleteFolder = true),
                            (pickedFolderId = folder.id)
                        "
                      >
                        <q-tooltip> {{ t('saved_and_hidden_items.delete_folder') }} </q-tooltip>
                      </q-icon>
                    </div>
                  </div>
                </div>
                <!--             </q-scroll-area>-->
              </div>
            </div>
          </div>
          <div
            class="absolute drawer-close-container"
            style="top: 50%; right: -34px; z-index: 200"
          >
            <q-btn
              dense
              class="drawer-close-btn"
              round
              unelevated
              color="accent"
              icon="close"
              @click="() => (leftDrawerOpen = false)"
            ></q-btn>
          </div>

          <!-- drawer content -->
        </q-drawer>
      </q-layout>
    </div>
  </div>
</template>
<script>
import { ref, computed, onMounted } from 'vue';
import store from '../../store/index.js';
import UserService from '../../services/userprofile.service';
import SchemeUtils from '../../utils/schemes';
import FolderReport from '../../components/share-components/FolderReport.vue';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    FolderReport,
  },
  name: 'SavedItemsSchemes',
  setup() {
    const { t, locale } = useI18n();

    const savedSchemesColumnsPortals = [
      {
        name: 'name',
        align: 'left',
        label: 'Naam',
        field: 'name',
        sortable: true,
      },
      {
        name: 'status',
        align: 'left',
        label: 'Status',
        sortable: true,
        class: 'table-heading',
        style: 'padding: 0px',
        field: (row) => row.individualSchemeStatus,
      },

      { name: 'alert', label: 'Alert', align: 'center' },
      { name: 'actions', align: 'center', label: 'Verwijderen' },
    ];
    const savedSchemesColumns = ref([
      {
        name: 'name',
        align: 'left',
        label: computed(() => t('share_and_download_schemes.name')),
        field: 'name',
        sortable: true,
        class: 'table-heading',
      },
      {
        name: 'status',
        align: 'left',
        label: computed(() => t('search_schemes.status')),
        field: 'status',
        sortable: true,
        class: 'table-heading',
      },
      { name: 'alert', label: computed(() => t('saved_and_hidden_items.alert')), align: 'center' },
      { name: 'actions', align: 'center' },
    ]);

    const savedItemsTab = ref('schemes');
    const selectedForSharingScheme = ref([]);
    const show = ref(false);
    const leftDrawerOpen = ref(false);
    const filterSchemes = ref('');
    const filter = ref('');

    const confirm = ref(false);
    const confirmSchemeDelete = ref(false);
    const confirmDeleteFolder = ref(false);
    const schemeItemId = ref(null);
    const savedSchemesStatuses = ref([]);
    const shareSelectedSchemes = ref([]);
    const shareScheme = ref(null);
    const foldersWithItems = computed({
      get: () => store.state.user.foldersList,
    });

    const folderId = ref(null);
    const selectedFolderId = ref(null);
    var folderName = ref('');
    var newFolderName = ref('');

    const firstFolderId = computed({
      get: () => store.state.user.firstFolderId,
    });

    const isVind = computed({
      get: () => store.state.auth.env === 0 || store.state.auth.env === 40,
    });

    onMounted(async () => {
      await UserService.getFoldersWithItems();
      await UserService.getIndividualSchemeStatus();
      const firstFolderName = await UserService.getFirstFolderName(firstFolderId.value);
      getItems(firstFolderId.value, firstFolderName.data);
      portalLabelUpdates();
    });

    const individualSchemeStatuses = computed({
      get: () => store.state.user.individualSchemeStatuses,
    });

    const folderSchemesItems = computed({
      get: () => store.state.user.folderSchemes,
    });
    
    
    const filteredArray = computed({
      get: () => store.state.user.filteredArray,
    });

    const getItems = async (folderId, name) => {
      selectedFolderId.value = folderId;
      folderName.value = name;
      UserService.getFolderItems(folderId);
    };

    const labelRemoveFavoriteItem = ref('');
    const deletePromptLabel = ref('');
    const labelDontRemoveFavoriteItem = ref('');
    const labelSavedItems = ref('');
    const showWarning = ref(false);
    const shareForMobile = ref(false);

    const portalLabelUpdates = () => {
      if (isVind.value) {
        labelRemoveFavoriteItem.value = t('saved_and_hidden_items.vind_remove_item_from_saved_items_confirmation');
        deletePromptLabel.value = t('saved_and_hidden_items.to_delete');
        labelDontRemoveFavoriteItem.value = t('common.cancel');
        labelSavedItems.value = t('search_schemes.saved_items');
      } else {
        labelRemoveFavoriteItem.value =
          'Deze regeling verwijderen uit opgeslagen items?';
        deletePromptLabel.value = t('search_schemes.yes');
        labelDontRemoveFavoriteItem.value = t('search_schemes.no');
        labelSavedItems.value = 'Favorieten';
      }
    };

    const applyFilter = async (searchId) => {
      store.commit('advancedSearch/updateapplySavedSearchPause', true);
      UserService.loadFilter(searchId);
      UserService.setApplyFilter(false);
      await SchemeUtils.searchSchemes(locale);
      store.commit('advancedSearch/updateapplySavedSearchPause', false);
    };

    const removeSavedScheme = async (schemeItemId, folderId) => {
      await UserService.removeSavedScheme(schemeItemId);
      await UserService.getFolderItems(folderId);
    };

    const updateAlert = async (itemId, folderId, value) => {
      await UserService.updateFolderItemAlert(itemId, value);
      await UserService.getFolderItems(folderId);
    };

    const enableAddNew = () => {
      store.commit('user/enableAddNew');
    };

    const custid = computed({
      get: () => store.state.auth.custid,
    });

    const showAddNew = computed({
      get: () => store.state.user.showAddNew,
    });

    const addNewFolder = async () => {
      await UserService.addNewFolder(newFolderName.value);
      store.commit('user/disableAddNew');
      UserService.getFoldersWithItems();
      newFolderName.value = '';
    };

    const disableAddNew = () => {
      store.commit('user/disableAddNew');
      newFolderName.value = '';
    };
    const initialPagination = {
      sortBy: 'desc',
      descending: false,
      page: 1,
      rowsPerPage: 10,
    };

    const foldersLength = computed({
      get: () => store.state.user.foldersList.length,
    });

    const generateFolderReportPopup = (id) => {
      var folders = foldersWithItems.value;
      var folderName = '';
      for (var i = 0; i < folders.length; i++) {
        if (folders[i].id == id) {
          folderName = folders[i].name;
        }
      }
      var selectedFolder = {
        id: id,
        name: folderName,
      };
      store.commit('grantItems/enableDownloadFolderReport', selectedFolder);
    };

    // the name of the folder to be modified
    const pickedFolderName = computed({
      get: () => store.state.user.pickedFolderName,
      set: (value) => store.commit('user/updatePickedFolderName', value),
    });

    //the id of the folder to be modified
    const pickedFolderId = ref(null);

    const showRenameFolderDialog = computed({
      get: () => store.state.user.showRenameFolderDialog,
    });

    const enableRenameFolder = (folderId, folderName) => {
      pickedFolderId.value = folderId;
      store.commit('user/enableRenameFolderDialog', folderName);
    };

    const disableRenameFolder = () => {
      store.commit('user/disableRenameFolderDialog');
    };

    const renameFolder = async () => {
      await UserService.renameFolder(pickedFolderName, pickedFolderId);
      store.commit('user/disableRenameFolderDialog');
      UserService.getFoldersWithItems();
      folderName.value = pickedFolderName.value;
    };

    const removeFolder = async (folderId) => {
      await UserService.removeFolder(folderId);
      UserService.getFoldersWithItems();
    };

    const shareGrantMob = () => {
      if (shareScheme.value != null) {
        store.commit('grantItems/enableShareGrant', shareScheme.value.scheme);
      } else showWarning.value = true;
    };
    const shareGrant = () => {
      if (shareSelectedSchemes.value.length > 0) {
        store.commit(
          'grantItems/enableShareGrant',
          shareSelectedSchemes.value[0].scheme
        );
      } else showWarning.value = true;
    };

    const updateIndividualSchemeStatus = (id, individualSchemeStatus) => {
      UserService.updateIndividualStatus(id, individualSchemeStatus);
    };

    return {
      t,
      locale,
      shareScheme,
      shareForMobile,
      selectedForSharingScheme,
      individualSchemeStatuses,
      updateIndividualSchemeStatus,
      shareGrant,
      shareGrantMob,
      filteredArray,
      savedSchemesStatuses,
      portalLabelUpdates,
      show,
      shareSelectedSchemes,
      isVind,
      deletePromptLabel,
      labelRemoveFavoriteItem,
      labelSavedItems,
      labelDontRemoveFavoriteItem,
      removeFolder,
      confirmDeleteFolder,
      renameFolder,
      disableRenameFolder,
      showRenameFolderDialog,
      pickedFolderId,
      pickedFolderName,
      enableRenameFolder,
      firstFolderId,
      foldersLength,
      initialPagination,
      splitterModel: ref(15),
      savedItemsTab,
      savedSchemesColumns,
      foldersWithItems,
      folderName,
      folderId,
      getItems,
      folderSchemesItems,
      filterSchemes,
      filter,
      confirm,
      applyFilter,
      showWarning,
      selectedFolderId,
      confirmSchemeDelete,
      removeSavedScheme,
      schemeItemId,
      savedSchemesColumnsPortals,
      updateAlert,
      enableAddNew,
      showAddNew,
      newFolderName,
      disableAddNew,
      addNewFolder,
      generateFolderReportPopup,
      custid,
      leftDrawerOpen,

      barStyle: {
        right: '2px',
        borderRadius: '9px',
        backgroundColor: '#f0f2f5',
        width: '8px',
        opacity: 0.2,
      },
      thumbStyle: {
        right: '4px',
        borderRadius: '7px',
        width: '4px',
        opacity: 0.75,
      },

      requiredFieldRule: [
        (val) => (val && val.length > 0) || t('common.required_field'),
      ],
      toggleLeftDrawer() {
        leftDrawerOpen.value = !leftDrawerOpen.value;
      },

      schemesFilter(rows, terms) {
        // rows contain the entire data
        // terms contains whatever you have as filter
        const lowerFilter = terms ? terms.toLowerCase() : '';
        if (lowerFilter != '') {
          var filtered = rows.filter(
            (row) => row.scheme.name.toLowerCase().indexOf(lowerFilter) > -1
          );
          store.commit('user/updateFilteredArray', filtered);
          return filtered;
        } else {
          store.commit('user/updateFilteredArray', rows);
          return rows;
        }
      },
    };
  },
};
</script>
