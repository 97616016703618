const state = () => ({
  isExportModalOpen: true,
  customersList: [],
  selectedCustomer: null,
  selectedCustomerId: 0,
  daysRange: {
    from: '',
    to: '',
  },
  startDate: null,
  endDate: null,
  onzeCustomersList: [],
});

const mutations = {
  setExportModalState(state, val) {
    state.isExportModalOpen = val;
  },
  updateCustomersList(state, val) {
    state.customersList = val;
  },
  updateSelectedCustomer(state, val) {
    state.selectedCustomer = val;
    state.selectedCustomerId = val.id;
  },
  updateOnzeCustomers(state, val) {
    state.onzeCustomersList = [];
    state.onzeCustomersList = val;
  },
  updateDaysRange(state, val) {
    state.daysRange.from = val.from;
    state.daysRange.to = val.to;
    state.startDate = val.from;
    state.endDate = val.to;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
