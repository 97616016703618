<template>
  <NavMenu />
  <router-view :key="$route.path" />
  <Footer v-if="$route.path != '/onze-bevindingen/nieuwsbrief' && $route.path != '/nieuwsbericht'" />
</template>

<script>
import NavMenu from './components/NavMenu.vue';
import Footer from './views/Footer.vue';

export default {
  name: 'App',
  components: {
    NavMenu,
    Footer,
  }
};
</script>
