<template>
  <div class="error-page">
    <div class="error-heading bold-font">404</div>
    <span class="error-message"
      >Deze pagina bestaat niet. Ga terug naar
      <a href="https://www.vindsubsidies.nl/" target="_blank">
        vindsubsidies.nl
      </a>
    </span>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',
  setup() {
    return {};
  },
};
</script>
