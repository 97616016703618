import axios from 'axios';
import axiosUrl from './axios.service';
import authHeader from '../helpers/auth-header';
import store from '../store/index';
const API_URL = axiosUrl.getAxiosUrl();

class FiltersService {
  async getDefaultFilters(locale) {
    return await axios
      .get(API_URL + `/Tags/tags`, {
        headers: authHeader(),
        params: {
          Language: locale !== undefined ? locale : ''
        }
      })
      .then((response) => {
        store.commit('advancedSearch/updateFilterActivities', response.data);
        store.commit('advancedSearch/createLocationsOptions');
        store.commit('advancedSearch/createLocationsOptionsString');
        store.commit('grantItems/disableSideBarLoading');
        store.commit('news/disableIsNewsSideBarLoading');
        store.commit('portalsData/updateIsPortalsSideBarLoading', false);
      });
  }

  async getApplicationTerms() {
    return await axios
      .get(API_URL + `/Tags/getAllTermsPairs`, {
        headers: authHeader(),
      })
      .then((response) => {
        store.commit('advancedFilters/updateTerms', response.data);
      });
  }
}

export default new FiltersService();
