<template>
  <q-dialog v-model="isModalActive" persistent>
    <q-card class="news-dialog-card">
      <q-card-section>
        <div class="text-h6">{{ currentNewsItem.title }}</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <p class="card-heading">
          Introduction:
        </p>

        <div v-html="currentNewsItem.introduction"></div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <p class="card-heading">
          Body:
        </p>

        <div v-html="currentNewsItem.body"></div>
      </q-card-section>

      <q-card-actions align="right" class="bg-white text-teal">
        <q-btn flat label="OK" @click="disableModal()" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { computed } from 'vue';
import store from '../../store/index.js';

export default {
  setup() {
    const isModalActive = computed({
      get: () => store.state.salesForce.showNewsDialog,
    });

    const currentNewsItem = computed({
      get: () => store.state.salesForce.currentNewsItem,
    });

    const disableModal = () => {
      store.commit('salesForce/disableNewsModal');
    };

    return {
      currentNewsItem,
      isModalActive,
      disableModal,
    };
  },
};
</script>
