<template>
  <ErrorModal />
  <q-dialog v-model="show" persistent class="save-scheme-main">
    <q-card style="min-width: 350px" class="save-dialog">
      <q-card-section>
        <div style="color: #0a108f" class="text-main bold-font">
          {{ t('warning_dialog.profile_not_completed') }}
        </div>
      </q-card-section>

      <q-separator />

      <q-card-section class="q-pt-none q-pa-md">
        <div class="row justify-around">
          {{ t('warning_dialog.continue_without_a_profile') }}
        </div>
      </q-card-section>

      <q-separator />

      <q-card-actions class="bold-font q-ml-sm" align="center">
        <q-btn
          color="white"
          class="orange-btn-filled"
          flat
          :label="t('search_schemes.yes')"
          style="width: 90px"
          no-caps
          @click="goToNext()"
        />
        <q-btn
          flat
          class="orange-btn-cancel"
          :label="t('search_schemes.no')"
          no-caps
          style="width: 90px"
          @click="disableModal()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import store from '../../store/index';
import { useRouter } from 'vue-router';
import UserService from '../../services/userprofile.service';

export default {
  setup() {
    onMounted(() => {});

    const show = ref(true);
    const toPath = computed({
      get: () => store.state.user.toPath,
    });

    const disableModal = () => {
      router.push({
        name: 'UserProfile',
        params: {
          tab: 'newsletter',
        },
      });
      store.commit('user/updateShowLeavingProfileModal', false);
      show.value = false;
    };
    const router = useRouter();
    const toPathTab = computed({
      get: () => store.state.user.toPathTab,
    });
    const localStorageUser = JSON.parse(localStorage.getItem('user'));

    const goToNext = async () => {
      store.commit('user/updateShowLeavingProfileModal', false);
      store.commit('user/setHasConfirmedLeave', true);
      if (toPath.value != 'UserProfile')
        router.push({
          name: toPath.value,
        });
      else {
        router.push({
          name: toPath.value,
          params: {
            tab: toPathTab.value,
          },
        });
      }
      await UserService.completeOnboarding(localStorageUser.id);
    };

    return {
      localStorageUser,
      toPathTab,
      goToNext,
      router,
      toPath,
      disableModal,
      show,
    };
  },
};
</script>
