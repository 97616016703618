<template>
  <q-dialog v-model="isModalActive" persistent>
    <q-card class="news-dialog-card">
      <q-card-section class="row items-center">
        <div class="text-h6">{{ currentNewsItemPerCustomer.label }}</div>
        <q-space> </q-space>

        <q-btn icon="close" flat round dense @click="disableModal()" />
      </q-card-section>

      <q-card-section class="q-pt-none">
        <div v-html="currentNewsItemPerCustomer.introduction"></div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <div v-html="currentNewsItemPerCustomer.body"></div>
      </q-card-section>

      <q-card-actions align="right" class="bg-white text-teal">
        <q-btn flat label="OK" @click="disableModal()" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { computed } from 'vue';
import store from '../../store/index.js';

export default {
  setup() {
    const isModalActive = computed({
      get: () => store.state.salesForce.showNewsDialogPerCustomer,
    });

    const currentNewsItemPerCustomer = computed({
      get: () => store.state.salesForce.currentNewsItemPerCustomer,
    });

    const disableModal = () => {
      store.commit('salesForce/disableNewsModalPerCustomer');
    };

    return {
      currentNewsItemPerCustomer,
      isModalActive,
      disableModal,
    };
  },
};
</script>
