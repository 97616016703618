<template>
  <main class="main-tag">3
    <div class="login-page" id="login-page" style="display: none"
      :class="isVind ? 'login-page-default' : 'login-page-portals'">
      <ErrorModal v-if="hasModalError" />
        <q-form @submit="onSubmit" @reset="onReset" class="q-gutter-md login-form-center">
          
          <div class="q-mt-lg q-mb-lg text-center">
            <div class="q-mt-lg q-mb-lg text-center">
              <div class="q-mt-lg q-mb-lg text-center" style="padding-top: 60px;">
                <div @click="loginms" class="btn-ms-signin"></div>
              </div>
            </div>
          </div>
        </q-form>
    </div>
  </main>
</template>

<script>
import { computed, reactive, onMounted } from 'vue';
import store from '../store/index.js';
import { useRouter } from 'vue-router';
import ErrorModal from './ErrorModal.vue';
import authService from '../services/auth.service.js';
import newsService from '../services/news.service';
import moment from 'moment';
import UserService from '../services/userprofile.service';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    ErrorModal,
  },
  setup() {
    const user = reactive({
      email: null,
      password: null,
      right: false,
    });

    const { t } = useI18n();

    onMounted(async () => {
      authService.getEnvironment();
      await authService.getPortalInfo();
      await newsService.getNewsForLoginPage();
    });

    const newsList = computed({
      get: () => store.state.news.latestNewsLoginPage,
    });

    const router = useRouter();

    const isLoggedIn = computed({
      get: () => store.state.auth.isLoggedIn,
    });
    const hasModalError = computed({
      get: () => store.state.auth.hasModalError,
    });

    const hasComeFromNewsletter = computed({
      get: () => store.state.user.hasComeFromNewsletter,
    });

    const newsletterId = computed({
      get: () => store.state.user.newsLetterId,
    });

    const updateHasClickedOnNews = () => {
      store.commit('auth/updateHasClickedNewsOnLoginPage', true);
    };

    if (isLoggedIn.value == true) {
      const localStorageUser = JSON.parse(localStorage.getItem('user'));
      let token = JSON.parse(
        Buffer.from(localStorageUser.token.split('.')[1], 'base64')
      );

      if (token.env == null) token.env = 0;

      store.commit('auth/setEnvironment', Number.parseInt(token.env));
      store.commit('auth/setCustid', Number.parseInt(token.custid));
      if (hasComeFromNewsletter.value) {
        router.push({
          name: 'NewsDetails',
          params: { newsId: newsletterId.value },
        });
        store.commit('user/setHasComeFromNewsletter', false);
      } else {
        router.push({
          name: 'SearchGrants',
        });
      }
    }

    const loginms = () => {
      authService.loginMsOidc();
    }

    const onSubmit = () => {
      store
        .dispatch('auth/login', user)
        .then(() => {
          const localStorageUser = JSON.parse(localStorage.getItem('user'));
          let token = JSON.parse(
            Buffer.from(localStorageUser.token.split('.')[1], 'base64')
          );

          if (token.env == null) token.env = 0;

          store.commit('auth/setEnvironment', Number.parseInt(token.env));
          store.commit('auth/setCustid', Number.parseInt(token.custid));
          if (
            isVind.value &&
            !localStorageUser.hasCompletedProfileSetup
          ) {
            router.push({
              name: 'UserProfile',
              params: { tab: 'newsletter' },
            });
            store.commit('user/updateHasBeenRedirectedToProfile', true);
            store.commit('user/setHasConfirmedLeave', false);
          } else if (hasComeFromNewsletter.value) {
            router.push({
              name: 'NewsDetails',
              params: { newsId: newsletterId.value },
            });

            store.commit('user/setHasComeFromNewsletter', true);
          } else if (hasUserClickedOnNews.value) {
            router.push({
              name: 'News',
            });
          } else if (isVind.value) {
            router.push({
              name: 'SearchGrants',
            });
          } else if (!isVind.value) {
            router.push({
              name: 'PortalSearchGrants',
            });
            UserService.getFolders();
          }

          if (Number.parseInt(token.custid) != 2) // if customer is not Vind, check language
            authService.getAppropriateLanguageBasedBaseUrl(localStorageUser.id);
        })
        .catch((error) => {
          if (error)
            if (error.response.data.errors != null) {
              store.commit(
                'auth/updateModalError',
                error.response.data.errors.Email[0]
              );
            } else {
              store.commit('auth/updateModalError', error.response.data);
            }
          store.commit('auth/enableModalError');
        });
    };

    const hasUserClickedOnNews = computed({
      get: () => store.state.auth.hasClickedNewsOnLoginPage,
    });

    const formatDate = (stringDate) => {
      const dateN = new Date(stringDate);
      return moment(dateN).format('DD-MM-YYYY');
    };

    const isVind = computed({
      get: () => store.state.auth.env === 0 || store.state.auth.env === 40,
    });

    const customerPortalSignatureName = computed({
      get: () => store.state.auth.customer.customerPortalSignatureName,
    });

    return {
      t,
      customerPortalSignatureName,
      formatDate,
      hasUserClickedOnNews,
      updateHasClickedOnNews,
      newsList,
      newsletterId,
      hasComeFromNewsletter,
      onSubmit,
      router,
      isLoggedIn,
      hasModalError,
      user,
      isVind,
      loginms,
      isValidEmail(val) {
        const emailPattern =
          /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
        return emailPattern.test(val) || t('common.invalid_email_address');
      },
      onReset() {
        user.email.value = null;
        user.password.value = null;
      },
    };
  },
};
</script>


<style>
.tb-login .q-field__control {
  height: 40px;
}

.btn-ms-signin {
  background-image: url(https://content.vindsubsidies.nl/css/img/ms-login.svg);
  border: 0;
  background-color: transparent;
  background-size: 100% 100%;
  border: 0;
  width: 200px;
  height: 40px;
  padding: 10px;
  cursor: pointer;
  margin: auto;
}
</style>