<template>
  <div class="news-sidebar" :class="!isVind ? 'portalnews-sidebar' : ''">
    <div class="sidebar-items">
      <div class="filter-results-title">
        <q-icon size="24px" class="filter-icon">
          <img
            class="svg-icon"
            src="../../assets/icons/filter-list-solid.svg"
          />
        </q-icon>
        <span class="title bold-font"> {{ t('search_schemes.filter_results') }} </span>
      </div>
      <div class="row results-info-row item-width">
        <p class="results-info-p">
          <span class="results-count">
            {{ resultCount }}
          </span>
          <span class="text-results"> {{ ' ' + t('search_schemes.results') }}</span>
        </p>
      </div>

      <q-list class="filters-list">
        <q-expansion-item
          :label="t('search_news.publication_date')"
          dense
          dense-toggle
          expand-icon="chevron_right"
        >
          <div class="row justify-end">
            <i
              class="las la-2x la-times-circle clear-filter-icon"
              @click="resetDate()"
            >
              <q-tooltip> {{ t('search_schemes.reset_filter') }} </q-tooltip></i
            >
          </div>
          <div class="sidebar-margin dateselect-box no-margin-left">
            <q-input
              class="budget-input"
              bg-color="white"
              v-model="daysRangeModel"
              borderless
              dense
              @click="$refs.qDateProxy.show()"
              :update="updateRange()"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer datepicker-icon">
                  <q-popup-proxy
                    ref="qDateProxy"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      range
                      v-model="days"
                      mask="DD-MM-YYYY"
                      :locale="myLocale"
                    >
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          :label="t('search_schemes.close')"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>
        </q-expansion-item>

        <q-expansion-item
          :label="t('search_schemes.audience_tags_label')"
          dense
          dense-toggle
          expand-icon="chevron_right"
          v-if="isVind"
        >
          <default-tree
            class="item-width white-bg-box news-search"
            :nodesState="audienceNodes.options"
            :heading="audienceNodes.label"
            :filtering="true"
            :news="'news'"
            :key="'1'"
            ref="audienceTree"
          />
        </q-expansion-item>

        <q-expansion-item
          :label="t('search_schemes.purposes_tags_label')"
          dense
          dense-toggle
          expand-icon="chevron_right"
          v-if="isVind"
        >
          <default-tree
            class="item-width white-bg-box"
            :nodesState="purposesNodes.options"
            :heading="purposesNodes.label"
            :news="'news'"
            :filtering="true"
            :key="'3'"
            ref="purposesTree"
          />
        </q-expansion-item>

        <q-expansion-item
          :label="t('search_schemes.location_tags_label')"
          dense
          dense-toggle
          v-if="!hideLocations && isVind"
          expand-icon="chevron_right"
        >
          <div class="row start dateselect-box no-margin-left">
            <q-select
              dense
              borderless
              bg-color="white"
              v-model="selectedLocations"
              multiple
              :options="filterLocationsTags"
              option-value="label"
              class="item-width white-bg-box"
              option-label="label"
              :placeholder="t('profile.search_for_location_municipality_or_province')"
              use-input
              input-debounce="0"
              behavior="menu"
              use-chips
              @filter="filterLocationsFn"
              clearable
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">
                    {{ t('profile.no_results') }}
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
        </q-expansion-item>

        <q-expansion-item
          :label="t('share_and_download_schemes.type_of_report')"
          dense
          dense-toggle
          expand-icon="chevron_right"
          v-if="isVind"
        >
          <default-tree
            class="item-width white-bg-box"
            :nodesState="schemeTypesNodes.options"
            :heading="schemeTypesNodes.label"
            :news="'news'"
            :key="'4'"
            :filtering="true"
            ref="schemeTypesTree"
          />
        </q-expansion-item>

        <q-expansion-item
          :label="t('search_schemes.organizations')"
          dense
          dense-toggle
          expand-icon="chevron_right"
          v-if="isVind"
        >
          <div class="row start dateselect-box no-margin-left">
            <q-select
              dense
              borderless
              bg-color="white"
              v-model="organisationsInclude"
              multiple
              :options="filterOrganisationsInclude"
              use-chips
              option-value="name"
              class="item-width white-bg-box"
              option-label="name"
              :label="t('search_schemes.search_only')"
              use-input
              input-debounce="0"
              behavior="menu"
              @filter="filterOrganisationsIncludeFn"
              clearable
            />
          </div>

          <div class="row start dateselect-box no-margin-left">
            <q-select
              dense
              borderless
              bg-color="white"
              v-model="organisationsExclude"
              multiple
              :options="filterOrganisationsExclude"
              use-chips
              option-value="name"
              class="item-width white-bg-box"
              option-label="name"
              :label="t('search_schemes.do_not_search')"
              use-input
              input-debounce="0"
              behavior="menu"
              @filter="filterOrganisationsExcludeFn"
              clearable
            />
          </div>
        </q-expansion-item>
      </q-list>
      <!-- This is just hidden for now, don't remove it: -->
      <!-- <div class="filter-box">
      <default-tree
        class="sidebar-margin"
        :nodesState="industriesNodes.options"
        :heading="industriesNodes.label"
        :news="'news'"
        :key="'2'"
      />
    </div> -->
    </div>
  </div>
</template>

<script>
import store from '../../store/index';
import { computed, onMounted, ref, watch } from 'vue';
import DefaultTree from '../search-components/DefaultTree.vue';
import OrganisationsService from '../../services/organisations.service';
import NewsUtils from '../../utils/news';
import FiltersService from '../../services/filters.service';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    DefaultTree,
  },
  setup() {
    const { t, locale } = useI18n();
    const audienceTree = ref(null);
    const purposesTree = ref(null);
    const schemeTypesTree = ref(null);
    const resultCount = computed({
      get: () => store.state.newsSearch.resultsCount,
    });

    const isApplyingFilters = computed({
      get: () => store.state.user.isApplyingFilters,
    });

    const selectedAudiencesGrants = computed({
      get: () =>
        store.state.advancedSearch.selectedAudiences != ''
          ? store.state.advancedSearch.selectedAudiences
          : sessionStorage.getItem('selectedAudiences'),
    });
    const selectedLocationsGrants = computed({
      get: () =>
        store.state.advancedSearch.locationsSelectArray &&
        store.state.advancedSearch.locationsSelectArray.length != 0
          ? store.state.advancedSearch.locationsSelectArray
          : JSON.parse(sessionStorage.getItem('locationsSelectArray')),
    });
    const selectedPurposesGrants = computed({
      get: () =>
        store.state.advancedSearch.selectedPurposes != ''
          ? store.state.advancedSearch.selectedPurposes
          : sessionStorage.getItem('selectedPurposes'),
    });
    const selectedSchemeTypesGrants = computed({
      get: () =>
        store.state.advancedSearch.selectedSchemeTypes != ''
          ? store.state.advancedSearch.selectedSchemeTypes
          : sessionStorage.getItem('selectedSchemeTypes'),
    });

    onMounted(async () => {
      await FiltersService.getDefaultFilters(locale.value);
      OrganisationsService.getAllOrganisationsWithBasicInfo();

      if (isApplyingFilters.value) {
        store.commit(
          'newsSearch/updateSelectedAudiences',
          selectedAudiencesGrants.value
        );
        store.commit(
          'newsSearch/updateSelectedPurposes',
          selectedPurposesGrants.value
        );
        store.commit(
          'newsSearch/updateSelectedLocations',
          selectedLocationsGrants.value
        );
        store.commit(
          'newsSearch/updateSelectedSchemeTypes',
          selectedSchemeTypesGrants.value
        );
        refreshTrees();
      }
    });

    const refreshTrees = async () => {
      audienceTree.value.refreshTree(selectedAudiencesGrants.value);
      purposesTree.value.refreshTree(selectedPurposesGrants.value);
      schemeTypesTree.value.refreshTree(selectedSchemeTypesGrants.value);
    };
    const audienceNodes = computed({
      get: () => store.state.advancedSearch.audience,
    });

    //This is just hidden for now, don't remove it:
    /*
    const industriesNodes = computed({
      get: () => store.state.advancedSearch.industries,
    });
    */

    const locationsNodes = computed({
      get: () => store.state.advancedSearch.locations,
    });
    const purposesNodes = computed({
      get: () => store.state.advancedSearch.purposes,
    });

    const schemeTypesNodes = computed({
      get: () => store.state.advancedSearch.schemeTypes,
    });

    const projectTypesNodes = computed({
      get: () => store.state.advancedSearch.projectTypes,
    });

    const allOrganisations = computed({
      get: () => store.state.organisations.organisationsWithBasicInfo,
    });

    const organisationsInclude = computed({
      get: () =>
        store.state.newsSearch.organisationsInclude &&
        store.state.newsSearch.organisationsInclude.length > 0
          ? store.state.newsSearch.organisationsInclude
          : JSON.parse(sessionStorage.getItem('organisationsInclude')),
      set: (val) => {
        store.commit('newsSearch/updateOrganisationsInclude', val);
        store.commit('advancedSearch/enableFiltersChange');
        store.commit('advancedSearch/updateOrganisationsInclude', val);
      },
    });

    const organisationsExclude = computed({
      get: () =>
        store.state.newsSearch.organisationsExclude &&
        store.state.newsSearch.organisationsExclude.length > 0
          ? store.state.newsSearch.organisationsExclude
          : JSON.parse(sessionStorage.getItem('organisationsExclude')),
      set: (val) => {
        store.commit('newsSearch/updateOrganisationsExclude', val);
        store.commit('advancedSearch/enableFiltersChange');
        store.commit('advancedSearch/updateOrganisationsExclude', val);
      },
    });

    const selectedLocations = computed({
      get: () =>
        store.state.newsSearch.locationsSelectArray &&
        store.state.newsSearch.locationsSelectArray.length > 0
          ? store.state.newsSearch.locationsSelectArray
          : JSON.parse(sessionStorage.getItem('locationsSelectArray')),
      set: (value) => {
        store.commit('newsSearch/updateSelectedLocations', value);
        store.commit('advancedSearch/enableFiltersChange');
        store.commit('advancedSearch/updateSelectedLocations', value);
      },
    });

    const locationsOptions = computed({
      get: () => store.state.advancedSearch.locationsOptions,
    });

    const filterLocationsTags = ref(locationsOptions.value);
    const filterOrganisationsInclude = ref(allOrganisations.value);
    const filterOrganisationsExclude = ref(allOrganisations.value);

    const days = computed({
      get: () =>
        store.state.newsSearch.daysRange != null &&
        store.state.newsSearch.daysRange.from != '' &&
        store.state.newsSearch.daysRange.to != ''
          ? store.state.newsSearch.daysRange
          : JSON.parse(sessionStorage.getItem('newsDaysRange')),
      set: (val) => {
        store.commit('newsSearch/updateDaysRange', val);
      },
    });

    watch(selectedLocations, () => {
      if (selectedLocations.value == null) {
        store.commit('newsSearch/updateSelectedLocations', null);
      }
      NewsUtils.searchNews(locale, isTableView.value);
    });

    watch(organisationsExclude, () => {
      if (organisationsExclude.value == null) {
        store.commit('newsSearch/updateOrganisationsExclude', []);
      }
      NewsUtils.searchNews(locale,isTableView.value);
    });

    watch(organisationsInclude, () => {
      if (organisationsInclude.value == null) {
        store.commit('newsSearch/updateOrganisationsInclude', []);
      }
      NewsUtils.searchNews(locale, isTableView.value);
    });

    const resetDate = () => {
      store.commit('newsSearch/updateDaysRange', { from: '', to: '' });
      NewsUtils.searchNews(locale, isTableView.value);
    };

    const dateFrom = computed({
      get: () =>
        store.state.newsSearch.daysRange.from != ''
          ? store.state.newsSearch.daysRange.from
          : JSON.parse(sessionStorage.getItem('newsDaysRange')) != null
          ? JSON.parse(sessionStorage.getItem('newsDaysRange')).from
          : '',
    });

    const dateTo = computed({
      get: () =>
        store.state.newsSearch.daysRange.to != ''
          ? store.state.newsSearch.daysRange.to
          : JSON.parse(sessionStorage.getItem('newsDaysRange')) != null
          ? JSON.parse(sessionStorage.getItem('newsDaysRange')).to
          : '',
    });

    watch(dateFrom, () => {
      NewsUtils.searchNews(locale, isTableView.value);
    });

    const daysRangeModel = ref(null);

    const updateRange = () => {
      daysRangeModel.value = [dateFrom.value, dateTo.value].join(' - ');
    };

    const isTableView = computed({
      get: () => store.state.newsSearch.isTableView,
    });

    const hideLocations =
      store.state.advancedSearch.locationsOptions.length < 4;

    const isVind = computed({
      get: () => store.state.auth.env === 0 || store.state.auth.env === 40,
    });

    return {
      t,
      locale,
      isVind,
      selectedAudiencesGrants,
      selectedLocationsGrants,
      selectedPurposesGrants,
      selectedSchemeTypesGrants,
      isApplyingFilters,
      audienceTree,
      purposesTree,
      schemeTypesTree,
      hideLocations,
      isTableView,
      daysRangeModel,
      updateRange,
      resetDate,
      days,
      filterOrganisationsExclude,
      filterOrganisationsInclude,
      locationsOptions,
      filterLocationsTags,
      dateFrom,
      dateTo,
      allOrganisations,
      organisationsInclude,
      organisationsExclude,
      audienceNodes,
      //This is just hidden for now, don't remove it:
      //industriesNodes,
      locationsNodes,
      purposesNodes,
      resultCount,
      schemeTypesNodes,
      projectTypesNodes,
      selectedLocations,
      filterLocationsFn(val, update) {
        update(() => {
          if (val === '') {
            filterLocationsTags.value = locationsOptions.value;
          } else {
            const needle = val.toLowerCase();

            filterLocationsTags.value = locationsOptions.value.filter(
              (v) => v.label.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
      filterOrganisationsIncludeFn(val, update) {
        update(() => {
          if (val === '') {
            filterOrganisationsInclude.value = allOrganisations.value;
          } else {
            const needle = val.toLowerCase();

            filterOrganisationsInclude.value = allOrganisations.value.filter(
              (v) => v.name.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },

      filterOrganisationsExcludeFn(val, update) {
        update(() => {
          if (val === '') {
            filterOrganisationsExclude.value = allOrganisations.value;
          } else {
            const needle = val.toLowerCase();

            filterOrganisationsExclude.value = allOrganisations.value.filter(
              (v) => v.name.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },

      myLocale: {
        pluralDay: 'dagen',
        months:
          'Januari_Februari_Maart_April_Mei_Juni_Juli_Augustus_September_Oktober_November_December'.split(
            '_'
          ),
        daysShort: 'ma_di_wo_do_vr_za_zo'.split('_'),
      },
    };
  },
};
</script>
