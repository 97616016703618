<template>
    <ErrorModal />
    <q-dialog v-model="show" persistent class="save-scheme-main">
      <q-card style="width: 350px" class="save-dialog-scheme">
        <q-form @submit="onSubmit">
          <q-card-section>

            <p class="q-mt-md">Stichting Kenniscentrum Sport vindt het belangrijk om te weten vanuit welke rol u zoekt naar subsidies.
                 Hiermee kunnen we het subsidieloket nog beter maken</p>
          </q-card-section>
  
          <q-separator />
          <q-card-section class="q-pt-none q-pa-md">
            <div class="column q-mb-md check-organisation">
              <q-select
                borderless
                bg-color="white"
                v-model="professionType"
                :options="professionTypes"
                option-label="name"
                label="Wie ben je?"
                style="min-width: 263px"
                lazy-rules
                :rules="requiredFieldRule"
                dense
              >
              </q-select>
            </div>
          </q-card-section> 
          <q-separator />
  
          <q-card-actions class="bold-font q-ml-sm" align="center">
            <q-btn
              color="white"
              class="orange-btn-filled"
              flat
              :label="t('common.ok')"
              style="width: 90px"
              no-caps
              type="submit"
            />
          </q-card-actions>
        </q-form>
      </q-card>
    </q-dialog>
  </template>
  
  <script>
  import { ref, onMounted, computed } from "vue";
  import store from "../../store/index";
  import authService from "../../services/auth.service";
  import UserService from "../../services/userprofile.service";
  import { useI18n } from 'vue-i18n';
  
  export default {
    setup() {
      const show = ref(false);
  
      onMounted(async () => {
        authService.getProfessionTypes();
        await UserService.getUser();  
        if(!isProfessionSelected.value) show.value = true;
        else show.value = false;     
      });
  
    const professionTypes = computed({
      get: () => store.state.auth.professionTypes,
    });

    const isProfessionSelected = computed({
        get: () => store.state.user.isProfessionSelected,
    });

    const professionType = computed({
      get: () => store.state.user.usersProfession,
      set: (val) => store.commit("user/updateProfessionType", val),
    });

      const isLoggedIn = computed({
        get: () => store.state.auth.isLoggedIn,
      });
  
      const onSubmit = async () => {
       UserService.updateProfessionType(professionType.value);
       show.value = false;
      };
  
      const { t } = useI18n();

      return {
        requiredFieldRule: [
          (val) => (val && val.length > 0) || t('common.required_field'),
        ],
        isLoggedIn,
        onSubmit,
        show,
        professionTypes,
        professionType,
        isProfessionSelected,
      };
    },
  };
  </script>
  