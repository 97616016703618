import axios from 'axios';
import axiosUrl from './axios.service';
import authHeader from '../helpers/auth-header';
import store from '../store/index';
const API_URL = axiosUrl.getAxiosUrl();

class SuggestionsService {
  async recordSuggestion(name, email, organisation) {
    return (await axios.post(
      API_URL + 'sharedItemFieldSuggestion/recordShareItemSuggestion'
    ),
    {
      name: name.value,
      email: email.value,
      organisation: organisation.value,
    },
    {
      headers: authHeader(),
    });
  }

  async getSuggestions(name /* , userId */) {
    return await axios
      .get(API_URL + '/sharedItemFieldSuggestion/getSuggestions', {
        headers: authHeader(),
        params: {
          name: name,
    
        },
      })

      .then((response) => {
        store.commit(
          'suggestions/updateSuggestions',
          response.data.suggestions
        );
      });
  }
}

export default new SuggestionsService();
