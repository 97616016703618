<template>
  <main class="main-tag">
    <div class="login-page login-page-default row" id="login-page">

      <div class="col-md-4 offset-md-4 col-sm-8 offset-sm-2 text-left pt-5">
        <h4 class="welcome-heading-text q-mb-sm ptl">
          Een ogenblik. Je wordt ingelogged.
        </h4>
        
      </div>
    </div>
  </main>
</template>

<script>
import { computed, onMounted } from 'vue';
import store from '../store/index.js';
import { useRouter } from 'vue-router'
import axios from 'axios';
// import authService from '../services/auth.service.js';
import axiosUrl from '../services/axios.service';

const API_URL = axiosUrl.getAxiosUrl();

export default {
  name: 'OidcAuth',
  setup() {
    const router = useRouter();
    const isLoggedIn = computed({
      get: () => store.state.auth.isLoggedIn,
    });

    if (isLoggedIn.value == true) {
      router.push({
        name: 'SearchGrants',
      });
    }
    const urlParams = new URLSearchParams(window.location.search);

    const hasComeFromNewsletter = computed({
      get: () => store.state.user.hasComeFromNewsletter,
    });

    const newsletterId = computed({
      get: () => store.state.user.newsLetterId,
    });

    const hasUserClickedOnNews = computed({
      get: () => store.state.auth.hasClickedNewsOnLoginPage,
    });
    onMounted(async () => {
      await axios
        .post(API_URL + '/users/authenticate-oidc', {
          username: urlParams.get('username'),
          code: urlParams.get('code'),
          sid: urlParams.get('sid')
        })
        .then(response => {
          if (response.data.token) {
            var token = JSON.stringify(response.data);
            localStorage.setItem('user', token);
            store.state.auth.isLoggedIn = true;
            store.state.auth.user = response.data;
            
            router.replace({ path: '/onze-bevindingen' });
            return;
            // if (!response.data.hasCompletedProfileSetup) {
            //   router.push({
            //     name: 'UserProfile',
            //     params: { tab: 'newsletter' },
            //   });
            //   store.commit('user/updateHasBeenRedirectedToProfile', true);
            //   store.commit('user/setHasConfirmedLeave', false);
            // } else if (hasComeFromNewsletter.value) {
            //   router.push({
            //     name: 'NewsDetails',
            //     params: { newsId: newsletterId.value },
            //   });

            //   store.commit('user/setHasComeFromNewsletter', true);
            // } else if (hasUserClickedOnNews.value) {
            //   router.push({
            //     name: 'News',
            //   });
            // } else  {
            //   router.push({
            //     name: 'SearchGrants',
            //   });
            // }
            // if (Number.parseInt(token.custid) != 2) // if customer is not Vind, check language
            //   authService.getAppropriateLanguageBasedBaseUrl(token.id);
          }
        })
        .catch(() => {
          router.replace({ path: '/geen-account' });
          return;
        });   
    });

    return {
      router,
      isLoggedIn,
      hasComeFromNewsletter,
      newsletterId,
      hasUserClickedOnNews
    };
  },
};
</script>
<style>
.ptl{
  padding-top:100px;
}
</style>