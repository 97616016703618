<template>
  <div class="rating-page">
    <ErrorModal />
    <div id="q-app" style="min-height: 75vh">
      <div class="q-pa-md">
        <div class="column" style="margin-top: 120px;">
          <p class="title">Leuk dat je een collega wilt aanmelden voor <a href="https://www.vindsubsidies.nl/">Vindsubsidies.nl.</a></p>
          <p class="title">Voer hieronder de gegevens van je collega in en je collega ontvangt binnen enkele minuten een uitnodiging om een account aan te maken.</p>
          <div class="inputs">
            <div class="col-md-6">
            <q-select
              outlined
              v-model="register.salutation"
              label="Aanhef"
              :options="salutationOptions"
              stack-label
              :rules="requiredFieldRule"
            />        
          </div>
            <q-input
              dense
              bg-color="white"
              outlined
              v-model="register.firstName"
              label="Voornaam"
              style="width: 263px"
              lazy-rules
              :rules="requiredFieldRule"
            />
            <q-input
              dense
              outlined
              bg-color="white"
              v-model="register.lastName"
              label="Achternaam"
              style="width: 263px"
              lazy-rules
              :rules="requiredFieldRule"
            />
            <q-input
              dense
              bg-color="white"
              outlined
              v-model="register.email"
              label="E-mailadres"
              lazy-rules
              :rules="[
                (val) => !!val || 'Vul een correct emailadres in',
                isValidEmail,
              ]"
              style="width: 263px"
            />
          </div>
          <q-btn
            flat
            class="orange-btn-filled submitBtn"
            color="white"
            :label="t('account_management.send')"
            @click="submitResponse()"
            no-caps
          />
        </div>
      </div>
    </div>
  </div>
  <q-dialog v-model="showInfoDialog" class="info-dialog">
    <q-card>
      <q-card-section>
        <h3 class="title">{{ t('account_management.recommendation_thanks') }}</h3>
      </q-card-section>
      <q-card-actions align="center">
        <q-btn
          flat
          class="orange-btn-filled"
          color="white"
          :label="t('common.ok')"
          no-caps
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
import { ref, onMounted, reactive } from "vue";
import { useRoute } from "vue-router";
import SurveyAnswersService from "../../services/surveyAnswers.service";
import ErrorModal from "../../components/userProfile-components/ErrorModal.vue";
import { useI18n } from 'vue-i18n';

export default {
  name: "NewUserSuggestionPage",
  components: {
    ErrorModal,
  },
  setup() {
    const route = useRoute();
    const token = ref("");
    const isSuccess = ref(false);
    const showInfoDialog = ref(false);
    const register = reactive({
      firstName: "",
      lastName: "",
      email: "",
      salutation: "",
    });
    onMounted(async () => {
      token.value = route.params.token;

    });

    const submitResponse = async () => {
        var s = await SurveyAnswersService.registerFromSurvey(token,register);
          if (s) {
            isSuccess.value = true;
            showInfoDialog.value = true;
          }
 
    };

    const { t } = useI18n();

    return {
      t,
      token,
      isSuccess,
      route,
      showInfoDialog,
      submitResponse,
      register,
      requiredFieldRule: [
        (val) => (val && val.length > 0) || t('common.required_field'),
      ],
      isValidEmail(val) {
        const emailPattern =
          /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
        return emailPattern.test(val) || "Ongeldig e-mailadres";
      },
      salutationOptions: [
        "Heer", 
        "Mevrouw",
        "Wil ik niet zeggen",
      ],
    };
  },
};
</script>
