<template>
  <div class="portal-scheme-card">
    <div class="row justify-between no-wrap">
      <router-link
        style="text-decoration: none"
        :to="{
          name: 'PortalSchemeDetails',
          params: { grantId: id },
          query: { t: searchText },
        }"
        class="title-router-link"
        target="_blank"
      >
        <span class="bold-font scheme-name row" v-html="title"></span>
      </router-link>
      <q-btn
        dense
        no-caps
        class="orange-btn-filled bold-font"
        color="white"
        flat
        @click="enableSaveScheme(id)"
        style="min-width: 12%; height: 36px"
      >
        <q-icon
          left="true"
          name="fa-solid fa-floppy-disk"
          class="q-mr-sm"
          size="18px"
        ></q-icon>
        Opslaan</q-btn
      >
    </div>
    <div class="row icons-row justify-between q-mt-md">
      <div class="icons-row-item column">
        <div class="item-title bold-font">
          <q-icon size="12px" class="heading-icon">
            <img
              class="svg-icon-portalSchemeCard"
              src="../../assets/icons/calendar-days-solid.svg"
            />
          </q-icon>
          <span class="status-span" style="vertical-align: text-top">
            Status
          </span>
        </div>
        <div class="item-value">{{ grant.status }}</div>
      </div>

      <div class="icons-row-item column">
        <div class="item-title bold-font">
          <q-icon size="12px" class="heading-icon">
            <img
              class="svg-icon-portalSchemeCard"
              src="../../assets/icons/coin-solid.svg"
            />
          </q-icon>
          <span class="status-span" style="vertical-align: text-top">
            Bijdrage
          </span>
        </div>
        <div v-if="grant.maxAmount && grant.maxAmount > 0" class="item-value">
          {{ formatEuro(grant.maxAmount) }}
        </div>
        <div v-else class="item-value">Onbepaald</div>
      </div>

      <div class="icons-row-item column">
        <div class="item-title bold-font">
          <q-icon size="12px" class="heading-icon">
            <img
              class="svg-icon-portalSchemeCard"
              src="../../assets/icons/clock-solid.svg"
            />
          </q-icon>
          <span class="status-span" style="vertical-align: text-top">
            Looptijd
          </span>
        </div>
        <div class="item-value">{{ grant.duration }}</div>
      </div>

      <div class="icons-row-item column">
        <div class="item-title bold-font">
          <q-icon size="12px" class="heading-icon">
            <img
              class="svg-icon-portalSchemeCard"
              src="../../assets/icons/gauge-min-solid.svg"
            />
          </q-icon>
          <span class="status-span" style="vertical-align: text-top">
            Moeilijkheid
          </span>
        </div>
        <div class="item-value">
          {{ getComplexityRatingLabel(grant.complexityRating) }}
        </div>
      </div>

      <div class="icons-row-item column">
        <div class="item-title bold-font">
          <q-icon size="12px" class="heading-icon">
            <img
              class="svg-icon-portalSchemeCard"
              src="../../assets/icons/circle-check-solid.svg"
            />
          </q-icon>
          <span class="status-span" style="vertical-align: text-top">
            Slaagkans
          </span>
        </div>
        <div class="item-value">
          {{ getChanceOfSuccessRatingLabel(grant.chanceOfSuccessRating) }}
        </div>
      </div>

      <div class="icons-row-item column">
        <div class="item-title bold-font">
          <q-icon size="12px" class="heading-icon">
            <img
              class="svg-icon-portalSchemeCard"
              src="../../assets/icons/timer-solid.svg"
            />
          </q-icon>
          <span class="status-span" style="vertical-align: text-top">
            Tijdsinvestering
          </span>
        </div>
        <div class="item-value">
          {{ getTimeForApplicationRatingLabel(grant.timeForApplicationRating) }}
        </div>
      </div>
    </div>

    <div class="intro q-mt-md row" v-html="introduction"></div>

    <div class="viewmore-section q-mt-md row">
      <router-link
        style="text-decoration: none"
        :to="{
          name: 'GrantDetails',
          params: { grantId: id },
          query: { t: searchText },
        }"
        class="title-router-link"
      >
        <span class="viewmore-link"
          >Bekijk subsidie
          <q-icon size="12px" class="chevron-icon">
            <img
              class="svg-icon-portalSchemeCard"
              src="../../assets/icons/chevron-right-solid.svg"
            />
          </q-icon>
        </span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import store from '../../store/index';
import grantsService from '../../services/grants.service';

export default {
  props: {
    title: String,
    introduction: String,
    id: String,
    grant: Object,
  },

  setup(props) {
    const schemeId = ref(props.id);

    const searchText = computed({
      get: () => store.state.advancedSearch.searchText,
    });

    const enableSaveScheme = (id) => {
      store.commit('user/enableSavePortalsScheme');
      store.commit('grantItems/addSelected', id);
      grantsService.getSchemeById(id);
    };

    return {
      searchText,
      schemeId,
      enableSaveScheme,

      getTimeForApplicationRatingLabel(rating) {
        switch (rating) {
          case 5:
            return 'Heel lang';
          case 4:
            return 'Langzaam';
          case 3:
            return 'Gemiddeld';
          case 2:
            return 'Snel';
          case 1:
            return 'Heel snel';
          default:
            return 'Tijdsduur';
        }
      },
      getChanceOfSuccessRatingLabel(rating) {
        switch (rating) {
          case 1:
            return 'Heel klein';
          case 2:
            return 'Klein';
          case 3:
            return 'Gemiddeld';
          case 4:
            return 'Groot';
          case 5:
            return 'Heel groot';
          default:
            return 'Slaagkans';
        }
      },
      getComplexityRatingLabel(rating) {
        switch (rating) {
          case 5:
            return 'Heel moeilijk';
          case 4:
            return 'Moeilijk';
          case 3:
            return 'Gemiddeld';
          case 2:
            return 'Eenvoudig';
          case 1:
            return 'Heel eenvoudig';
          default:
            return 'Moeilijkheid';
        }
      },

      formatEuro(amount) {
        var euroScale = Intl.NumberFormat('nl-NL', {
          style: 'currency',
          currency: 'EUR',
          maximumFractionDigits: 0,
        });
        return euroScale.format(amount);
      },
    };
  },
};
</script>
