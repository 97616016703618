import axios from 'axios';
import axiosUrl from './axios.service';
import store from '../store/index';
import authHeader from '../helpers/auth-header';
const API_URL = axiosUrl.getAxiosUrl();

class SalesforceService {
  async getAccountsSalesforce() {
    return await axios
      .get(API_URL + '/SalesForce/GetAccounts', {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.data.length > 0) {
          store.commit('salesForce/updateAccounts', response.data);
          store.commit('salesForce/setDefaultCustomerItem');
          store.commit('salesForce/resetSelectedNewsPerCustomer');
          store.commit('salesForce/updateNewsForCurrentCustomer');
        }
      });
  }

  async postNewsSelection(data, last) {
    return await axios
      .post(
        API_URL + '/SalesForce/PostNewsSelection',
        {
          itemsList: data,
          last: last
        },
        {
          headers: authHeader(),
        }
      );
  }
}

export default new SalesforceService();
