<template>
  <div class="tree q-mt-lg">
    <div>
      <q-tree
        ref="tree"
        :filter="filter"
        :filter-method="myFilterMethod"
        :nodes="nodesState"
        node-key="id"
        tick-strategy="leaf"
        v-model:ticked="ticked"
        v-model:expanded="expanded"
        v-model:selected="selected"
        @update:ticked="
          updatePairs(ticked);
          updateSelected(ticked);
        "
        class="default-tree"
        @update:selected="addSelectedAsTicked(selected)"
        icon="chevron_right"
      />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from 'vue';
import store from '../../store/index';
import SchemeUtils from '../../utils/schemes';
import NewsUtils from '../../utils/news';
import { useI18n } from 'vue-i18n';

export default {
  props: {
    nodesState: Array,
    heading: String,
    filtering: Boolean,
    news: String,
    profileSearchFlag: Boolean,
    savedTagsString: String,
  },

  setup(props) {
    const treeNodes = ref(props.nodesState);
    const ticked = ref([]);
    const expanded = ref([]);
    const selected = ref([]);
    const filter = ref('');
    const filterRef = ref(null);
    const tree = ref(null);
    const pairs = ref([]);
    const isProfileSearch = ref(props.profileSearchFlag);
    const savedTags = ref(props.savedTagsString);
    var ids = new Array();

    const { locale } = useI18n();
    const isApplyingFilters = computed({
      get: () => store.state.user.isApplyingFilters,
    });

    const isApplyingFiltersReset = computed({
      get: () => store.state.advancedSearch.isApplyingFiltersReset,
    });

    onMounted(async () => {
      if (isProfileSearch.value == true || isApplyingFilters.value == true) {
        if (savedTags.value != null && savedTags.value.length > 0) {
          ids = savedTags.value.split(',');

          for (let i = 0; i < props.nodesState.length; i++) {
            if (ids.includes(props.nodesState[i].id.toString()))
              ticked.value.push(props.nodesState[i].id);

            if (
              props.nodesState[i].children &&
              props.nodesState[i].children.length > 0
            ) {
              for (let j = 0; j < props.nodesState[i].children.length; j++) {
                if (
                  ids.includes(props.nodesState[i].children[j].id.toString())
                ) {
                  ticked.value.push(props.nodesState[i].children[j].id);
                }
              }
            }
          }

          updatePairs(ticked.value);
          updateSelected(ticked.value);
        }
      } else {
        if (props.news == 'news') {
          if (props.heading == 'Voor wie zoekt u subsidies?') {
            let dataArr =
              selectedAudiencesNews.value != null
                ? selectedAudiencesNews.value.split(',')
                : [];
            for (let i = 0; i < dataArr.length; i++) {
              if (!isNaN(parseInt(dataArr[i])))
                ticked.value.push(parseInt(dataArr[i]));
            }
          } else if (props.heading == 'Onderwerp') {
            let dataArr =
              selectedPurposesNews.value != null
                ? selectedPurposesNews.value.split(',')
                : [];
            for (let i = 0; i < dataArr.length; i++) {
              if (!isNaN(parseInt(dataArr[i])))
                ticked.value.push(parseInt(dataArr[i]));
            }
          } else if (props.heading == 'Type bijdrage') {
            let dataArr =
              selectedSchemeTypesNews.value != null
                ? selectedSchemeTypesNews.value.split(',')
                : [];
            for (let i = 0; i < dataArr.length; i++) {
              if (!isNaN(parseInt(dataArr[i])))
                ticked.value.push(parseInt(dataArr[i]));
            }
          } else if (props.heading == 'Project type') {
            let dataArr =
              selectedProjectTypesNews.value != null
                ? selectedProjectTypesNews.value.split(',')
                : [];
            for (let i = 0; i < dataArr.length; i++) {
              if (!isNaN(parseInt(dataArr[i])))
                ticked.value.push(parseInt(dataArr[i]));
            }
          } else if (props.heading == 'Locaties') {
            let dataArr =
              selectedLocationsNews.value != null
                ? selectedLocationsNews.value.split(',')
                : [];
            for (let i = 0; i < dataArr.length; i++) {
              if (!isNaN(parseInt(dataArr[i])))
                ticked.value.push(parseInt(dataArr[i]));
            }
          }
          // This is just hidden for now, don't remove it:
          // ADD selectedIndustriesNews instead of selectedIndustries here
          /*else if (props.heading == 'Industrieën') {
            let dataArr = selectedIndustries.value.split(',');
            for (let i = 0; i < dataArr.length; i++) {
              if (!isNaN(parseInt(dataArr[i])))
                ticked.value.push(parseInt(dataArr[i]));
            }
          }*/
        } else if (props.news != 'news') {
          if (props.heading == 'Voor wie zoekt u subsidies?') {
            let dataArr =
              selectedAudiences.value != null
                ? selectedAudiences.value.split(',')
                : [];
            for (let i = 0; i < dataArr.length; i++) {
              if (!isNaN(parseInt(dataArr[i])))
                ticked.value.push(parseInt(dataArr[i]));
            }
          } else if (props.heading == 'Onderwerp') {
            let dataArr =
              selectedPurposes.value != null
                ? selectedPurposes.value.split(',')
                : [];
            for (let i = 0; i < dataArr.length; i++) {
              if (!isNaN(parseInt(dataArr[i])))
                ticked.value.push(parseInt(dataArr[i]));
            }
          } else if (props.heading == 'Type bijdrage') {
            let dataArr =
              selectedSchemeTypes.value != null
                ? selectedSchemeTypes.value.split(',')
                : [];
            for (let i = 0; i < dataArr.length; i++) {
              if (!isNaN(parseInt(dataArr[i])))
                ticked.value.push(parseInt(dataArr[i]));
            }
          } else if (props.heading == 'Project type') {
            let dataArr = selectedProjectTypes.value.split(',');
            for (let i = 0; i < dataArr.length; i++) {
              if (!isNaN(parseInt(dataArr[i])))
                ticked.value.push(parseInt(dataArr[i]));
            }
          } else if (props.heading == 'Locaties') {
            let dataArr = selectedLocations.value.split(',');
            for (let i = 0; i < dataArr.length; i++) {
              if (!isNaN(parseInt(dataArr[i])))
                ticked.value.push(parseInt(dataArr[i]));
            }
          }
          //This is just hidden for now, don't remove it
          /*else if (props.heading == 'Industrieën') {
            let dataArr = selectedIndustries.value.split(',');
            for (let i = 0; i < dataArr.length; i++) {
              if (!isNaN(parseInt(dataArr[i])))
                ticked.value.push(parseInt(dataArr[i]));
            }
          }*/
          store.commit('advancedSearch/enableFiltersChange');

          if (!isSearchPaused.value) {
            await SchemeUtils.searchSchemes(locale);
          }
        }
      }
      //This is just hidden for now, don't remove it
      /*
        if (props.heading == 'Industrieën') {
          tree.value.setExpanded(treeNodes.value[0].id, true);
        }
      */
    });

    //just to add parent to selected
    const updatePairs = (keys) => {
      pairs.value = [];
      if (keys.length > 0) {
        for (let i = 0; i < keys.length; i++) {
          let node = tree.value.getNodeByKey(keys[i]);
          pairs.value.push(node);
        }
        var toAdd = [];
        for (let i = 0; i < props.nodesState.length; i++) {
          if (
            props.nodesState[i].children &&
            props.nodesState[i].children.length > 0
          ) {
            var hasAllChildrenTicked = true;

            for (let j = 0; j < props.nodesState[i].children.length; j++) {
              if (!keys.includes(props.nodesState[i].children[j].id)) {
                hasAllChildrenTicked = false;
                break;
              }
            }
            if (hasAllChildrenTicked) toAdd.push(props.nodesState[i]);
          } else {
            hasAllChildrenTicked = false;
          }
        }
        if (toAdd.length > 0) {
          for (let i = 0; i < toAdd.length; i++) pairs.value.push(toAdd[i]);
        }
      }
      filter.value = '';
    };

    const isResetSearch = computed({
      get: () => store.state.advancedSearch.isResetSearch,
    });

    const updateSelected = async (val) => {
      filter.value = '';

      if (props.news == 'news') {
        if (props.heading == 'Voor wie zoekt u subsidies?') {
          store.commit('newsSearch/updateSelectedAudiences', val);
          store.commit('advancedSearch/updateSelectedAudiences', val);
          store.commit('user/updateAudiencesPairs', pairs.value);
        }
        if (props.heading == 'Onderwerp') {
          store.commit('newsSearch/updateSelectedPurposes', val);
          store.commit('advancedSearch/updateSelectedPurposes', val);
          store.commit('user/updatePurposesPairs', pairs.value);
        }
        if (props.heading == 'Type bijdrage') {
          store.commit('newsSearch/updateSelectedSchemeTypes', val);
          store.commit('advancedSearch/updateSelectedSchemeTypes', val);
          store.commit('user/updateSchemeTypesPairs', pairs.value);
        }
        if (props.heading == 'Project type') {
          store.commit('newsSearch/updateSelectedProjectTypes', val);
        }
        if (props.heading == 'Locaties') {
          store.commit('newsSearch/updateSelectedLocations', val);
          store.commit('advancedSearch/updateSelectedLocations', val);
        }
        //This is just hidden for now, don't remove it
        // if (props.heading == 'Industrieën') {
        //   store.commit('newsSearch/updateSelectedIndustries', val);
        // }

        await NewsUtils.searchNews(locale, isTableView.value);
      } else if (props.news != 'news') {
        if (props.heading == 'Voor wie zoekt u subsidies?') {
          store.commit('advancedSearch/updateSelectedAudiences', val);
          store.commit('user/updateAudiencesPairs', pairs.value);
          store.commit('newsSearch/updateSelectedAudiences', val);
          store.commit('advancedSearch/updateActiveFiltersCount');
        } else if (props.heading == 'Onderwerp') {
          store.commit('advancedSearch/updateSelectedPurposes', val);
          store.commit('user/updatePurposesPairs', pairs.value);
          store.commit('newsSearch/updateSelectedPurposes', val);
          store.commit('advancedSearch/updateActiveFiltersCount');
        } else if (props.heading == 'Type bijdrage' || props.heading == 'Type Bijdrage') {
          store.commit('advancedSearch/updateSelectedSchemeTypes', val);
          store.commit('user/updateSchemeTypesPairs', pairs.value);
          store.commit('newsSearch/updateSelectedSchemeTypes', val);
          store.commit('advancedSearch/updateActiveFiltersCount');
        } else if (props.heading == 'Project type') {
          store.commit('advancedSearch/updateSelectedProjectTypes', val);
          store.commit('user/updateProjectTypesPairs', pairs.value);
          store.commit('newsSearch/updateSelectedProjectTypes', val);
          store.commit('advancedSearch/updateActiveFiltersCount');
        } else if (props.heading == 'Locaties') {
          store.commit('advancedSearch/updateSelectedLocations', val);
          store.commit('newsSearch/updateSelectedLocations', val);
          store.commit('advancedSearch/updateActiveFiltersCount');
        }
        //This is just hidden for now, don't remove it
        /*
        else if (props.heading == 'Industrieën') {
          store.commit('advancedSearch/updateSelectedIndustries', val);
          store.commit('user/updateIndustriesPairs', pairs.value);
        }*/
        store.commit('advancedSearch/enableFiltersChange');

        if (isApplyingFiltersReset.value) {
          await SchemeUtils.searchSchemesFavorites(locale);
          await NewsUtils.searchNewsWithGrantsFilter(locale);
        } else if (!isSearchPaused.value && !isResetSearch.value) {
          await SchemeUtils.searchSchemes(locale);
          await NewsUtils.searchNewsWithGrantsFilter(locale);
        }
        store.commit('advancedSearch/updateIsApplyingFiltersReset', false);
      }
    };

    const resetTree = () => {
      ticked.value = [];
    };

    const refreshTree = (favoriteTagsArray) => {
      resetTree();
      if (favoriteTagsArray) {
        for (let i = 0; i < props.nodesState.length; i++) {
          if (favoriteTagsArray.includes(props.nodesState[i].id))
            ticked.value.push(props.nodesState[i].id);
          if (
            props.nodesState[i].children &&
            props.nodesState[i].children.length > 0
          ) {
            for (let j = 0; j < props.nodesState[i].children.length; j++) {
              if (
                favoriteTagsArray.includes(props.nodesState[i].children[j].id)
              ) {
                ticked.value.push(props.nodesState[i].children[j].id);
              }
              if (
                props.nodesState[i].children[j].children &&
                props.nodesState[i].children[j].children.length > 0
              ) {
                for (
                  let k = 0;
                  k < props.nodesState[i].children[j].children.length;
                  k++
                ) {
                  if (
                    favoriteTagsArray.includes(
                      props.nodesState[i].children[j].children[k].id
                    )
                  ) {
                    ticked.value.push(
                      props.nodesState[i].children[j].children[k].id
                    );
                  }
                }
              }
            }
          }
        }
      }
      updatePairs(ticked.value);
      updateSelected(ticked.value);
    };

    const resetSelected = async () => {
      if (props.news == 'news') {
        if (props.heading == 'Voor wie zoekt u subsidies?') {
          store.commit('newsSearch/updateSelectedAudiences', '');
          ticked.value = [];
          filter.value = '';
        }
        if (props.heading == 'Onderwerp') {
          store.commit('newsSearch/updateSelectedPurposes', '');
          ticked.value = [];
          filter.value = '';
        }
        if (props.heading == 'Type bijdrage') {
          store.commit('newsSearch/updateSelectedSchemeTypes', '');
          ticked.value = [];
          filter.value = '';
        }
        if (props.heading == 'Project type') {
          store.commit('newsSearch/updateSelectedProjectTypes', '');
          ticked.value = [];
          filter.value = '';
        }
        if (props.heading == 'Locaties') {
          store.commit('newsSearch/updateSelectedLocations', '');
          ticked.value = [];
          filter.value = '';
        }
        //This is just hidden for now, don't remove it
        /*
        if (props.heading == 'Industrieën') {
          store.commit('newsSearch/updateSelectedIndustries', '');
          ticked.value = [];
          filter.value = '';
        }
        */
        await NewsUtils.searchNews(locale, isTableView.value);
      } else if (props.news != 'news') {
        if (props.heading == 'Voor wie zoekt u subsidies?') {
          store.commit('advancedSearch/updateSelectedAudiences', '');
          ticked.value = [];
          store.commit('user/updateAudiencesPairs', pairs.value);
          filter.value = '';
        } else if (props.heading == 'Onderwerp') {
          store.commit('advancedSearch/updateSelectedPurposes', '');
          ticked.value = [];
          store.commit('user/updatePurposesPairs', pairs.value);
          filter.value = '';
        } else if (props.heading == 'Type bijdrage') {
          store.commit('advancedSearch/updateSelectedSchemeTypes', '');
          store.commit('user/updateSchemeTypesPairs', pairs.value);
          ticked.value = [];
          filter.value = '';
        } else if (props.heading == 'Project type') {
          store.commit('advancedSearch/updateSelectedProjectTypes', '');
          store.commit('user/updateProjectTypesPairs', pairs.value);
          ticked.value = [];
          filter.value = '';
        } else if (props.heading == 'Locaties') {
          store.commit('advancedSearch/updateSelectedLocations', '');
          ticked.value = [];
          filter.value = '';
        }
        //This is just hidden for now, don't remove it
        /*
        else if (props.heading == 'Industrieën') {
          store.commit('advancedSearch/updateSelectedIndustries', '');
          store.commit('user/updateIndustriesPairs', pairs.value);
          ticked.value = [];
          filter.value = '';
        }
        */
        store.commit('advancedSearch/enableFiltersChange');
        if (!isSearchPaused.value) {
          await SchemeUtils.searchSchemes(locale);
          await NewsUtils.searchNewsWithGrantsFilter(locale);
        }
      }
    };

    const lastSelectedNode = ref(null);

    const addSelectedAsTicked = (node) => {
      // If node == null that means that a node has been deselected, that is why we store last selected node.
      if (node != null) lastSelectedNode.value = node;

      function removeNodeAndChildren(node, parent) {
        let idx = ticked.value.indexOf(node.id);
        if (idx > -1) ticked.value.splice(idx, 1);

        if (parent && parent != null) {
          let parentIdx = ticked.value.indexOf(parent.id);
          if (parentIdx > -1) ticked.value.splice(parentIdx, 1);
        }

        if (node.children && node.children.length > 0) {
          for (let i = 0; i < node.children.length; i++)
            removeNodeAndChildren(node.children[i]);
        }
      }

      function addNodeAndChildren(node) {
        let idx = ticked.value.indexOf(node.id);
        if (idx == -1) ticked.value.push(node.id);

        if (node.children && node.children.length > 0) {
          for (let i = 0; i < node.children.length; i++)
            addNodeAndChildren(node.children[i]);
        }
      }

      var index = -1;
      for (let i = 0; i < ticked.value.length; i++) {
        if (ticked.value[i] == lastSelectedNode.value) {
          index = i;
          break;
        }
      }

      if (index > -1) {
        for (let i = 0; i < props.nodesState.length; i++) {
          if (props.nodesState[i].id == lastSelectedNode.value) {
            removeNodeAndChildren(props.nodesState[i], null);
            break;
          }
          if (
            props.nodesState[i].children &&
            props.nodesState[i].children.length > 0
          ) {
            for (let j = 0; j < props.nodesState[i].children.length; j++) {
              if (
                props.nodesState[i].children[j].id == lastSelectedNode.value
              ) {
                removeNodeAndChildren(
                  props.nodesState[i].children[j],
                  props.nodesState[i]
                );
                break;
              }
              if (
                props.nodesState[i].children[j].children &&
                props.nodesState[i].children[j].children.length > 0
              ) {
                for (
                  let k = 0;
                  k < props.nodesState[i].children[j].children.length;
                  k++
                ) {
                  if (
                    props.nodesState[i].children[j].children[k].id ==
                    lastSelectedNode.value
                  ) {
                    removeNodeAndChildren(
                      props.nodesState[i].children[j].children[k],
                      props.nodesState[i].children[j]
                    );
                    break;
                  }
                }
              }
            }
          }
        }
        updatePairs(ticked.value);
      } else {
        for (let i = 0; i < props.nodesState.length; i++) {
          if (props.nodesState[i].id == lastSelectedNode.value) {
            addNodeAndChildren(props.nodesState[i]);
            break;
          }
          if (
            props.nodesState[i].children &&
            props.nodesState[i].children.length > 0
          ) {
            for (let j = 0; j < props.nodesState[i].children.length; j++) {
              if (
                props.nodesState[i].children[j].id == lastSelectedNode.value
              ) {
                addNodeAndChildren(props.nodesState[i].children[j]);
                break;
              }
              if (
                props.nodesState[i].children[j].children &&
                props.nodesState[i].children[j].children.length > 0
              ) {
                for (
                  let k = 0;
                  k < props.nodesState[i].children[j].children.length;
                  k++
                ) {
                  if (
                    props.nodesState[i].children[j].children[k].id ==
                    lastSelectedNode.value
                  ) {
                    addNodeAndChildren(
                      props.nodesState[i].children[j].children[k]
                    );
                    break;
                  }
                }
              }
            }
          }
        }
        updatePairs(ticked.value);
      }
      updateSelected(ticked.value);
    };

    watch(
      () => filter.value,
      async (next, prev) => {
        if (prev && prev.length > 0 && next.length == 0) {
          tree.value.collapseAll();
        }
      }
    );

    const selectedAudiences = computed({
      get: () =>
        store.state.advancedSearch.selectedAudiences != ''
          ? store.state.advancedSearch.selectedAudiences
          : sessionStorage.getItem('selectedAudiences'),
    });

    const selectedLocations = computed({
      get: () =>
        store.state.advancedSearch.selectedLocations != ''
          ? store.state.advancedSearch.selectedLocations
          : sessionStorage.getItem('selectedLocations'),
    });

    const selectedSchemeTypes = computed({
      get: () =>
        store.state.advancedSearch.selectedSchemeTypes != ''
          ? store.state.advancedSearch.selectedSchemeTypes
          : sessionStorage.getItem('selectedSchemeTypes'),
    });

    const selectedProjectTypes = computed({
      get: () => store.state.advancedSearch.selectedProjectTypes,
    });

    //This is just hidden for now, don't remove it
    /*
    const selectedIndustries = computed({
      get: () => store.state.advancedSearch.selectedIndustries,
    });

    */
    const selectedPurposes = computed({
      get: () =>
        store.state.advancedSearch.selectedPurposes != ''
          ? store.state.advancedSearch.selectedPurposes
          : sessionStorage.getItem('selectedPurposes'),
    });

    const selectedAudiencesNews = computed({
      get: () =>
        store.state.newsSearch.selectedAudiences != ''
          ? store.state.newsSearch.selectedAudiences
          : sessionStorage.getItem('selectedAudiences'),
    });

    const selectedLocationsNews = computed({
      get: () =>
        store.state.newsSearch.selectedLocations != ''
          ? store.state.newsSearch.selectedLocations
          : sessionStorage.getItem('selectedLocations'),
    });

    const selectedSchemeTypesNews = computed({
      get: () =>
        store.state.newsSearch.selectedSchemeTypes != ''
          ? store.state.newsSearch.selectedSchemeTypes
          : sessionStorage.getItem('selectedSchemeTypes'),
    });

    const selectedProjectTypesNews = computed({
      get: () => store.state.newsSearch.selectedProjectTypes,
    });

    const selectedPurposesNews = computed({
      get: () =>
        store.state.newsSearch.selectedPurposes != ''
          ? store.state.newsSearch.selectedPurposes
          : sessionStorage.getItem('selectedPurposes'),
    });
    // watch for audience, purpose, industries, locations
    // if they change and are not the same as favorites saved in profile (newsletter), turn off save filters
    const favoriteAudiences = computed({
      get: () => store.state.user.favoriteAudiencesIdsArray,
    });

    const isTableView = computed({
      get: () => store.state.newsSearch.isTableView,
    });

    const isSearchPaused = computed({
      get: () => store.state.advancedSearch.applySavedSearchPause,
    });

    return {
      locale,
      isSearchPaused,
      selectedAudiencesNews,
      selectedLocationsNews,
      selectedPurposesNews,
      selectedSchemeTypesNews,
      selectedProjectTypesNews,
      isTableView,
      favoriteAudiences,
      isApplyingFilters,
      selectedAudiences,
      //This is just hidden for now, don't remove it
      //selectedIndustries,
      selectedPurposes,
      selectedSchemeTypes,
      selectedProjectTypes,
      selectedLocations,
      addSelectedAsTicked,
      resetSelected,
      resetTree,
      pairs,
      tree,
      updatePairs,
      updateSelected,
      ticked,
      filter,
      filterRef,
      expanded,
      treeNodes,
      props,
      selected,
      isProfileSearch,
      savedTags,
      ids,
      refreshTree,
      myFilterMethod(node, filter) {
        const filt = filter.toLowerCase();

        if (node.label.toLowerCase().indexOf(filt) > -1 && filt.length > 0)
          tree.value.expandAll();
        return node.label && node.label.toLowerCase().indexOf(filt) > -1;
      },
      barStyle: {
        right: '2px',
        borderRadius: '9px',
        backgroundColor: '#f0f2f5',
        width: '8px',
        opacity: 0.2,
      },
      thumbStyle: {
        right: '4px',
        borderRadius: '7px',
        width: '4px',
        opacity: 0.75,
      },
    };
  },
};
</script>
