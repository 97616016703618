import store from '../index';

const state = () => ({
  userStats: {},
  usersTable: [{}],
  chartData: {},
  schemeVisitsData: [],
  siteVisitsData: [],
  newsVisitsData: [],
  chartDataLabels: [],

  onzeBarData: {},
  onzeBarLabelsWeeks: [],
});

const mutations = {
  updateOnzeBarData(state, val) {
    var barDataLabels = [];
    var barDataNewsItemsSent = [];
    var barDataNewsLetterBounce = [];
    var barDataNewsLetterOpen = [];
    var barDataNewsLetterClick = [];
    var barDataNewsLetterClickUnique = [];
    var barDataContacts = [];

    if (val != null) {
      for (let i = 0; i < val.weeks.length; i++) {
        barDataLabels.push('Week ' + val.weeks[i].week);
        barDataNewsItemsSent.push(val.weeks[i].newsItemSentCount);
        barDataNewsLetterBounce.push(val.weeks[i].newsLetterBounceCount);
        barDataNewsLetterOpen.push(val.weeks[i].newsLetterOpenCount);
        barDataNewsLetterClick.push(val.weeks[i].newsLetterClickCount);
        barDataNewsLetterClickUnique.push(
          val.weeks[i].newsLetterClickUniqueCount
        );
        barDataContacts.push(val.weeks[i].contactCount);
      }

      // REMOVE THIS LATER
      for (let i = 0; i < barDataNewsLetterBounce.length; i++) {
        if (barDataNewsLetterBounce[i] == 0)
          barDataNewsLetterBounce[i] = (i + 5) * 7;
      }

      state.onzeBarData = {
        labels: barDataLabels,
        datasets: [
          {
            label: 'News items sent',
            backgroundColor: '#FF5B33',
            data: barDataNewsItemsSent,
            color: '#FF5B33',
            borderColor: '#FF5B33',
          },
          {
            label: 'Newsletter bounce count',
            backgroundColor: '#0a108f',
            data: barDataNewsLetterBounce,
            color: '#0a108f',
            borderColor: '#0a108f',
          },

          {
            label: 'Newsletter open count',
            backgroundColor: '#1299d7',
            data: barDataNewsLetterOpen,
            color: '#1299d7',
            borderColor: '#1299d7',
          },
          {
            label: 'Newsletter click count',
            backgroundColor: '#503447',
            data: barDataNewsLetterClick,
            color: '#503447',
            borderColor: '#503447',
          },
          {
            label: 'Newsletter unique click count',
            backgroundColor: '#311555',
            data: barDataNewsLetterClickUnique,
            color: '#311555',
            borderColor: '#311555',
          },
        ],
      };
    }
  },

  updateGeneralUsersStatsFrontOffice(state, val) {
    state.userStats = {
      totalActiveUsersCount: val.totalActiveUsersCount,
      usersWithoutNewsletterCount: val.usersWithoutNewsletterCount,
      usersWithVisitInLastMonthCount: val.usersWithVisitInLastMonthCount,
      usersWithoutProfileCount: val.usersWithoutProfileCount,
    };
  },
  updateTableStats(state, val) {
    state.usersTable = val;
  },

  updateGeneralStatsFront(state, val) {
    state.schemeVisitsData = [];
    state.newsVisitsData = [];
    state.siteVisitsData = [];

    store.commit('userStats/updateDataSets', val);
    state.chartDataLabels = [];

    for (let i = 0; i < val.siteVisits.length; i++) {
      state.chartDataLabels.push(
        val.siteVisits[i].monthName + ' ' + val.siteVisits[i].year
      );
    }

    state.chartData = {
      labels: state.chartDataLabels,

      datasets: [
        {
          label: 'Aantal bezoeken',
          backgroundColor: '#FF5B33',
          data: state.siteVisitsData,
          color: '#FF5B33',
          borderColor: '#FF5B33',
        },
        {
          label: 'Regelingen bekeken',
          backgroundColor: '#0a108f',
          data: state.schemeVisitsData,
          borderColor: '#0a108f',
          color: '#0a108f',
        },
        {
          label: 'Nieuwsitems bekeken',
          backgroundColor: '#1299d7',
          data: state.newsVisitsData,
          borderColor: '#1299d7',
          color: '#1299d7',
        },
      ],
    };
  },

  updateDataSets(state, data) {
    for (let i = 0; i < data.schemeVisits.length; i++) {
      state.schemeVisitsData.push(data.schemeVisits[i].count);
    }
    for (let i = 0; i < data.newsVisits.length; i++) {
      state.newsVisitsData.push(data.newsVisits[i].count);
    }
    for (let i = 0; i < data.siteVisits.length; i++) {
      state.siteVisitsData.push(data.siteVisits[i].count);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
