import { createWebHistory, createRouter } from 'vue-router';
import Login from '@/views/Login.vue';
import LoginIgnite from '@/views/LoginIgnite.vue';
import NoAccount from '@/views/NoAccount.vue';
import Register from '@/views/Register.vue';
import UserProfile from '@/views/UserProfile.vue';
import SearchGrants from '@/views/SearchGrants.vue';
import News from '@/views/News.vue';
import GrantDetails from '@/views/GrantDetails.vue';
import Unauthorized from '@/views/Unauthorized.vue';
import NewsDetails from '@/views/NewsDetails';
import ResetPassword from '@/views/ResetPassword.vue';
import NewPassword from '@/views/NewPassword.vue';
import HomePage from '@/views/HomePage.vue';
import DeadlinesSchemes from '../components/homepage-components/DeadlinesSchemes.vue';
import NewestSchemes from '../components/homepage-components/NewestSchemes.vue';
import LatestNews from '../components/homepage-components/LatestNews.vue';
import SignificantChanges from '../components/homepage-components/SignificantChanges';
import store from '../store/index';
import PublicNewsDetails from '@/views/PublicNewsDetails.vue';
import SalesForce from '@/views/SalesForce.vue';
import SalesForceUnsubscribe from '@/views/SalesForceUnsubscribe.vue';
import SalesForceCallback from '@/views/SalesForceCallback.vue';
import NewsSelectFinal from '@/components/news-select-components/NewsSelectFinal.vue';
import NewsSelectHome from '@/components/news-select-components/NewsSelectHome.vue';
import NewsForCustomersFinal from '@/components/news-select-components/NewsForCustomersFinal.vue';
import NewsSelectPage from '@/views/NewsSelectPage.vue';
import OnzePublicNewsList from '@/views/OnzePublicNewsList.vue';
import OnzeExportPage from '@/views/Bevindingen.vue';
import PortalSearchGrants from '@/views/PortalSearchGrants';
import PortalSchemeDetails from '@/components/portals-components/PortalsSchemeDetails.vue';
import TestMailing from '@/views/TestMailing.vue';
import RatingPage from '../views/SurveyPages/RatingPage';
import ReactivationSuggestionPage from '../views/SurveyPages/ReactivationSuggestionPage';
import DeactivationForm from '../views/SurveyPages/DeactivationForm';
import NewUserSuggestionPage from '../views/SurveyPages/NewUserSuggestionPage';
import ErrorPage from '@/views/ErrorPage.vue';
import PortalExpired from '@/views/PortalExpired.vue';
import OidcAuth from '@/views/OidcAuth.vue';
import OidcSignOut from '@/views/OidcSignOut.vue';

const routes = [
  {
    path: '/',
    component: Login,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/change-password/token=:token(.*)',
    name: 'NewPassword',
    component: NewPassword,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/loginignite',
    name: 'LoginIgnite',
    component: LoginIgnite,
  },
  {
    path: '/geen-account',
    name: 'NoAccount',
    component: NoAccount,
  },
  {
    path: '/profile/:tab',
    name: 'UserProfile',
    component: UserProfile,
  },
  /*  {
    path: '/search-grants',
    name: 'SearchGrants',
    component: () => {
      if (isVind.value) return SearchGrants;
      else return PortalSearchGrants;
    },
  }, */
  {
    path: '/search-grants',
    name: 'SearchGrants',
    component: SearchGrants,
  },
  {
    path: '/search-grants',
    name: 'PortalSearchGrants',
    component: PortalSearchGrants,
  },
  {
    path: '/news',
    name: 'News',
    component: News,
  },
  {
    path: '/grant/:grantId/details:term?',
    name: 'GrantDetails',
    component: GrantDetails,
  },
  {
    path: '/grant/:grantId/details:term?',
    name: 'PortalSchemeDetails',
    component: PortalSchemeDetails,
  },
  {
    path: '/news/view/:newsId',
    name: 'NewsDetails',
    component: NewsDetails,
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: Unauthorized,
  },
  {
    path: '/home-page',
    name: 'HomePage',
    component: HomePage,
  },
  {
    path: '/home-page/deadlines',
    name: 'DeadlinesSchemes',
    component: DeadlinesSchemes,
  },
  {
    path: '/home-page/newest',
    name: 'NewestSchemes',
    component: NewestSchemes,
  },
  {
    path: '/home-page/latest-news',
    name: 'LatestNews',
    component: LatestNews,
  },
  {
    path: '/home-page/significant-changes',
    name: 'SignificantChanges',
    component: SignificantChanges,
  },
  {
    path: '/nieuwsbericht',
    name: 'PublicNewsDetails',
    component: PublicNewsDetails,
  },
  {
    path: '/onze-bevindingen',
    name: 'NewsSelectPage',
    component: NewsSelectPage,
  },
  {
    path: '/onze-bevindingen-uitschrijven',
    name: 'SalesForceUnsubscribe',
    component: SalesForceUnsubscribe,
  },
  {
    path: '/salesforce',
    name: 'SalesForce',
    component: SalesForce,
  },
  {
    path: '/salesforce/callback',
    name: 'SalesForceCallback',
    component: SalesForceCallback,
  },
  {
    path: '/onze-bevindingen/afgerond',
    name: 'NewsSelectFinal',
    component: NewsSelectFinal,
  },
  {
    path: '/onze-bevindingen/start',
    name: 'NewsSelectHome',
    component: NewsSelectHome,
  },
  {
    path: '/onze-bevindingen/nieuws-afgerond',
    name: 'NewsForCustomersFinal',
    component: NewsForCustomersFinal,
  },
  {
    path: '/onze-bevindingen/nieuwsbrief',
    name: 'OnzePublicNewsList',
    component: OnzePublicNewsList,
  },
  {
    path: '/onze-bevindingen/:tab',
    name: 'OnzeExportPage',
    component: OnzeExportPage,
  },
  {
    path: '/rating/token=:token(.*)/rateValue=:rateValue',
    name: 'RatingPage',
    component: RatingPage,
  },
  {
    path: '/reactivationSuggestion/token=:token(.*)',
    name: 'ReactivationSuggestionsPage',
    component: ReactivationSuggestionPage,
  },
  {
    path: '/deactivation/token=:token(.*)',
    name: 'DeactivationForm',
    component: DeactivationForm,
  },
  {
    path: '/newUserSuggestion/token=:token(.*)',
    name: 'NewUserSuggestionPage',
    component: NewUserSuggestionPage,
  },
  {
    path: '/mailing',
    name: 'Mailing',
    component: TestMailing,
  },
  {
    path: '/error',
    name: 'ErrorPage',
    component: ErrorPage,
  },
  {
    path: '/portal-expired',
    name: 'PortalExpired',
    component: PortalExpired,
  },
  {
    path: '/oidc-auth',
    name: 'OidcAuth',
    component: OidcAuth,
  },
  {
    path: '/oidc-signout',
    name: 'OidcSignOut',
    component: OidcSignOut,
  }
];

/* const isVind = computed({
  get: () => store.state.auth.env === 0 || store.state.auth.env === 40,
});
 */
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
const redirectToIgniteLogin = (route) => {
  const LOGIN = 'LoginIgnite';
  if (route.name != LOGIN) {
    return { name: LOGIN, replace: true };
  }
};
const redirectToLogin = (route) => {
  const LOGIN = 'Login';
  if (route.name != LOGIN) {
    return { name: LOGIN, replace: true };
  }
};
router.beforeEach((to) => {
  const publicPages = [
    '/login',
    '/register',
    '/reset-password',
    '/new-password',
    '/change-password',
    '/salesforce',
    '/nieuwsbericht',
    'SalesForce/CallBack',
    '/onze-bevindingen/nieuwsbrief',
    '/portal-expired',
    '/rating',
    '/reactivationSuggestion',
    '/deactivation',
    '/newUserSuggestion',
    '/oidc-auth',
    '/oidc-signout',
    '/geen-account'
  ];

  const routeContainsElementFromPublicPages = publicPages.some((element) => {
    if (to.path.includes(element)) {
      return true;
    }
    return false;
  });

  const authRequired = !routeContainsElementFromPublicPages;
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    if (to.path.substring(0, 10) == '/news/view') {
      store.commit('user/setHasComeFromNewsletter', true);
      store.commit('user/setNewsletterParams', to.params);
    }

    if (to.path == '/onze-bevindingen') {
      return redirectToIgniteLogin(to);
    }

    return redirectToLogin(to);
  }
});

export default router;
