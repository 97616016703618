<template>
  <div class="bar-parent">
    <Bar class="scheme-visits-bar" />
  </div>
</template>

<script>
import { h } from 'vue';

import { Bar } from 'vue-chartjs';
import { computed } from 'vue';
import store from '../../store/index';

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineController,
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineController
);

export default {
  name: 'BarChart',
  components: {
    Bar,
  },
  props: {
    chartId: {
      type: String,
      default: 'myChart',
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: '',
      type: String,
    },
  },
  setup(props) {
    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      barThickness: 30,
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            callback: function (label) {
              if (Math.floor(label) === label) {
                return label;
              }
            },
          },
        },
      },
    };

    const chartDataStore = computed({
      get: () => store.state.onzeStats.onzeBarDataConsultantSelected,
    });

    return () =>
      h(Bar, {
        chartData: chartDataStore.value,
        chartOptions,
        chartId: props.chartId,
        width: props.width,
        height: props.height,
        cssClasses: props.cssClasses,
        styles: props.styles,
        plugins: props.plugins,
      });
  },
};
</script>
