<template>
  <ErrorModal />
  <q-dialog v-model="show" persistent class="save-scheme-main">
    <q-card style="min-width: 350px" class="save-dialog">
      <q-card-section>
        <div style="color: #0a108f" class="text-main bold-font">
          {{ labelForModal }}
        </div>
      </q-card-section>

      <q-separator />

      <q-card-section class="q-pt-none q-pa-md">
        <div class="row justify-around">
          <q-select
            borderless
            bg-color="white"
            v-model="folderWithoutItems.folder"
            :options="folders"
            option-label="name"
            :label="t('save_scheme.select_folder')"
            stack-label
            behavior="menu"
            style="width: 100%"
            dense
            :rules="[requiredSelectRule]"
            :disable="showInput == true"
          >
          </q-select>
        </div>
        <div class="row justify-around make-map">
          <a v-if="folderWithoutItems.folder == null" @click="showInput = true">
            <i class="las la-1x la-plus"></i>
              {{ t('save_scheme.create_new_folder') }}
          </a>
          <q-input
            v-if="showInput && folderWithoutItems.folder == null"
            dense
            v-model="folderWithoutItems.newFolderName"
            borderless
            bg-color="white"
            stack-label
            :placeholder="t('save_scheme.create_new_folder')"
            :rules="requiredFieldRule"
            style="width: 100%"
          />
        </div>
      </q-card-section>

      <q-separator />

      <q-card-actions class="bold-font q-ml-sm" align="left">
        <q-btn
          color="white"
          class="orange-btn-filled"
          flat
          :label="t('search_schemes.save')"
          style="width: 90px"
          no-caps
          @click="saveSchemes()"
        />
        <q-btn
          flat
          class="orange-btn-cancel"
          :label="t('common.cancel')"
          no-caps
          style="width: 90px"
          @click="disableSaveScheme()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { computed, ref, reactive, onMounted } from 'vue';
import store from '../store/index';
import ErrorModal from './userProfile-components/ErrorModal.vue';
import UserService from '../services/userprofile.service';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    ErrorModal,
  },
  setup() {
    const showInput = ref(false);

    const folderWithoutItems = reactive({
      folder: null,
      newFolderName: '',
    });

    onMounted(() => {
      UserService.getFolders();
    });

    const folders = computed({
      get: () => store.state.user.folders,
    });

    const selectedResults = computed({
      get: () => store.state.grantItems.selectedResults,
    });

    const show = computed({
      get: () => store.state.user.showSaveScheme,
    });

    const disableSaveScheme = () => {
      store.commit('user/disableSaveScheme');
      showInput.value = false;
      folderWithoutItems.folder = null;
      folderWithoutItems.newFolderName = '';
    };

    const saveSchemes = async () => {
      await UserService.saveSchemes(folderWithoutItems, selectedResults.value);

      showInput.value = false;
      folderWithoutItems.folder = null;
      folderWithoutItems.newFolderName = '';
    };

    const labelForModal = computed({
      get: () => store.state.grantItems.labelForSaveModal,
    });

    const { t } = useI18n();

    return {
      t,
      labelForModal,
      show,
      folders,
      disableSaveScheme,
      saveSchemes,
      folderWithoutItems,
      showInput,
      selectedResults,
      requiredFieldRule: [
        (val) => (val && val.length > 0) || t('common.required_field'),
      ],
      requiredSelectRule(val) {
        if (val === null) {
          return t('save_scheme.select_folder_validation');
        }
      },
    };
  },
};
</script>
