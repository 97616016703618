<template>
  <div class="expired-portal-page bold-font">
    Deze portal is niet langer online. Neem contact op met de gemeente als je
    hier vragen over hebt.
  </div>
</template>

<script>
export default {
  name: 'PortalExpired',
  setup() {
    return {};
  },
};
</script>
