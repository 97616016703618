<template>

    <Line />

</template>
  
<script>
import { h, computed } from 'vue';
  
import { Line } from 'vue-chartjs';
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    CategoryScale,
  } from 'chart.js';
  
  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    CategoryScale
  );
  
  import store from '../../store/index.js';
    export default {
    name: 'LineGraphUser',
    components: {
      Line,
    },
    props: {
      chartId: {
        type: String,
        default: 'line-chart',
      },
      width: {
        type: Number,
        default: 700,
      },
      height: {
        type: Number,
        default: 400,
      },
      cssClasses: {
        default: '',
        type: String,
      },
    },
    setup(props) {
      const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              callback: function(label) {
                if (Math.floor(label) === label) {
                  return label;
                }
              },
            },
          },
        },
      };
  
      const chartDataStore = computed({
        get: () => store.state.userStats.chartData,
      });
  
      return () =>
        h(Line, {
          chartData: chartDataStore.value,
          chartOptions,
          chartId: props.chartId,
          width: props.width,
          height: props.height,
          cssClasses: props.cssClasses,
          styles: props.styles,
          plugins: props.plugins,
        });
    },
  };
  </script>
  