import axios from 'axios';
import axiosUrl from './axios.service';
import authHeader from '../helpers/auth-header';
import store from '../store/index';

const API_URL = axiosUrl.getAxiosUrl();
const localStorageUser = JSON.parse(localStorage.getItem('user'));
const env =  localStorageUser
 ? Number.parseInt(
     JSON.parse(Buffer.from(localStorageUser.token.split('.')[1], 'base64'))
       .env
   )
 : 0;
class MailingService {
  async sendTestMailing(receivers, subject, mailText) {
    return await axios
      .post(
        API_URL + '/salesforce/PostStartInvestmentNewsletter',
        {
          receivers: receivers,
          subject: subject,
          mailText: mailText,
          draft: true
        },
        {
          headers: authHeader(),
        }
      )
      .then(() => {
        // add some success here
        store.commit('grantItems/updateModalError', 'The mail test sending has started.'),
          store.commit('grantItems/enableModalError');
      })
      .catch((error) => {
        store.commit('grantItems/updateModalError', error.response.data),
          store.commit('grantItems/enableModalError');
      });

  }

  async sendMailing(receivers, subject, mailText) {
    return await axios
      .post(
        API_URL + '/salesforce/PostStartInvestmentNewsletter',
        {
          receivers: receivers,
          subject: subject,
          mailText: mailText,
          draft: false
        },
        {
          headers: authHeader(),
        }
      )
      .then(() => {
        // add some success here
        store.commit('grantItems/updateModalError', 'The mail sending has started.'),
          store.commit('grantItems/enableModalError');
      })
      .catch((error) => {
        store.commit('grantItems/updateModalError', error.response.data),
          store.commit('grantItems/enableModalError');
      });
  }

  async shareNews(name, organisation, address, id) {
    if (organisation == null || organisation == '') {
      store.commit('grantItems/updateModalError', 'E-mail is verplicht!'),
        store.commit('grantItems/enableModalError');
    }
    if (address == null || address == '') {
      store.commit('grantItems/updateModalError', 'E-mail is verplicht!'),
        store.commit('grantItems/enableModalError');
    }
    if (name == null || name == '') {
      store.commit('grantItems/updateModalError', 'Naam is verplicht!'),
        store.commit('grantItems/enableModalError');
    }

    return await axios
      .post(
        API_URL + '/mailing/shareNews',
        {
          receiverName: name,
          receiverOrganisationName: organisation,
          receiverEmail: address,
          newsId: id,
        },
        {
          headers: authHeader(),
        }
      )
      .then(() => {
        store.commit('news/disableShareNews');
      });
  }

  async shareNewsMultiple(recipients, id) {
    const noNull = recipients.filter((doc) => {
      return Object.values(doc).every((prop) => prop !== null);
    });
    if (noNull.length != recipients.length) {
      store.commit(
        'grantItems/updateModalError',
        'Naam, Organisatie, E-mail is verplicht!'
      ),
        store.commit('grantItems/enableModalError');
    }
    return await axios
      .post(
        API_URL + '/mailing/shareNewsMultiple',
        {
          recipients: recipients,
          newsId: id,
        },
        {
          headers: authHeader(),
        }
      )
      .then(() => {
        store.commit('news/disableShareNews');
      });
  }

  async shareScheme(email, message, name, organisation, id) {
    if(env == 0){
      if (message == null || message == '') {
        store.commit('grantItems/updateModalError', 'Het bericht is verplicht!'),
          store.commit('grantItems/enableModalError');
      }
    }
    if (email == null || email == '') {
      store.commit('grantItems/updateModalError', 'E-mail is verplicht!'),
        store.commit('grantItems/enableModalError');
    }

    return await axios
      .post(
        API_URL + '/mailing/shareScheme',
        {
          receiverName: name,
          receiverOrganisationName: organisation,
          receiverEmail: email,
          message: message,
          schemeIds: [id],
        },
        {
          headers: authHeader(),
        }
      )
      .then(() => {
        store.commit('grantItems/disableShareGrantItem');
      });
  }

  async shareSchemeMultiple(
    recipients,
    message,
    id,
    reportType
  ) {
    if(env == 0){
      if (message == null || message == '') {
        store.commit('grantItems/updateModalError', 'Het bericht is verplicht!'),
          store.commit('grantItems/enableModalError');
      }
    }
    return await axios
      .post(
        API_URL + '/mailing/shareSchemesMultiple',
        {
          recipients: recipients,
          message: message,
          reportType: reportType.value,
          schemeIds: [id],
        },
        {
          headers: authHeader(),
        }
      )
      .then(() => {
        store.commit('grantItems/disableShareGrantItem');
      });
  }

  async shareSchemes(
    address,
    message,
    description,
    reportTitle,
    customerName,
    customerAccountName,
    ids
  ) {
    if (message == null || message == '') {
      store.commit(
        'grantItems/updateModalError',
        'The message cannot be empty!'
      ),
        store.commit('grantItems/enableModalError');
    }
    if (address == null || address == '') {
      store.commit('grantItems/updateModalError', 'Email cannot be empty!'),
        store.commit('grantItems/enableModalError');
    }
    return await axios
      .post(
        API_URL + '/mailing/shareScheme',
        {
          receiverName: customerAccountName,
          receiverOrganisationName: customerName,
          receiverEmail: address,
          message: message,
          schemeIds: ids,
          reportTitle: reportTitle,
          reportDescription: description,
        },
        {
          headers: authHeader(),
        }
      )
      .then(() => {
        store.commit('grantItems/disableShareGrantsPage', false);
      });
  }

  async shareSchemesMultiple(
    recipients,
    message,
    description,
    reportTitle,
    ids,
    reportType
  ) {
    if(env == 0){
      if (message == null || message == '') {
        store.commit(
          'grantItems/updateModalError',
          'The message cannot be empty!'
        ),
          store.commit('grantItems/enableModalError');
      }
    }
  
    return await axios
      .post(
        API_URL + '/mailing/shareSchemesMultiple',
        {
          recipients: recipients,
          message: message,
          schemeIds: ids,
          reportTitle: reportTitle,
          reportDescription: description,
          reportType: reportType.value,
        },
        {
          headers: authHeader(),
        }
      )
      .then(() => {
        store.commit('grantItems/disableShareGrantsPage', false);
      });
  }
}

export default new MailingService();
