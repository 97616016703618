<template>
  <ErrorModal />
  <q-dialog v-model="shareGrantsPage" class="share-grants-page" full-width>
    <q-card class="share-dialog">
      <q-form
        @submit="
          shareSchemePageEmit(
            recipientsRows,
            message,
            description,
            reportTitle,
            reportType
          )
        "
      >
        <q-card-section>
          <div class="text-h5 bold-font text-main">
            {{ t('share_and_download_schemes.share_selected_items_as_a_report')}}
          </div>
        </q-card-section>
        <q-card-section>
          <div class="column justify-between top-section-responsive">
            <div class="column column-width">
              <span class="input-heading"> {{ t('share_and_download_schemes.report_title') }} * </span>
              <q-input
                class="q-mt-sm"
                borderless
                bg-color="white"
                v-model="reportTitle"
                dense
                :rules="requiredFieldRule"
              />

              <span class="input-heading q-mt-md"> {{ t('share_and_download_schemes.description') }} </span>
              <q-input
                class="q-mt-sm"
                v-model="description"
                borderless
                bg-color="white"
                type="textarea"
              />
            </div>

            <q-separator class="q-mt-md fullwidth-seperator" />
            <div class="text-h5 q-mt-sm">{{ t('share_and_download_schemes.receiver(s)') }}</div>

            <div class="row">
              <div class="row recipients-container">
                <div class="add-recipient">
                  <div class="share-text-sm">
                    <span class="share-info share-input"> {{ t('share_and_download_schemes.name') }} </span>

                    <q-select
                      class="q-mt-sm"
                      borderless
                      bg-color="white"
                      :model-value="customerName"
                      dense
                      :rules="requiredFieldRule"
                      :label="t('share_and_download_schemes.add_name')"
                      @filter="filterNamesFn"
                      :options="filterNamesOptions"
                      option-value="name"
                      option-label="name"
                      use-input
                      input-debounce="0"
                      behavior="menu"
                      @update:model-value="(val) => updateSelectedName(val)"
                      new-value-mode="add"
                      hide-dropdown-icon
                      @input-value="setName"
                      fill-input
                      hide-selected
                    />
                  </div>

                  <div class="share-text-sm">
                    <span class="share-info share-input">
                      {{ t('share_and_download_schemes.organization_name') }}
                    </span>
                    <q-input
                      class="q-mt-sm"
                      borderless
                      bg-color="white"
                      v-model="customerAccountName"
                      dense
                      :rules="requiredFieldRule"
                      label="Voeg een organisatie toe"
                    />
                  </div>

                  <div class="share-text-sm recipients">
                    <span class="share-info share-input"> {{ t('share_and_download_schemes.e_mail_address') }} </span>
                    <q-input
                      class="q-mt-sm mail-input"
                      borderless
                      bg-color="white"
                      v-model="address"
                      dense
                      type="email"
                      :rules="[isValidEmail, requiredFieldRule, isUniqueEmail]"
                      :label="t('share_and_download_schemes.add_email_address')"
                    />
                  </div>
                  <q-icon
                    name="add_circle"
                    class="action-icon q-ml-sm"
                    size="26px"
                    @click="
                      addRecipient(customerName, customerAccountName, address)
                    "
                  >
                    <q-tooltip>{{ t('share_and_download_schemes.add_recipient')}}</q-tooltip></q-icon
                  >
                </div>
                <div class="recipients-list q-mt-md">
                  <div
                    class="add-recipient"
                    v-for="recipient in recipientsRows"
                    :key="recipient.name"
                  >
                    <div class="share-text-sm">
                      <q-input
                        class="q-mt-sm"
                        outlined
                        v-model="recipient.name"
                        dense
                        :rules="requiredFieldRule"
                        :label="t('share_and_download_schemes.name')"
                        disable
                      />
                    </div>

                    <div class="share-text-sm">
                      <q-input
                        class="q-mt-sm"
                        outlined
                        v-model="recipient.organisation"
                        dense
                        :rules="requiredFieldRule"
                        :label="t('share_and_download_schemes.organization')"
                        disable
                      />
                    </div>

                    <div class="share-text-sm recipients">
                      <q-input
                        class="q-mt-sm mail-input"
                        outlined
                        v-model="recipient.email"
                        dense
                        type="email"
                        :rules="[isValidEmail, requiredFieldRule]"
                        :label="t('share_and_download_schemes.e_mail_address')"
                        disable
                      />
                    </div>
                    <i
                      class="las la-2x la-plus-circle action-icon q-ml-sm"
                      @click="addRecipient(name, accountName, address)"
                    >
                      <q-tooltip>{{ t('share_and_download_schemes.add_recipient') }}</q-tooltip></i
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </q-card-section>
        <q-card-section>
          <div class="share-text-sm">
            <span class="share-info"> {{ t('share_and_download_schemes.message') + '*' }} </span>
            <q-input
              :rules="requiredFieldRule"
              class="q-mt-sm"
              v-model="message"
              borderless
              bg-color="white"
              type="textarea"
            />
          </div>
        </q-card-section>

        <q-card-section class="report-type">
          <q-select
            square
            borderless
            bg-color="white"
            v-model="reportType"
            :options="options"
            :label="t('share_and_download_schemes.type_of_report')"
            style="width: 47%"
          />
        </q-card-section>

        <q-card-actions class="q-mt-sm bold-font" align="left">
          <q-btn
            flat
            no-caps
            :label="t('search_schemes.share')"
            class="orange-btn-filled"
            color="white"
            type="submit"
            style="width: 70px"
          />
          <q-btn
            no-caps
            flat
            :label="t('common.cancel')"
            class="orange-btn-cancel"
            v-close-popup
            @click="clearInputs()"
            style="width: 100px"
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import { computed, ref } from 'vue';
import store from '../../store/index';
import MailingService from '../../services/mailing.service';
import ErrorModal from './ErrorModal.vue';
import SuggestionsService from '../../services/suggestions.service';
import { useI18n } from 'vue-i18n';

export default {
  components: { ErrorModal },
  setup() {
    const shareGrantsPage = computed({
      get: () => store.state.grantItems.shareGrantsPage,
      set: (val) => store.commit('grantItems/disableShareGrantsPage', val),
    });
    const grant = computed({
      get: () => store.state.grantItems.grantToShare,
    });
    const selectedResultsIds = computed({
      get: () => store.state.grantItems.selectedResults.map((r) => r.schemeId),
    });

    const shareSchemePageEmit = async (
      recipientsRows,
      message,
      description,
      reportTitle,
      reportType
    ) => {
      var r = [];
      if (recipientsRows.length == 0) {
        r.push({
          receiverName: customerName.value,
          receiverOrganisationName: customerAccountName.value,
          receiverEmail: address.value,
        });
      }
      for (let i = 0; i < recipientsRows.length; i++) {
        r.push({
          receiverName: recipientsRows[i].name,
          receiverOrganisationName: recipientsRows[i].organisation,
          receiverEmail: recipientsRows[i].email,
        });
      }
      await MailingService.shareSchemesMultiple(
        r,
        message,
        description,
        reportTitle,
        selectedResultsIds.value,
        reportType
      );

      clearInputs();
    };
    const address = ref('');
    const message = ref('');
    const reportTitle = ref('');
    const description = ref('');
    const customerName = ref('');
    const reportTemplate = ref('');
    const templateOptions = ['Template 1', 'Template 2'];
    const searchCriteria = ref('');
    const customerAccountName = ref('');
    const reportType = ref({
      label: 'Volledig rapport',
      value: 0,
    });

    const recipientsColumns = [
      {
        name: 'name',
        align: 'left',
        label: 'Naam',
        field: 'name',
      },
      {
        name: 'organisation',
        align: 'left',
        label: 'Organisatienaam',
        field: 'organisation',
      },
      {
        name: 'email',
        align: 'left',
        label: 'E-mail',
        field: 'email',
      },
      { name: 'actions', align: 'center' },
    ];

    const recipientsRows = ref([]);
    const addRecipient = (recieverName, organisation, email) => {
      const emailPattern =
        /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
      var emailExists = false;
      for (let i = 0; i < recipientsRows.value.length; i++) {
        if (recipientsRows.value[i].email == email) {
          emailExists = true;
          break;
        }
      }

      if (
        !emailExists &&
        emailPattern.test(email) &&
        recieverName.length > 0 &&
        organisation.length > 0
      ) {
        recipientsRows.value.push({
          name: recieverName,
          organisation: organisation,
          email: email,
        });

        address.value = '';
        customerName.value = '';
        customerAccountName.value = '';
      }
    };

    const filterNamesOptions = ref([]);

    const userId = computed({
      get: () => store.state.user.userId,
    });

    const nameOptions = computed({
      get: () => store.state.suggestions.suggestions,
    });

    const updateSelectedName = (val) => {
      if (val != null) {
        if (val.name && val.name.length > 0) {
          customerName.value = val.name;
          customerAccountName.value = val.organisation;
          address.value = val.email;
        } else if (val && val.length > 0) name.value = val;
      }
    };

    const clearInputs = () => {
      recipientsRows.value = [];
      reportTitle.value = '';
      message.value = '';
      description.value = '';
      address.value = '';
      customerName.value = '';
      customerAccountName.value = '';
    };

    const setName = (val) => {
      customerName.value = val;
    };

    const { t } = useI18n();

    return {
      t,
      setName,
      clearInputs,
      updateSelectedName,
      userId,
      filterNamesOptions,
      addRecipient,
      recipientsColumns,
      recipientsRows,
      options: [
        {
          label: 'Volledig rapport',
          value: 0,
        },
        {
          label: 'Alleen kerngegevens',
          value: 10,
        },
        {
          label: 'Kerngegevens en samenvattingen',
          value: 20,
        },
      ],

      reportType,
      customerAccountName,
      selectedResultsIds,
      searchCriteria,
      templateOptions,
      reportTemplate,
      customerName,
      description,
      reportTitle,
      message,
      address,
      shareSchemePageEmit,
      shareGrantsPage,
      grant,
      requiredFieldRule: [
        (val) => {
          if (recipientsRows.value.length == 0)
            return (val && val.length > 0) || t('common.required_field')
;
        },
      ],
      isValidEmail(val) {
        const emailPattern =
          /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
        if (recipientsRows.value.length == 0)
          return emailPattern.test(val) || t('common.invalid_email_address');
      },
      isUniqueEmail(val) {
        var emailExists = false;
        for (let i = 0; i < recipientsRows.value.length; i++) {
          if (recipientsRows.value[i].email == val) {
            emailExists = true;
            break;
          }
        }
        return emailExists == false || t('news_details.existing_email_validation');
      },

      filterNamesFn(val, update) {
        update(() => {
          if (val && val.length > 0) {
            const needle = val.toLowerCase();
            SuggestionsService.getSuggestions(val /* , userId.value */);

            filterNamesOptions.value = nameOptions.value.filter(
              (v) => v.name.toLowerCase().indexOf(needle) > -1
            );
          } else {
            filterNamesOptions.value = [];
          }
        });
      },
    };
  },
};
</script>
