<template>
  <div class="sidebar-search-container">
    <div class="column sidebar-items">
      <div class="filter-results-title">
        <q-icon size="24px" class="filter-icon">
          <img
            class="svg-icon"
            src="../../assets/icons/filter-list-solid.svg"
          />
        </q-icon>
        <span class="title bold-font">
          {{ t("search_schemes.filter_results") }}</span
        >
      </div>
      <div class="row results-info-row item-width">
        <p class="results-info-p">
          <span class="results-count">
            {{ resultsCount }}
          </span>
          <span class="text-results">{{
            " " + t("search_schemes.results")
          }}</span>
        </p>
      </div>

      <div class="row q-mt-md apply-profile-filters item-width" v-if="isVind">
        <q-toggle
          v-model="isApplyingFilters"
          class="profile-filters-toggle"
          :label="t('search_schemes.apply_profile_filters')"
          checked-icon="check"
          id="v-step-11"
        />
      </div>
      <div class="row reset-btns justify-between">
        <span class="active-filters-count"
          >{{ t("search_schemes.active_filters") + " " }}
          {{ activeFiltersCount }}</span
        >

        <div class="reset-buttons">
          <button class="reset-button" v-on:click="resetSearch(false)">
            <span class="reset-clear">{{ t("search_schemes.reset") }}</span>
          </button>
          <span class="slash"> / </span>

          <button class="reset-button" v-on:click="resetSearch(true)">
            <span class="reset-clear">{{ t("search_schemes.clear") }}</span>
          </button>
        </div>
      </div>

      <q-list class="filters-list">
        <q-expansion-item
          :label="t('search_schemes.status')"
          dense
          dense-toggle
          expand-icon="chevron_right"
          id="v-step-0"
        >
          <FilterCheckBox
            class="sidebar-margin item-width statuses-box white-bg-box"
          />
        </q-expansion-item>
        <q-expansion-item
          :label="t('search_schemes.audience_tags_label')"
          dense
          dense-toggle
          expand-icon="chevron_right"
        >
          <default-tree
            class="sidebar-margin item-width white-bg-box schemes-search"
            style="min-width: 280px; width: 27vw"
            :nodesState="audienceNodes.options"
            :heading="audienceNodes.label"
            :filtering="true"
            :profileSearchFlag="profileSearchFlag"
            :savedTagsString="savedAudiencesString"
            ref="tree"
          >
          </default-tree>
        </q-expansion-item>

        <q-expansion-item
          :label="t('search_schemes.purposes_tags_label')"
          dense
          dense-toggle
          expand-icon="chevron_right"
        >
          <default-tree
            class="sidebar-margin item-width white-bg-box"
            :nodesState="purposesNodes.options"
            :heading="purposesNodes.label"
            :filtering="true"
            :profileSearchFlag="profileSearchFlag"
            :savedTagsString="savedPurposesString"
            ref="tree2"
          />
        </q-expansion-item>

        <q-expansion-item
          :label="t('search_schemes.location_tags_label')"
          dense
          dense-toggle
          expand-icon="chevron_right"
          v-if="!hideLocations"
        >
          <div
            class="sidebar-margin dateselect-box no-margin-left"
            v-if="!hideLocations"
          >
            <div class="row start">
              <q-select
                borderless
                bg-color="white"
                v-model="selectedLocations"
                multiple
                :options="filterLocationsTags"
                option-value="label"
                option-label="label"
                use-input
                :label="
                  t('profile.search_for_location_municipality_or_province')
                "
                input-debounce="0"
                behavior="menu"
                use-chips
                @filter="filterLocationsFn"
                style="width: 100%"
                dense
                ref="locationsSelectInput"
                @update:model-value="setInput()"
                clearable
                class="q-mt-sm"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      {{ t("profile.no_results") }}
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
          </div>
        </q-expansion-item>

        <q-expansion-item
          :label="t('search_schemes.scheme_type_tags_label')"
          dense
          dense-toggle
          expand-icon="chevron_right"
        >
          <default-tree
            class="sidebar-margin item-width schemetype-tree white-bg-box"
            :nodesState="schemeTypesNodes.options"
            :heading="schemeTypesNodes.label"
            :profileSearchFlag="profileSearchFlag"
            :savedTagsString="savedSchemeTypesString"
            ref="tree3"
          />
        </q-expansion-item>

        <!-- <default-tree
      class="sidebar-margin item-width projecttype-tree white-bg-box"
      :nodesState="projectTypesNodes.options"
      :heading="projectTypesNodes.label"
      :profileSearchFlag="profileSearchFlag"
      :savedTagsString="savedProjectTypesString"
      ref="tree4"
    /> -->

        <q-expansion-item
          :label="t('search_schemes.dates_range_label')"
          dense
          dense-toggle
          expand-icon="chevron_right"
        >
          <div class="sidebar-margin dateselect-box no-margin-left">
            <div class="row justify-end">
              <i
                class="las la-2x la-times-circle clear-filter-icon"
                @click="resetDatesRange()"
              >
                <q-tooltip> {{ t("search_schemes.reset_filter") }} </q-tooltip>
              </i>
            </div>

            <q-input
              class="budget-input"
              bg-color="white"
              v-model="daysRangeModel"
              borderless
              dense
              @click="$refs.qDateProxy.show()"
              :update="updateRange()"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer datepicker-icon">
                  <q-popup-proxy
                    ref="qDateProxy"
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      range
                      v-model="days"
                      mask="DD-MM-YYYY"
                      :locale="myLocale"
                    >
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          :label="t('search_schemes.close')"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>
        </q-expansion-item>

        <q-expansion-item
          :label="t('search_schemes.budget')"
          dense
          dense-toggle
          expand-icon="chevron_right"
        >
          <div class="sidebar-margin dateselect-box no-margin-left">
            <div class="row justify-end">
              <i
                class="las la-2x la-times-circle clear-filter-icon"
                @click="resetBudgets()"
              >
                <q-tooltip
                  >{{ t("search_schemes.reset_filter") }}
                </q-tooltip></i
              >
            </div>

            <div class="row start">
              <q-input
                v-model.number="min"
                type="number"
                min="0"
                :label="t('search_schemes.minimum') + ' €'"
                class="budget-input"
                bg-color="white"
                borderless
                dense
              />
            </div>

            <div class="row start">
              <q-input
                borderless
                v-model.number="max"
                type="number"
                min="0"
                :label="t('search_schemes.maximum') + ' €'"
                bg-color="white"
                style="width: 100%"
                dense
              />
            </div>
          </div>
        </q-expansion-item>

        <q-expansion-item
          :label="t('search_schemes.organizations')"
          dense
          dense-toggle
          expand-icon="chevron_right"
        >
          <div class="sidebar-margin dateselect-box no-margin-left">
            <div class="row start orgs-select q-mt-sm">
              <q-select
                borderless
                bg-color="white"
                v-model="organisationsInclude"
                multiple
                :options="filterOrganisationsInclude"
                use-chips
                option-value="name"
                class="budget-input"
                option-label="name"
                :label="t('search_schemes.search_only')"
                use-input
                input-debounce="0"
                behavior="menu"
                @filter="filterOrganisationsIncludeFn"
                dense
                ref="organisationsIncludeSelect"
                @update:model-value="setInput()"
                clearable
              />
            </div>

            <div class="row start orgs-select">
              <q-select
                borderless
                bg-color="white"
                v-model="organisationsExclude"
                multiple
                :options="filterOrganisationsExclude"
                use-chips
                option-value="name"
                style="width: 100%"
                option-label="name"
                :label="t('search_schemes.do_not_search')"
                use-input
                input-debounce="0"
                behavior="menu"
                @filter="filterOrganisationsExcludeFn"
                dense
                ref="organisationsExcludeSelect"
                @update:model-value="setInput()"
                clearable
              />
            </div>
          </div>
        </q-expansion-item>

        <AdvancedFilters class="adv-filters-box" ref="advancedFiltersBox" />
      </q-list>

      <q-dialog v-model="showProfileNotSetWarning" class="profile-popup">
        <q-card>
          <q-card-section class="column">
            <span class="q-ml-sm bold-font popup-text-error">
              {{ t("search_schemes.no_profile_error") }}
            </span>
            <q-icon
              name="error"
              size="32px"
              color="indigo-10"
              class="info popup-icon error-icon q-mt-sm"
            ></q-icon>
          </q-card-section>

          <q-card-actions align="center" class="q-mb-xs">
            <router-link
              :to="{ name: 'UserProfile', params: { tab: 'newsletter' } }"
              style="margin-right: 10px; text-decoration: none"
            >
              <q-btn
                class="orange-btn-filled bold-font q-mr-md q-mb-sm btn-profile"
                :label="t('search_schemes.go_to_profile')"
                no-caps
                dense
                @click="disableShowProfileNotSetWarning()"
              />
            </router-link>
            <q-btn
              :label="t('common.ok')"
              class="orange-btn bold-font q-mr-md q-mb-sm"
              style="width: 60px"
              dense
              @click="disableShowProfileNotSetWarning()"
            />
          </q-card-actions>
        </q-card>
      </q-dialog>

      <q-dialog v-model="showAppliedFiltersInfo" class="profile-popup">
        <q-card>
          <q-card-section class="row">
            <q-icon
              name="info"
              color="indigo-10"
              size="32px"
              class="popup-icon"
            ></q-icon>
            <span class="q-ml-sm bold-font popup-text">
              {{ t("search_schemes.applied_filters_info") }}
            </span>
          </q-card-section>

          <q-card-actions align="right">
            <q-btn
              :label="t('common.ok')"
              class="orange-btn bold-font q-mr-md q-mb-sm"
              style="width: 60px"
              @click="disableShowAppliedFiltersInfo()"
            />
          </q-card-actions>
        </q-card>
      </q-dialog>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { mapState } from "vuex";
import { computed } from "vue";
import store from "../../store/index.js";
import DefaultTree from "./DefaultTree.vue";
import FilterCheckBox from "./StatusesCheckBox.vue";
import AdvancedFilters from "../search-components/AdvancedFilters.vue";
import FiltersService from "../../services/filters.service";
import OrganisationsService from "../../services/organisations.service";
import SchemeUtils from "../../utils/schemes";
import moment from "moment";
import UserService from "../../services/userprofile.service";
import NewsUtils from "../../utils/news";
import { useI18n } from "vue-i18n";

export default {
  components: {
    FilterCheckBox,
    DefaultTree,
    AdvancedFilters,
  },
  setup() {
    const { t, locale } = useI18n();

    const audienceNodes = computed({
      get: () => store.state.advancedSearch.audience,
    });

    const locationsNodes = computed({
      get: () => store.state.advancedSearch.locations,
    });

    const purposesNodes = computed({
      get: () => store.state.advancedSearch.purposes,
    });

    const schemeTypesNodes = computed({
      get: () => store.state.advancedSearch.schemeTypes,
    });

    const projectTypesNodes = computed({
      get: () => store.state.advancedSearch.projectTypes,
    });

    const selectedSchemeTypesNews = computed({
      get: () =>
        store.state.newsSearch.selectedSchemeTypes != ""
          ? store.state.newsSearch.selectedSchemeTypes
          : sessionStorage.getItem("selectedSchemeTypes"),
    });

    onMounted(async () => {
      await FiltersService.getDefaultFilters(locale.value);
      OrganisationsService.getAllOrganisationsWithBasicInfo();

      if (isApplyingFilters.value) {
        store.commit(
          "advancedSearch/updateSelectedAudiences",
          store.state.user.favoriteAudiencesIdsArray
        );
        //This is just hidden for now, don't remove it:
        /*
        store.commit(
          'advancedSearch/updateSelectedIndustries',
          store.state.user.favoriteIndustriesIdsArray
        );
        */
        store.commit(
          "advancedSearch/updateSelectedPurposes",
          store.state.user.favoritePurposesIdsArray
        );
        store.commit(
          "advancedSearch/updateSelectedLocations",
          store.state.user.selectedFavoriteLocations
        );
        store.commit(
          "advancedSearch/updateSelectedSchemeTypes",
          selectedSchemeTypesNews.value
        );
        refreshTrees();
      }
    });

    const locationsOptions = computed({
      get: () => store.state.advancedSearch.locationsOptions,
    });
    const filterLocationsTags = ref(locationsOptions.value);
    const selectedLocations = computed({
      get: () =>
        store.state.advancedSearch.locationsSelectArray &&
        store.state.advancedSearch.locationsSelectArray.length > 0
          ? store.state.advancedSearch.locationsSelectArray
          : JSON.parse(sessionStorage.getItem("locationsSelectArray")),
      set: async (value) => {
        store.commit("advancedSearch/enableFiltersChange");
        store.commit("advancedSearch/updateSelectedLocations", value);
        store.commit("newsSearch/updateSelectedLocations", value);
        if (!isSearchPaused.value) {
          await SchemeUtils.searchSchemes(locale);
          await NewsUtils.searchNewsWithGrantsFilter(locale);
        }
      },
    });

    const showProfileNotSetWarning = computed({
      get: () => store.state.user.showProfileNotSetWarning,
    });

    const showAppliedFiltersInfo = computed({
      get: () => store.state.user.showAppliedFiltersInfo,
    });

    const min = computed({
      get: () =>
        store.state.advancedSearch.minBudget != null
          ? store.state.advancedSearch.minBudget
          : Number(sessionStorage.getItem("minBudget")),
      set: async (value) => {
        store.commit("advancedSearch/enableFiltersChange");
        store.commit("advancedSearch/updateMinBudget", value);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });
    const max = computed({
      get: () =>
        store.state.advancedSearch.maxBudget != null
          ? store.state.advancedSearch.maxBudget
          : Number(sessionStorage.getItem("maxBudget")),
      set: async (value) => {
        store.commit("advancedSearch/enableFiltersChange");
        store.commit("advancedSearch/updateMaxBudget", value);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });

    const startDate = computed({
      get: () => {
        let start =
          store.state.advancedSearch.startDate != null &&
          store.state.advancedSearch.startDate != ""
            ? store.state.advancedSearch.startDate
            : JSON.parse(sessionStorage.getItem("startDate"));
        return start != null && start != "" && start != '""'
          ? moment(start).format("DD-MM-YYYY")
          : null;
      },
      set: async (value) => {
        store.commit("advancedSearch/enableFiltersChange");
        store.commit("advancedSearch/updateStartDate", value);
        if (!isSearchPaused.value) {
          await SchemeUtils.searchSchemes(locale);
          await NewsUtils.searchNewsWithGrantsFilter(locale);
        }
      },
    });

    const endDate = computed({
      get: () => {
        let end =
          store.state.advancedSearch.endDate != null &&
          store.state.advancedSearch.endDate != ""
            ? store.state.advancedSearch.endDate
            : JSON.parse(sessionStorage.getItem("endDate"));
        return end != null && end != "" && end != '""'
          ? moment(end).format("DD-MM-YYYY")
          : null;
      },
      set: async (value) => {
        store.commit("advancedSearch/enableFiltersChange");
        store.commit("advancedSearch/updateEndDate", value);
        if (!isSearchPaused.value) {
          await SchemeUtils.searchSchemes(locale);
          await NewsUtils.searchNewsWithGrantsFilter(locale);
        }
      },
    });

    const allOrganisations = computed({
      get: () => store.state.organisations.organisationsWithBasicInfo,
    });
    const filterOrganisationsInclude = ref(allOrganisations.value);
    const filterOrganisationsExclude = ref(allOrganisations.value);

    const organisationsInclude = computed({
      get: () =>
        store.state.advancedSearch.organisationsInclude &&
        store.state.advancedSearch.organisationsInclude.length > 0
          ? store.state.advancedSearch.organisationsInclude
          : JSON.parse(sessionStorage.getItem("organisationsInclude")),
      set: async (val) => {
        store.commit("advancedSearch/enableFiltersChange");
        store.commit("advancedSearch/updateOrganisationsInclude", val);
        store.commit("newsSearch/updateOrganisationsInclude", val);
        if (!isSearchPaused.value) {
          await SchemeUtils.searchSchemes(locale);
          await NewsUtils.searchNewsWithGrantsFilter(locale);
        }
      },
    });

    const organisationsExclude = computed({
      get: () =>
        store.state.advancedSearch.organisationsExclude &&
        store.state.advancedSearch.organisationsExclude.length > 0
          ? store.state.advancedSearch.organisationsExclude
          : JSON.parse(sessionStorage.getItem("organisationsExclude")),
      set: async (val) => {
        store.commit("advancedSearch/enableFiltersChange");
        store.commit("advancedSearch/updateOrganisationsExclude", val);
        store.commit("newsSearch/updateOrganisationsExclude", val);
        if (!isSearchPaused.value) {
          await SchemeUtils.searchSchemes(locale);
          await NewsUtils.searchNewsWithGrantsFilter(locale);
        }
      },
    });

    const updateSearchedLocations = (needle) => {
      store.commit("advancedSearch/updateSearchedLocations", needle);
    };

    const resultsCount = computed({
      get: () => store.state.advancedSearch.numberOfResults,
    });

    const profileSearchFlag = computed({
      get: () => store.state.user.isProfileSearch,
    });

    const savedAudiencesString = computed({
      get: () => store.state.user.savedAudienceTags,
    });

    const savedPurposesString = computed({
      get: () => store.state.user.savedPurposesTags,
    });

    const savedSchemeTypesString = computed({
      get: () => store.state.user.savedSchemeTypesTags,
    });

    const savedProjectTypesString = computed({
      get: () => store.state.user.savedProjectTypesTags,
    });

    //profile tags
    const favoriteAudience = computed({
      get: () => store.state.user.favoriteAudiencesIdsArray,
    });
    const favoritePurposes = computed({
      get: () => store.state.user.favoritePurposesIdsArray,
    });
    const favoriteLocations = computed({
      get: () => store.state.user.selectedFavoriteLocations,
    });
    //This is just hidden for now, don't remove it
    /*
    const favoriteIndustries = computed({
      get: () => store.state.user.favoriteIndustriesIdsArray,
    });
    */
    const tree = ref(null);
    const tree2 = ref(null);
    const tree3 = ref(null);
    const advancedFiltersBox = ref(null);

    const resetSearch = async (isClear) => {
      store.commit("advancedSearch/updateIsResetSearch", true);
      store.commit("advancedSearch/resetSearchFilter", isClear);
      store.commit("grantItems/clearSelectedResults");
      advancedFiltersBox.value.resetValues();
      resetDatesRange();
      if (!isApplyingFilters.value) {
        tree3.value.resetTree();
        tree.value.resetTree();
        tree2.value.resetTree();
      }
      selectedLocations.value = [];
      if (isClear) {
        UserService.setApplyFilter(false);
        store.commit("user/setIsApplyingFilters", false);
      }

      if (!isClear && isApplyingFilters.value) {
        store.commit("advancedSearch/updateIsApplyingFiltersReset", true);
        //This is just hidden for now, don't remove it
        // if (showAdvancedFilters.value == true) {
        //   advancedFiltersBox.value.resetAndRefreshTree();
        // }
        store.commit(
          "advancedSearch/updateSelectedLocations",
          store.state.user.selectedFavoriteLocations
        );
        refreshTrees();
      }
      store.commit("advancedSearch/updateIsResetSearch", false);
    };

    const days = computed({
      get: () =>
        store.state.advancedSearch.daysRange != null &&
        store.state.advancedSearch.daysRange.from != "" &&
        store.state.advancedSearch.daysRange.to != ""
          ? store.state.advancedSearch.daysRange
          : JSON.parse(sessionStorage.getItem("daysRange")),
      set: async (val) => {
        store.commit("advancedSearch/enableFiltersChange");
        store.commit("advancedSearch/updateDaysRange", val);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      },
    });

    const locationsSelectInput = ref(null);
    const organisationsIncludeSelect = ref(null);
    const organisationsExcludeSelect = ref(null);

    const setInput = () => {
      locationsSelectInput.value.updateInputValue("");
      organisationsIncludeSelect.value.updateInputValue("");
      organisationsExcludeSelect.value.updateInputValue("");
    };

    const daysFrom = computed({
      get: () => {
        let daysRangeSession = JSON.parse(sessionStorage.getItem("daysRange"));
        return store.state.advancedSearch.daysRange.from != ""
          ? store.state.advancedSearch.daysRange.from
          : daysRangeSession != null
          ? daysRangeSession.from
          : "";
      },
    });

    const daysTo = computed({
      get: () => {
        let daysRangeSession = JSON.parse(sessionStorage.getItem("daysRange"));
        return store.state.advancedSearch.daysRange.to != ""
          ? store.state.advancedSearch.daysRange.to
          : daysRangeSession != null
          ? daysRangeSession.to
          : "";
      },
    });

    const daysRangeModel = ref(null);

    const updateRange = () => {
      daysRangeModel.value = [daysFrom.value, daysTo.value].join(" - ");
    };

    const resetSelectedLocations = async () => {
      locationsSelectInput.value.updateInputValue("");
      selectedLocations.value = null;
      store.commit("advancedSearch/updateSelectedLocations", "");
      if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
    };

    const resetDatesRange = async () => {
      daysRangeModel.value = "";
      store.commit("advancedSearch/updateDaysRange", { from: "", to: "" });
      if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
    };

    const resetBudgets = async () => {
      store.commit("advancedSearch/updateMinBudget", null);
      store.commit("advancedSearch/updateMaxBudget", null);
      if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
    };

    watch(selectedLocations, async () => {
      if (selectedLocations.value == null) {
        store.commit("advancedSearch/updateSelectedLocations", null);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      }
    });

    watch(organisationsExclude, async () => {
      if (organisationsExclude.value == null) {
        store.commit("advancedSearch/updateOrganisationsExclude", []);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      }
    });

    watch(organisationsInclude, async () => {
      if (organisationsInclude.value == null) {
        store.commit("advancedSearch/updateOrganisationsInclude", []);
        if (!isSearchPaused.value) await SchemeUtils.searchSchemes(locale);
      }
    });

    const showAdvancedFilters = computed({
      get: () => store.state.advancedFilters.showAdvancedFilters,
    });

    const refreshTrees = async () => {
      tree.value.refreshTree(favoriteAudience.value);
      tree2.value.refreshTree(favoritePurposes.value);
      tree3.value.refreshTree(selectedSchemeTypesNews.value);

      //This is just hidden for now, don't remove it
      // if (showAdvancedFilters.value == true) {
      //   advancedFiltersBox.value.refreshTree();
      // }

      //if (!isSearchPaused.value) await SchemeUtils.searchSchemes();
    };

    const activeFiltersCount = computed({
      get: () => store.state.advancedSearch.activeFiltersCount,
    });

    const activeFiltersLabel = ref(
      t("search_schemes.active_filters") + " " + activeFiltersCount.value
    );

    watch(activeFiltersCount, () => {
      activeFiltersLabel.value =
        t("search_schemes.active_filters") + " " + activeFiltersCount.value;
    });

    const applyProfileFilters = (val) => {
      UserService.setApplyFilter(val);

      if (val) {
        if (
          favoriteAudience.value.length == 0 &&
          favoritePurposes.value.length == 0 &&
          //This is just hidden for now, don't remove it
          //&& favoriteIndustries.value.length == 0
          favoriteLocations.value.length == 0
        ) {
          store.commit("user/enableShowProfileNotSetWarning");
        } else {
          store.commit(
            "advancedSearch/updateSelectedAudiences",
            store.state.user.favoriteAudiencesIdsArray
          );
          //This is just hidden for now, don't remove it
          /*
          store.commit(
            'advancedSearch/updateSelectedIndustries',
            store.state.user.favoriteIndustriesIdsArray
          );
          */
          store.commit(
            "advancedSearch/updateSelectedPurposes",
            store.state.user.favoritePurposesIdsArray
          );
          store.commit(
            "advancedSearch/updateSelectedLocations",
            store.state.user.selectedFavoriteLocations
          );
          store.commit("user/enableShowAppliedFiltersInfo");
        }
      }
    };

    const isApplyingFilters = computed({
      get: () => store.state.user.isApplyingFilters,
      set: (val) => {
        store.commit("advancedSearch/enableFiltersChange");
        store.commit("user/setIsApplyingFilters", val);
        applyProfileFilters(val);
      },
    });

    watch(
      () => isApplyingFilters.value,
      async (next, prev) => {
        if (!prev && next) {
          store.commit(
            "advancedSearch/updateSelectedAudiences",
            store.state.user.favoriteAudiencesIdsArray
          );
          //This is just hidden for now, don't remove it
          /*
          store.commit(
            'advancedSearch/updateSelectedIndustries',
            store.state.user.favoriteIndustriesIdsArray
          );
          */
          store.commit(
            "advancedSearch/updateSelectedPurposes",
            store.state.user.favoritePurposesIdsArray
          );
          store.commit(
            "advancedSearch/updateSelectedLocations",
            store.state.user.selectedFavoriteLocations
          );
          refreshTrees();
        } else if (prev && !next) {
          store.commit("advancedSearch/clearNewsletterFilters");
          tree.value.resetTree();
          tree2.value.resetTree();

          selectedLocations.value = [];
          store.commit("newsSearch/resetSearchFilter");
        }
      }
    );

    const disableShowProfileNotSetWarning = () => {
      store.commit("user/disableShowProfileNotSetWarning");
    };

    const disableShowAppliedFiltersInfo = () => {
      store.commit("user/disableShowAppliedFiltersInfo");
    };

    const isSearchPaused = computed({
      get: () => store.state.advancedSearch.applySavedSearchPause,
    });
    const isVind = computed({
      get: () => store.state.auth.env === 0 || store.state.auth.env === 40,
    });
    const shortList = computed({
      get: () => store.state.advancedSearch.locationsOptions.length < 4,
    });
    const hideLocations = computed({
      get: () => !isVind.value && shortList.value,
    });

    const drawer = ref(true);
    return {
      t,
      locale,
      drawer,
      selectedSchemeTypesNews,
      hideLocations,
      isVind,
      isSearchPaused,
      showProfileNotSetWarning,
      disableShowProfileNotSetWarning,
      isApplyingFilters,
      applyProfileFilters,
      activeFiltersCount,
      activeFiltersLabel,
      resetBudgets,
      resetDatesRange,
      resetSelectedLocations,
      updateRange,
      daysRangeModel,
      daysFrom,
      daysTo,
      organisationsIncludeSelect,
      organisationsExcludeSelect,
      setInput,
      days,
      advancedFiltersBox,
      tree,
      tree2,
      tree3,
      //tree4,
      resetSearch,
      resultsCount,
      filterOrganisationsInclude,
      filterOrganisationsExclude,
      filterLocationsTags,
      locationsOptions,
      selectedLocations,
      startDate,
      endDate,
      store,
      audienceNodes,
      purposesNodes,
      locationsNodes,
      min,
      max,
      organisationsExclude,
      organisationsInclude,
      allOrganisations,
      schemeTypesNodes,
      projectTypesNodes,
      statusOpen: ref(true),
      statusFuture: ref(true),
      statusClosed: ref(false),
      profileSearchFlag,
      savedAudiencesString,
      savedPurposesString,
      savedSchemeTypesString,
      savedProjectTypesString,
      locationsSelectInput,
      favoriteAudience,
      favoritePurposes,
      favoriteLocations,
      //This is just hidden for now, don't remove it
      //favoriteIndustries,
      showAdvancedFilters,
      showAppliedFiltersInfo,
      disableShowAppliedFiltersInfo,
      refreshTrees,
      nodes: computed(() => store),
      computed: {
        ...mapState({
          filterActivities: (state) => state.advancedSearch.filterActivities,
        }),
      },
      methods: {
        updateFilter: function () {
          store.commit("advancedSearch/updateFilterActivities");
        },
        updateTreeData: function () {},
      },
      updateSearchedLocations,
      filterLocationsFn(val, update, abort) {
        if (val.length < 1) {
          abort();
          return;
        }
        update(() => {
          if (val === "") {
            filterLocationsTags.value = locationsOptions.value;
          } else {
            const needle = val.toLowerCase();

            filterLocationsTags.value = locationsOptions.value.filter(
              (v) =>
                v.label.split(/,(.+)/)[0].toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },
      filterOrganisationsIncludeFn(val, update) {
        update(() => {
          if (val === "") {
            filterOrganisationsInclude.value = allOrganisations.value;
          } else {
            const needle = val.toLowerCase();

            filterOrganisationsInclude.value = allOrganisations.value.filter(
              (v) => v.name.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },

      filterOrganisationsExcludeFn(val, update) {
        update(() => {
          if (val === "") {
            filterOrganisationsExclude.value = allOrganisations.value;
          } else {
            const needle = val.toLowerCase();

            filterOrganisationsExclude.value = allOrganisations.value.filter(
              (v) => v.name.toLowerCase().indexOf(needle) > -1
            );
          }
        });
      },

      myLocale: {
        pluralDay: "dagen",
        months:
          "Januari_Februari_Maart_April_Mei_Juni_Juli_Augustus_September_Oktober_November_December".split(
            "_"
          ),
        daysShort: "ma_di_wo_do_vr_za_zo".split("_"),
      },
    };
  },
};
</script>
