<template>
  <div class="public-news-page">
    <!--     Public news page -->
    <form id="form1" runat="server">
      <div class="wrapper">
        <header>
          <img
            src="https://content.vindsubsidies.nl/nieuwsbrief/images/vindsubsidies-header.png"
            width="700"
            height="184"
            alt="Vindsubsidies"
          />
          <div class="header__info">
            <div class="header__socials">
              <a
                href="https://nl.linkedin.com/company/vindsubsidies-nl"
                title="Vindsubsidies LinkedIn"
                style="color: white"
              >
                <img
                  src="https://content.vindsubsidies.nl/nieuwsbrief/images/linkedin.png"
                  width="21"
                  height="21"
                  alt="LinkedIn"
                />
              </a>
              <a
                href="https://twitter.com/vindsubsidies"
                title="Vindsubsidies Twitter"
              >
                <img
                  src="https://content.vindsubsidies.nl/nieuwsbrief/images/twitter.png"
                  width="21"
                  height="21"
                  alt="Twitter"
                />
              </a>
            </div>
          </div>
        </header>
        <main>
          <article class="detail__page">
            <h1 id="h1NewsitemTitle" runat="server">Uitschrijven</h1>

            <div class="row">U bent uitgeschreven voor onze beVINDingen.</div>

            <div class="row">&nbsp;</div>
            <div class="row">&nbsp;</div>
            <div class="row">&nbsp;</div>
            <div class="clear"></div>
          </article>
        </main>
        <footer class="footer-news-details">
          <address>
            <p>
              <strong>Vindsubsidies</strong>
              <br />
              <a
                href="https://www.vindsubsidies.nl"
                class="top-margin"
                style="color: white"
                >www.vindsubsidies.nl</a
              ><br />
              <a href="tel:0882020401" style="color: white">088 - 2020 401</a
              ><br />
              <a href="mailto:info@vindsubsidies.nl" style="color: white"
                >info@vindsubsidies.nl</a
              >
            </p>
          </address>
          <div class="footer__socials">
            <div class="footer-socials__header">
              Social Media
            </div>
            <a
              href="https://nl.linkedin.com/company/vindsubsidies-nl"
              title="Vindsubsidies LinkedIn"
            >
              <img
                src="https://content.vindsubsidies.nl/nieuwsbrief/images/linkedin.png"
                width="21"
                height="21"
                alt="LinkedIn"
              />
            </a>
            <a
              href="https://twitter.com/vindsubsidies"
              title="Vindsubsidies Twitter"
            >
              <img
                src="https://content.vindsubsidies.nl/nieuwsbrief/images/twitter.png"
                width="21"
                height="21"
                alt="Twitter"
              />
            </a>
          </div>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
import { onMounted } from 'vue';
import axios from 'axios';
import axiosUrl from '../services/axios.service';
const API_URL = axiosUrl.getAxiosUrl();

export default {
  name: 'SalesForceUnsubscribe',
  setup() {
    onMounted(() => {
      const urlParams = new URLSearchParams(window.location.search);
      axios
        .get(
          `${API_URL}/News/SfUnsubscribe?code=${urlParams.get('code')}`
        );
    });
  },
};
</script>

<style>
body {
  background: #f1f1f1;
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, 'sans-serif' !important;
  line-height: 1.5;
}

.public-news-page {
  text-align: left !important;
}

.top-margin {
  margin-top: 40px !important;
}

a {
  text-decoration: none;
}

a:hover,
a:focus {
  color: #000000;
  text-decoration: underline;
}

h1 {
  font-weight: 500 !important;
  font-size: 1.866em;
  line-height: 4rem !important;
  color: #000 !important;
}

h2 {
  font-weight: 500;
  font-size: 1.3em;
  margin: 1.3em 0 0;
}

@media screen and (min-width: 30rem) {
  h2 {
    font-size: 1.5em;
    margin: 1.5em 0 0;
  }
}

.button {
  display: inline-block;
  padding: 0.75rem 1.25rem;
  background: #191d96;
  color: #f1f1f1;
  white-space: nowrap;
}

.button:hover,
.button:focus {
  text-decoration: none;
  background: #f1f1f1;
  color: #191d96;
  transition: all 0.2s ease-in-out;
}

div.wrapper {
  position: relative;
  width: 100%;
  max-width: 46.75rem;
  margin: 0 auto;
  background: #ffffff;
}

header img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
}

header .header__info {
  position: relative;
  display: flex;
  justify-content: end;
  flex-flow: column nowrap;
  background: #292929;
  padding: 0 1.5rem;
}

@media screen and (min-width: 30rem) {
  header .header__info {
    flex-flow: row nowrap;
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

header .header__info::before {
  content: '';
  position: absolute;
  z-index: 1;
  top: -0.975rem;
  left: 0;
  right: 0;
  padding-top: 1rem;
  background: #292929;
  -webkit-clip-path: polygon(0 0, 100% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 100%, 0% 100%);
}

header .header__title {
  flex-grow: 1;
  padding: 1rem 0 1rem;
}

@media screen and (min-width: 30rem) {
  header .header__title {
    padding-bottom: 1.25rem;
    min-height: 1.875rem;
  }
}

header .header__title h1 {
  margin: 0;
  color: #ffffff;
  font-size: 1.25em;
}

header .header__title a {
  color: #ffffff;
  font-size: 0.875rem;
}

header .header__title a span.icon {
  font-size: 1.5em;
  line-height: 1;
  margin-right: 0.25rem;
}

header .header__title a:hover,
header .header__title a:focus {
  text-decoration: none;
  color: #191d96;
}

header .header__socials {
  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 0;
  order: -1;
}

@media screen and (min-width: 30rem) {
  header .header__socials {
    order: 0;
    padding: 1.25rem 0;
    margin-left: 0.5rem;
  }
}

header .header__socials a:not(:last-child) {
  margin-right: 1rem;
}

/* main {
  padding: 0.5rem 1.5rem 0.5rem;
} */

@media screen and (min-width: 30rem) {
  main {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

article.news__item a {
  position: relative;
  display: block;
  padding: 1.5rem 0 1.5rem 2rem;
}

@media screen and (min-width: 30rem) {
  article.news__item a {
    padding-left: 2.5rem;
  }
}

article.news__item a::before {
  content: '';
  position: absolute;
  top: 1.75rem;
  left: 0;
  width: 1.5rem;
  height: 0.875rem;
  background: url('/public-news-details/news-item.png') 0 0 no-repeat;
  display: block;
}

article.news__item a {
  color: #000000;
}

article.news__item a:hover,
article.news__item a:focus {
  text-decoration: none;
}

article.news__item a:hover .news__title,
article.news__item a:focus .news__title {
  color: #191d96;
  text-decoration: underline;
}

article.news__item .news__title {
  font-size: 1em;
  font-weight: normal;
  margin-bottom: 0.25rem;
}

article.news__item .news__intro {
  font-size: 0.875em;
  color: #666666;
}

article.news__item:not(:last-child) {
  border-bottom: 0.125rem solid #f1f1f1;
}

article.detail__page {
  padding: 0.5rem 0 2rem;
  font-size: 0.9375em;
}

.detail__footer {
  margin-top: 2rem;
}

.footer-news-details {
  display: flex;
  flex-flow: column nowrap;
  background: #292929;
  color: #ffffff !important;
  padding: 0 1.5rem;
  font-size: 0.875em !important;
}

@media screen and (min-width: 30rem) {
  .footer-news-details {
    flex-direction: row;
    justify-content: space-between;
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

address {
  color: white;
  font-style: normal;
  font-size: 1em;
  font-family: 'Montserrat', 'Helvetica Neue', 'Helvetica', 'Arial',
    'sans-serif' !important;
  display: flex;
  flex-direction: column;
}

address > p {
  margin: 0;
  padding: 16px 0;
}

.footer address,
.footer__socials {
  margin-top: 16px;
}

.footer a {
  color: #ffffff;
  text-decoration: none !important;
}

.footer a:hover,
.footer a:focus {
  color: #191d96;
}

.footer p {
  margin: 0;
}

.footer address {
  flex-grow: 1;
  font-style: normal !important;
}

.footer-socials__header {
  margin-bottom: 0.5rem;
  font-size: 1em !important;
}

.footer__socials a:not(:last-child) {
  margin-right: 1rem;
}

.footer__socials a:hover,
.footer__socials a:focus {
  text-decoration: none;
}

article.news__cancel {
  padding-top: 20px;
}

article.news__cancel a {
  color: #222222;
  font-weight: normal;
  text-decoration: none;
  font-size: 12px;
  font-family: 'Montserrat', 'Helvetica Neue', 'Helvetica', 'Arial',
    'sans-serif' !important;
}
</style>
